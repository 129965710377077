import { useEffect, useState } from 'react';
import { SitePolicyControlController } from 'fragments/site-policies/fragments/site-policy-control/interfaces';
import { useAPISitePolicies } from 'services/site-policies/api-policies.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useSitePoliciesContext } from 'fragments/site-policies/context/site-policies.context';
import { useForm } from 'antd/lib/form/Form';
import { NewSitePolicyDto } from 'services/site-policies/site-policies.service';
import dayjs from 'dayjs';

export const useSitePolicyControlController = (
    policyId: string,
    sitePoliciesService = useAPISitePolicies(),
    messenger = useMessenger(),
): /* <--Dependency Injections  like services hooks */
SitePolicyControlController => {
    const [form] = useForm();
    const { getSitePolicies, isControlModalVisible, setIsControlModalVisible, sitePolicies } = useSitePoliciesContext();

    /* State */
    const [observation, setObservation] = useState<string>('');
    const [rejecting, setRejecting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [policy, setPolicy] = useState<NewSitePolicyDto | undefined>(
        sitePolicies.find((policy) => policy.id == policyId),
    );

    /* Listeners */
    useEffect(() => {
        const policy = sitePolicies.find((policy) => policy.id == policyId);
        setPolicy(policy);
        if (
            policy &&
            !dayjs(policy.expires_at).isSameOrBefore('1970-01-01', 'year') &&
            dayjs(policy.expires_at).isSameOrBefore(dayjs.utc(), 'minute')
        ) {
            setIsExpired(true);
        }
    }, [policyId]);

    /* View Events */
    const onCancelButtonPressed = () => {
        setIsControlModalVisible(false);
        setRejecting(false);
        setObservation('');
        form.resetFields();
    };
    const onAddObservation = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setObservation(e.target.value);
    };
    const onApprovePolicyButtonPressed = () => {
        setIsLoading(true);
        sitePoliciesService
            .approveSitePolicy(policyId)
            .then(() => {
                messenger.showSuccessMessage({ key: 'site-policies.approve-success' });
                getSitePolicies({});
                setIsControlModalVisible(false);
                form.resetFields();
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'site-policies.approve-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const onRejectPolicyButtonPressed = () => {
        setRejecting(true);
    };
    const onFinish = () => {
        setIsLoading(true);
        sitePoliciesService
            .sendSitePolicyToReview(policyId, observation)
            .then(() => {
                messenger.showSuccessMessage({ key: 'site-policies.sended-for-review-notification' });
                getSitePolicies({});
                setRejecting(false);
                setObservation('');
                form.resetFields();
                setIsControlModalVisible(false);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'site-policies.reject-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    /* Private Methods */

    // Return state and events
    return {
        form,
        isControlModalVisible,
        rejecting,
        isLoading,
        observation,
        policy,
        isExpired,
        onApprovePolicyButtonPressed,
        onRejectPolicyButtonPressed,
        onAddObservation,
        onCancelButtonPressed,
        onFinish,
    };
};
