import { SorterResult } from 'antd/lib/table/interface';
import { ITag, OrderTable, PaginationRequest, SorterInfo } from 'global/interfaces';
import React, { useContext } from 'react';
import { KeyDto, SearchKeysInterface } from 'services/keys/keys.service';
import { LockDto } from 'services/locks/locks.service';
// import { CreateKeySummaryDto } from '../fragments/create-key-summary/interfaces';
export interface GetAccessKeysInterface {
    pagination?: PaginationRequest;
    orderTable?: OrderTable;
    search?: SearchKeysInterface;
}
export interface KeyContextInterface {
    isLoadingTable: boolean;
    // creationSummary: CreateKeySummaryDto[];
    keys: KeyDto[];
    totalKeys?: number;
    currentPage: number;
    pageSize: number;
    assignedTo: string[];
    authorizer: string[];
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    isFilterModalVisible: boolean;
    selectedLockIds: string[];
    dateRange: { from: string; to: string };
    selectedKeyStatusOptions: number[];
    sortedInfo: SorterResult<SorterInfo>;
    isLoadingLocks: boolean;
    locks: LockDto[];
    tags: ITag[];

    setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
    setSelectedKeyStatusOptions: React.Dispatch<React.SetStateAction<number[]>>;
    setDateRange: React.Dispatch<React.SetStateAction<{ from: string; to: string }>>;
    setSelectedLockIds: React.Dispatch<React.SetStateAction<string[]>>;
    setIsFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    // setCreationSummary: React.Dispatch<React.SetStateAction<CreateKeySummaryDto[]>>;
    setKeys: React.Dispatch<React.SetStateAction<KeyDto[]>>;
    setTotalKeys: React.Dispatch<React.SetStateAction<number | undefined>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setAssignedTo: React.Dispatch<React.SetStateAction<string[]>>;
    setAuthorizer: React.Dispatch<React.SetStateAction<string[]>>;

    setIsLoadingTable: React.Dispatch<React.SetStateAction<boolean>>;
    setSortedInfo: React.Dispatch<React.SetStateAction<SorterResult<SorterInfo>>>;
    //________________Functions___________
    getKeys: (payload: GetAccessKeysInterface) => void;
    getLocks: () => void;
}

export const KeyContext = React.createContext<KeyContextInterface>({
    isLoadingTable: false,
    isLoadingLocks: false,
    isFilterModalVisible: false,
    locks: [],
    keys: [],
    assignedTo: [],
    authorizer: [],
    selectedKeyStatusOptions: [],
    tags: [],
    currentPage: 0,
    pageSize: 5,
    orderBy: undefined,
    order: undefined,
    selectedLockIds: [],
    dateRange: { from: '', to: '' },
    sortedInfo: { order: undefined, columnKey: undefined },
    setTags: () => {
        /* Nothing to do here*/
    },
    setSelectedKeyStatusOptions: () => {
        /* Nothing to do here*/
    },
    setDateRange: () => {
        /* Nothing to do here*/
    },
    setAuthorizer: () => {
        /* Nothing to do here*/
    },
    setSelectedLockIds: () => {
        /* Nothing to do here*/
    },
    setOrder: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setTotalKeys: () => {
        /* Nothing to do here*/
    },
    setIsFilterModalVisible: () => {
        /* Nothing to do here*/
    },
    // setCreationSummary: () => {
    //     /* Nothing to do here*/
    // },
    setKeys: () => {
        /* Nothing to do here*/
    },
    setAssignedTo: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setIsLoadingTable: () => {
        /* Nothing to do here*/
    },
    setSortedInfo: () => {
        /* Nothing to do here*/
    },
    //__________Functions__________
    getKeys: () => {
        /* Nothing to do here*/
    },
    getLocks: () => {
        /* Nothing to do here*/
    },
});

export const useKeysContext = (): KeyContextInterface => {
    const store = useContext(KeyContext);
    return store;
};
