import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ButtonSvg = () => (
    <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 768 768"
        stroke="currentColor"
        fill="currentColor"
    >
        <title>iconos_accefy_sga</title>
        <path d="M243.88,367.52v-122c0-47,37.87-85.58,85.58-86.28A86.21,86.21,0,0,1,415,245.47v80.66c13.33,1.41,26,4.92,37.18,11.93,28.76-30.87,47-71.55,47-117.15,0-93.29-76.46-169.75-169.75-169.75-94,0-169.76,76.46-169.76,169.75a169.46,169.46,0,0,0,84.18,146.61Z" />
        <path d="M609.34,458.71a42.82,42.82,0,0,0-79.27-22.45v-1.41a42.82,42.82,0,0,0-79.27-22.44v-.7A42.51,42.51,0,0,0,408,368.92a42.08,42.08,0,0,0-36.48,20.34V244.76c0-11.23-4.2-22.45-12.62-30.16S340,202,328.76,202A42.51,42.51,0,0,0,286,244.76V509.21c0,2.8-1.4,4.21-3.51,5.61-2.1.7-4.91.7-6.31-1.4l-43.49-43.49A42.62,42.62,0,0,0,178,465,46.38,46.38,0,0,0,159,494.48c-1.41,11.92,1.4,24.55,9.82,33.67l108,130.47A159.87,159.87,0,0,0,400.3,716.84h68a141.3,141.3,0,0,0,141-141Z" />
    </svg>
);
const ButtonIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={ButtonSvg} {...props} />;
export default ButtonIcon;
