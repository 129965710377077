import { useEffect, useReducer, useState } from 'react';
import {
    OperationListActionTypes,
    OperationListController,
    OperationListState,
    OperationTableRow,
    ReducerAction,
} from 'fragments/operations/fragments/operation-list/interfaces';
import { useOperationContext } from 'fragments/operations/context/operations.context';
import { TablePaginationConfig } from 'antd';
import dayjs from 'dayjs';
import { OperationCommand, OperationDto } from 'services/operations/operations.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useAPIOperations } from 'services/operations/api-operations.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { ErrorDto } from 'services/dtos/errors..dto';
import { EventTypes, SseEvent } from 'services/sse/sse.service';
import { useNavigate } from 'react-router';

export const useOperationListController = (): /* <--Dependency Injections  like services hooks */
OperationListController => {
    const { translate } = useTranslator();
    const [session] = useLocalSession();
    const operationsService = useAPIOperations();
    const { selectedSite } = session;
    const messenger = useMessenger();
    const navigate = useNavigate();

    /* State */
    const [eventError, setEventError] = useState<boolean>(false);
    const {
        operations,
        isTableLoading,
        totalOperations,
        pageSize,
        currentPage,
        sortedInfo,
        order,
        orderBy,
        isLoadingOperationCommand,
        activeOperation,
        getOperations,
        setOperations,
        setSortedInfo,
        setIsLoadingOperationCommand,
        setIsLoadingActions,
        setIsNewOperationModalVisible,
        setTotalOperations,
        operationEventSource,
        setOperationEventSource,
    } = useOperationContext();

    /* Listeners */
    useEffect(() => {
        setEventError(false);
        dispatch({ type: OperationListActionTypes.SET_INITIAL_STATE, payload: [] });
        setOperations([]);
        setTotalOperations(0);
        setOperationEventSource(undefined);
        if (session.token && selectedSite) {
            getOperations({
                pagination: { page: 0, pageSize },
                orderTable: { order: undefined, orderBy: undefined },
            });
        }
    }, [selectedSite]);

    useEffect(() => {
        if (operationEventSource) {
            operationEventSource.onerror = (e: any) => {
                setEventError(true);
                operationEventSource.close();
            };
            operationEventSource.onmessage = (event) => {
                const eventData: SseEvent<{ status_id?: number }> = JSON.parse(event.data);
                if (eventData.event_type !== EventTypes.EVENT_HEARTBEAT) {
                    dispatch({ type: OperationListActionTypes.HANDLE_EVENT, payload: eventData });
                }
            };
        }
    }, [operationEventSource]);

    useEffect(() => {
        dispatch({ type: OperationListActionTypes.SET_INITIAL_STATE, payload: operations });
    }, [operations]);

    const reducer: React.Reducer<OperationListState, ReducerAction> = (state, { type, payload }) => {
        switch (type) {
            case OperationListActionTypes.SET_INITIAL_STATE:
                return {
                    operations: payload,
                    events: [],
                };
            case OperationListActionTypes.HANDLE_EVENT: {
                let operations = state.operations;
                if (activeOperation) {
                    switch (payload.event_type) {
                        case EventTypes.EVENT_OPERATION_ACTION_CARD_STATUS_CHANGED: {
                            const { status_id } = payload.extended_info;
                            if (status_id && (status_id == 7 || status_id == 8 || status_id == 9)) {
                                operations = operations.map((o) => {
                                    return o.id == activeOperation.id ? { ...o, count: o.count + 1 } : o;
                                });
                            }
                            break;
                        }
                        case EventTypes.EVENT_OPERATION_CARD_CREATION_STOP_STARTED:
                        case EventTypes.EVENT_OPERATION_CARD_STOP_SUCCEEDED:
                        case EventTypes.EVENT_OPERATION_CARD_CREATION_SUCCEEDED:
                        case EventTypes.EVENT_OPERATION_CARD_CREATION_FAILED: {
                            const { status_id } = payload.extended_info;
                            operations = operations.map((o) =>
                                o.id == payload.sub_event_stream_id ? { ...o, status_id: status_id || o.status_id } : o,
                            );
                            if (status_id != 3) {
                                operationEventSource?.close();
                                getOperations({
                                    pagination: { page: currentPage, pageSize },
                                    orderTable: { order, orderBy },
                                });
                            }
                            break;
                        }
                        case EventTypes.EVENT_OPERATION_CARD_FAILED:
                        case EventTypes.EVENT_OPERATION_CARD_STOP_FAILED:
                        case EventTypes.EVENT_OPERATION_CARD_START_FAILED:
                        case EventTypes.EVENT_OPERATION_CARD_RETRY_FAILED:
                        case EventTypes.EVENT_OPERATION_UNHANDLED: {
                            messenger.showErrorMessage({ key: `operations.event-type-error.${payload.event_type}` });
                            operationEventSource?.close();
                            getOperations({
                                pagination: { page: currentPage, pageSize },
                                orderTable: { order, orderBy },
                            });
                            break;
                        }
                        default:
                            break;
                    }
                }

                return {
                    ...state,
                    operations,
                    events: [...state.events, payload],
                };
            }
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(reducer, {
        operations: [],
        events: [],
    });

    /* View Events */
    const getOperationCommand = (statusId: number): OperationCommand => {
        switch (statusId) {
            case 1:
                return OperationCommand.START;
            case 2:
                return OperationCommand.STOP;
            case 4:
                return OperationCommand.RESUME;
            case 6:
                return OperationCommand.RETRY;
            default:
                return OperationCommand.INVALID;
        }
    };

    const onViewOperationActions = (id: string) => {
        setIsLoadingActions(true);
        navigate('/operations/actions', { state: id });
    };

    const onReloadButtonPressed = () => {
        setEventError(false);
        getOperations({
            pagination: { page: 0, pageSize },
            orderTable: { order: undefined, orderBy: undefined },
        });
    };
    const onAddNewOperationButtonPressed = () => {
        setIsNewOperationModalVisible(true);
    };

    const onCommandButtonPressed = (id: string, status: number) => {
        const isRunningOperation = !!operations.find((op) => op.id == id && (op.status_id == 2 || op.status_id == 3));

        setIsLoadingOperationCommand(true);
        operationsService
            .operationsCommand(id, getOperationCommand(status))
            .then(() => {
                if (!isRunningOperation && !operationEventSource) {
                    getOperations({
                        pagination: { page: currentPage, pageSize },
                        orderTable: { order, orderBy },
                    });
                } else {
                    setIsLoadingOperationCommand(false);
                }
            })
            .catch((error: ErrorDto) => {
                const err = error.response.data;
                if (err.code == 409) {
                    if (err.msg == 'no_actions') {
                        getOperations({
                            pagination: { page: currentPage, pageSize },
                            orderTable: { order, orderBy },
                        });
                    }
                    console.log(err);
                }
                messenger.showErrorMessage({ key: 'operations.command-error' });
                setIsLoadingOperationCommand(false);
            });
    };

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.column?.key,
        });
        getOperations({
            pagination: { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            orderTable: {
                order: sorter.order,
                orderBy: sorter.column?.key,
            },
        });
    };

    /* Private Methods */
    const mapDtoToTableRow = (dto: OperationDto): OperationTableRow => {
        const viewModelObject = {
            id: dto.id,
            name: dto.name,
            createdByEmail: dto.created_by_email,
            type: translate({ key: `operations.type.${dto.type_id}` }),
            statusId: dto.status_id,
            createdAt: `${dayjs.utc(dto.created_at).format('DD/MM/YYYY HH:mm')} ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
            count: {
                finished: dto.count,
                total: dto.total_count,
            },
            updatedByEmail: dto.updated_by_email,
            updatedAt: `${dayjs.utc(dto.updated_at).format('DD/MM/YYYY HH:mm')} ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
        };
        return viewModelObject;
    };
    // Return state and events
    return {
        isTableLoading,
        handleTableChange,
        itemsViewModelTable: state.operations.map(mapDtoToTableRow),
        totalOperations,
        pageSize,
        currentPage,
        sortedInfo,
        isLoadingOperationCommand,
        onAddNewOperationButtonPressed,
        onViewOperationActions,
        onCommandButtonPressed,
        eventError,
        onReloadButtonPressed,
    };
};
