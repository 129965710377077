import React from 'react';
import 'fragments/site-policies/fragments/site-policy-action-list/site-policy-action-list.scss';
import { SitePolicyActionListFragmentProps } from 'fragments/site-policies/fragments/site-policy-action-list/interfaces';
import { useSitePolicyActionListController } from 'fragments/site-policies/fragments/site-policy-action-list/site-policy-action-list.controller';
import { Skeleton, Timeline, Typography } from 'antd';
import { UserAddOutlined, SyncOutlined } from '@ant-design/icons';
import KeyBtIcon from 'assets/icons/key-bt-icon';
const { Text } = Typography;

export const SitePolicyActionListFragment: React.FC<SitePolicyActionListFragmentProps> = (props) => {
    const { useController = useSitePolicyActionListController, policyId } = props;
    const controller = useController(policyId);
    //aca se define la disposicion de las columnas y se divide la data en una a tres columnas
    const sections = controller.actions.length < 5 ? 1 : controller.actions.length < 9 ? 2 : 3;
    const slice = Math.ceil(controller.actions.length / sections);
    const actionSections = [];
    for (let i = 0; i < sections; i += 1) {
        actionSections.push(controller.actions.slice(i * slice, (i + 1) * slice));
    }
    //___________________

    const getIconFromActionName = (name: string, extra?: string) => {
        switch (name) {
            case 'generar llave':
                return <KeyBtIcon style={{ width: '15px', height: '15px', color: '#108ee9' }} />;
            case 'asignar rol':
                if (extra === 'full') {
                    return <UserAddOutlined style={{ color: '#D48806' }} />;
                }
                return <UserAddOutlined style={{ color: '#777' }} />;
            case 'restablecer cerradura':
                return <SyncOutlined style={{ color: '#108ee9' }} />;
            default:
                return <></>;
        }
    };
    // Render
    return (
        <div className={'site-policy-action-list'} style={{ width: '100%', paddingTop: '0px' }}>
            {controller.isLoading ? (
                <>
                    <Skeleton.Input active size="small" block />
                    <Skeleton.Input active size="small" block />
                    <Skeleton.Input active size="small" block />
                </>
            ) : (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    {actionSections.map((actions, index) => (
                        <Timeline
                            key={index}
                            style={{ marginTop: '1rem', width: '100%' }}
                            items={actions.map((action) => {
                                const values = action.values;
                                return {
                                    key: action.position,
                                    style: { paddingBottom: '10px', gap: '1rem' },
                                    children: (
                                        <>
                                            <Text strong style={{ paddingRight: '6px' }}>
                                                {`${action.position + 1})`}
                                            </Text>
                                            {getIconFromActionName(action.name, values[0])}
                                            <Text strong style={{ paddingLeft: '6px' }}>
                                                {action.name === 'asignar rol' ? ` - ${values[1]}` : ` - ${values[0]}`}
                                            </Text>
                                        </>
                                    ),
                                };
                            })}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
