import { useState, useEffect } from 'react';
import { KeysDashboardController, KeysTableRow } from 'fragments/keys-dashboard/interfaces';
import { useAPIKeys } from 'services/keys/api-keys.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { KeyDto, KeyScheduleOption } from 'services/keys/keys.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
import { useKeysContext } from 'fragments/keys-dashboard/context/keys.context';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { capitalize } from 'tools/string-handling';
import { TablePaginationConfig } from 'antd';
dayjs.extend(utc);
// dayjs.extend(timezone);

export const useKeysDashboardController = (
    keyService = useAPIKeys(),
    { translate } = useTranslator(),
    messenger = useMessenger(),
): KeysDashboardController => {
    /* State */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreateKeyModalVisible, setIsCreateKeyModalVisible] = useState<boolean>(false);
    const [session] = useLocalSession();
    const {
        sortedInfo,
        setSortedInfo,
        setIsFilterModalVisible,
        keys,
        totalKeys,
        setCurrentPage,
        setPageSize,
        pageSize,
        currentPage,
        setOrder,
        setOrderBy,
        isLoadingTable,
        authorizer,
        setAuthorizer,
        assignedTo,
        setAssignedTo,
        selectedLockIds,
        setSelectedLockIds,
        selectedKeyStatusOptions,
        setSelectedKeyStatusOptions,
        dateRange,
        setDateRange,
        getKeys,
    } = useKeysContext();
    const { selectedSite } = session;

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            resetStates();
            getKeys({
                pagination: { page: 0, pageSize },
                orderTable: { order: undefined, orderBy: undefined },
                search: {
                    locks: selectedLockIds,
                    since: '',
                    until: '',
                    assignedTo: [],
                    authorizer: [],
                    status: [],
                },
            });
        }
    }, [selectedSite]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}

    const onFinishKeyCreation = () => {
        console.log('finish_refresh');
        resetStates();
        getKeys({ pagination: { page: 0, pageSize }, orderTable: { order: undefined, orderBy: undefined } });
    };
    const onConfirmDeleteKey = (keyId: string | number) => {
        setIsLoading(true);
        keyService
            .deleteKey(keyId)
            .then(() => {
                messenger.showSuccessMessage({ key: 'key.delete-success' });
                getKeys({
                    search: {
                        locks: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        assignedTo,
                        authorizer,
                        status: selectedKeyStatusOptions,
                    },
                });
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'key.delete-error' });
                console.log('key-delete-error', error);
            });
        setIsLoading(false);
    };

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.column?.key,
        });
        getKeys({
            pagination: { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            orderTable: {
                order: sorter.order,
                orderBy: sorter.column?.key,
            },
            search: {
                locks: selectedLockIds,
                since: dateRange.from,
                until: dateRange.to,
                assignedTo,
                authorizer,
                status: selectedKeyStatusOptions,
            },
        });
    };

    const onAddKeyButtonPressed = () => {
        setIsCreateKeyModalVisible(true);
    };
    const onCloseCreateKey = () => {
        setIsCreateKeyModalVisible(false);
    };
    const onFilterButtonPressed = () => {
        setIsFilterModalVisible(true);
    };

    /* Private Methods */
    const resetStates = () => {
        setAuthorizer([]);
        setAssignedTo([]);
        setSelectedLockIds([]);
        setSelectedKeyStatusOptions([]);
        setDateRange({ from: '', to: '' });
        setCurrentPage(0);
        setPageSize(10);
        setSortedInfo({
            order: undefined,
            columnKey: undefined,
        });
        setOrder(undefined);
        setOrderBy(undefined);
    };

    const mapDtoToTableRow = (dto: KeyDto): KeysTableRow => {
        const viewModelObject = {
            id: dto.id,
            userName: capitalize(dto.assigned_to_name),
            userEmail: dto.assigned_to_email,
            lockName: capitalize(dto.lock_name),
            validity: {
                from: dayjs.utc(dto.nbf).format('DD/MM/YYYY'),
                to: dayjs.utc(dto.expires_at).format('DD/MM/YYYY'),
                color: getValidityColor(dto.nbf, dto.expires_at, dto.status_id, dto.state_id).color,
                tooltip: getValidityColor(dto.nbf, dto.expires_at, dto.status_id, dto.state_id).tooltip,
            },

            lockStatusId: dto.lock_status_id,
            deleteDisabled: dto.state_id === 0,
            state: {
                label: getKeyState(dto.state_id),
                id: dto.state_id,
            },
            authorizer: capitalize(dto.created_by_name),
            authorizerEmail: dto.created_by_email,
            createdAt: `${dayjs(dto.issued_at).format('DD/MM/YYYY')}  ${dayjs(dto.issued_at).format('HH:mm')}  ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
            dow: getKeySchedules(dto.dow),
            time: formatTime(dto.nbf, dto.expires_at),
        };
        return viewModelObject;
    };

    const formatTime = (from: string, to: string) => {
        return `De ${from.substring(11, 16)} a ${to.substring(11, 16)} hs. ${
            selectedSite ? '(' + selectedSite.time_zone + ')' : ''
        }`;
    };

    const getValidityColor = (
        from: string,
        to: string,
        status: number,
        state: number,
    ): { tooltip?: string; color: string } => {
        if (state == 2 && status === 1) {
            return dayjs(from) > dayjs()
                ? { color: 'success', tooltip: translate({ key: 'key.tooltips.not-yet-valid-key' }) }
                : { color: 'success', tooltip: translate({ key: 'key.tooltips.valid-key' }) };
        }
        if (state == 1 && status === 7) {
            return { color: 'warning', tooltip: translate({ key: 'key.tooltips.stand-by-key' }) };
        }
        if (state == 0) {
            switch (status) {
                case 4:
                    return { color: 'red', tooltip: translate({ key: 'key.tooltips.rejected-key' }) };
                case 5:
                    return { color: 'red', tooltip: translate({ key: 'key.tooltips.deleted-key' }) };
                case 6:
                    return { color: 'red', tooltip: translate({ key: 'key.tooltips.overwrited-key' }) }; //aun no implementado este valor
                default:
                    if (dayjs(to) < dayjs()) {
                        return {
                            color: 'red',
                            tooltip: translate({ key: 'key.tooltips.expired-key' }),
                        };
                    } else {
                        return { color: 'error' };
                    }
            }
        }
        return { color: 'error' };
    };

    const getKeyState = (state_id: number): string => {
        if (state_id == 2) {
            return translate({ key: 'key.available' });
        }
        if (state_id == 1) {
            return translate({ key: 'key.stand-by' });
        }
        return translate({ key: 'key.unavailable' });
    };

    const getKeySchedules = (dow: number): KeyScheduleOption[] => {
        const defaults = { selected: false, disabled: false };
        const defaultKeySchedules = [
            { key: 1, label: translate({ key: 'general.monday' }), ...defaults },
            { key: 2, label: translate({ key: 'general.tuesday' }), ...defaults },
            { key: 4, label: translate({ key: 'general.wednesday' }), ...defaults },
            { key: 8, label: translate({ key: 'general.thursday' }), ...defaults },
            { key: 16, label: translate({ key: 'general.friday' }), ...defaults },
            { key: 32, label: translate({ key: 'general.saturday' }), ...defaults },
            { key: 64, label: translate({ key: 'general.sunday' }), ...defaults },
        ];
        for (let i = dow, j = 0; i >= 1; j++) {
            if (i % 2 === 1) {
                defaultKeySchedules[j].selected = true;
            }
            i = Math.floor(i / 2);
        }
        const temp = defaultKeySchedules.pop();
        defaultKeySchedules.unshift(temp!);
        return defaultKeySchedules;
    };

    // Return state and events
    return {
        isLoading,
        isLoadingTable,
        keys,
        totalKeys,
        pageSize,
        currentPage,
        sortedInfo,
        isCreateKeyModalVisible,
        onFinishKeyCreation,
        onCloseCreateKey,
        onAddKeyButtonPressed,
        onFilterButtonPressed,
        onConfirmDeleteKey,
        handleTableChange,
        itemsViewModelTable: keys.map(mapDtoToTableRow),
    };
};
