import React from 'react';
import 'fragments/site-policies/fragments/filter-site-policies-modal/filter-site-policies-modal.scss';
import { FilterSitePoliciesModalFragmentProps } from 'fragments/site-policies/fragments/filter-site-policies-modal/interfaces';
import { useFilterSitePoliciesModalController } from 'fragments/site-policies/fragments/filter-site-policies-modal/filter-site-policies-modal.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Select, Switch, Tag, Typography } from 'antd';
import { ITag } from 'global/interfaces';
const { Option } = Select;
const { Text, Title } = Typography;
const CheckboxGroup = Checkbox.Group;

export const FilterSitePoliciesModalFragment: React.FC<FilterSitePoliciesModalFragmentProps> = (props) => {
    const { useController = useFilterSitePoliciesModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));

    const forMap = (tag: ITag) => {
        const tagElem = (
            <Tag
                closable={controller.isTableLoading ? false : tag.closable}
                onClose={(e) => {
                    e.preventDefault();
                    controller.onCloseTag(tag);
                }}
                color={tag.color}
                style={{ fontWeight: 'bold' }}
            >
                <Text strong>{tag.name}: </Text> {tag.value}
            </Tag>
        );
        return (
            <span key={tag.id} style={{ display: 'inline-block', marginBottom: '2px' }}>
                {tagElem}
            </span>
        );
    };
    const tagChild = controller.tags.map(forMap);

    // Render
    return (
        <>
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#f2f2f2',
                    padding: '10px',
                }}
            >
                <Text strong>{translate({ key: 'general.filters' })}:</Text>

                <div className="filter-tags"> {tagChild} </div>
            </div>{' '}
            <Modal
                forceRender
                open={controller.isFilterModalVisible}
                destroyOnClose={true}
                title={
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'Busqueda y filtrado de politicas de sitio' })}
                    </Title>
                }
                className={'filter-site-policies-modal'}
                closable={false}
                centered
                footer={[
                    <Button
                        form="filrter-site-policies-form"
                        key="reset"
                        className={'button primary'}
                        style={{ marginRight: 'auto' }}
                        // loading={controller.isLoading}
                        onClick={controller.onResetButtonPressed}
                    >
                        {translate({ key: 'general.reset' })}
                    </Button>,
                    <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                        {translate({ key: 'general.cancel' })}
                    </Button>,
                    <Button
                        form="filrter-site-policies-form"
                        key="submit"
                        type="primary"
                        className={'button primary'}
                        // loading={controller.isLoading}
                        htmlType="submit"
                        // disabled={controller.submitDisabled}
                    >
                        {translate({ key: 'general.search' })}
                    </Button>,
                ]}
            >
                <Form
                    id="filrter-site-policies-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    size="middle"
                    className={'form'}
                    form={controller.form}
                    onFinish={controller.onSubmitButtonPressed}
                >
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.lock' })}
                            </Text>
                        }
                        name="lock_ids"
                        initialValue={controller.selectedLockIds}
                    >
                        <Select
                            placeholder={translate({ key: 'form.placeholder.lock.name' })}
                            mode="multiple"
                            allowClear
                            // loading={controller.isLoadingLocks}
                            value={controller.selectedLockIds}
                            onChange={controller.onLockSelect}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                const opt = controller.locks.find((item) => item.id == option?.value);
                                return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                            }}
                        >
                            {optionLocks}
                        </Select>
                    </Form.Item>
                    {/* {controller.tempLockIds.length > 1 && (
                        <div>
                            <Switch
                                size="small"
                                defaultChecked={controller.operatorSwitch}
                                onChange={controller.onOperatorSwitchChange}
                            />
                            <Text type="secondary" strong style={{ paddingLeft: '10px' }}>
                                {controller.operatorSwitch
                                    ? 'Debe incluir todas las cerraduras seleccionadas.'
                                    : 'Debe incluir al menos una de las cerraduras seleccionadas.'}
                            </Text>
                        </div>
                    )} */}
                    <Divider style={{ margin: '8px 0' }} />
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.name' })}
                            </Text>
                        }
                        name="name"
                        initialValue={controller.searchInput}
                    >
                        <Input
                            placeholder={translate({ key: 'label.name' })}
                            onChange={controller.onSearchInputChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.state' })}
                            </Text>
                        }
                        name="state"
                        required
                        rules={[
                            {
                                // required: !controller.tempStatusIds.length ? true : false,
                                message: translate({ key: 'form.select-an-option-error' }),
                            },
                        ]}
                    >
                        <div>
                            <Checkbox
                                indeterminate={controller.indeterminate}
                                onChange={controller.onCheckAllStatusOptions}
                                checked={controller.checkAll}
                            >
                                Seleccionar todos
                            </Checkbox>
                            <Divider style={{ margin: '5px' }} />
                            <CheckboxGroup
                                options={controller.statusOptions}
                                value={controller.tempStatusIds}
                                onChange={controller.onCheckStatusOptions}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
