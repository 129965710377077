import React from 'react';
import 'fragments/site-cards-dashboard/fragments/edit-site-card-modal/edit-site-card-modal.scss';
import { EditSiteCardModalFragmentProps } from 'fragments/site-cards-dashboard/fragments/edit-site-card-modal/interfaces';
import { useEditSiteCardModalController } from 'fragments/site-cards-dashboard/fragments/edit-site-card-modal/edit-site-card-modal.controller';
import { Button, Input, Modal, Form, Spin, Select, DatePicker } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { LoadingOutlined } from '@ant-design/icons';
import { SiteCardCustomField } from 'services/rfidcards/rfidcards.service';
import dayjs from 'dayjs';
import DebounceSelect from 'components/debounce-select/debounce-select.component';
const { Option } = Select;

export const EditSiteCardModalFragment: React.FC<EditSiteCardModalFragmentProps> = (props) => {
    const { useController = useEditSiteCardModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const mapFields = (fields: SiteCardCustomField[]) => {
        const fieldInputs = fields.map((f) => {
            switch (f.type_id) {
                case 1:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            required={!f.optional}
                            rules={[
                                {
                                    required: !f.optional,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            initialValue={f.value}
                        >
                            <Input
                                disabled={controller.isLoading}
                                placeholder={f.name}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                maxLength={500}
                            />
                        </Form.Item>
                    );
                case 2:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            required={!f.optional}
                            rules={[
                                {
                                    required: !f.optional,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            initialValue={f.value ? dayjs(f.value) : undefined}
                        >
                            <DatePicker
                                disabled={controller.isLoading}
                                format="YYYY-MM-DD HH:mm"
                                showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                            />
                        </Form.Item>
                    );
                case 3:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            required={!f.optional}
                            rules={[
                                {
                                    required: !f.optional,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            initialValue={f.value ? f.value : []}
                        >
                            <DebounceSelect
                                mode="tags"
                                placeholder={f.name}
                                fetchOptions={(e) => controller.autocompleteTags(e, f.id)}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{ width: '100%' }}
                                value={{ label: f.value, value: f.value }}
                                tokenSeparators={[',']}
                            />
                        </Form.Item>
                    );
                case 4:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            required={!f.optional}
                            name={f.name}
                            rules={[
                                {
                                    required: !f.optional,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            initialValue={f.value}
                        >
                            <Select
                                showSearch
                                placeholder={f.name}
                                allowClear
                                value={f.value}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{ width: '100%' }}
                                disabled={controller.isLoading}
                                filterOption={(inputValue, option) => {
                                    const opt = f.default_values?.find((item) => item.value == option?.value);
                                    return !!opt?.value.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                            >
                                {f.default_values?.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {v.value}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    );
                default:
                    return <></>;
            }
        });
        return fieldInputs;
    };

    // Render
    return (
        <div className={'edit-site-card-modal'}>
            <Modal
                open={controller.isEditModalVisible}
                destroyOnClose={true}
                title={
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'rfid-card.edit-modal-title' })}
                    </Title>
                }
                className={'edit-site-card-modal'}
                closable={false}
                centered
                footer={[
                    <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                        {translate({ key: 'general.cancel' })}
                    </Button>,
                    <Button
                        form="edit-site-card-form"
                        key="submit"
                        type="primary"
                        className={'button primary'}
                        loading={controller.isLoading}
                        disabled={!controller.editSiteCardDetail || controller.fieldLabelError}
                        onClick={controller.onSubmitButtonPressed}
                    >
                        {translate({ key: 'general.save' })}
                    </Button>,
                ]}
            >
                {controller.editSiteCardDetail && !controller.isLoadingDetail && (
                    <Form
                        id="edit-site-card-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        layout="horizontal"
                        size="middle"
                        className={'form'}
                        form={controller.form}
                    >
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.label' })}
                                </Text>
                            }
                            name="label"
                            initialValue={controller.newLabel}
                            required
                            validateStatus={controller.fieldLabelError ? 'error' : undefined}
                            help={controller.fieldLabelError ? translate({ key: 'rfid-card.label-error' }) : undefined}
                        >
                            <Input
                                value={controller.newLabel}
                                placeholder={translate({ key: 'label.label' })}
                                onChange={controller.onChangeLabel}
                                maxLength={100}
                            />
                        </Form.Item>
                        {!!controller.newCustomFields.length && <>{mapFields(controller.newCustomFields)}</>}
                    </Form>
                )}

                {!controller.editSiteCardDetail && controller.isLoadingDetail && (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '2rem 0' }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: '4rem' }} spin />} />
                    </div>
                )}
            </Modal>
        </div>
    );
};
