import React, { useContext } from 'react';
import { SiteDtoSimple } from 'services/sites/sites.service';

export interface SiteContextInterface {
    isCreateSiteFragmentVisible: boolean;
    sites: SiteDtoSimple[];
    setSites: React.Dispatch<React.SetStateAction<SiteDtoSimple[]>>;
    setIsCreateSiteFragmentVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SiteContext = React.createContext<SiteContextInterface>({
    isCreateSiteFragmentVisible: false,
    sites: [],
    setIsCreateSiteFragmentVisible: () => {
        /* Nothing to do here*/
    },
    setSites: () => {
        /* Nothing to do here*/
    },
});

export const useSitesContext = (): SiteContextInterface => {
    const store = useContext(SiteContext);
    return store;
};
