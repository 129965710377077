import { useEffect, useState } from 'react';
import { EditSitePolicyController } from 'fragments/site-policies/fragments/edit-site-policy/interfaces';
import { useAPISitePolicies } from 'services/site-policies/api-policies.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useForm } from 'antd/lib/form/Form';
import { useSitePoliciesContext } from 'fragments/site-policies/context/site-policies.context';
import {
    CascadeValue,
    CreateSitePolicy,
    CreateSitePolicyAction,
    CreateSitePolicyActionParamValue,
    NewSitePolicyActionDto,
    NewSitePolicyDto,
    SitePolicyActionEditFormDto,
    SitePolicyActionType,
    SitePolicyActionTypeParamsDto,
} from 'services/site-policies/site-policies.service';
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useEditSitePolicyController = (
    policyId: string,
    sitePoliciesService = useAPISitePolicies(),
    messenger = useMessenger(),
): /* <--Dependency Injections  like services hooks */
EditSitePolicyController => {
    const {
        setSitePolicyActionTypes,
        sitePolicyActionTypes,
        pageSize,
        getSitePolicies,
        isEditModalVisible,
        setIsEditModalVisible,
        sitePolicies,
    } = useSitePoliciesContext();
    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* State */
    const [policy, setPolicy] = useState<NewSitePolicyDto | undefined>(undefined);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingActions, setIsLoadingActions] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
    const [withoutCaducity, setWithoutCaducity] = useState<boolean>(false);
    const [withoutLimit, setWithoutLimit] = useState<boolean>(false);
    const [order, setOrder] = useState<number[]>([]);
    const [limit, setLimit] = useState<number>(1);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [expirationDate, setExpirationDate] = useState<string>('');
    const [actions, setActions] = useState<SitePolicyActionEditFormDto[]>([]);

    useEffect(() => {
        const sp = sitePolicies.find((policy) => policy.id == policyId);
        if (sp && isEditModalVisible) {
            setWithoutCaducity(dayjs(sp.expires_at).isSameOrBefore('1970-01-01', 'year'));
            setWithoutLimit(sp.redeem_total == 0);
            sp.redeem_total != 0 && setLimit(sp.redeem_total);
            setSelectedDate(sp.expires_at);
            setExpirationDate(sp.expires_at);
            setPolicy(sp);
            setIsLoadingActions(false);
        }
        isEditModalVisible && getActions(policyId);
        form.resetFields();
    }, [isEditModalVisible]);

    /* View Events */
    const onChangeSwitchWithoutCaducity = (checked: boolean) => {
        setWithoutCaducity(checked);
    };
    const onChangeSwitchWithoutLimit = (checked: boolean) => {
        setWithoutLimit(checked);
    };
    const onChangeLimit = (input: number | null) => {
        if (input == null) {
            return;
        }
        setLimit(Math.trunc(input));
    };
    const onCancelButtonPressed = () => {
        setPolicy(undefined);
        setWithoutCaducity(true);
        setWithoutLimit(true);
        setOrder([]);
        setLimit(1);
        setSelectedDate('');
        setExpirationDate('');
        form.resetFields();
        setIsEditModalVisible(false);
        setIsLoadingData(true);
    };
    const onFinish = (values: any) => {
        setIsFormLoading(true);
        const sitePolicyActionValues: CreateSitePolicyAction[] = [];
        actions.forEach((action) => {
            sitePolicyActionValues.push({
                site_policy_action_type_id: action.site_policy_action_type_id,
                site_policy_action_values: action.site_policy_action_values,
            });
        });

        const editSitePolicy: CreateSitePolicy = {
            name: values.id,
            expires_at: withoutCaducity ? '1970-01-01T00:00:00Z' : expirationDate,
            initial_count: withoutLimit ? 0 : limit,
            site_policy_actions: sitePolicyActionValues,
        };
        console.log(editSitePolicy);

        sitePoliciesService
            .editSitePolicy(policyId, editSitePolicy)
            .then(() => {
                messenger.showSuccessMessage({ key: 'site-policies.create-success' });
                getSitePolicies({
                    pagination: { page: 0, pageSize },
                    orderTable: { order: undefined, orderBy: undefined },
                });
                setIsEditModalVisible(false);
                form.resetFields();
                setWithoutCaducity(true);
                setWithoutLimit(true);
                setOrder([]);
                setLimit(1);
                setSelectedDate('');
                setExpirationDate('');
            })
            .catch(() => {
                if (session.token) {
                    messenger.showErrorMessage({ key: 'site-policies.list-error' });
                }
            })
            .finally(() => {
                setIsFormLoading(false);
            });
    };

    const onPickedDate = (date: Dayjs | null) => {
        if (date) {
            setSelectedDate(date.format('YYYY-MM-DD'));
            form.setFieldsValue({ time_picker: undefined });
            setExpirationDate(``);
        }
    };
    const onPickedHour = (date: Dayjs | null) => {
        if (date) {
            selectedDate && setExpirationDate(`${selectedDate}T${date.format('HH:00:00')}Z`);
            console.log(`${selectedDate}T${date.format('HH:00:00')}Z`);
        }
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current: Dayjs) => {
        return current && current < dayjs().startOf('day');
    };

    const disabledHour = (): number[] => {
        const currentHour = dayjs().get('hour');
        const disabledHoursArray: number[] = [];
        if (dayjs().format('YYYY-MM-DD') === selectedDate) {
            for (let i = 0; i < currentHour; i++) {
                disabledHoursArray.push(i);
            }
        }
        return disabledHoursArray;
    };

    const onAddAction = () => {
        const fieldActions = form.getFieldValue('action');
        const paramValues: CreateSitePolicyActionParamValue[] = [];

        for (let i = 1; i < fieldActions?.length; i++) {
            const element: string = fieldActions[i];
            paramValues.push({
                id: element.split(':')[0],
                value: element.split(':')[1],
            });
        }
        const selectedActionOption = sitePolicyActionTypes.find((spat) => spat.id == fieldActions[0]);
        const values: string[] = [];
        if (selectedActionOption && selectedActionOption.params) {
            for (let i = 1; i < fieldActions.length; i++) {
                const item = selectedActionOption.params.find((item) => item.value == fieldActions[i]);
                if (item) {
                    values.push(item.label);
                    if (item.children) {
                        const item2 = item.children.find((item) => item.value == fieldActions[i + 1]);
                        if (item2) {
                            values.push(item2.label);
                        }
                    }
                }
            }
        }

        const newActions = [
            ...actions,
            {
                name: selectedActionOption?.name || '',
                position: actions.length,
                site_policy_action_type_id: fieldActions[0],
                site_policy_action_values: paramValues,
                values: values,
            },
        ];
        setActions(newActions);
        form.resetFields(['action']);
    };

    const onRemoveAction = (index: number) => {
        const remainingActions = actions
            .filter((action) => action.position != index)
            .map((action) => {
                if (action.position > index) return { ...action, position: action.position - 1 };
                else return action;
            });
        setActions(remainingActions);
    };
    const onMoveUpAction = (index: number) => {
        const reorganizedActions = actions.map((action) => {
            if (action.position == index) return { ...action, position: action.position - 1 };
            else if (action.position == index - 1) return { ...action, position: action.position + 1 };
            else return action;
        });
        setActions(reorganizedActions.sort((a, b) => (a.position > b.position ? 1 : -1)));
    };
    const onMoveDownAction = (index: number) => {
        const reorganizedActions = actions.map((action) => {
            if (action.position == index) return { ...action, position: action.position + 1 };
            else if (action.position == index + 1) return { ...action, position: action.position - 1 };
            else return action;
        });
        setActions(reorganizedActions.sort((a, b) => (a.position > b.position ? 1 : -1)));
    };

    const onSitePolicyActionTypeParamSelected = (value: any) => {
        if (value.length < 2) {
            getSitePolicyActionTypeParameters(value[0]);
        }
    };

    const onLoadSitePolicyActionTypeParam = (values: any) => {
        //     console.log('Load ActionType', values);
    };

    /* Private Methods */

    const getSitePolicyActionTypeParameters = (actionTypeId: string) => {
        setIsLoading(true);
        const siteId: string = selectedSite === undefined ? '' : selectedSite?.id;
        sitePoliciesService
            .getSitePolicyActionTypeParameters(siteId, actionTypeId)
            .then((data) => {
                sitePolicyActionTypes.map((value: SitePolicyActionType) => {
                    if (value.id == actionTypeId) {
                        value.params = mapSitePolicyActionParamDtos(data);
                    }
                });

                setSitePolicyActionTypes(sitePolicyActionTypes);
            })
            .catch(() => {
                if (session.token) {
                    messenger.showErrorMessage({ key: 'site-policies.list-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapSitePolicyActionParamDtos = (dtos: SitePolicyActionTypeParamsDto[]): CascadeValue[] | undefined => {
        if (dtos.length == 0) {
            return undefined;
        }
        const values: CascadeValue[] = [];
        values.push({
            label: dtos[0].name,
            value: dtos[0].id,
            isLeaf: false,
            disabled: true,
        });
        dtos[0].options.forEach((element) => {
            values.push({
                label: element.value,
                value: dtos[0].id + ':' + element.id,
                isLeaf: dtos.length == 1,
                children: mapSitePolicyActionParamDtos(dtos.slice(1, dtos.length)),
            });
        });
        return values;
    };

    const mapSitePolicyActionTypeToCascadeValues = (dto: SitePolicyActionType): CascadeValue => {
        return {
            label: dto.name,
            value: dto.id,
            isLeaf: false,
            children: dto.params,
        };
    };

    const getActions = (id: string) => {
        setIsLoadingActions(true);
        sitePoliciesService
            .getSitePolicyActionEditFormById(id)
            .then((data) => {
                setActions(data);
                // console.log('actions:', data);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'site-policies.list-error' });
            })
            .finally(() => {
                setIsLoadingActions(false);
            });
    };

    // Return state and events
    return {
        cascadeValues: sitePolicyActionTypes.map(mapSitePolicyActionTypeToCascadeValues),
        form,
        isEditModalVisible,
        policy,
        isFormLoading,
        selectedDate,
        withoutCaducity,
        withoutLimit,
        limit,
        isLoadingData,
        onCancelButtonPressed,
        onFinish,
        onPickedDate,
        onPickedHour,
        disabledDate,
        disabledHour,
        onChangeLimit,
        onChangeSwitchWithoutCaducity,
        onChangeSwitchWithoutLimit,
        onAddAction,
        onRemoveAction,
        onLoadSitePolicyActionTypeParam,
        onSitePolicyActionTypeParamSelected,
        actions,
        isLoadingActions,
        onMoveUpAction,
        onMoveDownAction,
    };
};
