import { SorterResult } from 'antd/lib/table/interface';
import { GetSitePoliciesInterface, SitePolicyContext } from 'fragments/site-policies/context/site-policies.context';
import { ITag, SorterInfo } from 'global/interfaces';
import React, { useState } from 'react';
import { useAPILocks } from 'services/locks/api-locks.service';
import { LockDto } from 'services/locks/locks.service';
import { useAPISitePolicies } from 'services/site-policies/api-policies.service';
import { SitePolicyActionType, NewSitePolicyDto } from 'services/site-policies/site-policies.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

type Props = {
    children?: React.ReactNode;
};

const SitePolicyProvider: React.FC<Props> = (props) => {
    const [sitePolicies, setSitePolicies] = useState<NewSitePolicyDto[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [totalSitePolicies, setTotalSitePolicies] = useState<number>(0);
    const [isCreateSitePolicyModalVisible, setIsCreateSitePolicyModalVisible] = useState<boolean>(false);
    const [isListLoading, setIsListLoading] = useState(false);
    const [sitePolicyActionTypes, setSitePolicyActionTypes] = useState<SitePolicyActionType[]>([]);

    const [isControlModalVisible, setIsControlModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [isViewRedeemsModalVisible, setIsViewRedeemsModalVisible] = useState<boolean>(false);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [selectedLockIds, setSelectedLockIds] = useState<string[]>([]);
    const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>([]);
    const [operatorSwitch, setOperatorSwitch] = useState<boolean>(true);

    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>('descend');
    const [orderBy, setOrderBy] = useState<string | undefined>('date');
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });
    const [tags, setTags] = useState<ITag[]>([]);
    const [locks, setLocks] = useState<LockDto[]>([]);
    const [isLoadingLocks, setIsLoadingLocks] = useState(false);

    const [expandAll, setExpandAll] = useState<boolean>(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const [selectedPolicyIdForViewRedeemers, setSelectedPolicyIdForViewRedeemers] = useState<string>('');

    // Functions

    const sitePoliciesService = useAPISitePolicies();
    const locksService = useAPILocks();
    const messenger = useMessenger();

    const getSitePolicies = (payload: GetSitePoliciesInterface) => {
        let { pagination, orderTable, search } = payload;
        if (pagination) {
            pagination.page != currentPage && setCurrentPage(pagination.page);
            pagination.pageSize != pageSize && setPageSize(pagination.pageSize);
        } else {
            pagination = { page: currentPage, pageSize };
        }
        if (orderTable) {
            orderTable.order !== order && setOrder(orderTable.order);
            orderTable.orderBy !== orderBy && setOrderBy(orderTable.orderBy);
            setSortedInfo({
                order: orderTable.order,
                columnKey: orderTable.orderBy,
            });
        } else {
            orderTable = { order, orderBy };
        }
        if (!search) {
            search = {
                searchInput: '',
                locks: [],
                status: [],
                operator: true,
            };
        }
        setIsTableLoading(true);
        sitePoliciesService
            .listSitePolicies(pagination, orderTable, search)
            .then((data) => {
                // console.log(data.site_policies);
                setExpandAll(false);
                setExpandedRowKeys([]);
                setSitePolicies(data.site_policies);
                setTotalSitePolicies(data.total);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'site-policies.list-error' });
            })
            .finally(() => {
                setIsTableLoading(false);
            });
    };

    const getLocks = () => {
        setIsLoadingLocks(true);
        locksService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingLocks(false);
            });
    };

    return (
        <SitePolicyContext.Provider
            value={{
                isListLoading,
                setIsListLoading,
                sitePolicies,
                setSitePolicies,
                totalSitePolicies,
                setTotalSitePolicies,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize,
                searchInput,
                setSearchInput,
                isCreateSitePolicyModalVisible,
                setIsCreateSitePolicyModalVisible,
                sitePolicyActionTypes,
                setSitePolicyActionTypes,
                operatorSwitch,
                setOperatorSwitch,
                isFilterModalVisible,
                setIsFilterModalVisible,
                isViewRedeemsModalVisible,
                setIsViewRedeemsModalVisible,
                isTableLoading,
                setIsTableLoading,
                selectedLockIds,
                setSelectedLockIds,
                selectedStatusIds,
                setSelectedStatusIds,
                tags,
                setTags,
                sortedInfo,
                setSortedInfo,
                order,
                orderBy,
                setOrder,
                setOrderBy,
                locks,
                setLocks,
                isLoadingLocks,
                setIsLoadingLocks,
                expandedRowKeys,
                setExpandedRowKeys,
                expandAll,
                setExpandAll,
                selectedPolicyIdForViewRedeemers,
                setSelectedPolicyIdForViewRedeemers,
                isControlModalVisible,
                isEditModalVisible,
                setIsControlModalVisible,
                setIsEditModalVisible,
                // Functions
                getLocks,
                getSitePolicies,
            }}
        >
            {props.children}
        </SitePolicyContext.Provider>
    );
};

export default SitePolicyProvider;
