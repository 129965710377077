import { SorterResult } from 'antd/lib/table/interface';
import { OrderTable, PaginationRequest, SorterInfo } from 'global/interfaces';
import React, { useContext } from 'react';
import {
    CreateOperationInput,
    OperationDto,
    OperationType,
    PreviewActionItem,
} from 'services/operations/operations.service';
import { OperationActionDto, OperationActionType } from 'services/operation-actions/operation-actions.service';
import { OperationInfo } from '../fragments/operation-action-list/interfaces';

export interface OperationListPayload {
    pagination?: PaginationRequest;
    orderTable?: OrderTable;
}

export interface OperationContextInterface {
    isTableLoading: boolean;
    isLoadingOperationCommand: boolean;
    isLoadingActionCommand: boolean;
    operations: OperationDto[];
    totalOperations: number;
    currentPage: number;
    pageSize: number;
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    sortedInfo: SorterResult<SorterInfo>;
    isNewOperationModalVisible: boolean;
    operationType: OperationType;
    operationActionType: OperationActionType;
    previewActionList: PreviewActionItem[];
    createOperationName: string;
    isLoadingCheck: boolean;
    checkError: string;
    operationInfo: OperationInfo | undefined;
    actions: OperationActionDto[];
    totalOperationActions: number;
    isLoadingActions: boolean;
    operationEventSource?: EventSource;
    activeOperation?: OperationDto;
    setActiveOperation: React.Dispatch<React.SetStateAction<OperationDto | undefined>>;
    setOperationEventSource: React.Dispatch<React.SetStateAction<EventSource | undefined>>;
    setIsLoadingActions: React.Dispatch<React.SetStateAction<boolean>>;
    setOperationInfo: React.Dispatch<React.SetStateAction<OperationInfo | undefined>>;
    setActions: React.Dispatch<React.SetStateAction<OperationActionDto[]>>;
    setTotalOperationActions: React.Dispatch<React.SetStateAction<number>>;
    setCheckError: React.Dispatch<React.SetStateAction<string>>;
    setIsLoadingCheck: React.Dispatch<React.SetStateAction<boolean>>;
    setCreateOperationName: React.Dispatch<React.SetStateAction<string>>;
    setIsNewOperationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setOperationType: React.Dispatch<React.SetStateAction<OperationType>>;
    setOperationActionType: React.Dispatch<React.SetStateAction<OperationActionType>>;
    setPreviewActionList: React.Dispatch<React.SetStateAction<PreviewActionItem[]>>;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoadingOperationCommand: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoadingActionCommand: React.Dispatch<React.SetStateAction<boolean>>;
    setOperations: React.Dispatch<React.SetStateAction<OperationDto[]>>;
    setTotalOperations: React.Dispatch<React.SetStateAction<number>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSortedInfo: React.Dispatch<React.SetStateAction<SorterResult<SorterInfo>>>;
    getOperations: (payload: OperationListPayload) => void;
    checkOperation: (input: CreateOperationInput) => void;
    getOperationDetail: (operationId: string) => void;
}

export const OperationContext = React.createContext<OperationContextInterface>({
    isTableLoading: false,
    isLoadingOperationCommand: false,
    isLoadingActionCommand: false,
    operations: [],
    totalOperations: 0,
    currentPage: 0,
    pageSize: 10,
    order: undefined,
    orderBy: undefined,
    sortedInfo: { order: undefined, columnKey: undefined },
    isNewOperationModalVisible: false,
    operationType: OperationType.DEFAULT,
    operationActionType: OperationActionType.DEFAULT,
    previewActionList: [],
    createOperationName: '',
    isLoadingCheck: false,
    checkError: '',
    operationInfo: undefined,
    actions: [],
    totalOperationActions: 0,
    isLoadingActions: false,
    operationEventSource: undefined,
    activeOperation: undefined,
    //Functions
    setActiveOperation: () => {
        /* Nothing to do here*/
    },
    setOperationEventSource: () => {
        /* Nothing to do here*/
    },
    setIsLoadingActions: () => {
        /* Nothing to do here*/
    },
    setOperationInfo: () => {
        /* Nothing to do here*/
    },
    setActions: () => {
        /* Nothing to do here*/
    },
    setTotalOperationActions: () => {
        /* Nothing to do here*/
    },
    setCheckError: () => {
        /* Nothing to do here*/
    },
    setIsLoadingCheck: () => {
        /* Nothing to do here*/
    },
    setCreateOperationName: () => {
        /* Nothing to do here*/
    },
    setIsNewOperationModalVisible: () => {
        /* Nothing to do here*/
    },
    setOperationType: () => {
        /* Nothing to do here*/
    },
    setOperationActionType: () => {
        /* Nothing to do here*/
    },
    setPreviewActionList: () => {
        /* Nothing to do here*/
    },
    setIsTableLoading: () => {
        /* Nothing to do here*/
    },
    setIsLoadingOperationCommand: () => {
        /* Nothing to do here*/
    },
    setIsLoadingActionCommand: () => {
        /* Nothing to do here*/
    },
    setOperations: () => {
        /* Nothing to do here*/
    },
    setTotalOperations: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setOrder: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setSortedInfo: () => {
        /* Nothing to do here*/
    },
    getOperations: () => {
        /* Nothing to do here*/
    },
    checkOperation: () => {
        /* Nothing to do here*/
    },
    getOperationDetail: () => {
        /* Nothing to do here*/
    },
});

export const useOperationContext = (): OperationContextInterface => {
    const store = useContext(OperationContext);
    return store;
};
