import { useEffect, useState } from 'react';
import {
    RedeemTableRow,
    ViewRedeemersModalController,
} from 'fragments/site-policies/fragments/view-redeemers-modal/interfaces';
import { useSitePoliciesContext } from 'fragments/site-policies/context/site-policies.context';
import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { OrderTable, PaginationRequest, SorterInfo } from 'global/interfaces';
import { SitePolicyRedeemDto } from 'services/site-policies/site-policies.service';
import dayjs from 'dayjs';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPISitePolicies } from 'services/site-policies/api-policies.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useViewRedeemersModalController = (
    policyId: string,
    sitePoliciesService = useAPISitePolicies(),
    messenger = useMessenger(),
): /* <--Dependency Injections  like services hooks */
ViewRedeemersModalController => {
    /* State */
    const [searchInput, setSearchInput] = useState<string>('');
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>('descend');
    const [orderBy, setOrderBy] = useState<string | undefined>('date');
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(30);
    const [isLoading, setIsLoading] = useState(false);
    const [sitePolicyRedeems, setSitePolicyRedeems] = useState<SitePolicyRedeemDto[]>([]);
    const [totalRedeems, setTotalRedeems] = useState<number>(0);

    const { isViewRedeemsModalVisible, setIsViewRedeemsModalVisible, setSelectedPolicyIdForViewRedeemers } =
        useSitePoliciesContext();

    const [session] = useLocalSession();
    const { selectedSite } = session;
    /* Listeners */
    useEffect(() => {
        if (session.token && session.selectedSite && policyId) {
            getRedeems({ search: { id: policyId, searchInput } });
        }
    }, [policyId]);

    /* View Events */

    // ________________Button Events___________________
    const onCloseButtonPressed = () => {
        setSearchInput('');
        setSelectedPolicyIdForViewRedeemers('');
        setCurrentPage(0);
        setPageSize(30);
        setOrder(undefined);
        setOrderBy(undefined);
        setSitePolicyRedeems([]);
        setTotalRedeems(0);
        setSortedInfo({
            order: undefined,
            columnKey: undefined,
        });
        setIsViewRedeemsModalVisible(false);
    };
    const onSearchButtonPressed = () => {
        getRedeems({
            search: { id: policyId, searchInput },
            pagination: { page: 0, pageSize },
            orderTable: {
                order,
                orderBy,
            },
        });
    };

    // ________________Input Events___________________

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };
    // _______________________Table Events____________________

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setCurrentPage(page);
        setPageSize(currentPageSize);
        setOrder(sorter.order);
        setOrderBy(sorter.orderBy);
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.column?.key,
        });
        getRedeems({
            pagination: { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            orderTable: {
                order: sorter.order,
                orderBy: sorter.column?.key,
            },
            search: {
                searchInput,
                id: policyId,
            },
        });
    };

    /* Private Methods */
    const getRedeems = (request: {
        search: { id: string; searchInput: string };
        pagination?: PaginationRequest;
        orderTable?: OrderTable;
    }) => {
        const { search, pagination, orderTable } = request;
        setIsLoading(true);
        sitePoliciesService
            .getRedeemsByPolicyId({ search, pagination, order: orderTable })
            .then((data) => {
                setSitePolicyRedeems(data.redeems);
                setTotalRedeems(data.total);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapDtoToTableRow = (dto: SitePolicyRedeemDto): RedeemTableRow => {
        return {
            id: dto.id,
            name: dto.redeemer,
            email: dto.redeemer_email,
            date: dayjs.utc(dto.redeemed_at).format('DD/MM/YYYY HH:mm') + ' (' + selectedSite?.time_zone + ')',
        };
    };

    // Return state and events
    return {
        isViewRedeemsModalVisible,
        itemsViewModel: sitePolicyRedeems.map(mapDtoToTableRow),
        isLoading,
        searchInput,
        totalRedeems,
        currentPage,
        pageSize,
        sortedInfo,
        onSearchButtonPressed,
        onCloseButtonPressed,
        handleTableChange,
        onSearchInputChange,
    };
};
