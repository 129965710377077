import { useEffect, useState } from 'react';
import {
    AdminsFilterModalController,
    RoleOption,
} from 'fragments/admins-dashboard/fragments/admins-filter-modal/interfaces';
import { useAPIAdmin } from 'services/admin/api-admin.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useAdminContext } from 'fragments/admins-dashboard/context/admins.context';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { SearchAdminsInterface } from 'services/admin/admin.service';
import { capitalize } from 'tools/string-handling';
import { ITag } from 'global/interfaces';

export const useAdminsFilterModalController = (
    adminService = useAPIAdmin(),
    messenger = useMessenger(),
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
AdminsFilterModalController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [tempLockIds, setTempLockIds] = useState<string[]>([]);
    const [tempRoleIds, setTempRoleIds] = useState<number[]>([]);
    const [tempSearchInput, setTempSearchInput] = useState<string[]>([]);
    // const [keyStatusOptions, setKeyStatusOptions] = useState<KeyStatusOptions[]>([]);
    const {
        isFilterModalVisible,
        setIsFilterModalVisible,
        setSearchInput,
        setSelectedLockIds,
        setSelectedRoles,
        searchInput,
        selectedLockIds,
        selectedRoles,
        pageSize,
        roles,
        setRoles,
        setSortedInfo,
        tags,
        setTags,
        isTableLoading,
        admins,
        getAdmins,
        getLocks,
        locks,
        isLoadingLocks,
    } = useAdminContext();
    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);
    useEffect(() => {
        if (session.token && selectedSite) {
            getLocks();
            setDefaultRoleOptions();
            setTempLockIds([]);
            setTempSearchInput([]);
            form.resetFields();
        }
    }, [selectedSite]);
    //________________TAGS________________
    useEffect(() => {
        mapTags({ selectedLockIds, selectedRoles, searchInput });
        setTempLockIds(selectedLockIds);
        setTempSearchInput(searchInput);
    }, [selectedLockIds, selectedRoles, searchInput, locks]);

    useEffect(() => {
        form.resetFields();
    }, [admins]);

    /* View Events */
    //________________button events________________

    const onSubmitButtonPressed = () => {
        const search = {
            searchInput: tempSearchInput,
            selectedLockIds: tempLockIds,
            selectedRoles: tempRoleIds,
        };
        setSearchInput(tempSearchInput);
        setSelectedLockIds(tempLockIds);
        setSelectedRoles(tempRoleIds);
        getAdmins({ pagination: { page: 0, pageSize }, search });
        setIsFilterModalVisible(false);
    };

    const onCancelButtonPressed = () => {
        setIsFilterModalVisible(false);
        setTempLockIds(selectedLockIds);
        setTempSearchInput(searchInput);
        form.resetFields();
    };

    const onResetButtonPressed = () => {
        setSearchInput([]);
        setSelectedLockIds([]);
        setDefaultRoleOptions();
        setSortedInfo({
            order: undefined,
            columnKey: undefined,
        });
        getAdmins({ pagination: { page: 0, pageSize }, orderTable: { order: undefined, orderBy: undefined } });
        setIsFilterModalVisible(false);
    };

    //________________input events________________

    const onLockSelect = (value: string[]) => {
        setTempLockIds(value);
    };

    const onSearchInputChange = (value: string[]) => {
        setTempSearchInput(value);
    };

    const onRoleOptionPressed = (key: number) => {
        const rolesIds: number[] = [];
        const updatedRoleOptions: RoleOption[] = roleOptions.map((option) => {
            if (option.key == key) {
                option.selected = !option.selected;
            }
            if (option.selected) {
                rolesIds.push(option.key);
            }
            return option;
        });
        setRoleOptions(updatedRoleOptions);
        setTempRoleIds(rolesIds);
    };

    //________________TAGS________________

    const onCloseTag = (removedTag: ITag) => {
        switch (removedTag.name) {
            case translate({ key: 'label.lock' }): {
                const newSelectedLockIds = selectedLockIds.filter((lockId) => lockId !== removedTag.id);
                setSelectedLockIds(newSelectedLockIds);
                getAdmins({
                    pagination: { page: 0, pageSize },
                    search: { searchInput, selectedLockIds: newSelectedLockIds, selectedRoles },
                });
                break;
            }
            case translate({ key: 'label.user' }): {
                const newSearchInputValues = searchInput.filter((value) => value !== removedTag.id);
                setSearchInput(newSearchInputValues);
                getAdmins({
                    pagination: { page: 0, pageSize },
                    search: { searchInput: newSearchInputValues, selectedLockIds, selectedRoles },
                });
                break;
            }
            case translate({ key: 'label.role' }): {
                const newSelectedRoles = selectedRoles.filter((roleId) => roleId !== removedTag.id);
                setSelectedRoles(newSelectedRoles);
                onRoleOptionPressed(removedTag.id as number);
                getAdmins({
                    pagination: { page: 0, pageSize },
                    search: { searchInput, selectedLockIds, selectedRoles: newSelectedRoles },
                });
                break;
            }
            default:
                break;
        }
    };

    const mapTags = (search: SearchAdminsInterface) => {
        const { selectedLockIds, searchInput, selectedRoles } = search;
        const tempLockTags: ITag[] = [];
        const tempUserTags: ITag[] = [];
        const tempRoleTags: ITag[] = [];

        selectedLockIds.map((id) => {
            const selectedLock = locks.find((lock) => lock.id === id);
            tempLockTags.push({
                id,
                name: translate({ key: 'label.lock' }),
                value: `${selectedLock?.name}`,
                color: 'geekblue',
                closable: true,
            });
        });
        searchInput.map((value) => {
            tempUserTags.push({
                id: value,
                name: translate({ key: 'label.user' }),
                value: value,
                color: 'purple',
                closable: true,
            });
        });
        if (selectedRoles.length !== roleOptions.length) {
            selectedRoles.map((value) => {
                const selectedRole = roles.find((role) => role.value === value);
                tempRoleTags.push({
                    id: value,
                    name: translate({ key: 'label.role' }),
                    value: `${selectedRole?.name}`,
                    color: 'cyan',
                    closable: true,
                });
            });
            if (tempRoleTags.length === 1) {
                tempRoleTags[0].closable = false;
            }
        }
        setTags([...tempLockTags, ...tempUserTags, ...tempRoleTags]);
    };

    /* Private Methods */
    //Ex. const increaseCount = () => {}

    const setDefaultRoleOptions = (): void => {
        adminService
            .getUserZoneTypes()
            .then((data) => {
                const rolesIds: number[] = [];
                const defaultRoleOptions: RoleOption[] = [];
                setRoles(data);
                data.map((item) => {
                    defaultRoleOptions.push({
                        key: item.value,
                        label: capitalize(item.name),
                        selected: true,
                    });
                });
                defaultRoleOptions.map((item) => {
                    if (item.selected) {
                        rolesIds.push(item.key);
                    }
                });

                setRoleOptions(defaultRoleOptions);
                setTempRoleIds(rolesIds);
                setSelectedRoles(rolesIds);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'roles.list-error' });
                console.log('roles-list-error', error);
            });
    };

    // Return state and events
    return {
        form,
        isLoading,
        locks,
        isLoadingLocks,
        isFilterModalVisible,
        selectedLockIds,
        searchInput,
        selectedRoles,
        roleOptions,
        onRoleOptionPressed,
        onSearchInputChange,
        onCancelButtonPressed,
        onSubmitButtonPressed,
        onResetButtonPressed,
        onLockSelect,
        isTableLoading,
        onCloseTag,
        tags,
    };
};
