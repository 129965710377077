import { SorterResult } from 'antd/lib/table/interface';
import { Dayjs } from 'dayjs';
import { GetRfidCardsInterface } from 'fragments/site-cards-dashboard/context/site-cards.context';
import { FilterCustomField } from 'fragments/site-cards-dashboard/fragments/site-cards-filter-modal/interfaces';
import { ITag, OrderTable, PaginationRequest, SorterInfo } from 'global/interfaces';
import React, { useContext } from 'react';
import { LockDto } from 'services/locks/locks.service';
import { RfidCardDto } from 'services/rfidcards/rfidcards.service';
export interface GetAccessRfidCardsInterface {
    pagination?: PaginationRequest;
    orderTable?: OrderTable;
    search?: GetRfidCardsInterface;
}
export interface RfidCardsContextInterface {
    isLoadingTable: boolean;
    rfidCards: RfidCardDto[];
    totalRfidCards?: number;
    currentPage: number;
    pageSize: number;
    label: string[];
    cardNumber: string[];
    authorizer: string[];
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    isFilterModalVisible: boolean;
    selectedDevicesIds: string[];
    dateRange: [Dayjs | null, Dayjs | null];
    selectedRfidCardStatusOptions: number[];
    sortedInfo: SorterResult<SorterInfo>;
    isLoadingLocks: boolean;
    locks: LockDto[];
    tags: ITag[];
    isDeleteModalVisible: boolean;
    deleteCardId: string;
    deleteCardDeviceId: string;
    selectedCustomFields: FilterCustomField[];
    setSelectedCustomFields: React.Dispatch<React.SetStateAction<FilterCustomField[]>>;
    setDeleteCardDeviceId: React.Dispatch<React.SetStateAction<string>>;
    setDeleteCardId: React.Dispatch<React.SetStateAction<string>>;
    setIsDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
    setSelectedRfidCardStatusOptions: React.Dispatch<React.SetStateAction<number[]>>;
    setDateRange: React.Dispatch<React.SetStateAction<[Dayjs | null, Dayjs | null]>>;
    setSelectedDevicesIds: React.Dispatch<React.SetStateAction<string[]>>;
    setIsFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    setRfidCards: React.Dispatch<React.SetStateAction<RfidCardDto[]>>;
    setTotalRfidCards: React.Dispatch<React.SetStateAction<number | undefined>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setLabel: React.Dispatch<React.SetStateAction<string[]>>;
    setCardNumber: React.Dispatch<React.SetStateAction<string[]>>;
    setAuthorizer: React.Dispatch<React.SetStateAction<string[]>>;

    setIsLoadingTable: React.Dispatch<React.SetStateAction<boolean>>;
    setSortedInfo: React.Dispatch<React.SetStateAction<SorterResult<SorterInfo>>>;
    //________________Functions___________
    getRfidCards: (payload: GetAccessRfidCardsInterface) => void;
    getLocks: () => void;
}

export const RfidCardsContext = React.createContext<RfidCardsContextInterface>({
    isDeleteModalVisible: false,
    isLoadingTable: false,
    isLoadingLocks: false,
    isFilterModalVisible: false,
    locks: [],
    rfidCards: [],
    label: [],
    cardNumber: [],
    authorizer: [],
    selectedRfidCardStatusOptions: [],
    tags: [],
    currentPage: 0,
    pageSize: 20,
    orderBy: undefined,
    order: undefined,
    selectedDevicesIds: [],
    dateRange: [null, null],
    sortedInfo: { order: undefined, columnKey: undefined },
    deleteCardId: '',
    deleteCardDeviceId: '',
    selectedCustomFields: [],
    setSelectedCustomFields: () => {
        /* Nothing to do*/
    },
    setDeleteCardDeviceId: () => {
        /* Nothing to do here*/
    },
    setDeleteCardId: () => {
        /* Nothing to do here*/
    },
    setIsDeleteModalVisible: () => {
        /* Nothing to do here*/
    },
    setTags: () => {
        /* Nothing to do here*/
    },
    setSelectedRfidCardStatusOptions: () => {
        /* Nothing to do here*/
    },
    setDateRange: () => {
        /* Nothing to do here*/
    },
    setAuthorizer: () => {
        /* Nothing to do here*/
    },
    setSelectedDevicesIds: () => {
        /* Nothing to do here*/
    },
    setOrder: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setTotalRfidCards: () => {
        /* Nothing to do here*/
    },
    setIsFilterModalVisible: () => {
        /* Nothing to do here*/
    },
    setRfidCards: () => {
        /* Nothing to do here*/
    },
    setLabel: () => {
        /* Nothing to do here*/
    },
    setCardNumber: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setIsLoadingTable: () => {
        /* Nothing to do here*/
    },
    setSortedInfo: () => {
        /* Nothing to do here*/
    },
    //__________Functions__________
    getRfidCards: () => {
        /* Nothing to do here*/
    },
    getLocks: () => {
        /* Nothing to do here*/
    },
});

export const useRfidCardsContext = (): RfidCardsContextInterface => {
    const store = useContext(RfidCardsContext);
    return store;
};
