// import { ResetPasswordInput } from 'auth/services/auth/auth.service';
import { useState } from 'react';
import { ResetPasswordController } from 'auth/fragments/reset-password/interfaces';
// import { useParams, useHistory } from 'react-router-dom';

export const useResetPasswordController = (): ResetPasswordController => {
    /* State */
    const [isLoading] = useState(false);
    // const { token }: { token: string } = useParams();
    // const history = useHistory();

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);

    /* View Events */
    const onResetPasswordSubmit = () => {
        // setIsLoading(true);
        // const input = formInputs as ResetPasswordInput;
        // if (input.password != input.repeatPassword) {
        //     messenger.showErrorMessage({ key: 'auth.reset-pass-error-not-match' });
        //     return;
        // }
        // authService
        //     .resetPassword(token, input)
        //     .then(() => {
        //         messenger.showSuccessMessage({ key: 'auth.reset-pass-success' });
        //         history.push('/login');
        //     })
        //     .catch(() => {
        //         messenger.showErrorMessage({ key: 'auth.reset-pass-error' });
        //     })
        //     .finally(() => {
        //         setIsLoading(false);
        //     });
    };

    // Return state and events
    return { isLoading, onResetPasswordSubmit };
};
