import {
    CustomFields,
    SortCustomFieldsRequest,
    CustomField,
    AddCustomFieldsRequest,
    EditCustomFieldsRequest,
    GetCustomFieldsResponse,
    AutocompleteTagsResponse,
} from 'services/custom-fields/custom-fields.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';

export const useAPICustomFields = (): CustomFields => {
    const [session] = useLocalSession();
    const listCustomFields = async (): Promise<CustomField[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL + `/user/sites/${session.selectedSite?.id}/custom-fields`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as CustomField[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const sortCustomFields = async (input: SortCustomFieldsRequest): Promise<void> => {
        try {
            await axios.put(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/custom-fields/sorting`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const addCustomFields = async (input: AddCustomFieldsRequest): Promise<void> => {
        try {
            await axios.post(
                process.env.REACT_APP_API_RFID_BASE_URL + `/user/sites/${session.selectedSite?.id}/custom-fields`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const deleteCustomField = async (id: string): Promise<void> => {
        try {
            await axios.delete(
                process.env.REACT_APP_API_RFID_BASE_URL + `/user/sites/${session.selectedSite?.id}/custom-fields/${id}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const editCustomFields = async (id: string, input: EditCustomFieldsRequest): Promise<void> => {
        try {
            await axios.put(
                process.env.REACT_APP_API_RFID_BASE_URL + `/user/sites/${session.selectedSite?.id}/custom-fields/${id}`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const getCustomField = async (id: string): Promise<GetCustomFieldsResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL + `/user/sites/${session.selectedSite?.id}/custom-fields/${id}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as GetCustomFieldsResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const autocompleteTags = async (input: string, field_id: string): Promise<AutocompleteTagsResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/custom-fields/${field_id}/tags?input=${input}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as AutocompleteTagsResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    return {
        listCustomFields,
        sortCustomFields,
        addCustomFields,
        deleteCustomField,
        editCustomFields,
        getCustomField,
        autocompleteTags,
    };
};
