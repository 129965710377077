import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LockDto } from 'services/locks/locks.service';
import { PasscodesDto } from 'services/passcodes/passcodes.service';
import { PasscodesContext } from './passcodes.context';

type Props = {
    children?: React.ReactNode;
};

const PasscodesProvider: React.FC<Props> = (props) => {
    const [searchParams] = useSearchParams();
    const l = searchParams.get('l') || '';

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [totalPasscodes, setTotalPasscodes] = useState<number>(0);
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>('descend');
    const [orderBy, setOrderBy] = useState<string | undefined>('date');
    const [passcodes, setPasscodes] = useState<PasscodesDto[]>([]);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

    const [locks, setLocks] = useState<LockDto[]>([]);

    const [selectedLockIdsFilterModal, setSelectedLockIdsFilterModal] = useState<string[]>(l.length ? [l] : []);
    const [isVisibleFilterPasscodeModal, setIsVisibleFilterPasscodeModal] = useState<boolean>(false);
    const [searchCodeInput, setSearchCodeInput] = useState<string>('');
    const [searchLabelInput, setSearchLabelInput] = useState<string>('');
    const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
        from: '',
        to: '',
    });

    return (
        <PasscodesContext.Provider
            value={{
                isVisibleFilterPasscodeModal,
                setIsVisibleFilterPasscodeModal,
                selectedLockIdsFilterModal,
                setSelectedLockIdsFilterModal,
                dateRange,
                setDateRange,
                searchCodeInput,
                setSearchCodeInput,
                searchLabelInput,
                setSearchLabelInput,

                locks,
                setLocks,

                passcodes,
                setPasscodes,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize,
                totalPasscodes,
                setTotalPasscodes,
                isTableLoading,
                setIsTableLoading,
                order,
                setOrder,
                orderBy,
                setOrderBy,
            }}
        >
            {props.children}
        </PasscodesContext.Provider>
    );
};

export default PasscodesProvider;
