import React, { useEffect, useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { ITag, SorterInfo } from 'global/interfaces';
import { useAPILocks } from 'services/locks/api-locks.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { LockDto, SearchLocksInterface } from 'services/locks/locks.service';
import {
    FilterRfidCardsCustomFieldInterface,
    RfidCardDto,
    SearchRfidCardsInterface,
} from 'services/rfidcards/rfidcards.service';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { useSearchParams } from 'react-router-dom';
import { GetAccessRfidCardsInterface, RfidCardsContext } from './rfid-cards.context';
import dayjs, { Dayjs } from 'dayjs';
import { FilterCustomField } from 'fragments/site-cards-dashboard/fragments/site-cards-filter-modal/interfaces';

type Props = {
    children?: React.ReactNode;
};

const RfidCardsProvider: React.FC<Props> = (props) => {
    const [searchParams] = useSearchParams();
    const l = searchParams.get('l') || '';

    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [deleteCardId, setDeleteCardId] = useState<string>('');
    const [deleteCardDeviceId, setDeleteCardDeviceId] = useState<string>('');
    // const [creationSummary, setCreationSummary] = useState<CreateKeySummaryDto[]>([]);
    const [rfidCards, setRfidCards] = useState<RfidCardDto[]>([]);
    const [label, setLabel] = useState<string[]>([]);
    const [cardNumber, setCardNumber] = useState<string[]>([]);
    const [authorizer, setAuthorizer] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [totalRfidCards, setTotalRfidCards] = useState<number>();
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>(undefined);
    const [orderBy, setOrderBy] = useState<string | undefined>(undefined);
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [selectedDevicesIds, setSelectedDevicesIds] = useState<string[]>(l.length ? [l] : []);
    const [selectedRfidCardStatusOptions, setSelectedRfidCardStatusOptions] = useState<number[]>([]);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });

    const [selectedCustomFields, setSelectedCustomFields] = useState<FilterCustomField[]>([]);

    const [locks, setLocks] = useState<LockDto[]>([]);
    const [isLoadingLocks, setIsLoadingLocks] = useState(false);
    const [tags, setTags] = useState<ITag[]>([]);

    // Functions
    const rfidCardService = useAPIRfidcard();
    const messenger = useMessenger();
    const locksService = useAPILocks();

    useEffect(() => {
        if (l.length) setSelectedDevicesIds([l]);
    }, []);

    const parseCustomFieldsToRequest = (fields: FilterCustomField[]): FilterRfidCardsCustomFieldInterface[] => {
        return fields
            .map((f) => ({
                custom_field_id: f.id,
                type_id: f.type_id,
                values:
                    f.type_id == 2
                        ? [
                              `${dayjs(f.value[0]).format('YYYY-MM-DD')}T00:00:00Z`,
                              `${dayjs(f.value[1]).format('YYYY-MM-DD')}T23:59:59Z`,
                          ]
                        : f.type_id == 3
                        ? f.value.map((v: any) => v.value || v)
                        : f.value,
            }))
            .filter((f) => !!f.values.length);
    };
    const getRfidCards = (payload: GetAccessRfidCardsInterface) => {
        let { pagination, orderTable } = payload;
        if (pagination) {
            pagination.page != currentPage && setCurrentPage(pagination.page);
            pagination.pageSize != pageSize && setPageSize(pagination.pageSize);
        } else {
            pagination = { page: currentPage, pageSize };
        }
        if (orderTable) {
            orderTable.order !== order && setOrder(orderTable.order);
            orderTable.orderBy !== orderBy && setOrderBy(orderTable.orderBy);
            if (!orderTable.order && !orderTable.orderBy) {
                setSortedInfo({
                    order: undefined,
                    columnKey: undefined,
                });
            }
        } else {
            orderTable = { order, orderBy };
        }

        const searchInput: SearchRfidCardsInterface = payload.search
            ? { ...payload.search, custom_fields: parseCustomFieldsToRequest(payload.search.customFields) }
            : {
                  since: '',
                  until: '',
                  card: [],
                  label: [],
                  devices: [],
                  authorizer: [],
                  status: [],
                  custom_fields: [],
              };

        setIsLoadingTable(true);
        rfidCardService
            .listRfidCardsLocks({ pagination, orderTable, search: searchInput })
            .then((data) => {
                setRfidCards(data.rfid_cards);
                setTotalRfidCards(data.total);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'cards.list-error' });
            })
            .finally(() => {
                setIsLoadingTable(false);
            });
    };

    const getLocks = () => {
        setIsLoadingLocks(true);
        locksService
            .listDevicesRfidCardDevicesListFilter()
            .then((data) => {
                setLocks(data);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingLocks(false);
            });
    };

    return (
        <RfidCardsContext.Provider
            value={{
                sortedInfo,
                setSortedInfo,
                selectedRfidCardStatusOptions,
                setSelectedRfidCardStatusOptions,
                authorizer,
                setAuthorizer,
                selectedDevicesIds,
                setSelectedDevicesIds,
                dateRange,
                setDateRange,
                isFilterModalVisible,
                setIsFilterModalVisible,
                rfidCards,
                setRfidCards,
                totalRfidCards,
                setTotalRfidCards,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize,
                label,
                setLabel,
                cardNumber,
                setCardNumber,
                isLoadingTable,
                setIsLoadingTable,
                order,
                setOrder,
                orderBy,
                setOrderBy,
                locks,
                isLoadingLocks,
                //Functions
                getRfidCards,
                getLocks,
                tags,
                setTags,
                isDeleteModalVisible,
                setIsDeleteModalVisible,
                selectedCustomFields,
                setSelectedCustomFields,
                deleteCardId,
                setDeleteCardId,
                deleteCardDeviceId,
                setDeleteCardDeviceId,
            }}
        >
            {props.children}
        </RfidCardsContext.Provider>
    );
};

export default RfidCardsProvider;
