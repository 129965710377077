import React, { useEffect, useState } from 'react';
import { Menu, Divider, Typography } from 'antd';
import { Link, useLocation, matchPath, useNavigate } from 'react-router-dom';
import {
    UnlockOutlined,
    ExportOutlined,
    UnorderedListOutlined,
    UserSwitchOutlined,
    ExperimentOutlined,
    DownloadOutlined,
    PieChartOutlined,
    PartitionOutlined,
    FormOutlined,
} from '@ant-design/icons';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { Translator } from 'tools/view-hooks/translator-hook';
import KeyIcon from 'assets/icons/key-icon';
import PasscodeIcon from 'assets/icons/passcode-icon';
import RfidIcon from 'assets/icons/rfid-icon';
import { useAPIMain } from 'services/main/api-main.service';
import { ROLES } from 'services/main/main.service';
import { MenuDividerType, MenuItemGroupType, MenuItemType, SubMenuType } from 'antd/es/menu/hooks/useItems';
const { Text } = Typography;

export const NavigationMenu: React.FC<{ translate: Translator['translate']; controller: any }> = ({
    translate,
    controller,
}) => {
    const [showCustomField, setShowCustomField] = useState<boolean>(false);
    const [showAccessReports, setAccessReports] = useState<boolean>(false);
    const [currentKey, setCurrentKey] = useState<string>('');
    const [openSubMenus, setOpenSubMenu] = useState<string[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    // const matchSiteUrl: any = useRouteMatch('/site/:id');
    // const matchVisitorUrl: any = useRouteMatch('/visitor/:id');
    const matchSiteUrl: any = matchPath(location.pathname, '/site/:id');
    const matchVisitorUrl: any = matchPath(location.pathname, '/visitor/:id');

    const mainService = useAPIMain();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    //{/*  */}
    useEffect(() => {
        setActiveMenuKey(location.pathname);
        checkRole();
    }, [location.pathname]);

    useEffect(() => {
        checkRole();
    }, [selectedSite]);

    const setActiveMenuKey = (locationPath: string): void => {
        const isSiteDetails = matchSiteUrl?.url ? true : false;
        const isVisitorDetails = matchVisitorUrl?.url ? true : false;

        if (matchPath(location.pathname, '/rfidcards') || matchPath(location.pathname, '/custom-fields'))
            setOpenSubMenu(['rfidcardsmenu']);
        else setOpenSubMenu([]);

        if (isVisitorDetails) {
            return setCurrentKey('/visitors');
        }
        if (isSiteDetails) {
            return setCurrentKey('/sites');
        }
        return setCurrentKey(locationPath);
    };

    const checkRole = () => {
        mainService.checkRole().then((res) => {
            setShowCustomField(res.role == ROLES.ROLE_SITE_ADMIN || res.role == ROLES.ROLE_SUPERADMIN);
            setAccessReports(
                res.role == ROLES.ROLE_SITE_ADMIN ||
                    res.role == ROLES.ROLE_SUPERADMIN ||
                    res.role == ROLES.ROLE_FULL_ADMIN,
            );
        });
    };

    const showIf = (
        condition: boolean,
        item: MenuItemType | SubMenuType | MenuItemGroupType | MenuDividerType,
    ): MenuItemType | SubMenuType | MenuItemGroupType | MenuDividerType => {
        return { ...item, style: { ...item.style, display: condition ? item?.style?.display : 'none' } };
    };

    const menuItem = (link: string, Icon: React.ReactElement, label: string | React.ReactElement): MenuItemType => {
        return {
            key: link,
            onClick: controller.onMenuItemClick,
            icon: <div style={{ width: '16px', height: '16px' }}>{Icon}</div>,
            label: <Link to={link}>{label}</Link>,
        } as MenuItemType;
    };

    const divider = (title: string): MenuItemType => {
        return {
            key: title + '-divider',
            label: (
                <Divider plain>
                    <Text strong> {title} </Text>
                </Divider>
            ),
            style: {
                padding: 0,
            },
        } as MenuItemType;
    };

    return (
        <Menu
            mode="inline"
            theme="light"
            selectedKeys={[currentKey]}
            openKeys={openSubMenus}
            onOpenChange={(openKeys) => {
                setOpenSubMenu(openKeys);
            }}
            items={[
                showIf(showAccessReports, divider(translate({ key: 'nav.reports' }))),
                showIf(
                    showAccessReports,
                    menuItem('/reports', <UnorderedListOutlined />, translate({ key: 'nav.display' })),
                ),
                showIf(
                    showAccessReports,
                    menuItem('/reports/download', <DownloadOutlined />, translate({ key: 'nav.download' })),
                ),
                divider(translate({ key: 'nav.admin' })),
                menuItem('/locks', <UnlockOutlined />, translate({ key: 'nav.locks' })),
                menuItem('/keys', <KeyIcon />, translate({ key: 'nav.keys' })),
                menuItem('/passcodes', <PasscodeIcon />, translate({ key: 'nav.passcodes' })),
                showIf(showCustomField, {
                    key: 'rfidcardsmenu',
                    onTitleClick: () => {
                        navigate('/site-cards');
                        controller.onMenuItemClick;
                    },
                    icon: <RfidIcon style={{ width: '16px', height: '16px' }} />,
                    label: translate({ key: 'nav.rfid-cards' }),
                    children: [
                        menuItem(
                            '/custom-fields',
                            <FormOutlined />,
                            <div
                                style={{
                                    width: 'max-width',
                                    whiteSpace: 'break-spaces',
                                    lineHeight: '1rem',
                                }}
                            >
                                {translate({ key: 'nav.custom-fields' })}
                            </div>,
                        ),
                    ],
                }),
                // showIf(!showCustomField, menuItem('/rfidcards', <RfidIcon />, translate({ key: 'nav.cards' }))),
                menuItem(
                    '/rfidcards',
                    <RfidIcon style={{ width: '16px', height: '16px' }} />,
                    translate({ key: 'nav.access-cards' }),
                ),
                menuItem('/admins', <UserSwitchOutlined />, translate({ key: 'nav.admins' })),
                showIf(
                    session.user_profile?.role === 'superadmin',
                    menuItem('/operations', <PartitionOutlined />, translate({ key: 'nav.operations' })),
                ),
                showIf(
                    session.user_profile?.role === 'superadmin',
                    menuItem('/policies', <ExperimentOutlined />, translate({ key: 'nav.policies' })),
                ),
                showIf(
                    session.user_profile?.role === 'superadmin',
                    menuItem('/metrics', <PieChartOutlined />, translate({ key: 'nav.metrics' })),
                ),
                {
                    key: '8',
                    onClick: controller.handleLogOut,
                    icon: <ExportOutlined style={{ width: '16px', height: '16px' }} />,
                    label: translate({ key: 'general.logout' }),
                },
            ]}
        />
    );
};
