import {
    AccessReports,
    DownloadAccessReportDto,
    SearchAccessReportsInterface,
    PaginatedAccessReports,
    UserListDto,
    NewAccessReportDto,
    SearchAccessReportsRequestInterface,
    PaginatedReportFiles,
    ReportFileDetailResponse,
    ReportFileDto,
    CreateReportFileRequest,
    UserListCreateReportFileFormResponse,
    SiteCardListCreateReportFileFormResponse,
    AccessReportDto,
} from 'services/access-reports/access-reports.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { OrderTable, PaginationRequest } from 'global/interfaces';
import { ErrorDto } from 'services/dtos/errors..dto';

export const hardcodeSiteId: string[] = [
    '00cc4bc5-705a-42cf-aca2-35aacde45343', //accefy-arg prod
    '42d2286f-320a-41b8-b6ff-a21077ed7e2c', //accefy-arg testing
    '38ab80bf-7803-4467-a885-f7013e6031e9', // alpha inversiones prod
    '496e3f5f-2a12-43f2-9165-3e0000875fcb', // chez space prod
    '8921d36c-94da-4f72-9455-91c4eaf77893', // airsat
    '93bd40d8-59c5-4417-93bf-823cca80b7fa', // hospital neonatal
];

export const useAPIAccessReports = (): AccessReports => {
    const [session] = useLocalSession();
    const listAccessReports = async (
        pagination?: PaginationRequest,
        order?: OrderTable,
        search?: SearchAccessReportsRequestInterface,
    ): Promise<PaginatedAccessReports> => {
        pagination = pagination || { page: 0, pageSize: 15 };
        order = order || { order: undefined, orderBy: undefined };
        search = search || {
            locks: [],
            startDate: '',
            endDate: '',
            searchInput: '',
            accessMethodIds: [],
            reportStateIds: [],
            customFields: [],
        };

        const locks = JSON.stringify(search.locks);
        const type = JSON.stringify(search.accessMethodIds);
        let success = '';

        if (search.reportStateIds.length === 1) {
            success = search.reportStateIds[0].toString();
        }

        const toggleRequest = !!hardcodeSiteId.find((id) => id === session.selectedSite?.id);
        const path = toggleRequest ? 'reportlocks' : 'reportapps';
        const queryParams = !toggleRequest
            ? `page=${pagination.page}&pageSize=${pagination.pageSize}&startDate=${search.startDate}&endDate=${search.endDate}&assignedTo=${search.searchInput}&locks=${locks}&order=${order.order}&orderBy=${order.orderBy}`
            : `page=${pagination.page}&pageSize=${pagination.pageSize}&startDate=${search.startDate}&endDate=${
                  search.endDate
              }&searchTerm=${search.searchInput}&locks=${locks}&order=${order.order}&orderBy=${
                  order.orderBy
              }&type=${type}&status=${success}&custom_fields=${JSON.stringify(search.customFields)}`;
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_REPORTS_BASE_URL +
                    `/sga/reports/v1/user/sites/${session.selectedSite?.id}/${path}?${queryParams}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );

            return response.data as PaginatedAccessReports;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };
    const listReportFiles = async (pagination?: PaginationRequest): Promise<PaginatedReportFiles> => {
        pagination = pagination || { page: 0, pageSize: 20 };
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_REPORTS_BASE_URL +
                    `/sga/reports/v1/user/sites/${session.selectedSite?.id}/report-files?page=${pagination.page}&pageSize=${pagination.pageSize}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );

            return response.data as PaginatedReportFiles;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const downloadReportFile = async (reportFileId: string): Promise<any> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_REPORTS_BASE_URL +
                    `/sga/reports/v1/user/sites/${session.selectedSite?.id}/report-file/${reportFileId}/download`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );

            return response.data;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const getReportFileDetail = async (reportFileId: string): Promise<ReportFileDetailResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_REPORTS_BASE_URL +
                    `/sga/reports/v1/user/sites/${session.selectedSite?.id}/report-file/${reportFileId}/detail`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );

            return response.data as ReportFileDetailResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const listUsers = async (): Promise<UserListDto[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL + `/user/sites/${session.selectedSite?.id}/users`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as UserListDto[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const downloadAccessReports = async (
        userId: string,
        since: string,
        until: string,
    ): Promise<DownloadAccessReportDto[]> => {
        userId = userId === 'all' ? '' : userId;
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_REPORTS_BASE_URL +
                    `/sga/reports/v1/user/sites/${session.selectedSite?.id}/reports/download?userId=${userId}&since=${since}&until=${until}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as DownloadAccessReportDto[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };
    const downloadNewAccessReports = async (
        userId: string,
        since: string,
        until: string,
        reportType: number[],
    ): Promise<NewAccessReportDto[]> => {
        userId = userId === 'all' ? '' : userId;
        const report = JSON.stringify(reportType);
        const response = await axios.get(
            process.env.REACT_APP_API_REPORTS_BASE_URL +
                `/sga/reports/v1/sga/reports/v1/user/sites/${session.selectedSite?.id}/reportlocks/download?userId=${userId}&reportType=${report}&since=${since}&until=${until}`,
            {
                headers: { Authorization: 'Bearer ' + session.token },
            },
        );
        return response.data as NewAccessReportDto[];
    };

    const createReportFile = async (input: CreateReportFileRequest): Promise<void> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_REPORTS_BASE_URL + `/user/sites/${session.selectedSite?.id}/report-files`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const getUsersCreateReportFileForm = async (): Promise<UserListCreateReportFileFormResponse[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_USERS_BASE_URL +
                    `/users/v1/user/sites/${session.selectedSite?.id}/create-report-file-form`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );

            return response.data as UserListCreateReportFileFormResponse[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const getSiteCardsCreateReportFileForm = async (): Promise<SiteCardListCreateReportFileFormResponse[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/reports/v1/sites/${session.selectedSite?.id}/create-report-file-form`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );

            return response.data as SiteCardListCreateReportFileFormResponse[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    return {
        listAccessReports,
        downloadAccessReports,
        downloadNewAccessReports,
        listUsers,
        listReportFiles,
        downloadReportFile,
        getReportFileDetail,
        createReportFile,
        getUsersCreateReportFileForm,
        getSiteCardsCreateReportFileForm,
    };
};
