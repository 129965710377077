import { useLocalSession } from 'auth/helpers/session.hooks';
import { SeeEventService } from './sse.service';
import { EventSourcePolyfill } from 'event-source-polyfill';

export const useAPISee = (): SeeEventService => {
    const [session] = useLocalSession();

    const subscribeEvent = (eventId: string) => {
        const eventSource = new EventSourcePolyfill(
            `${process.env.REACT_APP_API_SSE_BASE_URL}/events/${eventId}/subscribe`,
            {
                headers: {
                    Authorization: 'Bearer ' + session.token,
                },
                heartbeatTimeout: 90000,
            },
        );
        return eventSource;
    };

    const subscribeStream = (eventStreamId: string) => {
        const eventSource = new EventSourcePolyfill(
            `${process.env.REACT_APP_API_SSE_BASE_URL}/stream/${eventStreamId}/subscribe`,
            {
                headers: {
                    Authorization: 'Bearer ' + session.token,
                },
                heartbeatTimeout: 90000,
            },
        );
        return eventSource;
    };

    return {
        subscribeEvent,
        subscribeStream,
    };
};
