import { useState, useEffect } from 'react';
import { CreateSitePolicyController } from 'fragments/site-policies/fragments/create-site-policy/interfaces';
import { useSitePoliciesContext } from 'fragments/site-policies/context/site-policies.context';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
// import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useAPISitePolicies } from 'services/site-policies/api-policies.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import {
    CascadeValue,
    SitePolicyActionType,
    SitePolicyActionTypeParamsDto,
    CreateSitePolicyActionParamValue,
    CreateSitePolicyAction,
    CreateSitePolicy,
} from 'services/site-policies/site-policies.service';
import { useForm } from 'antd/lib/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/lib/date-picker';

export const useCreateSitePolicyController = (
    sitePoliciesService = useAPISitePolicies(),
    // { translate } = useTranslator(),
    messenger = useMessenger(),
): /* <--Dependency Injections  like services hooks */ CreateSitePolicyController => {
    /* State */
    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;
    useEffect(() => {
        if (session.token && selectedSite) {
        }
    }, [session.selectedSite]);

    const {
        pageSize,
        setIsCreateSitePolicyModalVisible,
        setSitePolicyActionTypes,
        sitePolicyActionTypes,
        getSitePolicies,
    } = useSitePoliciesContext();
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [withoutCaducity, setWithoutCaducity] = useState<boolean>(true);
    const [withoutLimit, setWithoutLimit] = useState<boolean>(true);
    const [order, setOrder] = useState<number[]>([]);
    const [limit, setLimit] = useState<number>(1);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [expirationDate, setExpirationDate] = useState<string>('');

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);

    /* View Events */
    const onChangeSwitchWithoutCaducity = (checked: boolean) => {
        setWithoutCaducity(checked);
    };
    const onChangeSwitchWithoutLimit = (checked: boolean) => {
        setWithoutLimit(checked);
    };
    const onChangeLimit = (input: number | null) => {
        if (input === null) {
            return;
        }
        setLimit(Math.trunc(input));
    };

    const onFinish = (values: any) => {
        console.log('finish', values);
        setIsFormLoading(true);
        const sitePolicyActionValues: CreateSitePolicyAction[] = [];
        values.actions.forEach((action: any) => {
            const paramValues: CreateSitePolicyActionParamValue[] = [];
            for (let i = 1; i < action?.length; i++) {
                const element: string = action[i];
                paramValues.push({
                    id: element.split(':')[0],
                    value: element.split(':')[1],
                });
            }

            sitePolicyActionValues.push({
                site_policy_action_type_id: action[0],
                site_policy_action_values: paramValues,
            });
        });

        const createSitePolicy: CreateSitePolicy = {
            name: values.id,
            expires_at: withoutCaducity ? '1970-01-01T00:00:00Z' : expirationDate,
            initial_count: withoutLimit ? 0 : limit,
            site_policy_actions: sitePolicyActionValues,
        };
        const siteId: string = selectedSite === undefined ? '' : selectedSite?.id;

        sitePoliciesService
            .createSitePolicy(createSitePolicy, siteId)
            .then(() => {
                messenger.showSuccessMessage({ key: 'site-policies.create-success' });
                getSitePolicies({
                    pagination: { page: 0, pageSize },
                    orderTable: { order: undefined, orderBy: undefined },
                });
                setIsCreateSitePolicyModalVisible(false);
                form.resetFields();
                setWithoutCaducity(true);
                setWithoutLimit(true);
                setOrder([]);
                setLimit(1);
                setSelectedDate('');
                setExpirationDate('');
            })
            .catch(() => {
                if (session.token) {
                    messenger.showErrorMessage({ key: 'site-policies.list-error' });
                }
            })
            .finally(() => {
                setIsFormLoading(false);
            });
    };

    const onOrderChanged = (index: number, value: number) => {
        const newOrder: number[] = order;
        for (let i = 0; i < newOrder.length; i++) {
            const element = newOrder[i];
            if (value == element) {
                newOrder[i] = newOrder[index];
                break;
            }
        }
        newOrder[index] = value;
        setOrder(newOrder);
    };

    const onAddAction = () => {
        order.push(order.length + 1);
        const newOrder = order;
        setOrder(newOrder);
    };

    const onRemoveAction = (index: number) => {
        setOrder(removeByOrder(order, index));
    };

    const onCancelButtonPressed = () => {
        form.resetFields();
        setWithoutCaducity(true);
        setWithoutLimit(true);
        setOrder([]);
        setLimit(1);
        setSelectedDate('');
        setExpirationDate('');
        setIsCreateSitePolicyModalVisible(false);
    };

    const onSitePolicyActionTypeParamSelected = (value: any) => {
        if (value.length < 2) {
            getSitePolicyActionTypeParameters(value[0]);
        }
    };

    const onLoadSitePolicyActionTypeParam = () => {
        // console.log('selected');
    };
    const onPickedDate = (date: Dayjs | null) => {
        if (date) {
            setSelectedDate(date.format('YYYY-MM-DD'));
            form.setFieldsValue({ time_picker: undefined });
            setExpirationDate(``);
            console.log(date.format('YYYY-MM-DD'));
        }
    };
    const onPickedHour = (date: Dayjs | null) => {
        if (date) {
            selectedDate && setExpirationDate(`${selectedDate}T${date.format('HH:00:00')}Z`);
            console.log(`${selectedDate}T${date.format('HH:00:00')}Z`);
        }
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current: Dayjs) => {
        return current && current < dayjs().startOf('day');
    };

    const disabledHour = (): number[] => {
        const currentHour = dayjs().get('hour');
        const disabledHoursArray: number[] = [];
        if (dayjs().format('YYYY-MM-DD') === selectedDate) {
            for (let i = 0; i < currentHour; i++) {
                disabledHoursArray.push(i);
            }
        }
        return disabledHoursArray;
    };

    /* Private Methods */
    const removeByOrder = (array: number[], position: number): number[] => {
        const removedPosition: number[] = array.splice(position, 1);
        if (removedPosition[0] == array.length + 1) {
            return array;
        }
        array.map((value: number, index: number, arr: number[]) => {
            if (value > removedPosition[0]) {
                value--;
            }
            arr[index] = value;
        });
        return array;
    };

    const getSitePolicyActionTypeParameters = (actionTypeId: string) => {
        setIsLoading(true);
        const siteId: string = selectedSite === undefined ? '' : selectedSite?.id;
        sitePoliciesService
            .getSitePolicyActionTypeParameters(siteId, actionTypeId)
            .then((data) => {
                sitePolicyActionTypes.map((value: SitePolicyActionType) => {
                    if (value.id == actionTypeId) {
                        value.params = mapSitePolicyActionParamDtos(data);
                    }
                });

                setSitePolicyActionTypes(sitePolicyActionTypes);
            })
            .catch(() => {
                if (session.token) {
                    messenger.showErrorMessage({ key: 'site-policies.list-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const mapSitePolicyActionParamDtos = (dtos: SitePolicyActionTypeParamsDto[]): CascadeValue[] | undefined => {
        if (dtos.length == 0) {
            return undefined;
        }
        const values: CascadeValue[] = [];
        values.push({
            label: dtos[0].name,
            value: dtos[0].id,
            isLeaf: false,
            disabled: true,
        });
        dtos[0].options.forEach((element) => {
            values.push({
                label: element.value,
                value: dtos[0].id + ':' + element.id,
                isLeaf: dtos.length == 1,
                children: mapSitePolicyActionParamDtos(dtos.slice(1, dtos.length)),
            });
        });
        return values;
    };

    const mapSitePolicyActionTypeToCascadeValues = (dto: SitePolicyActionType): CascadeValue => {
        return {
            label: dto.name,
            value: dto.id,
            isLeaf: false,
            children: dto.params,
        };
    };

    // Return state and events
    return {
        cascadeValues: sitePolicyActionTypes.map(mapSitePolicyActionTypeToCascadeValues),
        order,
        form,
        isLoading,
        isFormLoading,
        selectedDate,
        withoutCaducity,
        withoutLimit,
        limit,
        onChangeLimit,
        onFinish,
        onOrderChanged,
        onAddAction,
        onRemoveAction,
        onCancelButtonPressed,
        onPickedDate,
        onPickedHour,
        disabledDate,
        disabledHour,
        onSitePolicyActionTypeParamSelected,
        onLoadSitePolicyActionTypeParam,
        onChangeSwitchWithoutCaducity,
        onChangeSwitchWithoutLimit,
    };
};
