import { OrderTable, PaginationRequest } from 'global/interfaces';
import React, { useContext } from 'react';
import { SearchKeysInterface } from 'services/keys/keys.service';
import { CreateRfidCardInput, SiteRfidCardDto } from 'services/rfidcards/rfidcards.service';
import { CreationCardRow } from '../fragments/massive-load-modal/interfaces';
export interface GetAccessKeysInterface {
    pagination?: PaginationRequest;
    orderTable?: OrderTable;
    search?: SearchKeysInterface;
}
export interface MassiveRfidCardsFormContextInterface {
    rows: CreationCardRow[];
    retryInputValues?: { nbf_date: string; exp_date: string; type_id: number };
    isMassiveLoadModalVisible: boolean;
    isEditModalVisible: boolean;
    isCreationFormLoading: boolean;
    isUserReassignmentModalVisible: boolean;
    selectedCard?: SiteRfidCardDto;
    creationEvent?: EventSource;
    isRetryCreationLoading: boolean;
    setIsRetryCreationLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setCreationEvent: React.Dispatch<React.SetStateAction<EventSource | undefined>>;
    setRows: React.Dispatch<React.SetStateAction<CreationCardRow[]>>;
    setIsMassiveLoadModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setisCreationFormLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUserReassignmentModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedCard: React.Dispatch<React.SetStateAction<SiteRfidCardDto | undefined>>;
    //________________Functions___________
    sendAndSubscribe: (requests: CreateRfidCardInput) => void;
    resetCreationResultStates: () => void;
}

export const MassiveRfidCardsFormContext = React.createContext<MassiveRfidCardsFormContextInterface>({
    rows: [],
    retryInputValues: undefined,
    isMassiveLoadModalVisible: false,
    isEditModalVisible: false,
    isCreationFormLoading: false,
    isUserReassignmentModalVisible: false,
    selectedCard: undefined,
    creationEvent: undefined,
    isRetryCreationLoading: false,
    setIsRetryCreationLoading: () => {
        //
    },
    setCreationEvent: () => {
        //
    },
    setRows: () => {
        //
    },
    setIsMassiveLoadModalVisible: () => {
        //
    },
    setIsEditModalVisible: () => {
        //
    },
    setisCreationFormLoading: () => {
        //
    },
    setIsUserReassignmentModalVisible: () => {
        //
    },
    setSelectedCard: () => {
        //
    },
    sendAndSubscribe: () => {
        //
    },
    resetCreationResultStates: () => {
        //
    },
});

export const useMassiveRfidCardsFormContext = (): MassiveRfidCardsFormContextInterface => {
    const store = useContext(MassiveRfidCardsFormContext);
    return store;
};
