import React from 'react';
import 'fragments/custom-fields-dashboard/fragments/add-custom-field-modal/add-custom-field-modal.scss';
import { AddCustomFieldModalFragmentProps } from 'fragments/custom-fields-dashboard/fragments/add-custom-field-modal/interfaces';
import { useAddCustomFieldModalController } from 'fragments/custom-fields-dashboard/fragments/add-custom-field-modal/add-custom-field-modal.controller';
import { Button, Form, Input, Modal, Select, Switch, Tag, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const { Text, Title } = Typography;

export const AddCustomFieldModalFragment: React.FC<AddCustomFieldModalFragmentProps> = (props) => {
    const { useController = useAddCustomFieldModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const fieldTypeOptions = controller.fieldTypeOptions.map((opt) => (
        <Option key={opt.id} value={opt.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {opt.value}
            </div>
        </Option>
    ));

    // Render
    return (
        <Modal
            open={controller.isAddFieldModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'custom-fields.add-new-custom-field' })}
                </Title>
            }
            className={'add-custom-field-modal'}
            closable={false}
            centered
            footer={[
                <Button
                    key="back"
                    className={'btn-cancel'}
                    onClick={controller.onCancelButtonPressed}
                    disabled={controller.isLoadingAdd}
                >
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form="create-add-custom-field-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isLoadingAdd}
                    disabled={controller.isLoadingAdd || controller.fieldNameError}
                    htmlType="submit"
                >
                    {translate({ key: 'general.generate' })}
                </Button>,
            ]}
        >
            <Form
                id="create-add-custom-field-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                form={controller.form}
                onFinish={controller.onCreateButtonPressed}
            >
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.name' })}
                        </Text>
                    }
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                    name="name"
                    validateStatus={controller.fieldNameError ? 'error' : undefined}
                    help={controller.fieldNameError ? translate({ key: 'custom-fields.name-error' }) : undefined}
                >
                    <Input
                        placeholder={translate({ key: 'form.placeholder.custom-fields.name' })}
                        maxLength={100}
                        minLength={3}
                        onChange={controller.onNameInputChange}
                        disabled={controller.isLoadingAdd}
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.optional-field' })}
                        </Text>
                    }
                    name="optional"
                >
                    <Switch
                        checkedChildren="Opcional"
                        unCheckedChildren="Requerido"
                        onChange={controller.onChangeOptionalSwitch}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginTop: '1.5rem' }}
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.field-type' })}
                        </Text>
                    }
                    name="field_type"
                    required
                    rules={[
                        {
                            required: !controller.selectedFieldType,
                            message: translate({ key: 'form.rules-required' }),
                        },
                    ]}
                    initialValue={undefined}
                >
                    <Select
                        placeholder={translate({ key: 'form.placeholder.custom-fields.type' })}
                        value={controller.selectedFieldType}
                        onChange={controller.onSelectFieldTypeOption}
                        style={{ width: '100%' }}
                        loading={controller.isLoadingAdd}
                        disabled={controller.isLoadingAdd}
                    >
                        {fieldTypeOptions}
                    </Select>
                </Form.Item>
                {controller.selectedFieldType == 4 && (
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.select-values' })}
                            </Text>
                        }
                        name="default-values"
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                            <Input
                                placeholder={translate({ key: 'form.placeholder.custom-fields.default-value' })}
                                maxLength={200}
                                minLength={1}
                                onChange={controller.onChangeDefaultValue}
                                disabled={controller.isLoadingAdd}
                                onSubmit={() => {
                                    console.log('submit');
                                }}
                            />

                            <Button
                                className={'button'}
                                type="default"
                                style={{ justifySelf: 'flex-end', padding: '3px' }}
                                onClick={controller.onAddDefaultValue}
                                icon={<PlusOutlined />}
                                disabled={controller.isLoadingAdd}
                            />
                        </div>
                        {controller.defaultValues.length > 0 && (
                            <div
                                style={{
                                    border: '1px solid #ddd',
                                    borderRadius: '5px',
                                    padding: '5px',
                                    marginTop: '10px',
                                }}
                            >
                                {controller.defaultValues.map((v) => (
                                    <Tag
                                        style={{ marginBottom: '1px' }}
                                        key={v}
                                        closable
                                        onClose={(e) => {
                                            e.preventDefault();
                                            controller.onRemoveDefaultValue(v);
                                        }}
                                    >
                                        {v}
                                    </Tag>
                                ))}
                            </div>
                        )}
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};
