export interface SseEvent<T> {
    sub_event_stream_id: string;
    EventID: string;
    event_type: string;
    extended_info: T;
}

export enum ReportFilesEventTypes {
    //Report files
    EVENT_CREATE_REPORT_FILE_STARTED = 'report_file.create_report_file.started',
    EVENT_CREATE_REPORT_FILE_SUCCEEDED = 'report_file.create_report_file.succeeded',
    EVENT_CREATE_REPORT_FILE_FAILED = 'report_file.create_report_file.failed',

    //others
    EVENT_HEARTBEAT = 'heartbeat',
    EVENT_TIMEOUT = 'timeout',
}

export interface SseEventServiceReportFiles {
    subscribeReporFilesEvent(timestamp: number): EventSource;
}
