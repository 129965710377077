import React from 'react';
import 'fragments/operations/fragments/operation-action-preview-list/operation-action-preview-list.scss';
import {
    OperationActionPreviewListFragmentProps,
    PreviewActionItemTableRow,
} from 'fragments/operations/fragments/operation-action-preview-list/interfaces';
import { useOperationActionPreviewListController } from 'fragments/operations/fragments/operation-action-preview-list/operation-action-preview-list.controller';
import { Button, Table, Tag, Tooltip, Typography, Modal, Space } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CheckCircleOutlined, InfoCircleOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Text } = Typography;
const { confirm } = Modal;

export const OperationActionPreviewListFragment: React.FC<OperationActionPreviewListFragmentProps> = (props) => {
    const { useController = useOperationActionPreviewListController } = props;
    const controller = useController();

    const { translate } = useTranslator();
    const showConfirmDeleteRowModal = (id: string) => {
        confirm({
            title: <Text strong>{translate({ key: 'operations.command-modal-title' })}</Text>,
            icon: <InfoCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'button primary' },
            okText: translate({ key: 'general.delete' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onDeleteRow(id);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };
    const showConfirmGenerateModal = () => {
        confirm({
            title: <Text strong>{translate({ key: 'operations.preview-generate-modal-title' })}</Text>,
            icon: <InfoCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'button primary' },
            okText: translate({ key: 'general.generate' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onGenerateButtonPressed();
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };

    // Render
    return (
        <div className={'operation-action-preview-list'}>
            <div
                style={{
                    backgroundColor: '#fff',
                    marginBottom: '1.5rem',
                    padding: '1rem',
                    width: '100%',
                    gap: '10px',
                    display: 'flex',
                }}
            >
                <div style={{ width: '100%', backgroundColor: '#ff000030', padding: '10px' }}>
                    <Text strong style={{ color: 'red' }}>
                        {translate({ key: 'general.notes' })}:{' '}
                    </Text>
                    <Text strong style={{ color: 'red' }}>
                        {translate({ key: 'operations.preview-message' })}
                    </Text>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '10px',
                }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    {!!controller.totalOperationActions && (
                        <>
                            <Paragraph
                                editable={
                                    controller.checkError === 'name' && {
                                        onChange: (value) => controller.onChangeOperationName(value),
                                    }
                                }
                                style={{
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    paddingRight: '2rem',
                                    marginBottom: 0,
                                    color: controller.checkError === 'name' ? 'red' : undefined,
                                }}
                            >
                                {controller.createOperationName}
                            </Paragraph>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tag color="default" style={{ fontWeight: 'bold' }} icon={<CheckCircleOutlined />}>
                                    {`${translate({ key: 'label.total' })}: ${controller.totalOperationActions}`}
                                </Tag>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tag color="error" style={{ fontWeight: 'bold' }} icon={<CloseCircleOutlined />}>
                                    {`${translate({ key: 'label.errors' })}: ${controller.invalidRows}`}
                                </Tag>
                            </div>
                        </>
                    )}
                </div>
                <Space>
                    <Button
                        className=" primary"
                        type="primary"
                        style={{ padding: '3px', fontWeight: '600' }}
                        onClick={controller.onGoBackButtonPressed}
                        disabled={controller.isLoading}
                    >
                        <Tooltip
                            placement="topLeft"
                            title={translate({
                                key: `operations.tooltips.preview-actions.discard-and-back`,
                            })}
                        >
                            {translate({ key: 'general.back' })}
                        </Tooltip>
                    </Button>

                    {(controller.invalidRows > 0 || controller.checkError === 'name') && (
                        <Button
                            className="primary"
                            type="primary"
                            style={{ padding: '3px', fontWeight: '600' }}
                            onClick={controller.onRevalidateButtonPressed}
                            disabled={controller.isLoadingCheck || controller.totalOperationActions == 0}
                            loading={controller.isLoadingCheck}
                        >
                            {translate({ key: 'label.revalidate' })}
                        </Button>
                    )}
                    <Tooltip
                        placement="topLeft"
                        title={
                            controller.invalidRows
                                ? translate({
                                      key: `operations.tooltips.preview-actions.error-rows`,
                                  })
                                : ''
                        }
                    >
                        <div>
                            <Button
                                className="primary"
                                type="primary"
                                style={{ padding: '3px', fontWeight: '600' }}
                                onClick={showConfirmGenerateModal}
                                disabled={
                                    controller.isLoading ||
                                    controller.invalidRows > 0 ||
                                    controller.totalOperationActions == 0 ||
                                    controller.checkError === 'name'
                                }
                            >
                                {translate({ key: 'operations.generate-all-actions' })}
                            </Button>
                        </div>
                    </Tooltip>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        key: 'index',
                        title: translate({ key: 'label.index' }),
                        dataIndex: 'index',
                        sorter: !!controller.totalOperationActions,
                        sortOrder: controller.sortedInfo.columnKey === 'index' ? controller.sortedInfo.order : null,
                        render: (index) => (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    maxWidth: '80px',
                                    width: '100%',
                                }}
                            >
                                {index}
                            </div>
                        ),
                    },
                    {
                        key: 'label',
                        title: translate({ key: 'label.label' }),
                        dataIndex: 'label',
                        sorter: !!controller.totalOperationActions,
                        sortOrder: controller.sortedInfo.columnKey === 'label' ? controller.sortedInfo.order : null,
                        render: (label, { id }) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                <Paragraph
                                    style={{
                                        marginBottom: 0,
                                        color: label.color,
                                    }}
                                    editable={
                                        label.editable && {
                                            onChange: (value) => controller.onFieldChange(id, label.key, value),
                                        }
                                    }
                                >
                                    {label.value}
                                </Paragraph>
                                {label.editable && (
                                    <Tooltip placement="topLeft" title={label.message}>
                                        <InfoCircleOutlined
                                            style={{ display: 'flex', alignSelf: 'center', color: '#1677FF' }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'card_number',
                        title: translate({ key: 'label.card' }),
                        dataIndex: 'card_number',
                        render: (card_number, { id }) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                <Paragraph
                                    style={{
                                        marginBottom: 0,
                                        color: card_number.color,
                                    }}
                                    editable={
                                        card_number.editable && {
                                            onChange: (value) => controller.onFieldChange(id, card_number.key, value),
                                        }
                                    }
                                >
                                    {card_number.value}
                                </Paragraph>
                                {card_number.editable && (
                                    <Tooltip placement="topLeft" title={card_number.message}>
                                        <InfoCircleOutlined
                                            style={{ display: 'flex', alignSelf: 'center', color: '#1677FF' }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'device_name',
                        title: translate({ key: 'label.device' }),
                        dataIndex: 'device_name',
                        sorter: !!controller.totalOperationActions,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'device_name' ? controller.sortedInfo.order : null,
                        render: (device_name) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {device_name}
                            </div>
                        ),
                    },
                    {
                        key: 'device_id',
                        title: translate({ key: 'label.device-id' }),
                        dataIndex: 'device_id',
                        render: (device_id, { id }) => (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    gap: '6px',
                                    minWidth: '140px',
                                }}
                            >
                                <Paragraph
                                    style={{
                                        marginBottom: 0,
                                        color: device_id.color,
                                    }}
                                    editable={
                                        device_id.editable && {
                                            onChange: (value) => controller.onFieldChange(id, device_id.key, value),
                                        }
                                    }
                                >
                                    {device_id.value}
                                </Paragraph>
                                {device_id.editable && (
                                    <Tooltip placement="topLeft" title={device_id.message}>
                                        <InfoCircleOutlined
                                            style={{ display: 'flex', alignSelf: 'center', color: '#1677FF' }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'status_id',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'status_id',
                        showSorterTooltip: false,
                        sorter: !!controller.totalOperationActions,
                        sortOrder: controller.sortedInfo.columnKey === 'status_id' ? controller.sortedInfo.order : null,
                        render: (status_id) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {status_id == 1 ? (
                                    <Tag color="success" style={{ fontWeight: 'bold' }} icon={<CheckCircleOutlined />}>
                                        {translate({ key: `operations.valid-action` })}
                                    </Tag>
                                ) : (
                                    <Tag color="error" style={{ fontWeight: 'bold' }} icon={<CloseCircleOutlined />}>
                                        {translate({ key: `operations.invalid-action` })}
                                    </Tag>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'actions',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id }) => (
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
                                <Button
                                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                                    onClick={() => showConfirmDeleteRowModal(id)}
                                />
                            </div>
                        ),
                    },
                ]}
                dataSource={controller.previewActionListPaginated}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalOperationActions,
                    pageSizeOptions: ['5', '10', '20'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isLoading}
                rowKey={(previewActionList: PreviewActionItemTableRow) => previewActionList.id}
            />
        </div>
    );
};
