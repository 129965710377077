import React from 'react';
import 'fragments/access-reports-dashboard/access-reports-dashboard.scss';
import {
    AccessReportsDashboardFragmentProps,
    AccessReportTableRow,
} from 'fragments/access-reports-dashboard/interfaces';
import { useAccessReportsDashboardController } from 'fragments/access-reports-dashboard/access-reports-dashboard.controller';
import { Button, Col, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { FilterModalAccessReportsFragment } from './fragments/filter-modal-access-reports/filter-modal-access-reports.fragment';
const { Text } = Typography;
import FilterOutlinedIcon from 'assets/icons/filter-outlined-icon';
import RfidIcon from 'assets/icons/rfid-icon';
import KeyBtIcon from 'assets/icons/key-bt-icon';
import FingerprintIcon from 'assets/icons/fingerprint-icon';
import PasscodeIcon from 'assets/icons/passcode-icon';
import ButtonIcon from 'assets/icons/button-icon';
import RemoteAccessIcon from 'assets/icons/remote-access-icon';
import { AccessReportCustomField } from 'services/access-reports/access-reports.service';

export const AccessReportsDashboardFragment: React.FC<AccessReportsDashboardFragmentProps> = (props) => {
    const { useController = useAccessReportsDashboardController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const getIcon = (type: number) => {
        switch (type) {
            case 1:
                return <KeyBtIcon style={{ width: '30px', height: '30px' }} />;
            case 4:
                return <PasscodeIcon style={{ width: '30px', height: '30px' }} />;
            case 7:
                return <RfidIcon style={{ width: '30px', height: '30px' }} />;
            case 8:
                return <FingerprintIcon style={{ width: '30px', height: '30px' }} />;
            case 12:
                return <RemoteAccessIcon style={{ width: '30px', height: '30px' }} />;
            case 46:
                return <ButtonIcon style={{ width: '30px', height: '30px' }} />;
            default:
                return <></>;
        }
    };

    const formatCustomFields = (fields: AccessReportCustomField[]) => {
        return fields.map((field) => (
            <Col key={field.name}>
                <Text strong>{field.name}:</Text>
                <Text> {field.value}</Text>
            </Col>
        ));
    };

    const TableV1 = () => {
        return (
            <Table
                columns={[
                    {
                        key: 'userFullName',
                        title: translate({ key: 'label.name' }),
                        dataIndex: 'userFullName',
                        sorter: !!controller.totalReports,
                    },
                    {
                        key: 'userEmail',
                        title: translate({ key: 'label.email' }),
                        dataIndex: 'userEmail',
                        sorter: !!controller.totalReports,
                    },
                    {
                        key: 'lockName',
                        title: translate({ key: 'label.lock' }),
                        dataIndex: 'lockName',
                        sorter: !!controller.totalReports,
                    },
                    {
                        key: 'date',
                        title: translate({ key: 'label.date' }),
                        dataIndex: 'reportDateTime',
                        sorter: !!controller.totalReports,
                    },
                ]}
                dataSource={controller.itemsViewModel}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalReports,
                    pageSizeOptions: ['20', '30', '40'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isTableLoading}
                rowKey={(itemsViewModel: AccessReportTableRow) => itemsViewModel.id}
            />
        );
    };
    const TableV2 = () => {
        return (
            <Table
                columns={[
                    {
                        key: 'userFullName',
                        title: translate({ key: 'label.name-label' }),
                        dataIndex: 'userFullName',
                        sorter: !!controller.totalReports,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'userFullName' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'userEmail',
                        title: translate({ key: 'label.email-identifier' }),
                        dataIndex: 'userEmail',
                        sorter: !!controller.totalReports,
                        sortOrder: controller.sortedInfo.columnKey === 'userEmail' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'lockName',
                        title: translate({ key: 'label.lock' }),
                        dataIndex: 'lockName',
                        sorter: !!controller.totalReports,
                        sortOrder: controller.sortedInfo.columnKey === 'lockName' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'recordType',
                        title: translate({ key: 'label.access-method' }),
                        // title: 'Modo de Apertura',
                        dataIndex: 'type',
                        sorter: !!controller.totalReports,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'recordType' ? controller.sortedInfo.order : null,
                        render: (type, { typeId }) => (
                            <div
                                style={{ display: 'flex', alignItems: 'center', gap: '6px', justifyContent: 'center' }}
                            >
                                {type === translate({ key: 'general.unknown' }) ? (
                                    <Text>{type}</Text>
                                ) : (
                                    <Tooltip title={type}>{getIcon(typeId)}</Tooltip>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'status',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'success',
                        sortOrder: controller.sortedInfo.columnKey === 'status' ? controller.sortedInfo.order : null,
                        sorter: !!controller.totalReports,
                        render: (success) => (
                            <div>
                                {success === 'Exito' ? (
                                    <Tag color="success" style={{ fontWeight: 'bold' }}>
                                        {success}
                                    </Tag>
                                ) : (
                                    <Tag color="error" style={{ fontWeight: 'bold' }}>
                                        {success}
                                    </Tag>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'date',
                        title: translate({ key: 'label.date' }),
                        dataIndex: 'reportDateTime',
                        sortOrder: controller.sortedInfo.columnKey === 'date' ? controller.sortedInfo.order : null,
                        sorter: !!controller.totalReports,
                    },
                ]}
                dataSource={controller.itemsViewModel}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalReports,
                    pageSizeOptions: ['20', '30', '40'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isTableLoading}
                rowKey={(itemsViewModel: AccessReportTableRow) => itemsViewModel.id}
                expandable={{
                    rowExpandable: (record) => record.typeId === 7 && !!record.customFields,
                    expandedRowRender: (report) => {
                        return (
                            <div>
                                {report.customFields && (
                                    <Row style={{ gap: '0 2rem' }}>{formatCustomFields(report.customFields)}</Row>
                                )}
                            </div>
                        );
                    },
                }}
            />
        );
    };
    // Render
    return (
        <div className={'access-reports-dashboard'}>
            {controller.isSuperAdmin && (
                <div style={{ backgroundColor: '#fff', marginBottom: '1.5rem', padding: '1rem', width: '100%' }}>
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: '#f2f2f2',
                            padding: '10px',
                        }}
                    >
                        <Text strong>Notas: </Text>

                        <Text>Bienvenidos a Accefy!</Text>
                    </div>
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '20px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <FilterModalAccessReportsFragment />
                </div>
                <Space>
                    <Tooltip title={translate({ key: 'general.filters' })}>
                        <Button
                            className={'button'}
                            type="default"
                            style={{ justifySelf: 'flex-end', padding: '3px' }}
                            onClick={controller.onOpenFilterModal}
                            icon={<FilterOutlinedIcon style={{ width: '22px', height: '22px' }} />}
                        />
                    </Tooltip>
                </Space>
            </div>

            {controller.toggleTable ? <TableV2 /> : <TableV1 />}
        </div>
    );
};
