import React from 'react';
import 'components/modal-form/modal-form.scss';
import { Form, Modal, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';

export interface ModalFormProps extends FormProps {
    isVisible: boolean;
    isLoading: boolean;
    title?: string;
    onCancel?: () => void;
    children?: React.ReactNode;
}

const ModalForm: React.FC<ModalFormProps> = (props) => {
    return (
        <Modal
            className={props.className}
            centered
            open={props.isVisible}
            cancelButtonProps={{ disabled: props.isLoading }}
            okButtonProps={{ loading: props.isLoading }}
            closable={!props.isLoading}
            maskClosable={!props.isLoading}
            onCancel={props.onCancel}
            onOk={props.form?.submit}
        >
            {props.title ? <Typography.Title level={3}>{props.title}</Typography.Title> : null}

            <Form colon={false} {...props}>
                {props.children}
            </Form>
        </Modal>
    );
};

export default ModalForm;
