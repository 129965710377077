import { OperationActionCommand, OperationActionDto } from 'services/operation-actions/operation-actions.service';
import { OperationType } from 'services/operations/operations.service';
import { SseEvent } from 'services/sse/sse.service';

export interface OperationActionListController {
    /* State */
    operationInfo?: OperationInfo;
    itemsViewModelTable: ActionTableRow[];
    totalOperationActions: number;
    isLoadingActions: boolean;
    isLoadingActionCommand: boolean;
    isLoadingOperationCommand: boolean;
    eventError: boolean;
    /* Events */
    onReloadButtonPressed: () => void;
    onGoBackButtonPressed: () => void;
    onCommandOperationButtonPressed: (operationId: string, statusId: number) => void;
    onCommandActionButtonPressed: (actionId: string, command: OperationActionCommand) => void;
}

export interface OperationActionListFragmentProps {
    useController?: () => OperationActionListController;
}
export interface ActionTableRow {
    id: string;
    label: string;
    deviceName: string;
    cardNumber: string;
    statusId: number;
    type: string;
    retryCount: number;
    createdAt: string;
    createdByEmail: string;
    updatedAt: string;
    updatedByEmail: string;
    msg?: string;
}
export interface OperationInfo {
    id: string;
    name: string;
    count: {
        finished: number;
        total: number;
    };
    typeId: OperationType;
    type: string;
    statusId: number;
}

export enum ActionListActionTypes {
    HANDLE_EVENT = 'HANDLE_EVENT',
    SET_INITIAL_STATE = 'SET_INITIAL_STATE',
}
export interface ActionListStateAction {
    type: ActionListActionTypes;
    payload: SseEvent<{ status_id?: number }> | OperationActionDto[];
}

export type HandleEventAction = {
    type: ActionListActionTypes.HANDLE_EVENT;
    payload: SseEvent<{ status_id?: number }>;
};

export type SetInitialStateAction = {
    type: ActionListActionTypes.SET_INITIAL_STATE;
    payload: OperationActionDto[];
};

export type ReducerAction = SetInitialStateAction | HandleEventAction;
export interface ActionListState {
    actions: OperationActionDto[];
    events: SseEvent<{ status_id?: number }>[];
    operationInfo?: OperationInfo;
}
