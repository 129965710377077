import { useState, useEffect } from 'react';
import { SitePoliciesController, SitePolicyTableRow } from 'fragments/site-policies/interfaces';
import { NewSitePolicyDto } from 'services/site-policies/site-policies.service';
import { useSitePoliciesContext } from 'fragments/site-policies/context/site-policies.context';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPISitePolicies } from 'services/site-policies/api-policies.service';
import dayjs from 'dayjs';
import { TablePaginationConfig } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';

import download from 'tools/download';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { capitalize } from 'tools/string-handling';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

export const useSitePoliciesController = (
    sitePoliciesService = useAPISitePolicies(),
    messenger = useMessenger(),
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */ SitePoliciesController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDownloadOne, setIsLoadingDownloadOne] = useState(false);
    const [isLoadingDownloadSelected, setIsLoadingDownloadSelected] = useState(false);
    const [isLoadingDownloadAll, setIsLoadingDownloadAll] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingValidate, setIsLoadingValidate] = useState(false);
    const [downloadPolicyId, setDownloadPolicyId] = useState<string>('');
    const [deletePolicyId, setDeletePolicyId] = useState<string>('');
    const [controlPolicyId, setControlPolicyId] = useState<string>('');
    const [editPolicyId, setEditPolicyId] = useState<string>('');
    const [session] = useLocalSession();

    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
    const [selectedPolicyIdsForDownload, setSelectedPolicyIdsForDownload] = useState<string[]>([]);
    const [selectedPolicyNamesForDownload, setSelectedPolicyNamesForDownload] = useState<string[]>([]);

    const {
        sitePolicies,
        searchInput,
        setIsCreateSitePolicyModalVisible,
        setSitePolicyActionTypes,
        setIsFilterModalVisible,
        setIsViewRedeemsModalVisible,
        expandedRowKeys,
        setExpandedRowKeys,
        expandAll,
        setExpandAll,
        totalSitePolicies,
        currentPage,
        pageSize,
        isTableLoading,
        operatorSwitch,
        selectedLockIds,
        selectedStatusIds,
        getSitePolicies,
        sortedInfo,
        selectedPolicyIdForViewRedeemers,
        setSelectedPolicyIdForViewRedeemers,
        setIsControlModalVisible,
        setIsEditModalVisible,
    } = useSitePoliciesContext();
    const { selectedSite } = session;

    /* Listeners */

    useEffect(() => {
        if (session.token && session.selectedSite) {
            setIsSuperAdmin(session.user_profile?.role === 'superadmin');
            getSitePolicies({
                pagination: { page: 0, pageSize: 20 },
                orderTable: { order: undefined, orderBy: undefined },
            });
            setSelectedPolicyIdForViewRedeemers('');
            setDownloadPolicyId('');
            setSelectedPolicyIdsForDownload([]);
            setSelectedPolicyNamesForDownload([]);
        }
    }, [session.selectedSite]);

    /* View Events */

    // _______________________Button Events____________________
    const onAddSitePolicyButtonPressed = () => {
        setIsCreateSitePolicyModalVisible(true);
        getSitePolicyActionTypes();
    };
    const onOpenControlModal = (id: string) => {
        setControlPolicyId(id);
        setIsControlModalVisible(true);
    };

    const onOpenEditModal = (id: string) => {
        setEditPolicyId(id);
        getSitePolicyActionTypes();
        setIsEditModalVisible(true);
    };

    const onDownloadSelectedPoliciesButtonPressed = () => {
        if (!isLoadingDownloadSelected) {
            setIsLoadingDownloadSelected(true);
            sitePoliciesService
                .downloadPolicies(selectedPolicyIdsForDownload)
                .then((data) => {
                    download(data, `selected-policies-${session.selectedSite?.name}.pdf`);
                })
                .catch(() => {
                    if (session.token) {
                        messenger.showErrorMessage({ key: 'site-policies.download-error' });
                    }
                })
                .finally(() => {
                    setIsLoadingDownloadSelected(false);
                });
        }
    };
    const onDownloadAllButtonPressed = () => {
        if (!isLoadingDownloadAll) {
            setIsLoadingDownloadAll(true);
            sitePoliciesService
                .downloadPolicies()
                .then((data) => {
                    download(data, `all-policies-${session.selectedSite?.name}.pdf`);
                })
                .catch(() => {
                    if (session.token) {
                        messenger.showErrorMessage({ key: 'site-policies.download-error' });
                    }
                })
                .finally(() => {
                    setIsLoadingDownloadAll(false);
                });
        }
    };
    const onFilterButtonPressed = () => {
        setIsFilterModalVisible(true);
    };
    const onExpandAllRowsButtonPressed = () => {
        setExpandedRowKeys(expandAll ? [] : sitePolicies.map((p) => p.id));
        setExpandAll(!expandAll);
    };
    const onExpandOneRowButtonPressed = (id: string, expanded: boolean) => {
        if (expanded) {
            setExpandedRowKeys([...expandedRowKeys, id]);
        } else {
            setExpandedRowKeys(expandedRowKeys.filter((key) => key !== id));
        }
    };
    const onViewRedeemsButtonPressed = (id: string) => {
        setIsViewRedeemsModalVisible(true);
        setSelectedPolicyIdForViewRedeemers(id);
    };
    const onDownloadOneButtonPressed = (id: string) => {
        if (!isLoadingDownloadOne) {
            setIsLoadingDownloadOne(true);
            setDownloadPolicyId(id);
            sitePoliciesService
                .downloadOnePolicyById(id)
                .then((data) => {
                    const policy = sitePolicies.find((policy) => policy.id === id);
                    download(data, `policy-${policy?.name}.pdf`);
                })
                .catch(() => {
                    if (session.token) {
                        messenger.showErrorMessage({ key: 'site-policies.download-error' });
                    }
                })
                .finally(() => {
                    setIsLoadingDownloadOne(false);
                    setDownloadPolicyId('');
                });
        }
    };
    const onConfirmDeleteButtonPressed = (id: string) => {
        if (!isLoadingDelete) {
            setIsLoadingDelete(true);
            setDeletePolicyId(id);
            sitePoliciesService
                .deletePolicy(id)
                .then((data) => {
                    messenger.showSuccessMessage({ key: 'site-policies.delete-success' });
                    getSitePolicies({
                        pagination: {
                            page: sitePolicies.length === 1 && currentPage > 0 ? currentPage - 1 : currentPage,
                            pageSize,
                        },
                        search: {
                            searchInput,
                            locks: selectedLockIds,
                            status: selectedStatusIds,
                            operator: operatorSwitch,
                        },
                    });
                })
                .catch(() => {
                    if (session.token) {
                        messenger.showErrorMessage({ key: 'site-policies.delete-error' });
                    }
                })
                .finally(() => {
                    setIsLoadingDelete(false);
                    setDeletePolicyId('');
                });
        }
    };

    // _______________________Table Events____________________
    const rowSelection: TableRowSelection<SitePolicyTableRow> = {
        onChange: (selectedRowKeys, selectedRows) => {
            const validPolicies = selectedRows.filter(
                (policy) =>
                    (policy.status.id === 1 && dayjs(policy.expires_at) >= dayjs()) ||
                    // dayjs(policy.expires_at !== 'Nunca' ? policy.expires_at : undefined) >= dayjs()) ||
                    (policy.status.id === 1 && policy.expires_at === 'Nunca'),
            );
            setSelectedPolicyIdsForDownload(validPolicies.map((policy) => policy.id));
            setSelectedPolicyNamesForDownload(
                validPolicies.map((policy) => policy.name).sort((a, b) => a.localeCompare(b)),
            );
        },
        onSelect: (record, selected, selectedRows) => {
            // console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            // console.log(selected, selectedRows, changeRows);
        },
    };

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        getSitePolicies({
            pagination: { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            orderTable: {
                order: sorter.order,
                orderBy: sorter.column?.key,
            },
            search: {
                searchInput,
                locks: selectedLockIds,
                status: selectedStatusIds,
                operator: operatorSwitch,
            },
        });
    };

    /* Private Methods */
    const getSitePolicyActionTypes = () => {
        setIsLoading(true);
        sitePoliciesService
            .getSitePolicyActionTypes()
            .then((data) => {
                setSitePolicyActionTypes(data);
            })
            .catch(() => {
                if (session.token) {
                    messenger.showErrorMessage({ key: 'site-policies.list-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getStatusData = (dto: NewSitePolicyDto): { id: number; label: string; color: string } => {
        switch (dto.status) {
            case 0:
                return {
                    id: dto.status,
                    label: translate({ key: 'site-policies.status-options.stand-by' }),
                    color: 'processing',
                };
            case 1:
                return {
                    id: dto.status,
                    label: translate({ key: 'site-policies.status-options.enabled' }),
                    color: 'success',
                };
            case 2:
                return {
                    id: dto.status,
                    label: translate({ key: 'site-policies.status-options.in-review' }),
                    color: 'cyan',
                };
            case 3:
                return {
                    id: dto.status,
                    label: translate({ key: 'site-policies.status-options.finished' }),
                    color: 'error',
                };
            case 4:
                return {
                    id: dto.status,
                    label: translate({ key: 'site-policies.status-options.deleted' }),
                    color: 'error',
                };
            case 5:
                return {
                    id: dto.status,
                    label: translate({ key: 'site-policies.status-options.expired' }),
                    color: 'error',
                };
            default:
                return { id: dto.status, label: '-', color: 'default' };
        }
    };

    const mapDtoToTableRow = (dto: NewSitePolicyDto): SitePolicyTableRow => {
        return {
            id: dto.id,
            name: dto.name,
            status: getStatusData(dto),
            created_by_email: dto.created_by_email,
            created_by_name: capitalize(dto.created_by_name),
            validated_by_email: dto.validated_by_email || '-',
            validated_at: dto.validated_at ? dayjs.utc(dto.validated_at).format('DD/MM/YYYY HH:mm') + ' (UTC)' : '-',
            // created_at: dayjs.utc(dto.created_at).format('DD/MM/YYYY HH:mm') + ' (' + selectedSite?.time_zone + ')',
            created_at: dayjs.utc(dto.created_at).format('DD/MM/YYYY HH:mm') + ' (UTC)',
            expires_at:
                dto.expires_at && !dayjs(dto.expires_at).isSameOrBefore('1970-01-01', 'year')
                    ? dayjs.utc(dto.expires_at).format('DD/MM/YYYY HH:mm') + ' (UTC)'
                    : translate({ key: 'general.never' }),
            last_redeemer: dto.last_redeemer || '-',
            count: {
                redeem_total: dto.redeem_total,
                redeems: dto.redeems,
            },
        };
    };

    // Return state and events
    return {
        isSuperAdmin,
        itemsViewModel: sitePolicies.map(mapDtoToTableRow),
        rowSelection,
        isLoadingDownloadOne,
        isLoadingDownloadSelected,
        isLoadingDownloadAll,
        isLoadingDelete,
        isLoadingValidate,
        downloadPolicyId,
        deletePolicyId,
        selectedPolicyIdForViewRedeemers,
        totalSitePolicies,
        currentPage,
        pageSize,
        expandAll,
        sortedInfo,
        isTableLoading,
        isLoading,
        selectedPolicyIdsForDownload,
        selectedPolicyNamesForDownload,
        expandedRowKeys,
        controlPolicyId,
        editPolicyId,

        handleTableChange,
        onAddSitePolicyButtonPressed,
        onDownloadAllButtonPressed,
        onDownloadSelectedPoliciesButtonPressed,
        onFilterButtonPressed,
        onViewRedeemsButtonPressed,
        onDownloadOneButtonPressed,
        onExpandOneRowButtonPressed,
        onExpandAllRowsButtonPressed,
        onConfirmDeleteButtonPressed,
        onOpenControlModal,
        onOpenEditModal,
    };
};
