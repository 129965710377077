import { useEffect, useState } from 'react';
import { PasscodesController, PasscodeTableRow } from 'fragments/passcodes/interfaces';
import { useAPIPasscodes } from 'services/passcodes/api-passcodes.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { usePasscodesContext } from './context/passcodes.context';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { TablePaginationConfig } from 'antd/es/table';
import { useAPILocks } from 'services/locks/api-locks.service';
import { OrderTable } from 'global/interfaces';
import dayjs from 'dayjs';
import { PasscodesDto } from 'services/passcodes/passcodes.service';
import { capitalize } from 'tools/string-handling';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

export const usePasscodesController = (
    passcodesService = useAPIPasscodes(),
    messenger = useMessenger(),
    locksService = useAPILocks(),
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */ PasscodesController => {
    /* State */
    const [isVisibleCreatePasscodeModal, setIsVisibleCreatePasscodeModal] = useState<boolean>(false);
    const {
        dateRange,
        searchLabelInput,
        searchCodeInput,
        selectedLockIdsFilterModal,
        passcodes,
        setPasscodes,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        totalPasscodes,
        setTotalPasscodes,
        isTableLoading,
        setIsTableLoading,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        setIsVisibleFilterPasscodeModal,
        locks,
        setLocks,
    } = usePasscodesContext();

    const [session] = useLocalSession();
    const { selectedSite } = session;
    /* Listeners */
    useEffect(() => {
        selectedSite && getPasscodeList(currentPage, pageSize, { order, orderBy });
        selectedSite && getLocks();
    }, [selectedSite]);

    /* View Events */
    const onOpenCreateModal = () => {
        setIsVisibleCreatePasscodeModal(true);
    };
    const onOpenFilterModal = () => {
        getLocks();
        setIsVisibleFilterPasscodeModal(true);
    };

    const onCloseCreateModal = () => {
        setIsVisibleCreatePasscodeModal(false);
    };
    const onFinishCreatePasscode = () => {
        getPasscodeList(currentPage, pageSize, { order, orderBy });
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filter: Record<string, FilterValue | null>,
        sorter: SorterResult<PasscodeTableRow> | SorterResult<PasscodeTableRow>[],
    ) => {
        const sort = Array.isArray(sorter) ? sorter[0] : sorter;
        const sortAsOrder: OrderTable = {
            order: sort.order as 'ascend' | 'descend' | undefined,
            orderBy: sort.column?.key as string,
        };
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setCurrentPage(currentPage === page ? 0 : page);
        setPageSize(currentPageSize);
        setOrder(sortAsOrder.order);
        setOrderBy(sortAsOrder.orderBy);
        getPasscodeList(currentPage === page ? 0 : page, currentPageSize, {
            order: sortAsOrder.order,
            orderBy: sortAsOrder.orderBy,
        });
    };

    /* Private Methods */

    const getPasscodeList = (currentPage: number, pageSize: number, orderTable: OrderTable) => {
        setIsTableLoading(true);
        const search = {
            since: dateRange.from,
            until: dateRange.to,
            label: searchLabelInput,
            code: searchCodeInput,
            locks: selectedLockIdsFilterModal,
        };
        passcodesService
            .listPasscodes(currentPage, pageSize, orderTable, search)
            .then((data) => {
                setPasscodes(data.passcodes);
                setTotalPasscodes(data.total);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'passcodes.list-error' });
                console.log('passcodes-list-error', error);
            })
            .finally(() => {
                setIsTableLoading(false);
            });
    };
    const getLocks = () => {
        // setIsLoadingLocks(true);
        locksService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks.filter((lock) => lock.lock_type_id === 5 && lock));
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                // setIsLoadingLocks(false);
            });
    };
    const mapDtoToTableRow = (dto: PasscodesDto): PasscodeTableRow => {
        const viewModelObject = {
            id: dto.id,
            state: dto.state,
            label: capitalize(dto.label),
            created_by_email: dto.created_by_email || '-',
            lock_name: capitalize(dto.lock_name),
            code: dto.code,
            type: getTypeById(dto.type),
            validity: {
                from: dayjs.utc(dto.since).format('DD/MM'),
                to: dayjs.utc(dto.until).format('DD/MM'),
                fromHour: dayjs.utc(dto.since).format('HH'),
                toHour: dayjs.utc(dto.until).format('HH'),
                tooltip: `Valido desde: ${dayjs.utc(dto.since).format('DD/MM/YYYY HH:00')} hasta: ${dayjs
                    .utc(dto.until)
                    .format('DD/MM/YYYY HH:00')} (${selectedSite?.time_zone})`,
                timeZone: `${selectedSite?.time_zone}`,
            },
        };
        return viewModelObject;
    };
    const getTypeById = (type: number): string => {
        switch (type) {
            case 1:
                return translate({ key: 'passcodes.single-use' });
            case 2:
                return translate({ key: 'passcodes.temporary' });
            default:
                return '';
        }
    };

    // Return state and events
    return {
        isTableLoading,
        locks,
        passcodes,
        currentPage,
        pageSize,
        totalPasscodes,
        isVisibleCreatePasscodeModal,
        handleTableChange,
        onOpenCreateModal,
        onCloseCreateModal,
        onOpenFilterModal,
        onFinishCreatePasscode,
        itemsViewModelTable: passcodes.map(mapDtoToTableRow),
    };
};
