import { useState, useEffect } from 'react';
import {
    EditAdminFormStructure,
    EditAdminModalController,
} from 'fragments/admins-dashboard/fragments/edit-admin-modal/interfaces';
import { LockDto } from 'services/locks/locks.service';
import { useAdminContext } from 'fragments/admins-dashboard/context/admins.context';
import { useAPIAdmin } from 'services/admin/api-admin.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPILocks } from 'services/locks/api-locks.service';
import { CreateAdminInput } from 'services/admin/admin.service';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useEditAdminModalController = (
    adminService = useAPIAdmin(),
    locksService = useAPILocks(),
    messenger = useMessenger(),
): EditAdminModalController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [locks, setLocks] = useState<LockDto[]>([]);
    const [initialValues, setInitialValues] = useState<EditAdminFormStructure>();
    const [selectedLockIds, setSelectedLockIds] = useState<string[]>([]);
    const {
        setIsEditAdminModalVisible,
        selectedAdmin,
        currentPage,
        pageSize,
        setAdmins,
        setTotalAdmins,
        selectedAdminId,
        setSelectedAdminId,
    } = useAdminContext();

    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */
    useEffect(() => {
        if (session.selectedSite) {
            getLocks();
        }
    }, [selectedSite]);

    useEffect(() => {
        // getInitialValuesForSelectedAdmin();
    }, [selectedAdminId]);

    /* View Events */
    const onCancelButtonPressed = () => {
        setInitialValues(undefined);

        setTimeout(() => {
            // Waiting for initialValues state change to take effect
            setIsEditAdminModalVisible(false);
            setSelectedAdminId(undefined);
            setSelectedLockIds([]);
            form.resetFields();
        }, 50);
    };

    const onEditAdminFormSubmit = () => {
        if (!selectedAdmin) return;
        setIsLoading(true);
        const input = {} as Partial<CreateAdminInput>;
        input.site_id = session.selectedSite?.id;
        setIsLoading(false);
    };

    const onLockSelect = (value: string[]) => {
        setSelectedLockIds(value);
    };

    /* Private Methods */
    const getLocks = () => {
        setIsLoading(true);
        locksService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks);
            })
            .catch((error) => {
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Return state and events
    return {
        isLoading,
        locks,
        selectedLockIds,
        form,
        initialValues,
        onLockSelect,
        onEditAdminFormSubmit,
        onCancelButtonPressed,
    };
};
