import { useEffect, useState, useReducer } from 'react';
import {
    ActionTableRow,
    ReducerAction,
    ActionListState,
    ActionListActionTypes,
    OperationActionListController,
} from 'fragments/operations/fragments/operation-action-list/interfaces';
import { OperationActionCommand, OperationActionDto } from 'services/operation-actions/operation-actions.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';
import dayjs from 'dayjs';
import { useAPIOperationActions } from 'services/operation-actions/api-operation-actions.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOperationContext } from 'fragments/operations/context/operations.context';
import { useAPIOperations } from 'services/operations/api-operations.service';
import { OperationCommand } from 'services/operations/operations.service';
import { ErrorDto } from 'services/dtos/errors..dto';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { EventTypes, SseEvent } from 'services/sse/sse.service';

export const useOperationActionListController = (): /* <--Dependency Injections  like services hooks */
OperationActionListController => {
    const { translate } = useTranslator();
    const [session] = useLocalSession();
    const navigate = useNavigate();
    const operationActionsService = useAPIOperationActions();
    const operationsService = useAPIOperations();
    const { selectedSite } = session;
    const messenger = useMessenger();
    const location = useLocation();
    const id = location.state;
    /* State */
    const [eventError, setEventError] = useState<boolean>(false);
    const [eventActionFailedMsg, setEventActionFailedMsg] = useState<{ id: string; msg: string }[]>([]);
    const {
        operationInfo,
        actions,
        totalOperationActions,
        isLoadingActions,
        isLoadingActionCommand,
        isLoadingOperationCommand,
        setOperationInfo,
        setActions,
        setTotalOperationActions,
        setIsLoadingActionCommand,
        setIsLoadingOperationCommand,
        getOperationDetail,
        operationEventSource,
        setOperationEventSource,
    } = useOperationContext();

    /* Listeners */
    useEffect(() => {
        if (operationEventSource) {
            operationEventSource.onerror = (e: any) => {
                setEventError(true);
                operationEventSource.close();
            };
            operationEventSource.onmessage = (event: any) => {
                const eventData: SseEvent<{ status_id?: number }> = JSON.parse(event.data);
                if (eventData.event_type !== EventTypes.EVENT_HEARTBEAT) {
                    dispatch({ type: ActionListActionTypes.HANDLE_EVENT, payload: eventData });
                }
            };
        }
    }, [operationEventSource]);

    useEffect(() => {
        !!id ? getOperationDetail(id) : onGoBackButtonPressed();
        !selectedSite && onGoBackButtonPressed();
    }, [id, selectedSite]);

    useEffect(() => {
        dispatch({ type: ActionListActionTypes.SET_INITIAL_STATE, payload: actions });
    }, [actions]);

    const reducer: React.Reducer<ActionListState, ReducerAction> = (state, { type, payload }) => {
        switch (type) {
            case ActionListActionTypes.SET_INITIAL_STATE:
                return {
                    actions: payload,
                    events: [],
                    operationInfo: operationInfo,
                };
            case ActionListActionTypes.HANDLE_EVENT: {
                let actions = state.actions;
                let operationInfo = state.operationInfo;
                switch (payload.event_type) {
                    case EventTypes.EVENT_OPERATION_ACTION_CARD_STATUS_CHANGED: {
                        const action = actions.find((a) => a.id == payload.sub_event_stream_id);
                        if (action) {
                            const { status_id } = payload.extended_info;
                            actions = actions.map((a) =>
                                a.id == payload.sub_event_stream_id
                                    ? {
                                          ...a,
                                          status_id: status_id!,
                                          retry_count: status_id == 5 ? a.retry_count + 1 : a.retry_count,
                                      }
                                    : a,
                            );
                            if ((status_id == 7 || status_id == 8 || status_id == 9) && operationInfo) {
                                operationInfo = {
                                    ...operationInfo,
                                    count: { ...operationInfo.count, finished: operationInfo.count.finished + 1 },
                                };
                            }
                        }
                        break;
                    }
                    case EventTypes.EVENT_OPERATION_CARD_STOP_SUCCEEDED:
                    case EventTypes.EVENT_OPERATION_CARD_CREATION_SUCCEEDED:
                    case EventTypes.EVENT_OPERATION_CARD_CREATION_FAILED: {
                        const { status_id } = payload.extended_info;
                        if (operationInfo && status_id)
                            operationInfo = {
                                ...operationInfo,
                                statusId: status_id,
                            };
                        operationEventSource?.close();
                        operationInfo && getOperationDetail(operationInfo.id);
                        break;
                    }
                    case EventTypes.EVENT_OPERATION_CARD_FAILED:
                    case EventTypes.EVENT_OPERATION_CARD_STOP_FAILED:
                    case EventTypes.EVENT_OPERATION_CARD_START_FAILED:
                    case EventTypes.EVENT_OPERATION_CARD_RETRY_FAILED:
                    case EventTypes.EVENT_OPERATION_UNHANDLED: {
                        messenger.showErrorMessage({ key: `operations.event-type-error.${payload.event_type}` });
                        operationEventSource?.close();
                        operationInfo && getOperationDetail(operationInfo.id);
                        break;
                    }
                    default:
                        break;
                }
                return {
                    ...state,
                    actions,
                    events: [...state.events, payload],
                    operationInfo,
                };
            }
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(reducer, {
        actions: [],
        events: [],
    });

    /* View Events */
    const onGoBackButtonPressed = () => {
        navigate('/operations', { replace: true });
        setOperationInfo(undefined);
        dispatch({ type: ActionListActionTypes.SET_INITIAL_STATE, payload: [] });
        setActions([]);
        setTotalOperationActions(0);
        operationEventSource && operationEventSource.close();
        setOperationEventSource(undefined);
    };

    const onReloadButtonPressed = () => {
        setEventError(false);
        operationInfo ? getOperationDetail(operationInfo.id) : navigate('/operations', { replace: true });
    };

    const onCommandOperationButtonPressed = (operationId: string, statusId: number) => {
        setIsLoadingOperationCommand(true);
        operationsService
            .operationsCommand(operationId, getOperationCommand(statusId))
            .then(() => {
                operationInfo && getOperationDetail(operationInfo.id);
            })
            .catch((error: ErrorDto) => {
                const err = error.response.data;
                if (err.code == 409 && err.msg == 'site_busy') {
                    messenger.showErrorMessage({ key: 'operations.command-error-site-busy' });
                } else if (err.msg == 'no_actions') {
                    operationInfo ? getOperationDetail(operationInfo.id) : navigate('/operations', { replace: true });
                } else {
                    messenger.showErrorMessage({ key: 'operations.command-error' });
                }
                setIsLoadingOperationCommand(false);
            });
    };

    const onCommandActionButtonPressed = (actionId: string, command: OperationActionCommand) => {
        setIsLoadingActionCommand(true);
        operationActionsService
            .operationActionsCommand(operationInfo?.id || '', actionId, command)
            .then(() => {
                if (operationInfo && operationInfo.statusId != 2 && operationInfo.statusId != 3) {
                    getOperationDetail(operationInfo.id);
                }
            })
            .catch((error: ErrorDto) => {
                messenger.showErrorMessage({ key: 'operations.command-error' });
            })
            .finally(() => {
                setIsLoadingActionCommand(false);
            });
    };

    /* Private Methods */
    const mapDtoToTableRow = (dto: OperationActionDto): ActionTableRow => {
        const msg = eventActionFailedMsg.find((e) => e.id == dto.id)?.msg;
        return {
            id: dto.id,
            label: dto.label,
            deviceName: dto.device_name,
            cardNumber: dto.card_number,
            statusId: dto.status_id,
            type: translate({ key: `operations.actions.type.${dto.type_id}` }),
            retryCount: dto.retry_count,
            createdAt: `${dayjs(dto.created_at).format('DD/MM/YYYY HH:mm')} ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
            createdByEmail: dto.created_by_email,
            updatedAt: `${dayjs(dto.updated_at).format('DD/MM/YYYY HH:mm')} ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
            updatedByEmail: dto.updated_by_email,
            msg: dto.msg || msg,
        };
    };

    const getOperationCommand = (statusId: number): OperationCommand => {
        switch (statusId) {
            case 1:
                return OperationCommand.START;
            case 2:
                return OperationCommand.STOP;
            case 4:
                return OperationCommand.RESUME;
            case 6:
                return OperationCommand.RETRY;
            default:
                return OperationCommand.INVALID;
        }
    };

    // Return state and events
    return {
        operationInfo: state.operationInfo,
        eventError,
        itemsViewModelTable: state.actions.map(mapDtoToTableRow),
        totalOperationActions,
        isLoadingActions,
        isLoadingActionCommand,
        isLoadingOperationCommand,
        onReloadButtonPressed,
        onGoBackButtonPressed,
        onCommandOperationButtonPressed,
        onCommandActionButtonPressed,
    };
};
