import { ReportFileDto } from 'services/access-reports/access-reports.service';
import { ReportFilesEventTypes } from 'services/sse/report-files/sse-report-files.service';
import { SseEvent } from 'services/sse/sse.service';

//  Definicion de action types
export enum ReportFileListActionTypes {
    HANDLE_EVENT = 'HANDLE_EVENT',
    SET_INITIAL_STATE = 'SET_INITIAL_STATE',
}

// Tipado del payload de los action types
export interface BroadcastListStateAction {
    type: ReportFileListActionTypes;
    payload: { event: SseEvent<{ status_id?: number }>; report_file_id: string } | ReportFileDto[];
}

//______
export type HandleEventAction = {
    type: ReportFileListActionTypes.HANDLE_EVENT;
    payload: { event: SseEvent<{ status_id?: number }>; report_file_id: string };
};
//______
export type SetInitialStateAction = {
    type: ReportFileListActionTypes.SET_INITIAL_STATE;
    payload: ReportFileDto[];
};

// Tipado del reducer
export type ReducerAction = SetInitialStateAction | HandleEventAction;

// Definicion de la interface del store
export interface ReportFilesListState {
    reportFiles: ReportFileDto[];
    events: SseEvent<{ status_id?: number }>[];
}

// creacion del reducer
export const reportFilesListReducer: React.Reducer<ReportFilesListState, ReducerAction> = (
    state,
    { type, payload },
) => {
    switch (type) {
        case ReportFileListActionTypes.SET_INITIAL_STATE:
            return {
                reportFiles: payload,
                events: [],
            };
        case ReportFileListActionTypes.HANDLE_EVENT: {
            let reportFiles = state.reportFiles;
            switch (payload.event.event_type) {
                case ReportFilesEventTypes.EVENT_CREATE_REPORT_FILE_STARTED:
                    {
                        reportFiles = reportFiles.map((b) => {
                            if (b.id == payload.report_file_id) {
                                return { ...b, status_id: 2 };
                            }
                            return b;
                        });
                    }
                    break;
                case ReportFilesEventTypes.EVENT_CREATE_REPORT_FILE_SUCCEEDED:
                    {
                        reportFiles = reportFiles.map((b) => {
                            if (b.id == payload.report_file_id) {
                                return { ...b, status_id: 3 };
                            }
                            return b;
                        });
                    }
                    break;
                case ReportFilesEventTypes.EVENT_CREATE_REPORT_FILE_FAILED:
                    {
                        reportFiles = reportFiles.map((b) => {
                            if (b.id == payload.report_file_id) {
                                return { ...b, status_id: 4 };
                            }
                            return b;
                        });
                    }
                    break;
                default:
                    break;
            }
            return {
                ...state,
                reportFiles,
                events: [...state.events, payload.event],
            };
        }

        default:
            return state;
    }
};
