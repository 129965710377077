import React, { useContext } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { AdminDto, UserZoneTypes } from 'services/admin/admin.service';
import { ITag, SorterInfo } from 'global/interfaces';
import { GetAdminsPayloadInterface } from './admins-provider.component';
import { LockDto } from 'services/locks/locks.service';

export interface AdminContextInterface {
    isCreateAdminModalVisible: boolean;
    isFilterModalVisible: boolean;
    admins: AdminDto[];
    currentPage: number;
    totalAdmins?: number;
    pageSize: number;
    isEditAdminModalVisible: boolean;
    selectedAdminId?: string;
    selectedAdmin?: AdminDto;
    roles: UserZoneTypes[];
    isTableLoading: boolean;
    userZones: AdminDto[];
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    sortedInfo: SorterResult<SorterInfo>;
    searchInput: string[];
    selectedLockIds: string[];
    selectedRoles: number[];
    tags: ITag[];
    locks: LockDto[];
    isLoadingLocks: boolean;

    setLocks: React.Dispatch<React.SetStateAction<LockDto[]>>;
    setIsLoadingLocks: React.Dispatch<React.SetStateAction<boolean>>;
    setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
    setSelectedLockIds: React.Dispatch<React.SetStateAction<string[]>>;
    setSelectedRoles: React.Dispatch<React.SetStateAction<number[]>>;
    setSearchInput: React.Dispatch<React.SetStateAction<string[]>>;
    setIsFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsCreateAdminModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditAdminModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAdminId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSelectedAdmin: React.Dispatch<React.SetStateAction<AdminDto | undefined>>;
    setAdmins: React.Dispatch<React.SetStateAction<AdminDto[]>>;
    setTotalAdmins: React.Dispatch<React.SetStateAction<number | undefined>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setRoles: React.Dispatch<React.SetStateAction<UserZoneTypes[]>>;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setUserZones: React.Dispatch<React.SetStateAction<AdminDto[]>>;
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSortedInfo: React.Dispatch<React.SetStateAction<SorterResult<SorterInfo>>>;
    //________________Functions___________
    getAdmins: (payload: GetAdminsPayloadInterface) => void;
    getLocks: () => void;
}

export const AdminContext = React.createContext<AdminContextInterface>({
    isCreateAdminModalVisible: false,
    isFilterModalVisible: false,
    admins: [],
    currentPage: 0,
    pageSize: 15,
    searchInput: [],
    selectedLockIds: [],
    selectedRoles: [],
    isEditAdminModalVisible: false,
    roles: [],
    isTableLoading: false,
    userZones: [],
    orderBy: undefined,
    order: undefined,
    sortedInfo: { order: undefined, columnKey: undefined },
    tags: [],
    locks: [],
    isLoadingLocks: false,
    setTags: () => {
        /* Nothing to do here*/
    },
    setSelectedLockIds: () => {
        /* Nothing to do here*/
    },
    setSelectedRoles: () => {
        /* Nothing to do here*/
    },
    setSearchInput: () => {
        /* Nothing to do here*/
    },
    setIsCreateAdminModalVisible: () => {
        /* Nothing to do here*/
    },
    setIsFilterModalVisible: () => {
        /* Nothing to do here*/
    },
    setIsEditAdminModalVisible: () => {
        /* Nothing to do here*/
    },
    setSelectedAdminId: () => {
        /* Nothing to do here*/
    },
    setSelectedAdmin: () => {
        /* Nothing to do here*/
    },
    setAdmins: () => {
        /* Nothing to do here*/
    },
    setTotalAdmins: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setRoles: () => {
        /* Nothing to do here*/
    },
    setIsTableLoading: () => {
        /* Nothing to do here*/
    },
    setUserZones: () => {
        /* Nothing to do here*/
    },
    setSortedInfo: () => {
        /* Nothing to do here*/
    },
    setOrder: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setIsLoadingLocks: () => {
        /* Nothing to do here*/
    },
    setLocks: () => {
        /* Nothing to do here*/
    },
    //__________Functions__________
    getAdmins: () => {
        /* Nothing to do here*/
    },
    getLocks: () => {
        /* Nothing to do here*/
    },
});

export const useAdminContext = (): AdminContextInterface => {
    const store = useContext(AdminContext);
    return store;
};
