import { SorterResult } from 'antd/lib/table/interface';
import { ITag, OrderTable, PaginationRequest, SorterInfo } from 'global/interfaces';
import React, { useState } from 'react';
import {
    AccessReportDto,
    FilterAccessReportsCustomFieldInterface,
    SearchAccessReportsInterface,
    SearchAccessReportsRequestInterface,
} from 'services/access-reports/access-reports.service';
import { useAPIAccessReports } from 'services/access-reports/api-access-reports.service';
import { useAPILocks } from 'services/locks/api-locks.service';
import { LockDto } from 'services/locks/locks.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { AccessReportsContext } from './access-reports.context';
import { FilterCustomField } from 'fragments/site-cards-dashboard/fragments/site-cards-filter-modal/interfaces';
import dayjs from 'dayjs';

type Props = {
    children?: React.ReactNode;
};
export interface GetAccessReportsInterface {
    pagination?: PaginationRequest;
    orderTable?: OrderTable;
    search?: SearchAccessReportsInterface;
}
const AccessReportsProvider: React.FC<Props> = (props) => {
    const [searchInput, setSearchInput] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [totalReports, setTotalReports] = useState<number>();
    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [accessReports, setAccessReports] = useState<AccessReportDto[]>([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [selectedLockIds, setSelectedLockIds] = useState<string[]>([]);
    const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
        from: '',
        to: '',
    });
    const [selectedAccessMethodIds, setSelectedAccessMethodIds] = useState<number[]>([]);
    const [selectedReportStateIds, setSelectedReportStateIds] = useState<number[]>([]);

    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>('descend');
    const [orderBy, setOrderBy] = useState<string | undefined>('date');
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });
    const [locks, setLocks] = useState<LockDto[]>([]);
    const [isLoadingLocks, setIsLoadingLocks] = useState(false);
    const [tags, setTags] = useState<ITag[]>([]);

    const [toggleTable, setToggleTable] = useState(false);

    const [selectedCustomFields, setSelectedCustomFields] = useState<FilterCustomField[]>([]);

    const parseCustomFieldsToRequest = (fields: FilterCustomField[]): FilterAccessReportsCustomFieldInterface[] => {
        return fields
            .map((f) => ({
                custom_field_id: f.id,
                type_id: f.type_id,
                values:
                    f.type_id != 2
                        ? f.value
                        : [
                              `${dayjs(f.value[0]).format('YYYY-MM-DD')}T00:00:00Z`,
                              `${dayjs(f.value[1]).format('YYYY-MM-DD')}T23:59:59Z`,
                          ],
            }))
            .filter((f) => !!f.values.length);
    };

    // Functions
    const accessReportService = useAPIAccessReports();
    const messenger = useMessenger();
    const locksService = useAPILocks();
    const getAccessReports = (payload: GetAccessReportsInterface) => {
        let { pagination, orderTable } = payload;
        if (pagination) {
            pagination.page != currentPage && setCurrentPage(pagination.page);
            pagination.pageSize != pageSize && setPageSize(pagination.pageSize);
        } else {
            pagination = { page: currentPage, pageSize };
        }
        if (orderTable) {
            orderTable.order !== order && setOrder(orderTable.order);
            orderTable.orderBy !== orderBy && setOrderBy(orderTable.orderBy);
            if (!orderTable.order && !orderTable.orderBy) {
                setSortedInfo({
                    order: undefined,
                    columnKey: undefined,
                });
            }
        } else {
            orderTable = { order, orderBy };
        }

        const searchInput: SearchAccessReportsRequestInterface = payload.search
            ? { ...payload.search, customFields: parseCustomFieldsToRequest(payload.search.customFields) }
            : {
                  startDate: '',
                  endDate: '',
                  searchInput: '',
                  locks: [],
                  accessMethodIds: [],
                  reportStateIds: [],
                  customFields: [],
              };
        setIsTableLoading(true);

        accessReportService
            .listAccessReports(pagination, orderTable, searchInput)
            .then((data) => {
                setAccessReports(data.reports);
                setTotalReports(data.total);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.list-error' });
                console.log('access_reports-list-error', error);
            })
            .finally(() => {
                setIsTableLoading(false);
            });
    };

    const getLocks = () => {
        setIsLoadingLocks(true);
        locksService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingLocks(false);
            });
    };

    return (
        <AccessReportsContext.Provider
            value={{
                locks,
                setLocks,
                selectedLockIds,
                setSelectedLockIds,
                accessReports,
                setAccessReports,
                searchInput,
                setSearchInput,
                dateRange,
                setDateRange,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize,
                totalReports,
                setTotalReports,
                isTableLoading,
                setIsTableLoading,
                isFilterModalVisible,
                setIsFilterModalVisible,
                order,
                setOrder,
                orderBy,
                setOrderBy,
                sortedInfo,
                setSortedInfo,
                isLoadingLocks,
                setIsLoadingLocks,
                tags,
                setTags,
                toggleTable,
                setToggleTable,
                selectedAccessMethodIds,
                setSelectedAccessMethodIds,
                selectedReportStateIds,
                setSelectedReportStateIds,
                selectedCustomFields,
                setSelectedCustomFields,
                //Functions
                getAccessReports,
                getLocks,
            }}
        >
            {props.children}
        </AccessReportsContext.Provider>
    );
};

export default AccessReportsProvider;
