import { PaginationRequest, OrderTable } from 'global/interfaces';

export interface OperationActionDto {
    id: string;
    operation_id: string;
    device_id: string;
    device_name: string;
    label: string;
    card_number: string;
    retry_count: number;
    status_id: number;
    type_id: OperationActionType;
    created_by_email: string;
    created_at: string;
    updated_by_email: string;
    updated_at: string;
    msg?: string;
}

export enum OperationActionCommand {
    INVALID = 0,
    CANCEL = 1,
    RETRY = 2,
}

export enum OperationActionType {
    DEFAULT = 0,
    ADD = 1,
    DELETE = 2,
}
export interface OperationActionsListInput {
    operationId: string;
    // pagination: PaginationRequest;
    // orderTable: OrderTable;
    // search?: any;
}

export interface PaginatedOperationActionsResponse {
    operation_id: string;
    operation_name: string;
    operation_status_id: number;
    operation_type_id: number;
    operation_actions: OperationActionDto[];
}

export interface OperationActions {
    operationActionsList(input: OperationActionsListInput): Promise<PaginatedOperationActionsResponse>;
    operationActionsCommand(operationId: string, actionId: string, command: OperationActionCommand): Promise<any>;
}
