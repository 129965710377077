import { useState, useEffect } from 'react';
import { LocksDashboardController, LocksTableRow } from 'fragments/locks-dashboard/interfaces';
import { useAPILocks } from 'services/locks/api-locks.service';
import { useLocksContext } from 'fragments/locks-dashboard/context/locks.context';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { LockDto, SearchLocksInterface } from 'services/locks/locks.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { capitalize } from 'tools/string-handling';
import { TablePaginationConfig } from 'antd';
import { OrderTable, PaginationRequest } from 'global/interfaces';
import { useNavigate } from 'react-router-dom';
import { ArgsProps } from 'antd/lib/notification';

export const useLocksDashboardController = (locksService = useAPILocks()): LocksDashboardController => {
    /* State */
    const navigate = useNavigate();
    const [isLoading] = useState<boolean>(false);
    const [isCreateKeyModalVisible, setIsCreateKeyModalVisible] = useState<boolean>(false);
    const [isGeneratePasscodeVisible, setIsGeneratePasscodeVisible] = useState<boolean>(false);
    const [isViewAdminsModalVisible, setIsViewAdminsModalVisible] = useState<boolean>(false);
    const [checkBtnsLoading, setCheckBtnsLoading] = useState<string[]>([]);
    const [resetBtnsLoading, setResetBtnsLoading] = useState<string[]>([]);
    const [selectedLock, setSelectedLock] = useState<string>('');
    const [selectedLockName, setSelectedLockName] = useState<string>('');

    const {
        searchInput,
        lockStatus,
        lockType,
        sortedInfo,
        setSortedInfo,
        setIsCreateLockModalVisible,
        setCurrentPage,
        pageSize,
        currentPage,
        totalLocks,
        locks,
        isLoadingTable,
        setIsLoadingTable,
        setPageSize,
        setOrder,
        setOrderBy,
        setLocks,
        setTotalLocks,
        setSearchInput,
        setIsFilterModalVisible,
    } = useLocksContext();
    const [session] = useLocalSession();
    const { selectedSite } = session;
    const { translate } = useTranslator();

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            setCurrentPage(0);
            setPageSize(10);
            setSortedInfo({
                order: undefined,
                columnKey: undefined,
            });
            setOrder(undefined);
            setOrderBy(undefined);
            getLocks();
        }
    }, [selectedSite]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onAddLockButtonPressed = () => {
        setIsCreateLockModalVisible(true);
    };
    const onFilterButtonPressed = () => {
        setIsFilterModalVisible(true);
    };
    const onOpenCreateKey = (id: string) => {
        setSelectedLock(id);
        setIsCreateKeyModalVisible(true);
    };
    const onCloseCreateKey = () => {
        setSelectedLock('');
        setIsCreateKeyModalVisible(false);
    };
    const onOpenViewAdmins = (id: string, lockName: string) => {
        setSelectedLock(id);
        setSelectedLockName(lockName);
        setIsViewAdminsModalVisible(true);
    };
    const onCloseViewAdmins = () => {
        setSelectedLock('');
        setSelectedLockName('');
        setIsViewAdminsModalVisible(false);
    };
    const onOpenGeneratePasscode = (id: string) => {
        setSelectedLock(id);
        setIsGeneratePasscodeVisible(true);
    };
    const onCloseGeneratePasscode = () => {
        setSelectedLock('');
        setIsGeneratePasscodeVisible(false);
    };
    const onRedirect = (value: string) => {
        navigate(value);
    };

    const onResetButtonPressed = (
        id: string,
        success: (options: ArgsProps) => void,
        error: (options: ArgsProps) => void,
    ) => {
        setResetBtnsLoading([...resetBtnsLoading, id]);
        locksService
            .resetLock(id)
            .then(() => {
                success({
                    message: translate({ key: 'lock.notification.success-reset-title' }),
                    description: translate({ key: 'lock.notification.success-reset-description' }),
                    duration: null,
                });
                getLocks();
            })
            .catch((err) => {
                console.log('error: ', err);
                error({
                    message: translate({ key: 'lock.notification.error-reset-title' }),
                    description: translate({ key: 'lock.notification.error-reset-description' }),
                    duration: null,
                });
            })
            .finally(() => {
                setResetBtnsLoading(resetBtnsLoading.filter((btn) => btn !== id));
            });
    };

    const onCheckConnectionStatus = (
        id: string,
        success: (options: ArgsProps) => void,
        error: (options: ArgsProps) => void,
    ) => {
        setCheckBtnsLoading([...checkBtnsLoading, id]);
        locksService
            .checkConnection(id)
            .then((res) => {
                switch (res.code) {
                    case 200:
                        success({
                            message: translate({ key: 'lock.notification.online-title' }),
                            description: translate({ key: 'lock.notification.online-description' }),
                            duration: null,
                        });
                        break;
                    case 202:
                        error({
                            message: translate({ key: 'lock.notification.offline-title' }),
                            description: translate({ key: 'lock.notification.offline-description' }),
                            duration: null,
                        });
                        break;
                }
            })
            .catch((err) => {
                console.log('error: ', err);
                error({
                    message: translate({ key: 'lock.notification.server-error-title' }),
                    description: translate({ key: 'lock.notification.server-error-description' }),
                    duration: null,
                });
            })
            .finally(() => {
                setCheckBtnsLoading(checkBtnsLoading.filter((btn) => btn !== id));
            });
    };

    const onPageChange = (page: number) => {
        setCurrentPage(page - 1); // Backend pagination index start at page 0
    };

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.column?.key,
        });
        setCurrentPage(currentPage === page ? 0 : page);
        setPageSize(currentPageSize);
        setOrder(sorter.order);
        setOrderBy(sorter.column?.key);

        getLocks(
            { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            {
                order: sorter.order,
                orderBy: sorter.column?.key,
            },
            {
                searchInput,
                lockStatus,
                lockType,
            },
        );
    };

    /* Private Methods */
    const getLocks = (pagination?: PaginationRequest, orderTable?: OrderTable, search?: SearchLocksInterface) => {
        if (!pagination) {
            pagination = { page: currentPage, pageSize: pageSize };
        }
        if (!orderTable) {
            orderTable = { order: sortedInfo.order as 'ascend' | 'descend', orderBy: sortedInfo.columnKey?.toString() };
        }
        if (!search) {
            search = { searchInput, lockStatus, lockType };
        }
        setIsLoadingTable(true);
        locksService
            .listLocks(pagination, orderTable, search)
            .then((data) => {
                setLocks(data.locks);
                setTotalLocks(data.total);
            })
            .catch((error) => {
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingTable(false);
            });
    };

    const mapDtoToTableRow = (dto: LockDto): LocksTableRow => {
        const viewModelObject = {
            id: dto.id,
            lockName: capitalize(dto.name),
            lockStatusId: dto.lock_status_id,
            lockType:
                dto.lock_type_id === 1 || dto.lock_type_id === 2 || dto.lock_type_id === 4
                    ? translate({ key: 'lock.type-ga' })
                    : dto.lock_type_id === 5
                    ? translate({ key: 'lock.type-gt' })
                    : translate({ key: 'general.unknown' }),
            lockTypeId: dto.lock_type_id,
            state: {
                label:
                    dto.lock_status_id == 1
                        ? translate({ key: 'label.enabled' })
                        : dto.lock_status_id == 2
                        ? translate({ key: 'label.down' })
                        : translate({ key: 'label.disabled' }),
                id: dto.lock_status_id,
            },
        };

        return viewModelObject;
    };

    // Return state and events
    return {
        isLoading,
        pageSize,
        currentPage,
        totalLocks,
        isLoadingTable,
        sortedInfo,
        isCreateKeyModalVisible,
        isGeneratePasscodeVisible,
        selectedLock,
        isViewAdminsModalVisible,
        resetBtnsLoading,
        checkBtnsLoading,
        selectedLockName,
        onOpenViewAdmins,
        onCloseViewAdmins,
        onPageChange,
        onAddLockButtonPressed,
        onFilterButtonPressed,
        handleTableChange,
        onOpenCreateKey,
        onCloseCreateKey,
        onOpenGeneratePasscode,
        onCloseGeneratePasscode,
        onRedirect,
        onResetButtonPressed,
        onCheckConnectionStatus,
        itemsViewModelTable: locks.map(mapDtoToTableRow),
    };
};
