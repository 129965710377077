import { useState, useEffect } from 'react';
import { CreateSiteController } from 'fragments/sites-modal/create-site/interfaces';
import { useSitesContext } from 'fragments/sites-modal/context/sites.context';
import { useMainContext } from 'fragments/context/main/main.context';
import { useAPISites } from 'services/sites/api-sites.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { CreateSiteInput, TimeZonesDto } from 'services/sites/sites.service';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useCreateSiteController = (
    siteService = useAPISites(),
    messenger = useMessenger(),
    [session, saveSession] = useLocalSession(),
): CreateSiteController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [timeZones, setTimeZones] = useState<TimeZonesDto[]>([]);
    const { setIsCreateSiteFragmentVisible, setSites } = useSitesContext();
    const { setIsAdminSitesModalVisible } = useMainContext();

    /* Listeners */
    useEffect(() => {
        getTimeZones();
    }, []);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onBackButtonPressed = () => {
        setIsCreateSiteFragmentVisible(false);
    };

    const onCancelButtonPressed = () => {
        setIsAdminSitesModalVisible(false);
        setIsCreateSiteFragmentVisible(false);
    };

    const onFormSubmit = (formInputs: unknown) => {
        setIsLoading(true);
        const input = formInputs as CreateSiteInput;
        input.name = input.name.trim();

        siteService
            .createSite(input)
            .then(() => {
                messenger.showSuccessMessage({ key: 'site.create-success' });
                setIsCreateSiteFragmentVisible(false);
                getSites();
            })
            .catch((errorMsg) => {
                if (errorMsg == 'site') {
                    messenger.showErrorMessage({ key: 'site.create-error-existing-site' });
                } else {
                    messenger.showErrorMessage({ key: 'site.create-error' });
                }
            });

        setIsLoading(false);
    };

    /* Private Methods */
    const getTimeZones = () => {
        setIsLoading(true);
        siteService
            .timeZones()
            .then((data) => {
                setTimeZones(data.timezones);
            })
            .catch((error) => {
                console.log('error-timezones', error);
            })
            .finally(() => setIsLoading(false));
    };

    const getSites = () => {
        setIsLoading(true);
        siteService
            .userSites()
            .then((data) => {
                setSites(data.sites);
                if (data.sites.length > 0 && !session.selectedSite) {
                    saveSession({
                        token: session.token,
                        user_profile: session.user_profile,
                        selectedSite: data.sites[0],
                    });
                }
            })
            .catch((error) => {
                console.log('error-sites', error);
            })
            .finally(() => setIsLoading(false));
    };

    // Return state and events
    return {
        isLoading,
        timeZones,
        onBackButtonPressed,
        onFormSubmit,
        onCancelButtonPressed,
    };
};
