import { PaginationRequest, OrderTable } from 'global/interfaces';
import { OperationActionType } from 'services/operation-actions/operation-actions.service';

export interface OperationDto {
    id: string;
    name: string;
    type_id: OperationType;
    status_id: number;
    count: number;
    total_count: number;
    created_by_email: string;
    created_at: string;
    updated_by_email: string;
    updated_at: string;
    site_id: string;
}

export interface PreviewActionItem {
    id: string;
    index: number;
    label: string;
    status_id: number;
    device_id: string;
    device_name: string;
    card_number: string;
    errors: PreviewActionItemError[];
    site_card_id: string;
}
export interface PreviewActionItemError {
    color?: string; //no viene del back
    column: string;
    message: string;
    related_rows: number[];
}

export enum OperationType {
    DEFAULT = 0,
    RFID = 1,
}
export enum OperationCommand {
    INVALID = 0,
    START = 1,
    STOP = 2,
    RESUME = 3,
    RETRY = 4,
}

export interface OperationListInput {
    pagination: PaginationRequest;
    orderTable: OrderTable;
    search?: any;
}

// TODO: definir
export interface CreateOperationInput {
    name: string;
    operation_type: OperationType;
    operation_action_type: OperationActionType;
    actions: {
        device_id: string;
        label: string;
        card_number: string;
        site_card_id: string;
    }[];
}

export interface PaginatedOperationsResponse {
    total: number;
    operations: OperationDto[];
}

// TODO: definir
export interface CreateOperationResponse {
    code: number;
}

export interface Operations {
    checkOperation(input: CreateOperationInput): Promise<PreviewActionItem[]>;
    createOperation(input: CreateOperationInput): Promise<CreateOperationResponse>;
    operationsList(input: OperationListInput): Promise<PaginatedOperationsResponse>;
    operationsCommand(operationId: string, command: OperationCommand): Promise<any>;
}
