import React from 'react';
import 'fragments/locks-dashboard/locks-dashboard.scss';
import { Button, Modal, notification, Space, Table, Tag, Tooltip } from 'antd';
import { LocksDashboardFragmentProps, LocksTableRow } from 'fragments/locks-dashboard/interfaces';
import { useLocksDashboardController } from 'fragments/locks-dashboard/locks-dashboard.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CreateLockFragment } from 'fragments/locks-dashboard/fragments/create-lock/create-lock.fragment';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { UserSwitchOutlined, RedoOutlined, ExclamationCircleOutlined, WifiOutlined } from '@ant-design/icons';
import { CreateKeyFragment } from 'fragments/common/create-key/create-key.fragment';
import { CreatePasscodeModalFragment } from 'fragments/common/create-passcode-modal/create-passcode-modal.fragment';
import { FilterLocksModalFragment } from './fragments/filter-locks-modal/filter-locks-modal.fragment';
import { ViewAdminsModalFragment } from 'fragments/common/view-admins-modal/view-admins-modal.fragment';
import KeyIcon from 'assets/icons/key-icon';
import PasscodeIcon from 'assets/icons/passcode-icon';
import RfidIcon from 'assets/icons/rfid-icon';
import { ArgsProps } from 'antd/lib/notification';
const { confirm } = Modal;

export const LocksDashboardFragment: React.FC<LocksDashboardFragmentProps> = (props) => {
    const { useController = useLocksDashboardController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const [session] = useLocalSession();

    const openNotification = (status: number, options: ArgsProps) => {
        switch (status) {
            case 1:
                notification.success(options);
                break;
            default:
                notification.error(options);
                break;
        }
    };

    const showConfirmModal = (zoneId: string, lockName: string) => {
        confirm({
            title: translate({ key: 'general.sure-to-reset' }) + lockName + '?',
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action' }),
            okText: translate({ key: 'lock.reset' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onResetButtonPressed(
                    zoneId,
                    (options: ArgsProps) => openNotification(1, options),
                    (options: ArgsProps) => openNotification(0, options),
                );
            },
        });
    };

    // Render
    return (
        <div className={'locks-dashboard'}>
            <Space style={{ marginBottom: '10px', justifyContent: 'flex-end' }}>
                <Button
                    className={'button primary'}
                    type="primary"
                    style={{ justifySelf: 'flex-end', marginBottom: '10px' }}
                    onClick={controller.onFilterButtonPressed}
                >
                    {translate({ key: 'general.filters' })}
                </Button>
                {session.user_profile?.role === 'superadmin' && (
                    <Button
                        className={'button primary'}
                        type="primary"
                        style={{ justifySelf: 'flex-end', marginBottom: '10px' }}
                        onClick={controller.onAddLockButtonPressed}
                    >
                        {translate({ key: 'general.add' })}
                    </Button>
                )}
            </Space>
            <Table
                columns={[
                    {
                        key: 'lockName',
                        title: translate({ key: 'label.lock' }),
                        dataIndex: 'lockName',
                        sorter: !!controller.totalLocks,
                        sortOrder: controller.sortedInfo.columnKey === 'lockName' ? controller.sortedInfo.order : null,
                        render: (lockName) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {/* {!lockStatusId && (
                                    <Tooltip
                                        placement="topLeft"
                                        title={translate({ key: 'lock.tooltips.lock-not-configured' })}
                                    >
                                        <ExclamationCircleOutlined style={{ color: '#faad14', marginTop: '4px' }} />
                                    </Tooltip>
                                )} */}
                                {lockName}
                            </div>
                        ),
                    },
                    {
                        key: 'lockType',
                        title: translate({ key: 'label.type' }),
                        dataIndex: 'lockType',
                        sorter: !!controller.totalLocks,
                        sortOrder: controller.sortedInfo.columnKey === 'lockType' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'lockStatus',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'state',
                        sorter: !!controller.totalLocks,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'lockStatus' ? controller.sortedInfo.order : null,
                        render: (state) => (
                            <div>
                                {state.id === 1 ? (
                                    <Tag color="success" style={{ fontWeight: 'bold' }}>
                                        {state.label}
                                    </Tag>
                                ) : state.id === 2 ? (
                                    <Tooltip placement="topLeft" title={translate({ key: 'lock.tooltips.lock-down' })}>
                                        <Tag color="error" style={{ fontWeight: 'bold' }}>
                                            {state.label}
                                        </Tag>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        placement="topLeft"
                                        title={translate({ key: 'lock.tooltips.lock-not-configured' })}
                                    >
                                        <Tag color="error" style={{ fontWeight: 'bold' }}>
                                            {state.label}
                                        </Tag>
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'action',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id, lockTypeId, lockStatusId, lockName }) => (
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <Tooltip title={translate({ key: 'lock.tooltips.keys-list' })}>
                                    <Button
                                        style={{ padding: '5px' }}
                                        icon={<KeyIcon style={{ width: '20px', height: '20px' }} />}
                                        onClick={() => controller.onRedirect('/keys?l=' + id)}
                                    />
                                </Tooltip>
                                {lockTypeId === 5 && (
                                    <Tooltip title={translate({ key: 'lock.tooltips.passcodes-list' })}>
                                        <Button
                                            style={{ padding: '5px' }}
                                            icon={<PasscodeIcon style={{ width: '20px', height: '20px' }} />}
                                            onClick={() => controller.onRedirect('/passcodes?l=' + id)}
                                            disabled={lockStatusId == 0}
                                        />
                                    </Tooltip>
                                )}
                                {lockTypeId === 5 && (
                                    <Tooltip title={translate({ key: 'lock.tooltips.cards-list' })}>
                                        <Button
                                            style={{ padding: '5px' }}
                                            icon={<RfidIcon style={{ width: '20px', height: '20px' }} />}
                                            onClick={() => controller.onRedirect('/rfidcards?l=' + id)}
                                            disabled={lockStatusId == 0}
                                        />
                                    </Tooltip>
                                )}
                                <Tooltip title={translate({ key: 'lock.tooltips.admins-list' })}>
                                    <Button
                                        icon={<UserSwitchOutlined style={{ width: '20px', height: '20px' }} />}
                                        onClick={() => controller.onOpenViewAdmins(id, lockName)}
                                    />
                                </Tooltip>
                                {session.user_profile?.role === 'superadmin' && (
                                    <Tooltip title={translate({ key: 'lock.tooltips.reset-lock' })}>
                                        <Button
                                            loading={
                                                controller.resetBtnsLoading.length > 0 &&
                                                controller.resetBtnsLoading.some((btn) => btn === id)
                                            }
                                            icon={<RedoOutlined style={{ width: '20px', height: '20px' }} />}
                                            onClick={() => showConfirmModal(id, lockName)}
                                            disabled={lockStatusId == 0}
                                        />
                                    </Tooltip>
                                )}
                                {session.user_profile?.role === 'superadmin' && lockTypeId === 5 && (
                                    <Tooltip title={translate({ key: 'lock.tooltips.check-connection' })}>
                                        <Button
                                            loading={
                                                controller.checkBtnsLoading.length > 0 &&
                                                controller.checkBtnsLoading.some((btn) => btn === id)
                                            }
                                            icon={<WifiOutlined style={{ width: '20px', height: '20px' }} />}
                                            onClick={() => {
                                                controller.onCheckConnectionStatus(
                                                    id,
                                                    (options: ArgsProps) => openNotification(1, options),
                                                    (options: ArgsProps) => openNotification(0, options),
                                                );
                                            }}
                                            disabled={lockStatusId == 0}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModelTable}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalLocks,
                    pageSizeOptions: ['5', '10', '20'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isLoadingTable}
                rowKey={(itemsViewModelTable: LocksTableRow) => itemsViewModelTable.id}
            />
            <FilterLocksModalFragment />
            <CreateKeyFragment
                onClose={controller.onCloseCreateKey}
                isModalVisible={controller.isCreateKeyModalVisible}
                lockId={controller.selectedLock}
            />
            <CreatePasscodeModalFragment
                onClose={controller.onCloseGeneratePasscode}
                isModalVisible={controller.isGeneratePasscodeVisible}
                lockId={controller.selectedLock}
            />
            <ViewAdminsModalFragment
                onClose={controller.onCloseViewAdmins}
                isModalVisible={controller.isViewAdminsModalVisible}
                lockId={controller.selectedLock}
                lockName={controller.selectedLockName}
            />
            {session.user_profile?.role === 'superadmin' && <CreateLockFragment />}
        </div>
    );
};
