import { useState, useEffect } from 'react';
import {
    CreateAdminModalController,
    SelectDto,
} from 'fragments/admins-dashboard/fragments/create-admin-modal/interfaces';
import { useAdminContext } from 'fragments/admins-dashboard/context/admins.context';
import { useAPIAdmin } from 'services/admin/api-admin.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { CreateAdminInput, UserZoneTypes } from 'services/admin/admin.service';
import { LockDto } from 'services/locks/locks.service';
import { useAPILocks } from 'services/locks/api-locks.service';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useCreateAdminModalController = (
    adminService = useAPIAdmin(),
    locksService = useAPILocks(),
    messenger = useMessenger(),
    [session] = useLocalSession(),
): CreateAdminModalController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [locks, setLocks] = useState<LockDto[]>([]);
    const [selectedAdminEmails, setSelectedAdminEmails] = useState<string[]>([]);
    const [selectedLockIds, setSelectedLockIds] = useState<string[]>([]);
    const { setIsCreateAdminModalVisible, roles, setIsTableLoading, getAdmins } = useAdminContext();
    const [form] = useForm();
    const [selectedRole, setSelectedRole] = useState('');

    /* Listeners */
    useEffect(() => {
        if (session.selectedSite) {
            getLocks();
        }
    }, [session.selectedSite]);

    /* View Events */
    const onCreateAdminFormSubmit = (formInputs: unknown) => {
        if (!session.selectedSite) return messenger.showErrorMessage({ key: 'admin.create-error' });
        setIsLoading(true);
        const input = formInputs as CreateAdminInput;

        input.emails = selectedAdminEmails.map((email) => {
            return email.toLowerCase();
        });

        input.locks = selectedLockIds;
        input.site_id = session.selectedSite?.id || '';
        input.type_id = selectedRole;

        adminService
            .createAdmin(input)
            .then(() => {
                setIsTableLoading(true);
                messenger.showSuccessMessage({ key: 'admin.create-success' });
                getAdmins({});
                setIsCreateAdminModalVisible(false);
                form.resetFields();
            })
            .catch((errorMsg) => {
                if (errorMsg == 'user') {
                    messenger.showErrorMessage({ key: 'admin.create-error-user-not-exist' });
                } else {
                    messenger.showErrorMessage({ key: 'admin.create-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onCancelButtonPressed = () => {
        setIsCreateAdminModalVisible(false);
    };

    const onLockSelect = (value: string[]) => {
        setSelectedLockIds(value);
    };

    const onAdminEmailAdd = (value: string[]) => {
        setSelectedAdminEmails(value);
    };

    const onRoleSelected = (value: string) => {
        setSelectedRole(value);
    };

    /* Private Methods */
    const getLocks = () => {
        setIsLoading(true);
        locksService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks);
            })
            .catch((error) => {
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const roleToSelect = (role: UserZoneTypes): SelectDto => {
        return {
            label: role.name.charAt(0).toUpperCase() + role.name.slice(1),
            value: role.id,
        };
    };

    // Return state and events
    return {
        isLoading,
        locks,
        selectedAdminEmails,
        selectedLockIds,
        form,
        roleOptions: roles.map(roleToSelect),
        onRoleSelected,
        onLockSelect,
        onAdminEmailAdd,
        onCreateAdminFormSubmit,
        onCancelButtonPressed,
    };
};
