import React from 'react';
import 'fragments/keys-dashboard/fragments/keys-filter-modal/keys-filter-modal.scss';
import { KeysFilterModalFragmentProps } from 'fragments/keys-dashboard/fragments/keys-filter-modal/interfaces';
import { useKeysFilterModalController } from 'fragments/keys-dashboard/fragments/keys-filter-modal/keys-filter-modal.controller';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Modal, Row, Select, Tag, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ITag } from 'global/interfaces';
import dayjs from 'dayjs';
const { Option } = Select;
const { Text, Title } = Typography;
const CheckboxGroup = Checkbox.Group;

export const KeysFilterModalFragment: React.FC<KeysFilterModalFragmentProps> = (props) => {
    const { useController = useKeysFilterModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));

    const forMap = (tag: ITag) => {
        const tagElem = (
            <Tag
                closable={controller.isLoadingTable ? false : tag.closable}
                onClose={(e) => {
                    e.preventDefault();
                    controller.onCloseTag(tag);
                }}
                color={tag.color}
                style={{ fontWeight: 'bold' }}
            >
                <Text strong>{tag.name}: </Text> {tag.value}
            </Tag>
        );
        return (
            <span key={tag.id} style={{ display: 'inline-block', marginBottom: '2px' }}>
                {tagElem}
            </span>
        );
    };
    const tagChild = controller.tags.map(forMap);

    // Render
    return (
        <>
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#f2f2f2',
                    padding: '10px',
                }}
            >
                <Text strong>{translate({ key: 'general.filters' })}:</Text>

                <div className="filter-tags"> {tagChild} </div>
            </div>
            <Modal
                forceRender
                open={controller.isFilterModalVisible}
                destroyOnClose={true}
                title={
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'Busqueda y filtrado de llaves' })}
                    </Title>
                }
                className={'keys-filter-modal'}
                closable={false}
                centered
                footer={[
                    <Button
                        form="filrter-keys-form"
                        key="reset"
                        className={'button primary'}
                        style={{ marginRight: 'auto' }}
                        loading={controller.isLoading}
                        disabled={controller.submitDisabled}
                        onClick={controller.onResetButtonPressed}
                    >
                        {translate({ key: 'general.reset' })}
                    </Button>,
                    <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                        {translate({ key: 'general.cancel' })}
                    </Button>,
                    <Button
                        form="filrter-keys-form"
                        key="submit"
                        type="primary"
                        className={'button primary'}
                        loading={controller.isLoading}
                        htmlType="submit"
                        disabled={controller.submitDisabled}
                    >
                        {translate({ key: 'general.search' })}
                    </Button>,
                ]}
            >
                <Form
                    id="filrter-keys-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    size="middle"
                    className={'form'}
                    form={controller.form}
                    onFinish={controller.onSubmitButtonPressed}
                >
                    <Col xs={24} id="create-key-date-picker">
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.duration' })}
                                </Text>
                            }
                            name="date_picker"
                            initialValue={
                                controller.dateRange.from && controller.dateRange.from
                                    ? [
                                          dayjs(controller.dateRange.from, 'YYYY/MM/DD'),
                                          dayjs(controller.dateRange.to, 'YYYY/MM/DD'),
                                      ]
                                    : undefined
                            }
                        >
                            <DatePicker.RangePicker
                                style={{ width: '100%' }}
                                onChange={controller.onPickedDate}
                                getPopupContainer={() =>
                                    document.getElementById('create-key-date-picker') as HTMLElement
                                }
                                format="DD-MM-YYYY"
                            />
                        </Form.Item>
                    </Col>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.lock' })}
                            </Text>
                        }
                        name="lock_ids"
                        initialValue={controller.selectedLockIds}
                    >
                        <Select
                            placeholder={translate({ key: 'form.placeholder.lock.name' })}
                            mode="multiple"
                            allowClear
                            loading={controller.isLoadingLocks}
                            // value={controller.selectedLockIds}
                            onChange={controller.onLockSelect}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                const opt = controller.locks.find((item) => item.id == option?.value);
                                return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                            }}
                        >
                            {optionLocks}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.user' })}
                            </Text>
                        }
                        name="assigned_to"
                        initialValue={controller.assignedTo}
                    >
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            allowClear
                            onChange={controller.onAssignedToInputChange}
                            tokenSeparators={[',']}
                            placeholder={translate({ key: 'form.placeholder.key.search-user-tags' })}
                            dropdownStyle={{ display: 'none' }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.authorizer' })}
                            </Text>
                        }
                        name="authorizer"
                        initialValue={controller.authorizer}
                    >
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            allowClear
                            // value={controller.authorizer}
                            onChange={controller.onAuthorizerInputChange}
                            tokenSeparators={[',']}
                            placeholder={translate({ key: 'form.placeholder.key.search-user-tags' })}
                            dropdownStyle={{ display: 'none' }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.state' })}
                            </Text>
                        }
                        required
                        rules={[
                            {
                                required: !controller.tempStatusIds.length ? true : false,
                                message: translate({ key: 'form.select-an-option-error' }),
                            },
                        ]}
                    >
                        <>
                            <Checkbox
                                indeterminate={controller.indeterminate}
                                onChange={controller.onCheckAllKeyStatusOptions}
                                checked={controller.checkAll}
                            >
                                Seleccionar todos
                            </Checkbox>
                            <Divider style={{ margin: '5px' }} />
                            <CheckboxGroup
                                options={controller.keyStatusOptions}
                                value={controller.tempStatusIds}
                                onChange={controller.onCheckKeyStatusOptions}
                            />
                        </>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
