import React, { useState } from 'react';
import 'components/input-debounce/input-debounce.scss';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { SearchOutlined } from '@ant-design/icons';
import { DebounceInput } from 'react-debounce-input';

export interface SearchDebounceProps {
    onSearch?: (value: string) => void;
    onLoading?: (value: boolean) => void;
    timeout?: number;
    minLength?: number;
    style?: React.CSSProperties;
}

const InputDebounce: React.FC<SearchDebounceProps> = (props) => {
    const { translate } = useTranslator();
    // State
    const [search, setSearch] = useState<string>('');
    const minLength = props.minLength || 3;

    // listeners

    // event functions
    const changeLoad = (event: any) => {
        if (event.target.value.length >= minLength) {
            props.onLoading && props.onLoading(true);
        } else {
            if (event.code != 'Backspace' && event.target.value.length == minLength - 1) {
                props.onLoading && props.onLoading(true);
            }
            if (search != '') {
                props.onLoading && props.onLoading(true);
            }
        }
    };
    const finishTimeout = (event: any) => {
        if (event.target.value.length >= minLength) {
            props.onSearch && props.onSearch(event.target.value);
            setSearch(event.target.value);
        } else {
            if (search != '') {
                props.onSearch && props.onSearch('');
                setSearch('');
            }
        }
    };

    // private functions

    return (
        <DebounceInput
            onKeyDown={changeLoad}
            style={props.style}
            //minLength={props.minLength}
            debounceTimeout={props.timeout}
            placeholder={
                translate({ key: 'form.placeholder.search' }) +
                ' ' +
                translate({ key: 'form.placeholder.minletters-1' }) +
                ' ' +
                minLength +
                ' ' +
                translate({ key: 'form.placeholder.minletters-2' })
            }
            suffix={
                <SearchOutlined
                    style={{
                        color: '#d9d9d9',
                    }}
                />
            }
            onChange={finishTimeout}
            className={'input-search'}
        />
    );
};

export default InputDebounce;
