import { useEffect, useState } from 'react';
import { FilterModalAccessReportsController } from 'fragments/access-reports-dashboard/fragments/filter-modal-access-reports/interfaces';
import { useAccessReportsContext } from 'fragments/access-reports-dashboard/context/access-reports.context';
import { useForm } from 'antd/lib/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/lib/date-picker';
import { SearchAccessReportsInterface } from 'services/access-reports/access-reports.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { CheckboxOption, ITag } from 'global/interfaces';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FilterCustomField } from 'fragments/site-cards-dashboard/fragments/site-cards-filter-modal/interfaces';
import { CustomField } from 'services/custom-fields/custom-fields.service';
import { useAPICustomFields } from 'services/custom-fields/api-custom-fields.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useFilterModalAccessReportsController = (
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
FilterModalAccessReportsController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [accessMethodOptions, setAccessMethodOptions] = useState<CheckboxOption[]>([]);
    const [checkAllAccessMethod, setCheckAllAccessMethod] = useState(false);
    const [indeterminate, setIndeterminate] = useState(true);
    const [reportStateOptions, setReportStateOptions] = useState<CheckboxOption[]>([]);
    const [tempAccessMethodIds, setTempAccessMethodIds] = useState<number[]>([]);
    const [tempReportStateIds, setTempReportStateIds] = useState<number[]>([]);
    const [tempLockIds, setTempLockIds] = useState<string[]>([]);
    const [tempDateRange, setTempDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [tempSearchInput, setTempSearchInput] = useState<string>('');

    const [tempSelectedCustomFields, setTempSelectedCustomFields] = useState<FilterCustomField[]>([]);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);

    const {
        selectedLockIds,
        searchInput,
        dateRange,
        pageSize,
        isFilterModalVisible,
        locks,
        tags,
        setTags,
        setIsFilterModalVisible,
        setSelectedLockIds,
        setSearchInput,
        setDateRange,
        isLoadingLocks,
        isTableLoading,
        getAccessReports,
        accessReports,
        toggleTable,
        selectedAccessMethodIds,
        selectedReportStateIds,
        setSelectedAccessMethodIds,
        setSelectedReportStateIds,
        selectedCustomFields,
        setSelectedCustomFields,
    } = useAccessReportsContext();
    const [form] = useForm();

    const customFieldsService = useAPICustomFields();
    const messenger = useMessenger();

    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */

    useEffect(() => {
        if (session.token && selectedSite) {
            getCustomFields();
            setDefaultAccessMethodOptions();
            setDefaultReportStateOptions();
        }
    }, [selectedSite]);

    //________________TAGS________________
    useEffect(() => {
        mapTags({
            locks: selectedLockIds,
            startDate: dateRange.from,
            endDate: dateRange.to,
            searchInput,
            accessMethodIds: selectedAccessMethodIds,
            reportStateIds: selectedReportStateIds,
            customFields: selectedCustomFields,
        });
        setTempLockIds(selectedLockIds);
        setTempSearchInput(searchInput);
        setTempDateRange(dateRange);
        setTempAccessMethodIds(selectedAccessMethodIds);
        setTempReportStateIds(selectedReportStateIds);
        setTempSelectedCustomFields(selectedCustomFields);
    }, [
        selectedLockIds,
        dateRange,
        searchInput,
        locks,
        selectedReportStateIds,
        selectedAccessMethodIds,
        selectedCustomFields,
    ]);

    useEffect(() => {
        form.resetFields();
    }, [accessReports]);

    /* View Events */

    //________________button events________________

    const onSubmitButtonPressed = () => {
        setSearchInput(tempSearchInput);
        setDateRange(tempDateRange);
        setSelectedLockIds(tempLockIds);
        setSelectedAccessMethodIds(tempAccessMethodIds);
        setSelectedReportStateIds(tempReportStateIds);
        const rfid_selected = tempAccessMethodIds.find((x) => x == 7);
        !!rfid_selected && setSelectedCustomFields(tempSelectedCustomFields);
        getAccessReports({
            pagination: { page: 0, pageSize },
            search: {
                startDate: tempDateRange.from,
                endDate: tempDateRange.to,
                searchInput: tempSearchInput,
                locks: tempLockIds,
                accessMethodIds: tempAccessMethodIds,
                reportStateIds: tempReportStateIds,
                customFields: !!rfid_selected ? tempSelectedCustomFields : [],
            },
        });
        setIsFilterModalVisible(false);
    };

    const onCancelButtonPressed = () => {
        setTempLockIds(selectedLockIds);
        setTempSearchInput(searchInput);
        setTempDateRange(dateRange);
        setTempAccessMethodIds(selectedAccessMethodIds);
        setTempReportStateIds(selectedReportStateIds);
        setIndeterminate(
            !!selectedAccessMethodIds.length && selectedAccessMethodIds.length < defaultAccessMethodOptions.length,
        );
        customFields.map((f) => {
            form.setFieldsValue({
                [f.name]: selectedCustomFields.find((field) => field.id == f.id)?.value,
            });
        });
        setIsFilterModalVisible(false);
        form.resetFields();
    };

    const onResetButtonPressed = () => {
        form.resetFields();
        setDateRange({ from: '', to: '' });
        setSelectedLockIds([]);
        setDefaultAccessMethodOptions();
        setDefaultReportStateOptions();
        setSearchInput('');
        setSelectedCustomFields([]);
        getAccessReports({
            pagination: { page: 0, pageSize },
            orderTable: { order: undefined, orderBy: undefined },
            search: {
                startDate: '',
                endDate: '',
                searchInput: '',
                locks: [],
                accessMethodIds: [],
                reportStateIds: [],
                customFields: [],
            },
        });
        setIsFilterModalVisible(false);
    };

    //________________input events________________

    const onPickedDate = (date: any) => {
        if (date) {
            setTempDateRange({ from: dayjs(date[0]).format('YYYY-MM-DD'), to: dayjs(date[1]).format('YYYY-MM-DD') });
        } else {
            setTempDateRange({ from: '', to: '' });
        }
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current: Dayjs) => {
        return current && current > dayjs().endOf('day');
    };

    const onLockSelect = (value: string[]) => {
        setTempLockIds(value);
    };

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTempSearchInput(e.target.value);
    };
    const onCheckAccessMethodOption = (list: CheckboxValueType[]) => {
        setTempAccessMethodIds(list as number[]);
        setIndeterminate(!!list.length && list.length < accessMethodOptions.length);
        setCheckAllAccessMethod(list.length === accessMethodOptions.length);
    };

    const onCheckAllAccessMethodOption = (e: CheckboxChangeEvent) => {
        setTempAccessMethodIds(e.target.checked ? accessMethodOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAllAccessMethod(e.target.checked);
    };

    const onCheckReportStateOption = (list: CheckboxValueType[]) => {
        setTempReportStateIds(list as number[]);
    };

    /* Private Methods */
    //________________TAGS________________

    const onCloseTag = (removedTag: ITag) => {
        switch (removedTag.name) {
            case translate({ key: 'label.lock' }): {
                const newSelectedLockIds = selectedLockIds.filter((lockId) => lockId !== removedTag.id);
                setSelectedLockIds(newSelectedLockIds);
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput,
                        locks: newSelectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            case translate({ key: 'label.name-email' }): {
                setSearchInput('');
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput: '',
                        locks: selectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            case translate({ key: 'label.duration' }): {
                setDateRange({ from: '', to: '' });
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: '',
                        endDate: '',
                        searchInput,
                        locks: selectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            case translate({ key: 'label.access-method' }): {
                const newSelectedAccessMethodIds = selectedAccessMethodIds.filter((id) => id !== removedTag.id);
                setSelectedAccessMethodIds(newSelectedAccessMethodIds);
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput,
                        locks: selectedLockIds,
                        accessMethodIds: newSelectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            case translate({ key: 'label.state' }): {
                const newSelectedReportStateIds = selectedReportStateIds.filter((id) => id !== removedTag.id);
                setSelectedReportStateIds(newSelectedReportStateIds);
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput,
                        locks: selectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: newSelectedReportStateIds,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            default:
                const newSelectedCustomFields = selectedCustomFields.map((field) => {
                    if (field.type_id == 2 && field.id == removedTag.id) {
                        return { ...field, value: [] };
                    } else {
                        const newValues = field.value.filter((f: any) => `${field.id}_${f}` != removedTag.id);
                        return { ...field, value: newValues };
                    }
                });
                setSelectedCustomFields(newSelectedCustomFields);
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput,
                        locks: selectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                        customFields: newSelectedCustomFields,
                    },
                });
                break;
        }
    };

    const mapTags = (search: SearchAccessReportsInterface) => {
        const {
            locks: selectedLockIds,
            searchInput,
            startDate,
            endDate,
            accessMethodIds,
            reportStateIds,
            customFields,
        } = search;
        const tempLockTags: ITag[] = [];
        const tempSearchTags: ITag[] = [];
        const tempDateTags: ITag[] = [];
        const tempAccessMethodTags: ITag[] = [];
        const tempReportStatusTags: ITag[] = [];
        const tempSelectedCustomFields: ITag[] = [];

        customFields.map((field) => {
            if (field.type_id == 2) {
                !!field.value.length &&
                    tempSelectedCustomFields.push({
                        id: field.id,
                        name: field.name,
                        value: `Desde: ${dayjs(field.value[0]).format('DD/MM/YYYY')} Hasta: ${dayjs(
                            field.value[1],
                        ).format('DD/MM/YYYY')}`,
                        color: 'magenta',
                        closable: true,
                    });
            } else {
                !!field.value.length &&
                    field.value.map((f: any) => {
                        tempSelectedCustomFields.push({
                            id: `${field.id}_${f}`,
                            name: field.name,
                            value: f,
                            color: 'magenta',
                            closable: true,
                        });
                    });
            }
        });

        selectedLockIds.map((id) => {
            const selectedLock = locks.find((lock) => lock.id === id);
            tempLockTags.push({
                id,
                name: translate({ key: 'label.lock' }),
                value: `${selectedLock?.name}`,
                color: 'geekblue',
                closable: true,
            });
        });

        searchInput &&
            tempSearchTags.push({
                id: searchInput,
                name: translate({ key: 'label.name-email' }),
                value: searchInput,
                color: 'purple',
                closable: true,
            });

        if (startDate !== '' || endDate !== '') {
            tempDateTags.push({
                id: startDate + endDate,
                name: translate({ key: 'label.duration' }),
                value: `Desde: ${startDate} Hasta: ${endDate}`,
                color: 'cyan',
                closable: true,
            });
        }
        if (selectedAccessMethodIds.length !== accessMethodOptions.length && toggleTable) {
            accessMethodIds.map((value) => {
                const selectedAccessMethod = accessMethodOptions.find((option) => option.value === value);
                tempAccessMethodTags.push({
                    id: value,
                    name: translate({ key: 'label.access-method' }),
                    value: `${selectedAccessMethod?.label}`,
                    color: 'green',
                    closable: true,
                });
            });
            if (tempAccessMethodTags.length === 1) {
                tempAccessMethodTags[0].closable = false;
            }
        }
        if (selectedReportStateIds.length !== reportStateOptions.length && toggleTable) {
            reportStateIds.map((value) => {
                const selectedReportState = reportStateOptions.find((option) => option.value === value);
                tempReportStatusTags.push({
                    id: value,
                    name: translate({ key: 'label.state' }),
                    value: `${selectedReportState?.label}`,
                    color: 'lime',
                    closable: true,
                });
            });
            if (tempReportStatusTags.length === 1) {
                tempReportStatusTags[0].closable = false;
            }
        }
        setTags([
            ...tempLockTags,
            ...tempSearchTags,
            ...tempDateTags,
            ...tempAccessMethodTags,
            ...tempReportStatusTags,
            ...tempSelectedCustomFields,
        ]);
    };

    //___________Set Filter Options __________
    const defaultAccessMethodOptions: CheckboxOption[] = [
        { value: 1, label: translate({ key: 'access-reports.access-method-option.app' }) },
        { value: 4, label: translate({ key: 'access-reports.access-method-option.passcode' }) },
        { value: 7, label: translate({ key: 'access-reports.access-method-option.rfid' }) },
        { value: 8, label: translate({ key: 'access-reports.access-method-option.fingerprint' }) },
        // { value: 12, label: translate({ key: 'access-reports.access-method-option.remote-access' }) },
        { value: 46, label: translate({ key: 'access-reports.access-method-option.button' }) },
    ];
    const defaultReportStateOptions: CheckboxOption[] = [
        { value: 0, label: translate({ key: 'access-reports.report-state-option.error' }) },
        { value: 1, label: translate({ key: 'access-reports.report-state-option.success' }) },
    ];
    const setDefaultAccessMethodOptions = (): void => {
        const accessMethodIds: number[] = defaultAccessMethodOptions.map((item) => item.value);
        setAccessMethodOptions(defaultAccessMethodOptions);
        setTempAccessMethodIds(accessMethodIds);
        setSelectedAccessMethodIds(accessMethodIds);
        setCheckAllAccessMethod(true);
    };
    const setDefaultReportStateOptions = (): void => {
        const reportStateIds: number[] = defaultReportStateOptions.map((item) => item.value);
        setReportStateOptions(defaultReportStateOptions);
        setTempReportStateIds(reportStateIds);
        setSelectedReportStateIds(reportStateIds);
    };

    const onChangeField = (id: string, type: number, value: any) => {
        const field = tempSelectedCustomFields.find((f) => f.id == id);
        if (field) {
            setTempSelectedCustomFields(
                tempSelectedCustomFields.map((f) => {
                    if (f.id == id) {
                        return { ...f, value: value };
                    }
                    return f;
                }),
            );
        } else {
            setTempSelectedCustomFields([
                ...tempSelectedCustomFields,
                { id, type_id: type, name: customFields.find((f) => f.id == id)?.name || '', value },
            ]);
        }
    };
    const getCustomFields = () => {
        setIsLoadingCustomFields(true);
        customFieldsService
            .listCustomFields()
            .then((response: CustomField[]) => {
                setCustomFields(response);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: translate({ key: 'custom-fields.error-list' }) });
            })
            .finally(() => {
                setIsLoadingCustomFields(false);
            });
    };

    // Return state and events
    return {
        form,
        tags,
        isLoading,
        selectedLockIds,
        searchInput,
        dateRange,
        isLoadingLocks,
        isTableLoading,
        isFilterModalVisible,
        locks,
        toggleTable,
        accessMethodOptions,
        reportStateOptions,
        selectedAccessMethodIds,
        selectedReportStateIds,
        onSearchInputChange,
        onResetButtonPressed,
        disabledDate,
        onPickedDate,
        onLockSelect,
        onSubmitButtonPressed,
        onCancelButtonPressed,
        onCloseTag,
        onCheckAccessMethodOption,
        onCheckReportStateOption,
        tempAccessMethodIds,
        tempReportStateIds,
        onCheckAllAccessMethodOption,
        checkAllAccessMethod,
        indeterminate,
        customFields,
        onChangeField,
        selectedCustomFields,
    };
};
