import { useState } from 'react';
import {
    AddCustomFieldModalController,
    FieldTypeOption,
} from 'fragments/custom-fields-dashboard/fragments/add-custom-field-modal/interfaces';
import { useCustomFieldsContext } from 'fragments/custom-fields-dashboard/context/custom-fields.context';
import { useAPICustomFields } from 'services/custom-fields/api-custom-fields.service';
import { useForm } from 'antd/es/form/Form';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { AddCustomFieldsRequest } from 'services/custom-fields/custom-fields.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { MainError } from 'services/dtos/errors..dto';

export const useAddCustomFieldModalController = (): /* <--Dependency Injections  like services hooks */
AddCustomFieldModalController => {
    const customFieldsService = useAPICustomFields();
    const [form] = useForm();
    const { translate } = useTranslator();
    const messenger = useMessenger();

    const fieldTypeOptions: FieldTypeOption[] = [
        {
            id: 1,
            value: translate({ key: `custom-fields.type.1` }),
        },
        {
            id: 2,
            value: translate({ key: `custom-fields.type.2` }),
        },
        {
            id: 3,
            value: translate({ key: `custom-fields.type.3` }),
        },
        {
            id: 4,
            value: translate({ key: `custom-fields.type.4` }),
        },
    ];

    /* State */
    const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>(false);
    const [fieldName, setFieldName] = useState<string>('');
    const [fieldNameError, setFieldNameError] = useState<boolean>(false);
    const [defaultValue, setDefaultValue] = useState<string>('');
    const [optionalChecked, setOptionalChecked] = useState<boolean>(false);
    const [selectedFieldType, setSelectedFieldType] = useState<number>(0);
    const [defaultValues, setDefaultValues] = useState<string[]>([]);

    const { isAddFieldModalVisible, setIsAddFieldModalVisible, getCustomFieldsList } = useCustomFieldsContext();
    /* Listeners */

    /* View Events */

    const resetFields = () => {
        setFieldName('');
        setOptionalChecked(false);
        setSelectedFieldType(0);
        setDefaultValues([]);
        setDefaultValue('');
        setFieldNameError(false);
        form.resetFields();
    };
    const onCancelButtonPressed = () => {
        resetFields();
        setIsAddFieldModalVisible(false);
    };
    const onCreateButtonPressed = () => {
        const input: AddCustomFieldsRequest = {
            name: fieldName.trim(),
            optional: optionalChecked,
            type_id: selectedFieldType,
            default_values: defaultValues,
        };
        setIsLoadingAdd(true);
        customFieldsService
            .addCustomFields(input)
            .then(() => {
                setIsAddFieldModalVisible(false);
                resetFields();
                form.resetFields();
                getCustomFieldsList();
            })
            .catch((err: MainError) => {
                if (err.code == 409) {
                    switch (err.msg) {
                        case 'locked':
                            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.locked-error' }) });
                            break;
                        case 'name':
                            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.name-error' }) });
                            setFieldNameError(true);
                            break;
                        default:
                            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.edit-error' }) });
                            break;
                    }
                } else {
                    messenger.showErrorMessage({ key: translate({ key: 'custom-fields.add-error' }) });
                }
            })
            .finally(() => {
                setIsLoadingAdd(false);
            });
    };
    const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldName(e.target.value);
        setFieldNameError(false);
    };

    const onChangeOptionalSwitch = (value: boolean) => {
        setOptionalChecked(value);
    };

    const onSelectFieldTypeOption = (value: number) => {
        setSelectedFieldType(value);
    };

    const onAddDefaultValue = () => {
        const foundValue = defaultValues.find((v) => v == defaultValue);
        if (!foundValue) {
            setDefaultValues([...defaultValues, defaultValue]);
        }
        form.resetFields(['default-values']);
    };

    const onChangeDefaultValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultValue(e.target.value);
    };

    const onRemoveDefaultValue = (value: string) => {
        const filteredValues = defaultValues.filter((v) => v != value);
        setDefaultValues(filteredValues);
    };

    /*public methods*/

    // Return state and events
    return {
        isAddFieldModalVisible,
        isLoadingAdd,
        form,
        fieldName,
        fieldNameError,
        optionalChecked,
        selectedFieldType,
        fieldTypeOptions,
        defaultValue,
        defaultValues,
        onCancelButtonPressed,
        onCreateButtonPressed,
        onNameInputChange,
        onChangeOptionalSwitch,
        onSelectFieldTypeOption,
        onAddDefaultValue,
        onChangeDefaultValue,
        onRemoveDefaultValue,
    };
};
