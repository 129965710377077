import { PaginationRequest } from 'global/interfaces';
import React, { useContext } from 'react';
import { ReportFileDto } from 'services/access-reports/access-reports.service';

export interface ReportFilesContextInterface {
    reportFiles: ReportFileDto[];
    currentPage: number;
    pageSize: number;
    totalReportFiles: number;
    isLoadingTable: boolean;
    eventSource?: EventSource;
    isCreateReportFileModalVisible: boolean;
    setIsCreateReportFileModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setEventSource: React.Dispatch<React.SetStateAction<EventSource | undefined>>;
    setReportFiles: React.Dispatch<React.SetStateAction<ReportFileDto[]>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setTotalReportFiles: React.Dispatch<React.SetStateAction<number>>;
    setIsLoadingTable: React.Dispatch<React.SetStateAction<boolean>>;

    getReportFiles: (pagination?: PaginationRequest) => void;
}

export const ReportFilesContext = React.createContext<ReportFilesContextInterface>({
    reportFiles: [],
    currentPage: 0,
    pageSize: 20,
    totalReportFiles: 0,
    isLoadingTable: false,
    eventSource: undefined,
    isCreateReportFileModalVisible: false,
    setIsCreateReportFileModalVisible: () => {
        /* Nothing to do here*/
    },
    setEventSource: () => {
        /* Nothing to do here*/
    },
    setReportFiles: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setTotalReportFiles: () => {
        /* Nothing to do here*/
    },
    setIsLoadingTable: () => {
        /* Nothing to do here*/
    },
    getReportFiles: () => {
        /* Nothing to do here*/
    },
});

export const useReportFilesContext = (): ReportFilesContextInterface => {
    const store = useContext(ReportFilesContext);
    return store;
};
