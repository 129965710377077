import React from 'react';
import 'fragments/operations/fragments/operation-action-list/operation-action-list.scss';
import {
    ActionTableRow,
    OperationActionListFragmentProps,
} from 'fragments/operations/fragments/operation-action-list/interfaces';
import { useOperationActionListController } from 'fragments/operations/fragments/operation-action-list/operation-action-list.controller';
import { Button, Table, Tag, Tooltip, Typography, Modal, Space } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    ClockCircleOutlined,
    SyncOutlined,
    LoadingOutlined,
    BorderOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined,
    StopOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import { OperationActionCommand } from 'services/operation-actions/operation-actions.service';

const { Text } = Typography;
const { confirm } = Modal;

export const OperationActionListFragment: React.FC<OperationActionListFragmentProps> = (props) => {
    const { useController = useOperationActionListController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const showConfirmActionCommandModal = (id: string, command: OperationActionCommand) => {
        confirm({
            title: <Text strong>{translate({ key: 'operations.command-modal-title' })}</Text>,
            icon: <InfoCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'button primary' },
            okText: translate({ key: 'general.execute' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onCommandActionButtonPressed(id, command);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };

    const showConfirmOperationCommandModal = (id: string, statusId: number) => {
        confirm({
            title: <Text strong>{translate({ key: 'operations.command-modal-title' })}</Text>,
            icon: <InfoCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'button primary' },
            okText: translate({ key: 'general.execute' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onCommandOperationButtonPressed(id, statusId);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };
    const getOperationCommandButton = (id: string, statusId: number) => {
        let tooltip = '';
        let icon = <></>;
        switch (statusId) {
            case 2:
                tooltip = translate({ key: 'operations.tooltips.stop' });
                icon = <StopOutlined />;
                break;
            case 1:
            case 4:
                tooltip = translate({ key: `operations.tooltips.${statusId == 1 ? 'start' : 'resume'}` });
                icon = <PlayCircleOutlined />;
                break;

            case 6:
                tooltip = translate({ key: 'operations.tooltips.retry' });
                icon = <ReloadOutlined />;
                break;
            default:
                return <></>;
        }
        return (
            <Tooltip placement="topLeft" title={tooltip}>
                <Button
                    disabled={controller.isLoadingOperationCommand || controller.isLoadingActionCommand}
                    danger={statusId == 2}
                    icon={icon}
                    onClick={() => showConfirmOperationCommandModal(id, statusId)}
                />
            </Tooltip>
        );
    };

    const getStatusTag = (status: number) => {
        let icon = <></>;
        let color = '';
        switch (status) {
            case 1: // CREATED
            case 8: // SUCCEDED
                icon = <CheckCircleOutlined />;
                color = status == 8 ? 'success' : 'default';
                break;
            case 2: // WAITING
                icon = <ClockCircleOutlined />;
                color = 'default';
                break;
            case 3: // IN PROGRES
            case 5: // RETRYING
                icon = <SyncOutlined spin />;
                color = 'processing';
                break;
            case 4: // TO RETRY
                icon = <ReloadOutlined />;
                color = 'orange';
                break;
            case 6: // CANCELING
                icon = <LoadingOutlined />;
                color = 'warning';
                break;
            case 7: // CANCELED
            case 9: // FAILED
                icon = <CloseCircleOutlined />;
                color = 'error';
                break;
            default:
                return <></>;
        }
        return (
            <Tag color={color} style={{ fontWeight: 'bold' }} icon={icon}>
                {translate({ key: `operations.actions.status.${status}` })}
            </Tag>
        );
    };

    const getOperationStatusTag = (status: number) => {
        let icon = <></>;
        let color = '';
        switch (status) {
            case 1:
                icon = <ClockCircleOutlined />;
                color = 'default';
                break;
            case 2:
                icon = <SyncOutlined spin />;
                color = 'processing';
                break;
            case 3:
                icon = <LoadingOutlined />;
                color = 'warning';
                break;
            case 4:
                icon = <BorderOutlined />;
                color = 'orange';
                break;
            case 5:
                icon = <CheckCircleOutlined />;
                color = 'success';
                break;
            case 6:
                icon = <CloseCircleOutlined />;
                color = 'error';
                break;
            default:
                return <></>;
        }
        return (
            <Tag color={color} style={{ fontWeight: 'bold' }} icon={icon}>
                {translate({ key: `operations.status.${status}` })}
            </Tag>
        );
    };

    // Render
    return (
        <div className={'operation-action-list'}>
            <div
                style={{
                    backgroundColor: '#fff',
                    marginBottom: '1.5rem',
                    padding: '1rem',
                    width: '100%',
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {controller.eventError && (
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: '#ff000030',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <Text strong style={{ color: 'red' }}>
                                {translate({ key: 'general.error' })}:{' '}
                            </Text>
                            <Text strong style={{ color: 'red' }}>
                                {translate({ key: 'operations.reload-message' })}
                            </Text>
                        </div>
                        <Button
                            className=" primary"
                            type="primary"
                            style={{ padding: '3px', fontWeight: '600' }}
                            onClick={controller.onReloadButtonPressed}
                        >
                            {translate({ key: 'general.reload' })}
                        </Button>
                    </div>
                )}
                <div
                    style={{
                        width: '100%',
                        backgroundColor: '#FAAD1499',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <Text strong>{translate({ key: 'general.notes' })}: </Text>
                        <Text>{translate({ key: 'operations.actions.retry' })}</Text>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '10px',
                }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    {controller.operationInfo != undefined && (
                        <>
                            <Text style={{ fontSize: '1.5rem', fontWeight: 'bold', paddingRight: '2rem' }}>
                                {controller.operationInfo.name}
                            </Text>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tag color="default" style={{ fontWeight: 'bold' }} icon={<CheckCircleOutlined />}>
                                    {`${controller.operationInfo.count.finished}/${controller.operationInfo.count.total}`}
                                </Tag>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {getOperationStatusTag(controller.operationInfo.statusId)}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {getOperationCommandButton(
                                    controller.operationInfo.id,
                                    controller.operationInfo.statusId,
                                )}
                            </div>
                        </>
                    )}
                </div>
                <Space>
                    <Button
                        className=" primary"
                        type="primary"
                        style={{ padding: '3px', fontWeight: '600' }}
                        onClick={controller.onGoBackButtonPressed}
                    >
                        {translate({ key: 'general.back' })}
                    </Button>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        key: 'label',
                        title: translate({ key: 'label.label' }),
                        dataIndex: 'label',
                        render: (label) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {label}
                            </div>
                        ),
                    },
                    {
                        key: 'deviceName',
                        title: translate({ key: 'label.device' }),
                        dataIndex: 'deviceName',
                        render: (deviceName) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {deviceName}
                            </div>
                        ),
                    },
                    {
                        key: 'cardNumber',
                        title: translate({ key: 'label.card' }),
                        dataIndex: 'cardNumber',
                        render: (cardNumber) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '100px' }}>
                                {cardNumber}
                            </div>
                        ),
                    },
                    {
                        key: 'type',
                        title: translate({ key: 'label.type' }),
                        dataIndex: 'type',
                        render: (type) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px' }}>{type}</div>
                        ),
                    },
                    {
                        key: 'retryCount',
                        title: translate({ key: 'label.retry-count' }),
                        dataIndex: 'retryCount',
                        render: (retryCount) => (
                            <Text
                                style={{
                                    textAlign: 'center',
                                    color: retryCount > 0 ? 'red' : undefined,
                                    fontWeight: retryCount > 0 ? 'bold' : undefined,
                                }}
                            >
                                {retryCount}
                            </Text>
                        ),
                    },
                    {
                        key: 'statusId',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'statusId',
                        render: (statusId, { msg }) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {getStatusTag(statusId)}
                                {msg && (
                                    <Tooltip title={translate({ key: `operations.tooltips.${msg}` })}>
                                        <InfoCircleOutlined
                                            style={{ fontSize: '1rem', color: '#1890FF', alignSelf: 'center' }}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'actions',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id, statusId }) => (
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
                                {(statusId == 1 || statusId == 2 || statusId == 4 || statusId == 9) && (
                                    <Tooltip
                                        placement="topLeft"
                                        title={translate({ key: 'operations.tooltips.cancel' })}
                                    >
                                        <Button
                                            disabled={
                                                controller.isLoadingOperationCommand ||
                                                controller.isLoadingActionCommand
                                            }
                                            danger
                                            icon={<CloseCircleOutlined />}
                                            onClick={() =>
                                                showConfirmActionCommandModal(id, OperationActionCommand.CANCEL)
                                            }
                                        />
                                    </Tooltip>
                                )}
                                {statusId == 9 && controller.operationInfo?.statusId == 2 && (
                                    <Tooltip
                                        placement="topLeft"
                                        title={translate({ key: 'operations.tooltips.retry' })}
                                    >
                                        <Button
                                            disabled={
                                                controller.isLoadingOperationCommand ||
                                                controller.isLoadingActionCommand
                                            }
                                            icon={<PlayCircleOutlined />}
                                            onClick={() =>
                                                showConfirmActionCommandModal(id, OperationActionCommand.RETRY)
                                            }
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModelTable}
                loading={controller.isLoadingActions}
                rowKey={(itemsViewModelTable: ActionTableRow) => itemsViewModelTable.id}
                pagination={false}
            />
        </div>
    );
};
