import React from 'react';
import 'fragments/site-policies/fragments/site-policy-history/site-policy-history.scss';
import { SitePolicyHistoryFragmentProps } from 'fragments/site-policies/fragments/site-policy-history/interfaces';
import { useSitePolicyHistoryController } from 'fragments/site-policies/fragments/site-policy-history/site-policy-history.controller';
import { Skeleton, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
const { Text } = Typography;
import { SyncOutlined, CloseCircleOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

export const SitePolicyHistoryFragment: React.FC<SitePolicyHistoryFragmentProps> = (props) => {
    const { useController = useSitePolicyHistoryController, policyId } = props;
    const controller = useController(policyId);

    const getStatusIcon = (status: number) => {
        switch (status) {
            case 4:
                return <CloseCircleOutlined style={{ color: '#FF4D4F' }} />;
            case 2:
                return <ClockCircleOutlined style={{ color: '#08979C' }} />;
            case 0:
                return <SyncOutlined spin style={{ color: '#7C5FBC' }} />;
            case 1:
                return <CheckCircleOutlined style={{ color: '#52C41A' }} />;
            default:
                return <></>;
        }
    };
    // Render
    return (
        <div className={'site-policy-history'} style={{ width: '100%' }}>
            {controller.isLoading ? (
                <>
                    <Skeleton.Input active size="small" block />
                    <Skeleton.Input active size="small" block />
                    <Skeleton.Input active size="small" block />
                </>
            ) : (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <Timeline
                        mode="left"
                        style={{ marginTop: '1rem', width: '100%' }}
                        items={controller.history.map((item, index) => {
                            return {
                                dot: getStatusIcon(item.site_policy_status_id),
                                position: 'left',
                                key: index,
                                style: {
                                    paddingBottom: '12px',
                                    // gap: '1rem',
                                },
                                label: dayjs.utc(item.created_at).format('DD/MM/YY HH:mm') + 'hs',
                                children: (
                                    <>
                                        <Text strong style={{ paddingLeft: '6px' }}>
                                            {`${item.created_by_email} - `}
                                        </Text>
                                        <Text style={{ paddingLeft: '6px' }}>{item.observation}</Text>
                                    </>
                                ),
                            };
                        })}
                    />
                </div>
            )}
        </div>
    );
};
