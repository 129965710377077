import React from 'react';
import 'fragments/site-policies/fragments/site-policy-control/site-policy-control.scss';
import { SitePolicyControlFragmentProps } from 'fragments/site-policies/fragments/site-policy-control/interfaces';
import { useSitePolicyControlController } from 'fragments/site-policies/fragments/site-policy-control/site-policy-control.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useSitePoliciesContext } from 'fragments/site-policies/context/site-policies.context';
import {
    Form,
    Input,
    Button,
    Tooltip,
    Modal,
    Typography,
    Cascader,
    DatePicker,
    TimePicker,
    Col,
    InputNumber,
    Switch,
    Tag,
} from 'antd';
const { Title, Text } = Typography;

import {
    ExclamationCircleOutlined,
    PlusOutlined,
    UpCircleOutlined,
    DownCircleOutlined,
    InfoCircleOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

export const SitePolicyControlFragment: React.FC<SitePolicyControlFragmentProps> = (props) => {
    const { useController = useSitePolicyControlController, policyId } = props;
    const controller = useController(policyId);

    const { translate } = useTranslator();

    // Render
    return (
        <Modal
            open={controller.isControlModalVisible}
            destroyOnClose={true}
            className={'site-policy-control'}
            closable={false}
            centered
            footer={[
                <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                    {translate({ key: 'general.cancel' })}
                </Button>,
                !controller.rejecting && (
                    <Button key="reject" className={'btn-cancel'} onClick={controller.onRejectPolicyButtonPressed}>
                        {translate({ key: 'site-policies.request-correction' })}
                    </Button>
                ),
                !controller.rejecting && (
                    <Button
                        key="approve"
                        disabled={controller.isExpired}
                        type="primary"
                        className={'button primary'}
                        loading={controller.isLoading}
                        onClick={controller.onApprovePolicyButtonPressed}
                    >
                        {translate({ key: 'general.approve' })}
                    </Button>
                ),
                controller.rejecting && (
                    <Button
                        form="site-policy-control-form"
                        key="submit"
                        type="primary"
                        className={'button primary'}
                        loading={controller.isLoading}
                        onClick={controller.onFinish}
                        disabled={!controller.observation}
                    >
                        {translate({ key: 'general.submit' })}
                    </Button>
                ),
            ]}
        >
            {!controller.rejecting && (
                <>
                    <Title level={4} style={{ padding: 0, marginBottom: '1rem' }}>
                        {translate({ key: 'site-policies.sure-to-validate' })}
                    </Title>
                    {controller.isExpired && (
                        <Tag color="orange" style={{ padding: '.5rem', width: '100%', marginBottom: '1rem' }}>
                            <Text strong>
                                {translate({ key: 'Esta politica se encuentra vencida. Debes enviarla a revision.' })}
                            </Text>
                        </Tag>
                    )}
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <ExclamationCircleOutlined style={{ fontSize: '2rem', color: 'orange', alignSelf: 'center' }} />
                        <div>
                            {translate({ key: 'site-policies.one-way-action' })}
                            <br />
                            <Text strong>{translate({ key: 'general.one-way-action' })}</Text>
                        </div>
                    </div>
                </>
            )}
            {controller.rejecting && (
                <>
                    <Title level={4} style={{ padding: 0, marginBottom: '1rem' }}>
                        {translate({ key: 'site-policies.send-for-review-title' })}
                    </Title>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        {translate({ key: 'site-policies.send-for-review-text' })}
                    </div>
                </>
            )}
            {controller.rejecting && (
                <Form
                    name="site-policy-control-form"
                    className={'form'}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    size="middle"
                    form={controller.form}
                >
                    <Form.Item
                        label={
                            <>
                                <Text type="secondary" strong style={{ paddingRight: '8px' }}>
                                    {translate({ key: 'label.observation' })}
                                </Text>
                            </>
                        }
                        required
                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                        name={'observation'}
                    >
                        <TextArea rows={4} maxLength={255} onChange={controller.onAddObservation} />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};
