import {
    Passcodes,
    PaginatedPasscodesResponse,
    CreatePasscodeInput,
    CreatePasscodeResponse,
    SearchPasscode,
    CheckPasscodeSameDate,
} from 'services/passcodes/passcodes.service';
import { OrderTable } from 'global/interfaces';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';

export const useAPIPasscodes = (): Passcodes => {
    const [session] = useLocalSession();
    const listPasscodes = async (
        page: number,
        pageSize: number,
        orderTable: OrderTable,
        search: SearchPasscode,
    ): Promise<PaginatedPasscodesResponse> => {
        const locks = JSON.stringify(search.locks);
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_PASSCODES_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/passcodes?page=${page}&pageSize=${pageSize}&order=${orderTable.order}&orderBy=${orderTable.orderBy}&locks=${locks}&since=${search.since}&until=${search.until}&label=${search.label}&code=${search.code}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as PaginatedPasscodesResponse;
        } catch (error) {
            throw error;
        }
    };

    const checkPasscodeSameDateExistsByLockId = async (input: CheckPasscodeSameDate): Promise<boolean> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_PASSCODES_BASE_URL +
                    `/user/passcodes/check-same-date?since=${input.since}&until=${input.until}&lock=${input.lockId}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data.available;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    const createPasscode = async (input: CreatePasscodeInput): Promise<CreatePasscodeResponse> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_PASSCODES_BASE_URL + `/user/passcodes`, input, {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as CreatePasscodeResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    return {
        listPasscodes,
        checkPasscodeSameDateExistsByLockId,
        createPasscode,
    };
};
