import React from 'react';
import 'fragments/custom-fields-dashboard/custom-fields-dashboard.scss';
import { CustomFieldsDashboardFragmentProps } from 'fragments/custom-fields-dashboard/interfaces';
import { useCustomFieldsDashboardController } from 'fragments/custom-fields-dashboard/custom-fields-dashboard.controller';
import { Button, Col, Modal, Row, Space, Table, Tooltip, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    PlusOutlined,
    DeleteFilled,
    EditOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
const { Text } = Typography;
const { confirm } = Modal;

import { CustomField, CustomFieldValue } from 'services/custom-fields/custom-fields.service';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DndContext } from '@dnd-kit/core';

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableAntdTableRow from 'components/sortable-antd-table-row/sortable-antd-table-row.component';
import { AddCustomFieldModalFragment } from './fragments/add-custom-field-modal/add-custom-field-modal.fragment';
import { EditCustomFieldModalFragment } from './fragments/edit-custom-field-modal/edit-custom-field-modal.fragment';

export const CustomFieldsDashboardFragment: React.FC<CustomFieldsDashboardFragmentProps> = (props) => {
    const { useController = useCustomFieldsDashboardController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const showConfirmDeleteModal = (id: string) => {
        confirm({
            title: <Text strong>{translate({ key: 'general.sure-to-delete' })}</Text>,
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action' }),
            okText: translate({ key: 'general.remove' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onDeleteButtonPressed(id);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };
    const formatValues = (values: CustomFieldValue[]) => {
        const columnLenght = Math.ceil(values.length / 3);
        const array1 = values.slice(0, columnLenght);
        const array2 = values.slice(columnLenght, columnLenght * 2);
        const array3 = values.slice(columnLenght * 2);
        return (
            <>
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {array1.map((x) => (
                        <Text key={x.id}>- {x.value}</Text>
                    ))}
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {array2.map((x) => (
                        <Text key={x.id}>- {x.value}</Text>
                    ))}
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {array3.map((x) => (
                        <Text key={x.id}>- {x.value}</Text>
                    ))}
                </Col>
            </>
        );
    };

    // Render
    return (
        <div className={'custom-fields-dashboard'}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '10px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <EditCustomFieldModalFragment />
                    <AddCustomFieldModalFragment />
                </div>
                <Space>
                    <Tooltip title={translate({ key: 'custom-fields.add-new-custom-field' })}>
                        <Button
                            className={'button'}
                            type="default"
                            style={{ justifySelf: 'flex-end', padding: '3px' }}
                            onClick={controller.onAddButtonPressed}
                            icon={<PlusOutlined />}
                        />
                    </Tooltip>
                </Space>
            </div>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={controller.onDragEnd}>
                <SortableContext
                    // rowKey array
                    items={controller.itemsViewModelTable.map((i) => i.id)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        dataSource={controller.itemsViewModelTable}
                        columns={[
                            {
                                key: 'sort',
                            },
                            Table.EXPAND_COLUMN,
                            // {
                            //     key: 'order',
                            //     title: translate({ key: 'label.order' }),
                            //     dataIndex: 'order',
                            //     render: (order) => (
                            //         <div
                            //             style={{
                            //                 display: 'flex',
                            //                 minWidth: '50px',
                            //                 maxWidth: '50px',
                            //             }}
                            //         >
                            //             <Text style={{ margin: 'auto' }}>{order}</Text>
                            //         </div>
                            //     ),
                            // },
                            {
                                key: 'name',
                                title: translate({ key: 'label.name' }),
                                dataIndex: 'name',
                                render: (name) => (
                                    <div style={{ display: 'flex', alignItems: 'start', minWidth: '130px' }}>
                                        {name}
                                    </div>
                                ),
                            },
                            {
                                key: 'optional',
                                title: translate({ key: 'label.optional' }),
                                dataIndex: 'optional',
                                render: (optional) => (
                                    <div style={{ display: 'flex', alignItems: 'start', minWidth: '80px' }}>
                                        {optional ? (
                                            <CheckCircleOutlined style={{ fontSize: '1.5rem', color: '#52C41A' }} />
                                        ) : (
                                            <CloseCircleOutlined style={{ fontSize: '1.5rem', color: '#CF1322' }} />
                                        )}
                                    </div>
                                ),
                            },
                            {
                                key: 'type',
                                title: translate({ key: 'label.type' }),
                                dataIndex: 'type_id',
                                render: (type_id) => (
                                    <div style={{ display: 'flex', alignItems: 'start', minWidth: '80px' }}>
                                        {translate({ key: `custom-fields.type.${type_id}` })}
                                    </div>
                                ),
                            },
                            {
                                key: 'action',
                                title: translate({ key: 'label.actions' }),
                                render: (_, { id }) => (
                                    <div style={{ display: 'flex', gap: '.5rem' }}>
                                        <Button
                                            // disabled={controller.isLoadingEdit}
                                            // loading={controller.isLoadingEdit}
                                            icon={<EditOutlined />}
                                            onClick={() => controller.onEditButtonPressed(id)}
                                        />
                                        <Button
                                            disabled={controller.isDeletingField}
                                            loading={controller.deletingFieldId == id && controller.isDeletingField}
                                            danger
                                            icon={<DeleteFilled />}
                                            onClick={() => showConfirmDeleteModal(id)}
                                        />
                                    </div>
                                ),
                            },
                        ]}
                        loading={controller.isLoadingList}
                        rowKey={(itemsViewModelTable: CustomField) => itemsViewModelTable.id}
                        pagination={{
                            pageSize: controller.itemsViewModelTable.length,
                            disabled: true,
                            hideOnSinglePage: true,
                        }}
                        components={{
                            body: {
                                row: SortableAntdTableRow,
                            },
                        }}
                        expandable={{
                            expandedRowRender: (field) => {
                                return (
                                    <div>
                                        <Row style={{ gap: '2rem' }}>
                                            <Col>
                                                <Text strong>{translate({ key: 'label.responsible' })}: </Text>
                                                <Text>
                                                    {field.created_by_name} - {field.created_by_email}
                                                </Text>
                                            </Col>

                                            <Col>
                                                <Text strong>{translate({ key: 'label.creation-date' })}:</Text>
                                                <Text> {field.created_at}</Text>
                                            </Col>
                                        </Row>
                                        <Row style={{ gap: '2rem' }}>
                                            <Col>
                                                <Text strong>{translate({ key: 'label.updated-by' })}: </Text>
                                                <Text>
                                                    {field.updated_by_name} - {field.updated_by_email}
                                                </Text>
                                            </Col>
                                            <Col>
                                                <Text strong>{translate({ key: 'label.last-update' })}:</Text>
                                                <Text> {field.updated_at}</Text>
                                            </Col>
                                        </Row>
                                        {field.type_id == 4 && field.values && (
                                            <Row style={{ flexDirection: 'column' }}>
                                                <Text strong>{translate({ key: 'label.select-values' })}: </Text>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        border: '1px solid #d0d0d0',
                                                        borderRadius: '5px',
                                                        padding: '10px',
                                                        paddingRight: '30px',
                                                    }}
                                                >
                                                    {formatValues(field.values)}
                                                </div>
                                            </Row>
                                        )}
                                    </div>
                                );
                            },
                        }}
                    />
                </SortableContext>
            </DndContext>
        </div>
    );
};
