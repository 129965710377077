import { SiteContext } from 'fragments/sites-modal/context/sites.context';
import React, { useState } from 'react';
import { SiteDtoSimple } from 'services/sites/sites.service';

type Props = {
    children?: React.ReactNode;
};

const SitesProvider: React.FC<Props> = (props) => {
    const [isCreateSiteFragmentVisible, setIsCreateSiteFragmentVisible] = useState<boolean>(false);
    const [sites, setSites] = useState<SiteDtoSimple[]>([]);

    return (
        <SiteContext.Provider value={{ isCreateSiteFragmentVisible, setIsCreateSiteFragmentVisible, sites, setSites }}>
            {props.children}
        </SiteContext.Provider>
    );
};

export default SitesProvider;
