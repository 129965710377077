import React, { ReactNode } from 'react';
import 'components/simple-item-list/simple-item-list.scss';
import { List, Row, Col, Typography, Dropdown, Menu, Modal } from 'antd';
import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslator } from 'tools/view-hooks/translator-hook';

const { Text, Title } = Typography;
const { confirm } = Modal;

export interface SimpleItemListProps {
    keyValue: number | string;
    title: string;
    titleSize?: 'large' | 'small';
    detail1?: { icon?: ReactNode; label?: string; value: string };
    detail2?: { icon?: ReactNode; label?: string; value?: string };
    detail3?: { icon?: ReactNode; label?: string; value?: string };
    optionalDetail?: { label?: string; value?: string };
    list?: ItemListDto[];
    actions?: {
        // TODO: Sacar esta logica de los fragments que usan el component,
        //en vez de onOptionsClick para setear el item seleccionado, lo seleccionamos en la misma accion)
        // pasandole el id como argumento
        onOptionClick?: () => void;
        delete?: {
            icon: ReactNode;
            label: string;
            onClick?: () => void;
            onConfirm?: (key: number | string) => void;
            onCancel?: () => void;
        };
        edit?: { icon: ReactNode; label: string; onClick?: () => void };
    };
    wrapperLinkTo?: string;
}

export interface ItemListDto {
    id: number;
    itemName?: string;
    itemValue?: string | number;
}

const SimpleItemList: React.FC<SimpleItemListProps> = (props) => {
    // State

    const { translate } = useTranslator();

    const showConfirmModal = () => {
        confirm({
            title: translate({ key: 'general.sure-to-delete' }),
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action' }),
            okText: translate({ key: 'general.remove' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                props.actions?.delete?.onConfirm?.(props.keyValue);
            },
            onCancel() {
                props.actions?.delete?.onCancel;
            },
        });
    };

    const menu = (
        <Menu>
            {props.actions?.edit && (
                <Menu.Item key="edit" icon={props.actions?.edit?.icon} onClick={props.actions?.edit?.onClick}>
                    {props.actions?.edit?.label}
                </Menu.Item>
            )}
            {props.actions?.delete && (
                <Menu.Item
                    danger
                    key="delete"
                    icon={props.actions?.delete?.icon}
                    onClick={props.actions?.delete?.onClick ?? showConfirmModal}
                >
                    {props.actions?.delete?.label}
                </Menu.Item>
            )}
        </Menu>
    );

    function LinkWrapper(props: any) {
        if (props.wrapperLinkTo) {
            return (
                <Link to={props.wrapperLinkTo} style={{ width: '100%' }}>
                    {props.children}
                </Link>
            );
        }
        return props.children;
    }

    return (
        <List.Item>
            <Row style={{ width: '100%' }} align="middle" justify="space-between">
                <Col xs={props.actions ? 23 : 24}>
                    <LinkWrapper wrapperLinkTo={props.wrapperLinkTo}>
                        <Row>
                            <Col xs={24}>
                                {props.titleSize == 'large' && (
                                    <Title level={4} style={{ textTransform: 'capitalize' }}>
                                        {props.title}
                                    </Title>
                                )}
                                {props.titleSize !== 'large' && (
                                    <Text strong style={{ textTransform: 'capitalize' }}>
                                        {props.title}
                                    </Text>
                                )}
                            </Col>
                            {props.detail1 && (
                                <Col xs={24}>
                                    <Text type="secondary">
                                        {props.detail1.icon}
                                        {props.detail1.label}: {props.detail1.value}
                                    </Text>
                                </Col>
                            )}
                            {props.optionalDetail && (
                                <Col xs={24}>
                                    <Text type="secondary">
                                        {props.optionalDetail.label}: {props.optionalDetail.value}
                                    </Text>
                                </Col>
                            )}

                            {props.detail2 && (
                                <Col xs={24}>
                                    <Text type="secondary">
                                        {props.detail2?.icon}
                                        {props.detail2.label}: {props.detail2?.value}
                                    </Text>
                                </Col>
                            )}

                            {props.detail3 && (
                                <Col xs={24}>
                                    <Text type="secondary">
                                        {props.detail3.label}: {props.detail3.value}{' '}
                                    </Text>
                                </Col>
                            )}
                            {props.list && (
                                <Col xs={24}>
                                    <ul>
                                        {props.list &&
                                            props.list.length > 0 &&
                                            props.list.map((item) => (
                                                <li key={item.id} style={{ textTransform: 'capitalize' }}>
                                                    <Text type="secondary">
                                                        {item.itemName}, ({item.itemValue})
                                                    </Text>
                                                </li>
                                            ))}
                                    </ul>
                                </Col>
                            )}
                        </Row>
                    </LinkWrapper>
                </Col>
                {props.actions && (
                    <Col xs={1}>
                        <Dropdown trigger={['click']} overlay={menu} key={props.keyValue}>
                            <MoreOutlined onClick={props.actions?.onOptionClick} style={{ fontSize: '1.5rem' }} />
                        </Dropdown>
                    </Col>
                )}
            </Row>
        </List.Item>
    );
};

export default SimpleItemList;
