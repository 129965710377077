import React from 'react';
import 'auth/fragments/login/login.scss';
import { LoginFragmentProps } from 'auth/fragments/login/interfaces';
import { useLoginController } from 'auth/fragments/login/login.controller';
import { Button, Col, Form, Input, Row, Layout, Typography } from 'antd';
import ModalForm from 'components/modal-form/modal-form.component';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const LoginFragment: React.FC<LoginFragmentProps> = (props) => {
    const { useController = useLoginController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'login'}>
            <Header className={'login-header'}>
                <img src={'logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <ModalForm
                    form={controller.recoverPassForm}
                    isVisible={controller.isRecoverPassVisible}
                    isLoading={controller.isRecoverPassLoading}
                    onFinish={controller.onRecoverPassSubmit}
                    onCancel={controller.onCancelRecoverPass}
                    title={translate({ key: 'auth.recover-password-title' })}
                >
                    <Form.Item
                        label={<Text strong>{translate({ key: 'auth.email-input-label' })}</Text>}
                        name="email"
                        rules={[{ required: true }]}
                        className={'form-item'}
                    >
                        <Input />
                    </Form.Item>
                </ModalForm>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'login-form'}
                        initialValues={{ remember: true }}
                        onFinish={controller.onLoginSubmit}
                    >
                        <Title level={2}>{translate({ key: 'auth.login-title' })}</Title>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'auth.login-subtitle' })}
                        </Text>
                        <Form.Item
                            label={<Text strong>{translate({ key: 'label.username' })}</Text>}
                            name="username"
                            rules={[{ required: true }]}
                        >
                            <Input placeholder={translate({ key: 'form.placeholder-username' })} />
                        </Form.Item>

                        <Form.Item
                            label={<Text strong>{translate({ key: 'label.password' })}</Text>}
                            name="password"
                            rules={[{ required: true }]}
                        >
                            <Input.Password
                                placeholder={translate({ key: 'form.placeholder-enter-password' })}
                                onChange={controller.onPasswordInputChange}
                            />
                        </Form.Item>

                        <div className={'login-forgot-container'}>
                            <Button
                                className={'button primary'}
                                type="primary"
                                block={true}
                                htmlType="submit"
                                loading={controller.isLoading}
                                disabled={controller.password.length < 6 ? true : false}
                            >
                                {translate({ key: 'auth.login-button-label' })}
                            </Button>
                            <Button
                                className={'forgot-pass-btn'}
                                type={'link'}
                                onClick={controller.onForgotPassPressed}
                            >
                                {translate({ key: 'auth.forgot-password-button-label' })}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
