import { useEffect, useReducer, useState } from 'react';
import {
    DeleteSiteCardProgressModalActionTypes,
    DeleteSiteCardProgressModalController,
    DeleteSiteCardProgressModalState,
    ReducerAction,
} from './interfaces';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { EventTypes, SseEvent } from 'services/sse/sse.service';
import { useSiteCardsContext } from 'fragments/site-cards-dashboard/context/site-cards.context';

export const useDeleteSiteCardProgressModalController = (
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
DeleteSiteCardProgressModalController => {
    /* State */
    const [eventError, setEventError] = useState<boolean>(false);
    const [enableRetry, setEnableRetry] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean | undefined>();
    const {
        deleteCard,
        isVisibleDeleteCardProgressModal,
        deletionModalRows,
        deletionEventSource,
        isLoadingDelete,
        deleteSiteCard,
        setIsLoadingDelete,
        setDeleteCard,
        setIsVisibleDeleteCardProgressModal,
        getRfidCards,
        setDeletionEventSource,
    } = useSiteCardsContext();

    useEffect(() => {
        setEnableRetry(false);
        if (!deletionEventSource) return;
        dispatch({ type: DeleteSiteCardProgressModalActionTypes.SET_INITIAL_STATE, payload: deletionModalRows });
        setIsDeleting(true);
        setSuccess(undefined);
        deletionEventSource.onerror = (e) => {
            setEventError(true);
            deletionEventSource.close();
            setIsDeleting(false);
        };
        deletionEventSource.onmessage = (event) => {
            const eventData: SseEvent<{ code?: number; message?: string }> = JSON.parse(event.data);
            if (eventData.event_type !== EventTypes.EVENT_HEARTBEAT) {
                dispatch({ type: DeleteSiteCardProgressModalActionTypes.HANDLE_EVENT, payload: eventData });
            }
        };

        return () => {
            deletionEventSource.close();
        };
    }, [deletionEventSource]);
    const getErrorMessage = (code?: number, msg?: string): string => {
        if (!code) return '';
        if (code != 400 && code != 409 && code != 500) {
            return translate({ key: `rfid-card.errors.code.${code}` });
        }
        return msg || '';
    };
    const reducer: React.Reducer<DeleteSiteCardProgressModalState, ReducerAction> = (state, { type, payload }) => {
        switch (type) {
            case DeleteSiteCardProgressModalActionTypes.SET_INITIAL_STATE:
                return {
                    rows: payload,
                    events: [],
                    statusCount: {
                        success: 0,
                        failed: 0,
                        warning: 0,
                        unknown: 0,
                    },
                };
            case DeleteSiteCardProgressModalActionTypes.HANDLE_EVENT: {
                let rows = state.rows;
                const statusCount = state.statusCount;
                switch (payload.event_type) {
                    case EventTypes.EVENT_CARDS_CARD_LOCK_DELETION_STARTED: {
                        rows = rows.map((r) =>
                            r.sub_event_stream_id == payload.sub_event_stream_id ? { ...r, status_id: 1 } : r,
                        );
                        break;
                    }
                    case EventTypes.EVENT_CARDS_CARD_LOCK_DELETION_SUCCEEDED: {
                        rows = rows.map((r) =>
                            r.sub_event_stream_id == payload.sub_event_stream_id ? { ...r, status_id: 2 } : r,
                        );
                        statusCount.success = statusCount.success + 1;
                        break;
                    }
                    case EventTypes.EVENT_CARDS_CARD_LOCK_DELETION_MISSING_ID: {
                        rows = rows.map((r) =>
                            r.sub_event_stream_id == payload.sub_event_stream_id ? { ...r, status_id: 5 } : r,
                        );
                        statusCount.failed = statusCount.failed + 1;
                        break;
                    }
                    case EventTypes.EVENT_CARDS_CARD_LOCK_DELETION_FAILED:
                    case EventTypes.EVENT_TTLOCKAPI_CARD_DELETION_FAILED: {
                        const { code, message } = payload.extended_info;
                        const canRetry =
                            code == 1 || code == 41 || code == 42 || code == 43 || code == 47 || code == 48;
                        rows = rows.map((r) =>
                            r.sub_event_stream_id == payload.sub_event_stream_id
                                ? { ...r, status_id: canRetry ? 4 : 3, message: getErrorMessage(code, message) }
                                : r,
                        );
                        if (canRetry) {
                            statusCount.warning = statusCount.warning + 1;
                        } else {
                            statusCount.failed = statusCount.failed + 1;
                        }
                        break;
                    }
                    case EventTypes.EVENT_CARDS_REMOTE_SITE_CARD_DELETION_ENDED:
                        setIsLoadingDelete(false);
                        setIsDeleting(false);
                        deletionEventSource?.close();
                        break;
                    case EventTypes.EVENT_CARDS_REMOTE_SITE_CARD_DELETION_SUCCEEDED:
                        setEnableRetry(false);
                        setSuccess(true);
                        break;
                    case EventTypes.EVENT_CARDS_REMOTE_SITE_CARD_DELETION_FAILED:
                        setEnableRetry(statusCount.warning + statusCount.failed > 0);
                        setSuccess(false);
                        break;
                    case EventTypes.EVENT_STREAM_ERROR:
                        setEventError(true);
                        break;
                    default:
                        break;
                }

                return {
                    ...state,
                    rows,
                    statusCount,
                    events: [...state.events, payload],
                };
            }

            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(reducer, {
        rows: deletionModalRows,
        events: [],
        statusCount: {
            success: 0,
            failed: 0,
            warning: 0,
            unknown: 0,
        },
    });
    /* View Events */
    const onCloseModalPressed = () => {
        dispatch({ type: DeleteSiteCardProgressModalActionTypes.SET_INITIAL_STATE, payload: [] });
        deletionEventSource?.close();
        setDeletionEventSource(undefined);
        setEventError(false);
        setDeleteCard(undefined);
        getRfidCards({});
        setIsVisibleDeleteCardProgressModal(false);
    };
    const onTryAgainPressed = () => {
        setIsDeleting(true);
        dispatch({ type: DeleteSiteCardProgressModalActionTypes.SET_INITIAL_STATE, payload: [] });
        setDeletionEventSource(undefined);
        setEventError(false);
        setSuccess(undefined);
        deleteCard && deleteSiteCard(deleteCard.id, deleteCard.cardNumber);
    };

    /*private methods*/

    // Return state and events
    return {
        enableRetry,
        eventError,
        isLoadingDelete,
        statusCount: state.statusCount,
        rows: state.rows,
        deleteCard,
        isVisibleDeleteCardProgressModal,
        isDeleting,
        success,
        onCloseModalPressed,
        onTryAgainPressed,
    };
};
