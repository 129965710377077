import { useEffect, useState } from 'react';
import { SitePolicyHistoryController } from 'fragments/site-policies/fragments/site-policy-history/interfaces';
import { SitePolicyHistoryDto } from 'services/site-policies/site-policies.service';
import { useAPISitePolicies } from 'services/site-policies/api-policies.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useSitePolicyHistoryController = (
    policyId: string,
): /* <--Dependency Injections  like services hooks */
SitePolicyHistoryController => {
    const sitePoliciesService = useAPISitePolicies();
    const messenger = useMessenger();

    /* State */
    const [history, setHistory] = useState<SitePolicyHistoryDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    /* Listeners */
    useEffect(() => {
        getHistory(policyId);
    }, []);
    /* View Events */
    const onButtonPressed = () => {
        // Example event
    };

    /* Private Methods */
    const getHistory = (id: string) => {
        setIsLoading(true);
        sitePoliciesService
            .getSitePolicyHistory(id)
            .then((data) => {
                setHistory(data);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'site-policies.list-history-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Return state and events
    return { history, isLoading, onButtonPressed };
};
