import { useState, useEffect } from 'react';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
import { TablePaginationConfig } from 'antd';
import { SiteCardCustomField, SiteRfidCardDto } from 'services/rfidcards/rfidcards.service';
import { SiteCardsDashboardController, SiteCardsTableRow } from './interfaces';
import { useSiteCardsContext } from './context/site-cards.context';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { OrderTable } from 'global/interfaces';
import { arrayToString } from 'tools/string-handling';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { MainError } from 'services/dtos/errors..dto';
import { useAPISee } from 'services/sse/api-sse.service';

dayjs.extend(utc);
// dayjs.extend(timezone);

export const useSiteCardsDashboardController = (
    showRoleErrorModal: () => void,
    { translate } = useTranslator(),
    rfidCardService = useAPIRfidcard(),
    sseService = useAPISee(),
    messenger = useMessenger(),
): SiteCardsDashboardController => {
    /* State */
    const [isLoading] = useState<boolean>(false);
    const [isLoadingOpenModal, setIsLoadingOpenModal] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isLoadingRestore, setIsLoadingRestore] = useState<boolean>(false);
    const [cardRestoreId, setCardRestoreId] = useState<string>('');
    const [isCreateRfidCardModalVisible, setIsCreateRfidCardModalVisible] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<{ rowId: string; expanded: boolean }[]>([]);
    const [session, , , ,] = useLocalSession();
    const {
        sortedInfo,
        setSortedInfo,
        setIsFilterModalVisible,
        rfidCards,
        totalRfidCards,
        setCurrentPage,
        setPageSize,
        pageSize,
        currentPage,
        setOrder,
        setOrderBy,
        isLoadingTable,
        authorizer,
        setAuthorizer,
        label,
        setLabel,
        cardNumber,
        setCardNumber,
        selectedLockIds,
        setSelectedLockIds,
        selectedRfidCardStatusOptions,
        setSelectedRfidCardStatusOptions,
        dateRange,
        setDateRange,
        getRfidCards,
        setDeleteCard,
        deleteCard,
        setIsVisibleDeleteCardProgressModal,
        setDeletionModalRows,
        setDeletionEventSource,
        setIsEditModalVisible,
        getSiteCardDetail,
        selectedCustomFields,
    } = useSiteCardsContext();
    const { selectedSite } = session;

    /* Listeners */
    useEffect(() => {
        const arr = rfidCards.map((e: SiteRfidCardDto) => {
            return { rowId: e.id, expanded: false };
        });
        setExpandedRows(arr);
    }, [rfidCards]);

    useEffect(() => {
        if (session.token && selectedSite) {
            resetStates();
            getRfidCards({ pagination: { page: 0, pageSize }, orderTable: { order: 'ascend', orderBy: 'label' } });
        }
    }, [selectedSite]);

    /* View Events */
    const handleTableChange = (
        pagination: TablePaginationConfig,
        filter: Record<string, FilterValue | null>,
        sorter: SorterResult<SiteCardsTableRow> | SorterResult<SiteCardsTableRow>[],
    ) => {
        const sort = Array.isArray(sorter) ? sorter[0] : sorter;
        const sortAsOrder: OrderTable = {
            order: sort.order as 'ascend' | 'descend' | undefined,
            orderBy: sort.column?.key as string,
        };
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setSortedInfo({
            order: sort.order,
            columnKey: sort.column?.key,
        });
        getRfidCards({
            pagination: { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            orderTable: sortAsOrder,
            search: {
                since: dateRange.from,
                until: dateRange.to,
                card: cardNumber,
                devices: selectedLockIds,
                label,
                authorizer,
                status: selectedRfidCardStatusOptions,
                customFields: selectedCustomFields,
            },
        });
    };

    const reloadList = () => {
        getRfidCards({ pagination: { page: 0, pageSize }, orderTable: { order: 'ascend', orderBy: 'label' } });
    };
    const onCloseCreateRfidCard = () => {
        setIsCreateRfidCardModalVisible(false);
    };
    const onFilterButtonPressed = () => {
        setIsFilterModalVisible(true);
    };
    const onEditButtonPressed = (id: string) => {
        setIsEditModalVisible(true);
        getSiteCardDetail(id);
    };

    const onReopenModalButtonPressed = (id: string, cardNumber: string) => {
        setIsLoadingOpenModal(true);
        setDeleteCard({ id, cardNumber });
        rfidCardService
            .getDeletingSiteRfidCard(id)
            .then((data) => {
                setDeletionModalRows(data.sub_event_streams);
                setDeletionEventSource(sseService.subscribeEvent(data.event_id));
                setIsVisibleDeleteCardProgressModal(true);
            })
            .catch((err: MainError) => {
                if (err.code == 409) {
                    switch (err.msg) {
                        case 'role':
                            showRoleErrorModal();
                            break;
                        default:
                            messenger.showErrorMessage({ key: 'rfid-card.reopen-modal-error' });
                    }
                } else {
                    messenger.showErrorMessage({ key: 'rfid-card.reopen-modal-error' });
                }
                setDeleteCard(undefined);
            })
            .finally(() => {
                setIsLoadingOpenModal(false);
            });
    };

    const onRestoreStatusButtonPressed = (id: string) => {
        setIsLoadingRestore(true);
        setCardRestoreId(id);
        rfidCardService
            .restoreRfidCardStatusId(id)
            .then(() => {
                getRfidCards({});
            })
            .catch((err: MainError) => {
                if (err.code == 409) {
                    switch (err.msg) {
                        case 'role':
                            showRoleErrorModal();
                            break;
                        default:
                            messenger.showErrorMessage({ key: 'rfid-card.restore-error' });
                    }
                } else {
                    messenger.showErrorMessage({ key: 'rfid-card.restore-error' });
                }
            })
            .finally(() => {
                setIsLoadingRestore(false);
                setCardRestoreId('');
            });
    };

    const onOnDeleteSiteCardButtonPressed = (id: string, cardNumber: string) => {
        setIsLoadingDelete(true);
        setDeleteCard({ id, cardNumber });
        rfidCardService
            .deleteSiteRfidCard(id)
            .then((data) => {
                setDeletionModalRows(data.sub_event_streams);
                setDeletionEventSource(sseService.subscribeEvent(data.event_id));
                setIsVisibleDeleteCardProgressModal(true);
            })
            .catch((err: MainError) => {
                if (err.code == 409) {
                    switch (err.msg) {
                        case 'role':
                            showRoleErrorModal();
                            break;
                        case 'locked':
                            messenger.showErrorMessage({ key: 'rfid-card.locked-error' });
                            break;
                        default:
                            messenger.showErrorMessage({ key: 'rfid-card.delete-error' });
                    }
                } else {
                    messenger.showErrorMessage({ key: 'rfid-card.delete-error' });
                }
                setDeleteCard(undefined);
            })
            .finally(() => {
                setIsLoadingDelete(false);
            });
    };

    const onExpandChange = (id: string, value: boolean) => {
        setExpandedRows(expandedRows.map((e) => (e.rowId === id ? { ...e, expanded: value } : e)));
    };

    /* Private Methods */
    const resetStates = () => {
        setAuthorizer([]);
        setLabel([]);
        setCardNumber([]);
        setSelectedLockIds([]);
        setSelectedRfidCardStatusOptions([1, 4]);
        setDateRange({ from: '', to: '' });
        setCurrentPage(0);
        setPageSize(20);
        setSortedInfo({
            order: 'ascend',
            columnKey: 'label',
        });
        setOrder('ascend');
        setOrderBy('label');
        setCardRestoreId('');
        setIsLoadingDelete(false);
        setDeleteCard(undefined);
        setIsLoadingRestore(false);
    };

    const mapDtoToTableRow = (dto: SiteRfidCardDto): SiteCardsTableRow => {
        const devicesArr = (JSON.parse(dto.devices) || []) as string[];
        const viewModelObject: SiteCardsTableRow = {
            id: dto.id,
            label: dto.label,
            card_number: dto.card_number,
            devices: {
                multiple: devicesArr.length > 1,
                text: arrayToString(devicesArr, translate({ key: 'general.and' })),
            },
            device_count: dto.device_count,
            state: {
                id: dto.status,
                label: getLabelByState(dto.status),
            },
            updatedAt: `${dayjs(dto.updated_at).format('DD/MM/YYYY')}  ${dayjs(dto.updated_at).format('HH:mm')}  ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
        };
        if (dto.custom_fields) {
            viewModelObject.customFields = parseCustomFields(dto.custom_fields);
        }
        return viewModelObject;
    };

    const parseCustomFields = (fields: SiteCardCustomField[]): SiteCardCustomField[] => {
        return fields.map((field) => {
            switch (field.type_id) {
                case 2:
                    return {
                        ...field,
                        value: `${dayjs(field.value).format('DD/MM/YYYY')}  ${dayjs(field.value).format('HH:mm')}`,
                    };
                case 3:
                    return {
                        ...field,
                        value: JSON.parse(field.value).join(', '),
                    };
                default:
                    return field;
            }
        });
    };

    const getLabelByState = (state: number): string => {
        switch (state) {
            case 1:
                return translate({ key: 'rfid-card.label.valid' });
            case 2:
                return translate({ key: 'rfid-card.label.deleted' });
            case 3:
                return translate({ key: 'rfid-card.label.deleting' });
            case 4:
                return translate({ key: 'rfid-card.label.delete-failed' });
            default:
                return 'invalid';
        }
    };

    // Return state and events
    return {
        isLoading,
        isLoadingDelete,
        isLoadingTable,
        isLoadingOpenModal,
        rfidCards,
        totalRfidCards,
        pageSize,
        currentPage,
        sortedInfo,
        isCreateRfidCardModalVisible,
        expandedRows,
        deleteCard,
        isLoadingRestore,
        cardRestoreId,
        onReopenModalButtonPressed,
        onRestoreStatusButtonPressed,
        onExpandChange,
        onCloseCreateRfidCard,
        onFilterButtonPressed,
        handleTableChange,
        reloadList,
        onOnDeleteSiteCardButtonPressed,
        itemsViewModelTable: rfidCards.map(mapDtoToTableRow),
        onEditButtonPressed,
    };
};
