import React from 'react';
import 'fragments/operations/fragments/operation-import-modal/operation-import-modal.scss';
import { OperationImportModalFragmentProps } from 'fragments/operations/fragments/operation-import-modal/interfaces';
import { useOperationImportModalController } from 'fragments/operations/fragments/operation-import-modal/operation-import-modal.controller';
import { Button, Card, Col, Input, Menu, Modal, Row, Typography, Upload, Form, Tag } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { UploadOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { OperationType } from 'services/operations/operations.service';
const { Title, Text, Paragraph } = Typography;

export const OperationImportModalFragment: React.FC<OperationImportModalFragmentProps> = (props) => {
    const { useController = useOperationImportModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div>
            <Modal
                width={700}
                open={controller.isNewOperationModalVisible}
                destroyOnClose={true}
                title={
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'operations.modal.create-title' })}
                    </Title>
                }
                className={'operation-import-modal'}
                closable={false}
                centered
                footer={[
                    controller.currentStep == 0 && (
                        <Button key="cancel" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                            {translate({ key: 'general.cancel' })}
                        </Button>
                    ),
                    controller.currentStep == 1 && (
                        <Button
                            key="back"
                            className={'btn-cancel'}
                            onClick={controller.onBackButtonPressed}
                            disabled={controller.isLoadingCheck}
                        >
                            {translate({ key: 'general.back' })}
                        </Button>
                    ),
                    controller.currentStep == 1 && (
                        <Button
                            key="submit"
                            className={'button primary'}
                            onClick={controller.onSubmitButtonPressed}
                            loading={controller.isLoadingCheck}
                            disabled={controller.submitDisabled || controller.isLoadingCheck}
                        >
                            {translate({ key: 'general.preview' })}
                        </Button>
                    ),
                ]}
            >
                {controller.currentStep == 0 && (
                    <Row justify="space-between" gutter={0}>
                        <Col className="operation-import-modal-card-item" span={8}>
                            <Card
                                title={translate({ key: 'operations.modal.menu.rfid' })}
                                bordered={true}
                                style={{ width: '100%' }}
                            >
                                <Menu
                                    onClick={(info) => controller.onSelectOption(info, OperationType.RFID)}
                                    style={{ width: '100%' }}
                                    items={[
                                        { key: 1, label: translate({ key: 'operations.modal.menu.rfid-item-1' }) },
                                        {
                                            key: 2,
                                            label: translate({ key: 'operations.modal.menu.rfid-item-2' }),
                                            disabled: true,
                                        },
                                        {
                                            key: 0,
                                            label: translate({ key: 'operations.modal.menu.rfid-item-3' }),
                                            disabled: true,
                                        },
                                    ]}
                                />
                            </Card>
                        </Col>
                        <Col className="operation-import-modal-card-item" span={8}>
                            <Card
                                title={translate({ key: 'operations.modal.menu.keys' })}
                                bordered={false}
                                style={{ width: '100%' }}
                            >
                                <Menu
                                    onClick={(info) => controller.onSelectOption(info, OperationType.DEFAULT)}
                                    style={{ width: '100%' }}
                                    items={[
                                        {
                                            key: 1,
                                            label: translate({ key: 'operations.modal.menu.keys-item-1' }),
                                            disabled: true,
                                        },
                                        {
                                            key: 2,
                                            label: translate({ key: 'operations.modal.menu.keys-item-2' }),
                                            disabled: true,
                                        },
                                    ]}
                                />
                            </Card>
                        </Col>
                        <Col className="operation-import-modal-card-item" span={8}>
                            <Card
                                title={translate({ key: 'operations.modal.menu.admins' })}
                                bordered={false}
                                style={{ width: '100%' }}
                            >
                                <Menu
                                    onClick={(info) => controller.onSelectOption(info, OperationType.DEFAULT)}
                                    style={{ width: '100%' }}
                                    items={[
                                        {
                                            key: 1,
                                            label: translate({ key: 'operations.modal.menu.admins-item-1' }),
                                            disabled: true,
                                        },
                                        {
                                            key: 2,
                                            label: translate({ key: 'operations.modal.menu.admins-item-2' }),
                                            disabled: true,
                                        },
                                    ]}
                                />
                            </Card>
                        </Col>
                    </Row>
                )}
                {controller.currentStep == 1 && (
                    <>
                        <div style={{ background: '#FAAD1499', padding: '.5rem .8rem 0.1rem', marginBottom: '1rem' }}>
                            <Text strong>{translate({ key: 'general.notes' })}: </Text>
                            <Paragraph>{translate({ key: 'operations.modal.csv-format' })}</Paragraph>
                        </div>
                        <Form
                            id="create-operation-form"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            layout="horizontal"
                            size="middle"
                            className={'form'}
                            form={controller.form}
                            disabled={controller.isLoadingCheck}
                        >
                            <Form.Item
                                label={
                                    <Text type="secondary" strong>
                                        {translate({ key: 'label.name' })}
                                    </Text>
                                }
                                required
                                validateStatus={controller.checkError == 'name' ? 'error' : ''}
                                help={
                                    controller.checkError == 'name'
                                        ? translate({ key: 'operations.modal.form.error-name' })
                                        : ''
                                }
                                rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                name="name"
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder={translate({ key: 'form.placeholder.operation.name' })}
                                    maxLength={100}
                                    minLength={3}
                                    onChange={controller.onChangeOperationName}
                                    disabled={controller.isLoadingCheck}
                                    value={controller.createOperationName}
                                />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <Text type="secondary" strong>
                                        {translate({ key: 'label.csv-file' })}
                                    </Text>
                                }
                                name="csv-file"
                                required
                                rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                validateStatus={controller.checkError == 'csv' ? 'error' : ''}
                                help={
                                    controller.checkError == 'csv'
                                        ? translate({ key: 'operations.modal.form.csv-format-error' })
                                        : ''
                                }
                            >
                                <div className="file-select">
                                    {controller.file ? (
                                        <Tag
                                            color={
                                                controller.array.length == 0 || controller.checkError == 'csv'
                                                    ? 'error'
                                                    : 'success'
                                            }
                                            icon={
                                                controller.array.length == 0 || controller.checkError == 'csv' ? (
                                                    <CloseCircleOutlined />
                                                ) : (
                                                    <CheckCircleOutlined />
                                                )
                                            }
                                            closable={!controller.isLoadingCheck}
                                            closeIcon={<DeleteOutlined style={{ color: 'red' }} />}
                                            onClose={controller.onCloseTag}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {controller.file.name}
                                        </Tag>
                                    ) : (
                                        <>
                                            <input
                                                disabled={controller.isLoadingCheck}
                                                name="csv"
                                                type="file"
                                                accept="text/csv"
                                                onChange={controller.onUploadFile}
                                                style={{ background: 'red' }}
                                            />
                                            <div className="file-select-content">
                                                {<UploadOutlined />}
                                                <div style={{ marginTop: 8 }}>
                                                    {translate({ key: 'general.upload' })}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </Modal>
        </div>
    );
};
