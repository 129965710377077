import { useEffect, useState } from 'react';
import {
    FilterLocksModalController,
    LockStatusOption,
    LockTypeOption,
} from 'fragments/locks-dashboard/fragments/filter-locks-modal/interfaces';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPILocks } from 'services/locks/api-locks.service';
import { useForm } from 'antd/lib/form/Form';
import { useLocksContext } from 'fragments/locks-dashboard/context/locks.context';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { OrderTable, PaginationRequest } from 'global/interfaces';
import { SearchLocksInterface } from 'services/locks/locks.service';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useFilterLocksModalController = (
    messenger = useMessenger(),
    locksService = useAPILocks(),
): /* <--Dependency Injections  like services hooks */
FilterLocksModalController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [form] = useForm();
    const { translate } = useTranslator();
    const [session] = useLocalSession();
    const { selectedSite } = session;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {
        lockType,
        lockStatus,
        setLockType,
        setLockStatus,
        order,
        orderBy,
        pageSize,
        isFilterModalVisible,
        setIsFilterModalVisible,
        searchInput,
        setSearchInput,
        setIsLoadingTable,
        setLocks,
        setTotalLocks,
        setSortedInfo,
        setOrder,
        setOrderBy,
        setCurrentPage,
    } = useLocksContext();

    const [lockStatusOptions, setLockStatusOptions] = useState<LockStatusOption[]>([]);
    const [lockTypeOptions, setLockTypeOptions] = useState<LockTypeOption[]>([]);

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);
    // Ex.
    useEffect(() => {
        setSearchInput('');
        setDefaultLockTypeOptions();
        setDefaultLockStatusOptions();
        setSortedInfo({
            order: undefined,
            columnKey: undefined,
        });
        setOrder(undefined);
        setOrderBy(undefined);
    }, [selectedSite]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}

    const onSubmitButtonPressed = () => {
        const search = {
            searchInput,
            lockStatus,
            lockType,
        };
        getLocks(undefined, undefined, search);
        setIsFilterModalVisible(false);
    };

    const onResetButtonPressed = () => {
        setSortedInfo({
            order: undefined,
            columnKey: undefined,
        });
        setOrder(undefined);
        setOrderBy(undefined);
        setSearchInput('');
        form.resetFields();
        setDefaultLockTypeOptions();
        setDefaultLockStatusOptions();
        getLocks();
        setIsFilterModalVisible(false);
    };

    const onCancelButtonPressed = () => {
        setIsFilterModalVisible(false);
    };

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };
    const onLockTypeOptionPressed = (key: number) => {
        const lockTypeIds: number[] = [];
        const updatedLockTypeOptions: LockTypeOption[] = lockTypeOptions.map((option) => {
            if (option.key == key) {
                option.selected = !option.selected;
            }
            if (option.selected) {
                lockTypeIds.push(option.key);
            }
            return option;
        });
        setLockTypeOptions(updatedLockTypeOptions);
        setLockType(lockTypeIds);
    };
    const onLockStatusOptionPressed = (key: number) => {
        const lockStatusIds: number[] = [];
        const updatedLockStatusOptions: LockStatusOption[] = lockStatusOptions.map((option) => {
            if (option.key == key) {
                option.selected = !option.selected;
            }
            if (option.selected) {
                lockStatusIds.push(option.key);
            }
            return option;
        });
        setLockStatusOptions(updatedLockStatusOptions);
        setLockStatus(lockStatusIds);
    };

    /* Private Methods */
    //Ex. const increaseCount = () => {}
    const getLocks = (pagination?: PaginationRequest, orderTable?: OrderTable, search?: SearchLocksInterface) => {
        setIsLoadingTable(true);
        setIsLoading(true);
        if (!pagination) {
            pagination = { page: 0, pageSize };
        }
        if (!orderTable) {
            orderTable = { order, orderBy };
        }
        locksService
            .listLocks(pagination, orderTable, search)
            .then((data) => {
                setLocks(data.locks);
                setTotalLocks(data.total);
                setCurrentPage(pagination?.page || 0);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingTable(false);
                setIsLoading(false);
            });
    };

    const setDefaultLockStatusOptions = (): void => {
        const lockStatusIds: number[] = [];
        const defaultLockStatusOptions: LockStatusOption[] = [
            {
                key: 0,
                label: translate({ key: 'label.disabled' }),
                selected: true,
            },
            {
                key: 1,
                label: translate({ key: 'label.enabled' }),
                selected: true,
            },
            {
                key: 2,
                label: translate({ key: 'label.down' }),
                selected: true,
            },
        ];
        defaultLockStatusOptions.map((item) => {
            if (item.selected) {
                lockStatusIds.push(item.key);
            }
        });

        setLockStatusOptions(defaultLockStatusOptions);
        setLockStatus(lockStatusIds);
    };
    const setDefaultLockTypeOptions = (): void => {
        const lockTypeIds: number[] = [];
        const defaultLockTypeOptions: LockTypeOption[] = [
            {
                key: 1,
                label: translate({ key: 'lock.type-ga' }),
                selected: true,
            },
            {
                key: 5,
                label: translate({ key: 'lock.type-gt' }),
                selected: true,
            },
        ];
        defaultLockTypeOptions.map((item) => {
            if (item.selected) {
                lockTypeIds.push(item.key);
            }
        });

        setLockTypeOptions(defaultLockTypeOptions);
        setLockType(lockTypeIds);
    };

    // Return state and events
    return {
        form,
        isLoading,
        isFilterModalVisible,
        searchInput,
        lockType,
        lockStatus,
        lockStatusOptions,
        lockTypeOptions,
        onSubmitButtonPressed,
        onResetButtonPressed,
        onCancelButtonPressed,
        onSearchInputChange,
        onLockTypeOptionPressed,
        onLockStatusOptionPressed,
    };
};
