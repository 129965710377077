import { SorterResult } from 'antd/lib/table/interface';
import { ITag, OrderTable, PaginationRequest, SorterInfo } from 'global/interfaces';
import React, { useReducer, useState } from 'react';
import { AdminDto, PaginatedAdminsResponse, SearchAdminsInterface, UserZoneTypes } from 'services/admin/admin.service';
import { useAPIAdmin } from 'services/admin/api-admin.service';
import { useAPILocks } from 'services/locks/api-locks.service';
import { LockDto } from 'services/locks/locks.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { AdminContext } from './admins.context';

type Props = {
    children?: React.ReactNode;
};
export interface GetAdminsPayloadInterface {
    pagination?: PaginationRequest;
    orderTable?: OrderTable;
    search?: SearchAdminsInterface;
}
const AdminsProvider: React.FC<Props> = (props) => {
    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [isCreateAdminModalVisible, setIsCreateAdminModalVisible] = useState<boolean>(false);
    const [isEditAdminModalVisible, setIsEditAdminModalVisible] = useState<boolean>(false);
    const [selectedAdminId, setSelectedAdminId] = useState<string>();
    const [selectedAdmin, setSelectedAdmin] = useState<AdminDto>();
    const [admins, setAdmins] = useState<AdminDto[]>([]);
    const [userZones, setUserZones] = useState<AdminDto[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(15);
    const [totalAdmins, setTotalAdmins] = useState<number>();
    const [roles, setRoles] = useState<UserZoneTypes[]>([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>(undefined);
    const [orderBy, setOrderBy] = useState<string | undefined>(undefined);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });
    const [searchInput, setSearchInput] = useState<string[]>([]);
    const [selectedLockIds, setSelectedLockIds] = useState<string[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
    const [tags, setTags] = useState<ITag[]>([]);

    const [locks, setLocks] = useState<LockDto[]>([]);
    const [isLoadingLocks, setIsLoadingLocks] = useState(false);

    // Functions
    const adminService = useAPIAdmin();
    const messenger = useMessenger();
    const locksService = useAPILocks();

    const getAdmins = (payload: GetAdminsPayloadInterface) => {
        let { pagination, orderTable, search } = payload;
        if (pagination) {
            pagination.page != currentPage && setCurrentPage(pagination.page);
            pagination.pageSize != pageSize && setPageSize(pagination.pageSize);
        } else {
            pagination = { page: currentPage, pageSize };
        }
        if (orderTable) {
            orderTable.order !== order && setOrder(orderTable.order);
            orderTable.orderBy !== orderBy && setOrderBy(orderTable.orderBy);
            if (!orderTable.order && !orderTable.orderBy) {
                setSortedInfo({
                    order: undefined,
                    columnKey: undefined,
                });
            }
        } else {
            orderTable = { order, orderBy };
        }
        if (!search) {
            search = {
                searchInput,
                selectedLockIds,
                selectedRoles,
            };
        }
        setIsTableLoading(true);

        adminService
            .listAdmins(pagination, orderTable, search)
            .then((data) => {
                setAdmins(data.data);
                setTotalAdmins(data.total);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'admin.list-error' });
                console.log('admin-list-error', error);
            })
            .finally(() => {
                setIsTableLoading(false);
            });
    };
    const getLocks = () => {
        setIsLoadingLocks(true);
        locksService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingLocks(false);
            });
    };
    return (
        <AdminContext.Provider
            value={{
                isFilterModalVisible,
                setIsFilterModalVisible,
                isCreateAdminModalVisible,
                setIsCreateAdminModalVisible,
                isEditAdminModalVisible,
                setIsEditAdminModalVisible,
                selectedAdminId,
                setSelectedAdminId,
                selectedAdmin,
                setSelectedAdmin,
                searchInput,
                setSearchInput,
                selectedLockIds,
                setSelectedLockIds,
                selectedRoles,
                setSelectedRoles,
                admins,
                setAdmins,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize,
                totalAdmins,
                setTotalAdmins,
                roles,
                setRoles,
                isTableLoading,
                setIsTableLoading,
                userZones,
                setUserZones,
                sortedInfo,
                setSortedInfo,
                order,
                setOrder,
                orderBy,
                setOrderBy,
                tags,
                setTags,
                locks,
                isLoadingLocks,
                setLocks,
                setIsLoadingLocks,
                //________________Functions___________
                getAdmins,
                getLocks,
            }}
        >
            {props.children}
        </AdminContext.Provider>
    );
};

export default AdminsProvider;
