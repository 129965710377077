import { CreateRfidCardRelation } from 'services/rfidcards/rfidcards.service';
import { SseEvent } from 'services/sse/sse.service';

export interface LockStatusOption {
    key: number;
    label: string;
    selected: boolean;
}

export interface LockTypeOption {
    key: number;
    label: string;
    selected: boolean;
}

export interface MassiveLoadModalController {
    /* State */
    rows: CreationCardRow[];
    statusCount: StatusCount;
    isLoading: boolean;
    isRetryCreationLoading: boolean;
    retryRelations: CreateRfidCardRelation[];
    isMassiveLoadModalVisible: boolean;
    /* Events */
    onCloseModalPressed: () => void;
    onTryAgainPressed: () => void;
}

export interface MassiveLoadModalFragmentProps {
    useController?: () => MassiveLoadModalController;
    resetForm: () => void;
}

export interface CreationCardRow {
    sub_event_stream_id: string;
    label: string;
    card_number: string;
    device_name: string;
    card_id: string;
    device_id: string;
    status: number;
    message: string;
}
export interface StatusCount {
    success: number;
    failed: number;
    warning: number;
    unknown: number;
}

export enum MassiveLoadModalActionTypes {
    HANDLE_EVENT = 'HANDLE_EVENT',
    SET_INITIAL_STATE = 'SET_INITIAL_STATE',
    UPDATE_STATUS_COUNT = 'UPDATE_STATUS_COUNT',
}
export interface MassiveLoadModalAction {
    type: MassiveLoadModalActionTypes;
    payload: SseEvent<{ code?: number; message?: string }> | CreationCardRow[];
}

export type HandleEventAction = {
    type: MassiveLoadModalActionTypes.HANDLE_EVENT;
    payload: SseEvent<{ code?: number; message?: string }>;
};

export type SetInitialStateAction = {
    type: MassiveLoadModalActionTypes.SET_INITIAL_STATE;
    payload: CreationCardRow[];
};
export type UpdateStatusCountAction = {
    type: MassiveLoadModalActionTypes.UPDATE_STATUS_COUNT;
    payload: null;
};

export type ReducerAction = SetInitialStateAction | HandleEventAction | UpdateStatusCountAction;

export interface MassiveLoadModalState {
    rows: CreationCardRow[];
    events: SseEvent<{ code?: number; message?: string }>[];
    statusCount: StatusCount;
}
