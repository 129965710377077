import React, { useContext } from 'react';
import { CustomField, GetCustomFieldsResponse } from 'services/custom-fields/custom-fields.service';

export interface CustomFieldsContextInterface {
    isLoadingList: boolean;
    customFieldsList: CustomField[];
    isAddFieldModalVisible: boolean;
    isLoadingDetail: boolean;
    isEditFieldModalVisible: boolean;
    editFieldData?: GetCustomFieldsResponse;
    setEditFieldData: React.Dispatch<React.SetStateAction<GetCustomFieldsResponse | undefined>>;
    setIsLoadingDetail: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditFieldModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAddFieldModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoadingList: React.Dispatch<React.SetStateAction<boolean>>;
    setCustomFieldsList: React.Dispatch<React.SetStateAction<CustomField[]>>;
    //________________Functions___________
    getCustomFieldsList: () => void;
    getCustomField: (id: string) => void;
}

export const CustomFieldsContext = React.createContext<CustomFieldsContextInterface>({
    customFieldsList: [],
    isLoadingList: false,
    isAddFieldModalVisible: false,
    isLoadingDetail: false,
    isEditFieldModalVisible: false,
    editFieldData: undefined,
    setIsEditFieldModalVisible: () => {
        //
    },
    setIsLoadingDetail: () => {
        //
    },
    setEditFieldData: () => {
        //
    },
    setIsAddFieldModalVisible: () => {
        //
    },
    setIsLoadingList: () => {
        //
    },
    setCustomFieldsList: () => {
        //
    },
    getCustomFieldsList: () => {
        //
    },
    getCustomField: () => {
        //
    },
});

export const useCustomFieldsContext = (): CustomFieldsContextInterface => {
    const store = useContext(CustomFieldsContext);
    return store;
};
