import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const PasscodeSvg = () => (
    <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 768 768"
        stroke="currentColor"
        fill="currentColor"
    >
        <title>iconos_accefy_sga</title>
        <circle cx="151.89" cy="167.76" r="88.89" />
        <path d="M151.89,118.19a49.57,49.57,0,1,1-49.57,49.57,49.63,49.63,0,0,1,49.57-49.57m0-39.31a88.89,88.89,0,1,0,88.89,88.88,88.88,88.88,0,0,0-88.89-88.88Z" />
        <path d="M384,118.19a49.57,49.57,0,1,1-49.57,49.57A49.63,49.63,0,0,1,384,118.19m0-39.31a88.89,88.89,0,1,0,88.89,88.88A88.88,88.88,0,0,0,384,78.88Z" />
        <path d="M616.11,118.19a49.57,49.57,0,1,1-49.57,49.57,49.62,49.62,0,0,1,49.57-49.57m0-39.31A88.89,88.89,0,1,0,705,167.76a88.88,88.88,0,0,0-88.89-88.88Z" />
        <path d="M151.89,334.43A49.57,49.57,0,1,1,102.32,384a49.63,49.63,0,0,1,49.57-49.57m0-39.32A88.89,88.89,0,1,0,240.78,384a88.89,88.89,0,0,0-88.89-88.89Z" />
        <circle cx="384" cy="384" r="88.89" />
        <path d="M384,334.43A49.57,49.57,0,1,1,334.43,384,49.63,49.63,0,0,1,384,334.43m0-39.32A88.89,88.89,0,1,0,472.89,384,88.89,88.89,0,0,0,384,295.11Z" />
        <circle cx="616.11" cy="384" r="88.89" />
        <path d="M616.11,334.43A49.57,49.57,0,1,1,566.54,384a49.62,49.62,0,0,1,49.57-49.57m0-39.32A88.89,88.89,0,1,0,705,384a88.89,88.89,0,0,0-88.89-88.89Z" />
        <path d="M151.89,550.67a49.57,49.57,0,1,1-49.57,49.57,49.62,49.62,0,0,1,49.57-49.57m0-39.32a88.89,88.89,0,1,0,88.89,88.89,88.89,88.89,0,0,0-88.89-88.89Z" />
        <path d="M384,550.67a49.57,49.57,0,1,1-49.57,49.57A49.62,49.62,0,0,1,384,550.67m0-39.32a88.89,88.89,0,1,0,88.89,88.89A88.89,88.89,0,0,0,384,511.35Z" />
        <circle cx="616.11" cy="600.24" r="88.89" />
        <path d="M616.11,550.67a49.57,49.57,0,1,1-49.57,49.57,49.62,49.62,0,0,1,49.57-49.57m0-39.32A88.89,88.89,0,1,0,705,600.24a88.89,88.89,0,0,0-88.89-88.89Z" />
    </svg>
);

const PasscodeIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={PasscodeSvg} {...props} />;
export default PasscodeIcon;
