import {
    CreateKeyInput,
    Keys,
    PaginatedKeysResponse,
    OverwriteSmartTokenRecordRequest,
    SearchKeysInterface,
} from 'services/keys/keys.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';
import { CreateKeySummaryDto } from 'fragments/common/create-key/interfaces';
import { OrderTable, PaginationRequest } from 'global/interfaces';

export const useAPIKeys = (): Keys => {
    const [session] = useLocalSession();

    const listKeys = async (
        pagination?: PaginationRequest,
        order?: OrderTable,
        search?: SearchKeysInterface,
    ): Promise<PaginatedKeysResponse> => {
        pagination = pagination || { page: 0, pageSize: 10 };
        order = order || { order: undefined, orderBy: undefined };
        search = search || { assignedTo: [], authorizer: [], status: [], locks: [], since: '', until: '' };
        const assignedTo = JSON.stringify(search.assignedTo);
        const authorizer = JSON.stringify(search.authorizer);
        const status = JSON.stringify(search.status);
        const locks = JSON.stringify(search.locks);
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/smarttokenrecords?page=${pagination.page}&pageSize=${pagination.pageSize}&inputUser=${assignedTo}&locks=${locks}&inputAuthorizer=${authorizer}&status=${status}&order=${order.order}&orderBy=${order.orderBy}&since=${search.since}&until=${search.until}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as PaginatedKeysResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const createKey = async (input: CreateKeyInput) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/user/smarttokenrecords', input, {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as CreateKeySummaryDto[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    const deleteKey = async (keyId: string | number) => {
        try {
            const response = await axios.delete(
                process.env.REACT_APP_API_BASE_URL + `/user/smarttokenrecords/${keyId}/delete`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as 'Success';
        } catch (error) {
            throw error;
        }
    };

    const overwriteKeys = async (input: OverwriteSmartTokenRecordRequest[]) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + `/user/smarttokenrecords/overwrite`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as CreateKeySummaryDto[];
        } catch (error) {
            throw error;
        }
    };

    return {
        listKeys,
        overwriteKeys,
        createKey,
        deleteKey,
    };
};
