import { useEffect, useState } from 'react';
import {
    CustomFieldTag,
    EditSiteCardModalController,
} from 'fragments/site-cards-dashboard/fragments/edit-site-card-modal/interfaces';
import { useSiteCardsContext } from 'fragments/site-cards-dashboard/context/site-cards.context';
import { useForm } from 'antd/es/form/Form';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { EditSiteRfidCardRequest, SiteCardCustomField } from 'services/rfidcards/rfidcards.service';
import { useAPICustomFields } from 'services/custom-fields/api-custom-fields.service';
import dayjs from 'dayjs';
import { MainError } from 'services/dtos/errors..dto';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { isArray } from 'lodash';

export const useEditSiteCardModalController = (): /* <--Dependency Injections  like services hooks */
EditSiteCardModalController => {
    const [form] = useForm();
    const rfidCardService = useAPIRfidcard();
    const messenger = useMessenger();
    const customFieldsService = useAPICustomFields();
    const { translate } = useTranslator();

    /* State */
    const {
        isEditModalVisible,
        setIsEditModalVisible,
        editSiteCardDetail,
        setEditSiteCardDetail,
        isLoadingDetail,
        getRfidCards,
    } = useSiteCardsContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newLabel, setNewLabel] = useState<string>('');
    const [newCustomFields, setNewCustomFields] = useState<SiteCardCustomField[]>([]);
    const [fieldLabelError, setFieldLabelError] = useState<boolean>(false);

    /* Listeners */
    useEffect(() => {
        if (editSiteCardDetail) {
            setNewLabel(editSiteCardDetail.label);
            form.setFieldValue('label', editSiteCardDetail.label);
            if (editSiteCardDetail.custom_fields) {
                setNewCustomFields(
                    editSiteCardDetail.custom_fields.map((f) => {
                        switch (f.type_id) {
                            case 2:
                                form.setFieldValue(f.name, f.value ? dayjs(f.value as string) : undefined);
                                return { ...f, value: f.value ? dayjs(f.value as string) : undefined };
                            case 3:
                                form.setFieldValue(f.name, f.value ? JSON.parse(f.value) : []);
                                return { ...f, value: f.value ? JSON.parse(f.value) : [] };
                            default:
                                form.setFieldValue(f.name, f.value);
                                return { ...f, value: f.value };
                        }
                    }),
                );
            } else {
                setNewCustomFields([]);
            }
        }
    }, [editSiteCardDetail]);
    useEffect(() => {
        !newCustomFields.length && form.resetFields();
    }, [newCustomFields]);

    /* View Events */
    const resetStates = () => {
        setIsLoading(false);
        setNewLabel('');
        setNewCustomFields([]);
        setEditSiteCardDetail(undefined);
        setFieldLabelError(false);
        form.resetFields();
    };

    const onCancelButtonPressed = () => {
        resetStates();
        setIsEditModalVisible(false);
    };

    const onChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewLabel(e.target.value);
        setFieldLabelError(false);
    };

    const onChangeField = (id: string, type: number, value: any) => {
        let v: any;
        switch (type) {
            case 1:
                v = value.target.value;
                break;
            case 2:
                v = dayjs(value).format('YYYY-MM-DDTHH:mm:ssZ');
                break;
            case 3:
                if (isArray(value)) {
                    v = value.map((val: { value: string }) => {
                        val.value = val.value.trim();
                        return val;
                    });
                }

                break;
            case 4:
                v = value;
                break;
        }
        setNewCustomFields(
            newCustomFields.map((f) => {
                if (f.id == id) {
                    f.value = v;
                }
                return f;
            }),
        );
    };

    const onSubmitButtonPressed = () => {
        setIsLoading(true);
        form.validateFields()
            .then(() => {
                const input: EditSiteRfidCardRequest = {
                    id: editSiteCardDetail!.id,
                    label: newLabel,
                };
                if (newCustomFields.length > 0) {
                    input.custom_fields = newCustomFields.map((f): { id: string; value: any } => ({
                        id: f.id,
                        value:
                            f.type_id == 3
                                ? f.value
                                    ? JSON.stringify(
                                          f.value.map((v: any) => {
                                              return v.value ? v.value.trim() : v.trim();
                                          }),
                                      )
                                    : ''
                                : f.value,
                    }));
                }
                rfidCardService
                    .editSiteRfidCard(input)
                    .then(() => {
                        getRfidCards({});
                        setIsEditModalVisible(false);
                        resetStates();
                    })
                    .catch((err: MainError) => {
                        if (err.code == 409 && err.msg == 'label') {
                            messenger.showErrorMessage({ key: translate({ key: 'rfid-card.label-error' }) });
                            setFieldLabelError(true);
                        } else if (err.code == 409 && err.msg == 'locked') {
                            messenger.showErrorMessage({ key: translate({ key: 'rfid-card.locked-error' }) });
                        } else {
                            messenger.showErrorMessage({ key: translate({ key: 'rfid-card.edit-error' }) });
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    async function autocompleteTags(input: string, fieldId: string): Promise<CustomFieldTag[]> {
        return customFieldsService
            .autocompleteTags(input, fieldId)
            .then((res) => res.tags.map((t) => ({ label: t, value: t })));
    }

    /* Private Methods */

    // Return state and events
    return {
        form,
        isLoading,
        isLoadingDetail,
        isEditModalVisible,
        editSiteCardDetail,
        newLabel,
        newCustomFields,
        fieldLabelError,
        /* Events */
        autocompleteTags,
        onCancelButtonPressed,
        onSubmitButtonPressed,
        onChangeLabel,
        onChangeField,
    };
};
