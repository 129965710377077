import { Main, CheckRoleResponse, ROLES } from 'services/main/main.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';

export const useAPIMain = (): Main => {
    const [session] = useLocalSession();
    const checkRole = async (): Promise<CheckRoleResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL + `/user/sites/${session.selectedSite?.id}/check-role`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            if (response.status == 202) {
                return { role: ROLES.OTHER } as CheckRoleResponse;
            }
            return response.data as CheckRoleResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    return {
        checkRole,
    };
};
