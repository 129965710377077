import React from 'react';
import 'fragments/admins-dashboard/fragments/edit-admin-modal/edit-admin-modal.scss';
import { EditAdminModalFragmentProps } from 'fragments/admins-dashboard/fragments/edit-admin-modal/interfaces';
import { useEditAdminModalController } from 'fragments/admins-dashboard/fragments/edit-admin-modal/edit-admin-modal.controller';
import { Modal, Typography, Button, Form, Select } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useAdminContext } from 'fragments/admins-dashboard/context/admins.context';
const { Option } = Select;
const { Text, Title } = Typography;

export const EditAdminModalFragment: React.FC<EditAdminModalFragmentProps> = (props) => {
    const { useController = useEditAdminModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { isEditAdminModalVisible } = useAdminContext();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));

    // Render
    return (
        <Modal
            open={isEditAdminModalVisible}
            destroyOnClose
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'admin.edit-title' })}
                </Title>
            }
            className={'edit-admin-modal'}
            closable={false}
            centered
            footer={[
                <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form="edit-admin-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isLoading}
                    htmlType="submit"
                >
                    {translate({ key: 'general.edit' })}
                </Button>,
            ]}
        >
            <Form
                id="edit-admin-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                form={controller.form}
                size="middle"
                className={'form'}
                onFinish={controller.onEditAdminFormSubmit}
                initialValues={controller.initialValues}
            >
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.lock' })}
                        </Text>
                    }
                    name="lock_ids"
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                >
                    <Select
                        placeholder={translate({ key: 'form.placeholder.lock.name' })}
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        value={controller.selectedLockIds}
                        onChange={controller.onLockSelect}
                        filterOption={(inputValue, option) => {
                            const opt = controller.locks.find((item) => item.id == option?.value);
                            return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    >
                        {optionLocks}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.admin-email' })}
                        </Text>
                    }
                    name="admin_emails"
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        allowClear
                        tokenSeparators={[' ']}
                        disabled
                        placeholder={translate({ key: 'form.placeholder.admin.admin-email' })}
                        dropdownStyle={{ display: 'none' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
