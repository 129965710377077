import React from 'react';
import 'fragments/metrics-dashboard/fragments/user-by-state/user-by-state.scss';
import { UserByStateFragmentProps } from 'fragments/metrics-dashboard/fragments/user-by-state/interfaces';
import { useUserByStateController } from 'fragments/metrics-dashboard/fragments/user-by-state/user-by-state.controller';
import { Pie } from '@ant-design/plots';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Button, Empty, Modal, Typography } from 'antd';
import { SyncOutlined, InfoCircleOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;

export const UserByStateFragment: React.FC<UserByStateFragmentProps> = (props) => {
    const { useController = useUserByStateController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const info = () => {
        Modal.info({
            title: translate({ key: 'metrics.user-by-state' }),
            content: (
                <div>
                    <p>{/* TODO: Inserte aqui la descripcion de esta metrica */}</p>
                </div>
            ),
            onOk() {
                console.log();
            },
        });
    };

    // Render
    const config = {
        appendPadding: 10,
        data: controller.data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.5,
        meta: {
            value: {
                formatter: (v: string | number) => `${v}`,
            },
        },
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 20,
            },
            autoRotate: false,
            content: '{value}',
        },

        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
    };
    return (
        <div className={'user-by-state'}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                <Title style={{ padding: '10px', textAlign: 'left' }} level={3}>
                    <InfoCircleOutlined style={{ marginRight: '8px', color: '#F7B28B' }} onClick={info} />
                    {translate({ key: 'metrics.user-by-state' })}
                </Title>
                <div style={{ padding: '10px' }}>
                    <Text strong style={{ paddingRight: '10px' }}>
                        {controller.updatedAt}
                    </Text>
                    <Button
                        type="primary"
                        onClick={controller.onRefreshButtonPressed}
                        icon={<SyncOutlined spin={controller.isLoading} />}
                    />
                </div>
            </div>
            {controller.data.length ? <Pie {...config} /> : <Empty style={{ padding: '1.5rem' }} />}
        </div>
    );
};
