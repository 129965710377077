import { useState, useEffect } from 'react';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ViewAdminsModalController } from './interfaces';
import { useAPIAdmin } from 'services/admin/api-admin.service';
import { AdminDto } from 'services/admin/admin.service';
import { useNavigate } from 'react-router-dom';

export const useViewAdminsModalController = (
    onClose: () => void,
    lockId?: string,
    lockName?: string,
    onFinish?: () => void,
    adminsService = useAPIAdmin(),
    messenger = useMessenger(),
): ViewAdminsModalController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalAdmins, setTotalAdmins] = useState<number>(0);
    const [admins, setAdmins] = useState<AdminDto[]>([]);
    const [adminList, setAdminList] = useState<AdminDto[]>([]);
    const navigate = useNavigate();

    const [session] = useLocalSession();
    // const [form] = useForm();

    /* Listeners */
    useEffect(() => {
        lockId && getAdmins(lockId);
    }, [lockId]);

    const onAdminButtonPressed = () => {
        setAdmins([]);
        setAdminList([]);
        setTotalAdmins(0);
        setCurrentPage(0);
        navigate(`/admins`, { state: lockId });
        onClose();
    };

    const onCloseModal = () => {
        setAdmins([]);
        setAdminList([]);
        setTotalAdmins(0);
        setCurrentPage(0);
        onFinish && onFinish();
        onClose();
    };

    const onLoadMore = () => {
        if (isLoading) {
            return;
        }
        lockId && getAdmins(lockId);
    };
    // private methods
    const getAdmins = (lockId: string) => {
        setIsLoading(true);
        adminsService
            .listAdmins({ page: currentPage, pageSize }, undefined, {
                selectedRoles: [],
                searchInput: [],
                selectedLockIds: [lockId],
            })
            .then((data) => {
                const newData = admins.concat(data.data);
                setAdmins(newData);
                setAdminList(newData);
                setTotalAdmins(data.total);
                setCurrentPage(currentPage + 1);
            })
            .catch((error) => {
                console.log('get-admins-error', error);
                messenger.showErrorMessage({ key: 'admins.list-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Return state and events
    return {
        isLoading,
        admins,
        adminList,
        totalAdmins,
        onCloseModal,
        onAdminButtonPressed,
        onLoadMore,
    };
};
