import { useEffect, useState } from 'react';
import { KeysFilterModalController } from 'fragments/keys-dashboard/fragments/keys-filter-modal/interfaces';
import { useKeysContext } from 'fragments/keys-dashboard/context/keys.context';
import dayjs from 'dayjs';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { SearchKeysInterface } from 'services/keys/keys.service';
import { CheckboxOption, ITag } from 'global/interfaces';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export const useKeysFilterModalController = (): /* <--Dependency Injections  like services hooks */
KeysFilterModalController => {
    /* State */

    const { translate } = useTranslator();
    const [isLoading, setIsLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [keyStatusOptions, setKeyStatusOptions] = useState<CheckboxOption[]>([]);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(true);
    //________________TAGS________________
    const [tempLockIds, setTempLockIds] = useState<string[]>([]);
    const [tempAssignedTo, setTempAssignedTo] = useState<string[]>([]);
    const [tempAuthorizer, setTempAuthorizer] = useState<string[]>([]);
    const [tempDateRange, setTempDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [tempStatusIds, setTempStatusIds] = useState<number[]>([]);
    const {
        isFilterModalVisible,
        selectedLockIds,
        dateRange,
        assignedTo,
        authorizer,
        pageSize,
        selectedKeyStatusOptions,
        setSelectedKeyStatusOptions,
        setAuthorizer,
        setAssignedTo,
        setIsFilterModalVisible,
        setDateRange,
        setSelectedLockIds,
        setCurrentPage,
        setPageSize,
        setSortedInfo,
        setOrder,
        setOrderBy,
        locks,
        isLoadingLocks,
        getLocks,
        getKeys,
        tags,
        setTags,
        keys,
        isLoadingTable,
    } = useKeysContext();

    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);

    useEffect(() => {
        if (session.token && selectedSite) {
            getLocks();
            setDefaultKeyStatusOptions();
        }
    }, [selectedSite]);

    useEffect(() => {
        setSubmitDisabled(disableSubmitButton());
    }, [tempLockIds, tempAssignedTo, tempAuthorizer, tempDateRange, tempStatusIds]);

    //________________TAGS________________
    useEffect(() => {
        mapTags({
            locks: selectedLockIds,
            since: dateRange.from,
            until: dateRange.to,
            assignedTo,
            authorizer,
            status: selectedKeyStatusOptions,
        });
        setTempLockIds(selectedLockIds);
        setTempAssignedTo(assignedTo);
        setTempAuthorizer(authorizer);
        setTempDateRange(dateRange);
        setTempStatusIds(selectedKeyStatusOptions);
    }, [selectedLockIds, dateRange, assignedTo, authorizer, selectedKeyStatusOptions, locks]);

    useEffect(() => {
        form.resetFields();
    }, [keys]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}

    // ________________Button Events___________________

    const onCancelButtonPressed = () => {
        setTempLockIds(selectedLockIds);
        setTempAssignedTo(assignedTo);
        setTempAuthorizer(authorizer);
        setTempDateRange(dateRange);
        setTempStatusIds(selectedKeyStatusOptions);
        setIsFilterModalVisible(false);
        setIndeterminate(
            !!selectedKeyStatusOptions.length && selectedKeyStatusOptions.length < defaultKeyStatusOptions.length,
        );
        form.resetFields();
    };

    const onResetButtonPressed = () => {
        resetStates();
        getKeys({ pagination: { page: 0, pageSize }, orderTable: { order: undefined, orderBy: undefined } });
        setIsFilterModalVisible(false);
        setDefaultKeyStatusOptions();
        form.resetFields();
    };

    const disableSubmitButton = (): boolean => {
        // if (selectedLockIds.length || selectedKeyStatusOptions.length) return false;
        // if (dateRange.from || dateRange.to) return false;
        // if (assignedTo.length || authorizer.length) return false;
        if (tempLockIds.length || tempStatusIds.length) return false;
        if (tempDateRange.from || tempDateRange.to) return false;
        if (tempAssignedTo.length || tempAuthorizer.length) return false;
        return true;
    };

    const onSubmitButtonPressed = () => {
        setSelectedLockIds(tempLockIds);
        setAssignedTo(tempAssignedTo);
        setAuthorizer(tempAuthorizer);
        setDateRange(tempDateRange);
        setSelectedKeyStatusOptions(tempStatusIds);
        getKeys({
            pagination: { page: 0, pageSize },
            search: {
                locks: tempLockIds,
                since: tempDateRange.from,
                until: tempDateRange.to,
                assignedTo: tempAssignedTo,
                authorizer: tempAuthorizer,
                status: tempStatusIds,
            },
        });
        setIsFilterModalVisible(false);
    };
    // ________________Input Events___________________

    const onLockSelect = (value: string[]) => {
        setTempLockIds(value);
        // setSelectedLockIds(value);
    };

    const onAssignedToInputChange = (value: string[]) => {
        setTempAssignedTo(value);
        // setAssignedTo(value);
    };

    const onAuthorizerInputChange = (value: string[]) => {
        setTempAuthorizer(value);
        // setAuthorizer(value);
    };

    const onPickedDate = (date: any) => {
        date &&
            setTempDateRange({ from: dayjs(date[0]).format('YYYY-MM-DD'), to: dayjs(date[1]).format('YYYY-MM-DD') });
        // date && setDateRange({ from: dayjs(date[0]).format('YYYY-MM-DD'), to: dayjs(date[1]).format('YYYY-MM-DD') });
    };

    const onCheckKeyStatusOptions = (list: CheckboxValueType[]) => {
        setTempStatusIds(list as number[]);
        setIndeterminate(!!list.length && list.length < keyStatusOptions.length);
        setCheckAll(list.length === keyStatusOptions.length);
    };

    const onCheckAllKeyStatusOptions = (e: CheckboxChangeEvent) => {
        setTempStatusIds(e.target.checked ? keyStatusOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    /* Private Methods */
    //Ex. const increaseCount = () => {}
    // ________________TAGS___________________

    const onCloseTag = (tag: ITag) => {
        switch (tag.name) {
            case translate({ key: 'label.lock' }): {
                const newSelectedLockIds = selectedLockIds.filter((lockId) => lockId !== tag.id);
                setSelectedLockIds(newSelectedLockIds);
                getKeys({
                    pagination: { page: 0, pageSize },
                    search: {
                        locks: newSelectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        assignedTo,
                        authorizer,
                        status: selectedKeyStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.user' }): {
                const newAssignedTo = assignedTo.filter((searchTerm) => searchTerm !== tag.id);
                console.log('assignedTo', newAssignedTo);
                setAssignedTo(newAssignedTo);
                getKeys({
                    pagination: { page: 0, pageSize },
                    search: {
                        locks: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        assignedTo: newAssignedTo,
                        authorizer,
                        status: selectedKeyStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.authorizer' }): {
                const newAuthorizer = authorizer.filter((searchTerm) => searchTerm !== tag.id);
                console.log('authorizer', newAuthorizer, authorizer);
                setAuthorizer(newAuthorizer);
                getKeys({
                    pagination: { page: 0, pageSize },
                    search: {
                        locks: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        assignedTo,
                        authorizer: newAuthorizer,
                        status: selectedKeyStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.duration' }): {
                setDateRange({ from: '', to: '' });
                getKeys({
                    pagination: { page: 0, pageSize },
                    search: {
                        since: '',
                        until: '',
                        assignedTo,
                        authorizer,
                        locks: selectedLockIds,
                        status: selectedKeyStatusOptions,
                    },
                });
                break;
            }

            case translate({ key: 'label.state' }): {
                const newSelectedKeyStatus = selectedKeyStatusOptions.filter((id) => id !== tag.id);
                setSelectedKeyStatusOptions(newSelectedKeyStatus);
                getKeys({
                    pagination: { page: 0, pageSize },
                    search: {
                        locks: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        assignedTo,
                        authorizer,
                        status: newSelectedKeyStatus,
                    },
                });
                break;
            }
            default:
                break;
        }
    };

    const mapTags = (search: SearchKeysInterface) => {
        const { locks: selectedLockIds, since, until, assignedTo, authorizer, status } = search;
        const tempLockTags: ITag[] = [];
        const tempAssignedToTags: ITag[] = [];
        const tempAuthorizerTags: ITag[] = [];
        const tempDateTags: ITag[] = [];
        const tempSelectedKeyStatus: ITag[] = [];

        selectedLockIds.map((id) => {
            const selectedLock = locks.find((lock) => lock.id === id);
            tempLockTags.push({
                id,
                name: translate({ key: 'label.lock' }),
                value: `${selectedLock?.name || '...'}`,
                color: 'geekblue',
                closable: true,
            });
        });

        assignedTo.map((searchTerm) => {
            tempAssignedToTags.push({
                id: searchTerm,
                name: translate({ key: 'label.user' }),
                value: searchTerm,
                color: 'purple',
                closable: true,
            });
        });

        authorizer.map((searchTerm) => {
            tempAuthorizerTags.push({
                id: searchTerm,
                name: translate({ key: 'label.authorizer' }),
                value: searchTerm,
                color: 'purple',
                closable: true,
            });
        });

        if (since !== '' || until !== '') {
            tempDateTags.push({
                id: since + until,
                name: translate({ key: 'label.duration' }),
                value: `Desde: ${since} Hasta: ${until}`,
                color: 'cyan',
                closable: true,
            });
        }
        if (status.length !== keyStatusOptions.length) {
            status.map((value) => {
                const selectedKeyStatus = keyStatusOptions.find((option) => option.value === value);
                tempSelectedKeyStatus.push({
                    id: value,
                    name: translate({ key: 'label.state' }),
                    value: `${selectedKeyStatus?.label}`,
                    color: 'green',
                    closable: true,
                });
            });
            if (tempSelectedKeyStatus.length === 1) {
                tempSelectedKeyStatus[0].closable = false;
            }
        }

        setTags([
            ...tempLockTags,
            ...tempAssignedToTags,
            ...tempAuthorizerTags,
            ...tempDateTags,
            ...tempSelectedKeyStatus,
        ]);
    };

    // ________________Form___________________
    const defaultKeyStatusOptions: CheckboxOption[] = [
        { value: 0, label: translate({ key: 'key.key-status-option.expired' }) },
        { value: 1, label: translate({ key: 'key.key-status-option.valid' }) },
        { value: 2, label: translate({ key: 'key.key-status-option.stand-by' }) },
        { value: 3, label: translate({ key: 'key.key-status-option.rejected' }) },
        { value: 4, label: translate({ key: 'key.key-status-option.deleted' }) },
    ];
    const setDefaultKeyStatusOptions = (): void => {
        const keyStatusIds: number[] = defaultKeyStatusOptions.map((item) => item.value);
        setKeyStatusOptions(defaultKeyStatusOptions);
        setTempStatusIds(keyStatusIds);
        setSelectedKeyStatusOptions(keyStatusIds);
        setCheckAll(true);
    };

    const resetStates = () => {
        setAuthorizer([]);
        setAssignedTo([]);
        setSelectedLockIds([]);
        setSelectedKeyStatusOptions([]);
        setDateRange({ from: '', to: '' });
        setCurrentPage(0);
        setPageSize(10);
        setSortedInfo({
            order: undefined,
            columnKey: undefined,
        });
        setOrder(undefined);
        setOrderBy(undefined);
        setDefaultKeyStatusOptions();
    };
    // Return state and events
    return {
        tags,
        form,
        isLoading,
        locks,
        isLoadingLocks,
        isFilterModalVisible,
        selectedLockIds,
        assignedTo,
        submitDisabled,
        authorizer,
        keyStatusOptions,
        tempStatusIds,
        isLoadingTable,
        dateRange,
        onAssignedToInputChange,
        onCancelButtonPressed,
        onSubmitButtonPressed,
        onResetButtonPressed,
        onAuthorizerInputChange,
        onLockSelect,
        onPickedDate,
        onCloseTag,
        checkAll,
        indeterminate,
        onCheckKeyStatusOptions,
        onCheckAllKeyStatusOptions,
    };
};
