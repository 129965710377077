import React from 'react';
import 'fragments/site-cards-dashboard/fragments/site-cards-filter-modal/site-cards-filter-modal.scss';
import { Button, Checkbox, Col, DatePicker, Divider, Drawer, Form, Select, Tag, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ITag } from 'global/interfaces';
import { SiteCardsFilterModalFragmentProps } from './interfaces';
import { useSiteCardsFilterModalController } from './site-cards-filter-modal.controller';
import { LockDto } from 'services/locks/locks.service';
import { CustomField } from 'services/custom-fields/custom-fields.service';
import DebounceSelect from 'components/debounce-select/debounce-select.component';
const { Option } = Select;
const { Text, Title } = Typography;

export const SiteCardsFilterModalFragment: React.FC<SiteCardsFilterModalFragmentProps> = (props) => {
    const { useController = useSiteCardsFilterModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const optionLocks = controller.locks.map((lock: LockDto) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));

    const forMap = (tag: ITag) => {
        const tagElem = (
            <Tag
                closable={controller.isLoadingTable ? false : tag.closable}
                onClose={(e) => {
                    e.preventDefault();
                    controller.onCloseTag(tag);
                }}
                color={tag.color}
                style={{ fontWeight: 'bold' }}
            >
                <Text strong>{tag.name}: </Text> {tag.value}
            </Tag>
        );
        return (
            <span key={tag.id} style={{ display: 'inline-block', marginBottom: '2px' }}>
                {tagElem}
            </span>
        );
    };
    const tagChild = controller.tags.map(forMap);

    const mapFields = (fields: CustomField[]) => {
        const fieldInputs = fields.map((f) => {
            switch (f.type_id) {
                case 1:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            initialValue={controller.selectedCustomFields.find((field) => field.id == f.id)?.value}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                allowClear
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                tokenSeparators={[',']}
                                placeholder={translate({ key: 'form.placeholder.rfid-card.custom-field-tags' })}
                                dropdownStyle={{ display: 'none' }}
                            />
                        </Form.Item>
                    );
                case 3:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            initialValue={controller.selectedCustomFields.find((field) => field.id == f.id)?.value}
                        >
                            <DebounceSelect
                                mode="multiple"
                                placeholder={f.name}
                                fetchOptions={(e) => controller.autocompleteTags(e, f.id)}
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{ width: '100%' }}
                                tokenSeparators={[',']}
                            />
                        </Form.Item>
                    );
                case 2:
                    return (
                        <Col key={f.id} xs={24} id={`filter-site-cards-date-picker-${f.name}`}>
                            <Form.Item
                                label={
                                    <Text type="secondary" strong>
                                        {f.name}
                                    </Text>
                                }
                                name={f.name}
                                initialValue={controller.selectedCustomFields.find((field) => field.id == f.id)?.value}
                            >
                                <DatePicker.RangePicker
                                    style={{ width: '100%' }}
                                    getPopupContainer={() =>
                                        document.getElementById(
                                            `filter-site-cards-date-picker-${f.name}`,
                                        ) as HTMLElement
                                    }
                                    format="DD-MM-YYYY"
                                    onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                />
                            </Form.Item>
                        </Col>
                    );

                case 4:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            initialValue={controller.selectedCustomFields.find((field) => field.id == f.id)?.value}
                        >
                            <Select
                                showSearch
                                placeholder={f.name}
                                allowClear
                                mode="multiple"
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{ width: '100%' }}
                                disabled={controller.isLoading}
                                filterOption={(inputValue, option) => {
                                    const opt = f.values?.find((item: any) => item.value == option?.name);
                                    return !!opt?.value.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                            >
                                {f.values?.map((v) => (
                                    <Option key={v.id} value={v.id} name={v.value}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {v.value}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    );
                default:
                    return <></>;
            }
        });
        return fieldInputs;
    };

    // Render
    return (
        <>
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#f2f2f2',
                    padding: '10px',
                }}
            >
                <Text strong>{translate({ key: 'general.filters' })}:</Text>

                <div className="filter-tags"> {tagChild} </div>
            </div>
            <Drawer
                open={controller.isFilterModalVisible}
                destroyOnClose={true}
                placement="right"
                title={
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'rfid-card.filter-modal-title' })}
                    </Title>
                }
                width={650}
                closable={false}
                footer={
                    <div style={{ width: '100%', display: 'flex' }}>
                        {[
                            <Button
                                form="filter-rfid-cards-form"
                                key="reset"
                                className={'button primary'}
                                style={{ marginRight: 'auto' }}
                                loading={controller.isLoading}
                                disabled={controller.submitDisabled}
                                onClick={controller.onResetButtonPressed}
                            >
                                {translate({ key: 'general.reset' })}
                            </Button>,
                            <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                                {translate({ key: 'general.cancel' })}
                            </Button>,
                            <Button
                                form="filter-rfid-cards-form"
                                key="submit"
                                type="primary"
                                className={'button primary'}
                                loading={controller.isLoading}
                                htmlType="submit"
                                disabled={controller.submitDisabled}
                            >
                                {translate({ key: 'general.search' })}
                            </Button>,
                        ]}
                    </div>
                }
            >
                <Form
                    id="filter-rfid-cards-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    size="middle"
                    className={'form'}
                    form={controller.form}
                    onFinish={controller.onSubmitButtonPressed}
                >
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.lock' })}
                            </Text>
                        }
                        name="lock_ids"
                        initialValue={controller.selectedLockIds}
                    >
                        <Select
                            placeholder={translate({ key: 'form.placeholder.lock.name' })}
                            mode="multiple"
                            allowClear
                            loading={controller.isLoadingLocks}
                            // value={controller.selectedLockIds}
                            onChange={controller.onLockSelect}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                const opt = controller.locks.find((item: LockDto) => item.id == option?.value);
                                return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                            }}
                        >
                            {optionLocks}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.card' })}
                            </Text>
                        }
                        rules={[
                            {
                                required: false,
                                message: translate({ key: 'form.rules-required' }),
                            },
                            () => ({
                                validator(_, value) {
                                    const invalidCardNumbers = value.filter(
                                        (cardNumber: number) =>
                                            !controller.validateCardNumberRegExp.test(cardNumber.toString()),
                                    );
                                    const invalidCardNumbersString = invalidCardNumbers.join(', ');
                                    if (!invalidCardNumbers.length) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            translate({ key: 'rfid-card.rules.card-number-error' }) +
                                                invalidCardNumbersString,
                                        ),
                                    );
                                },
                            }),
                        ]}
                        name="card-number"
                        initialValue={controller.cardNumber}
                    >
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            allowClear
                            onChange={controller.onCardNumberChange}
                            tokenSeparators={[',', ' ']}
                            placeholder={translate({ key: 'form.placeholder.rfid-card.search-card-number-tags' })}
                            dropdownStyle={{ display: 'none' }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.label' })}
                            </Text>
                        }
                        name="label"
                        initialValue={controller.label}
                    >
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            allowClear
                            onChange={controller.onLabelInputChange}
                            tokenSeparators={[',']}
                            placeholder={translate({ key: 'form.placeholder.rfid-card.search-label-tags' })}
                            dropdownStyle={{ display: 'none' }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.state' })}
                            </Text>
                        }
                        name="state"
                        required
                        rules={[
                            {
                                required: !controller.tempStatusIds.length ? true : false,
                                message: translate({ key: 'form.select-an-option-error' }),
                            },
                        ]}
                    >
                        <>
                            <Checkbox
                                indeterminate={controller.indeterminate}
                                onChange={controller.onCheckAllRfidCardStatusOptions}
                                checked={controller.checkAll}
                            >
                                {translate({ key: 'form.select-all' })}
                            </Checkbox>
                            <Divider style={{ margin: '5px' }} />
                            <Checkbox.Group
                                options={controller.rfidCardStatusOptions}
                                value={controller.tempStatusIds}
                                onChange={controller.onCheckRfidCardStatusOptions}
                            />
                        </>
                    </Form.Item>
                    {!!controller.customFields.length && <>{mapFields(controller.customFields)}</>}
                </Form>
            </Drawer>
            {/* </Modal> */}
        </>
    );
};
