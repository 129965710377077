import React, { ReactNode, useEffect, useState } from 'react';
import 'fragments/common/view-admins-modal/view-admins-modal.scss';
import { Button, Modal, Typography, List, Tag, Avatar, Skeleton, Divider } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ViewAdminsModalFragmentProps } from './interfaces';
import { useViewAdminsModalController } from './view-admins-modal.controller';
import { capitalize } from 'tools/string-handling';
import InfiniteScroll from 'react-infinite-scroll-component';
const { Title, Text } = Typography;

export const ViewAdminsModalFragment: React.FC<ViewAdminsModalFragmentProps> = (props) => {
    const { useController = useViewAdminsModalController, isModalVisible, onClose, lockId, lockName, onFinish } = props;
    const controller = useController(onClose, lockId, lockName, onFinish);
    const { translate } = useTranslator();

    return (
        <Modal
            open={isModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'admin.view-admin-title' })}
                    {lockName}
                </Title>
            }
            className={'view-admins-modal'}
            closable={false}
            centered
            footer={[
                <Button key="close" type="default" onClick={controller.onCloseModal}>
                    {translate({ key: 'general.close' })}
                </Button>,
                <Button key="redirect" type="primary" onClick={controller.onAdminButtonPressed}>
                    {translate({ key: 'admin.manage' })}
                </Button>,
            ]}
        >
            <div
                id="scrollableDiv"
                style={{
                    maxHeight: 400,
                    padding: '0 16px',
                }}
            >
                <InfiniteScroll
                    dataLength={controller.adminList.length}
                    hasMore={controller.adminList.length < controller.totalAdmins}
                    next={controller.onLoadMore}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    // endMessage={<Divider plain>Fin</Divider>}
                    scrollableTarget="scrollableDiv"
                    height={300}
                >
                    <List
                        itemLayout="horizontal"
                        dataSource={controller.admins}
                        loading={controller.isLoading}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                    title={<Text strong>{capitalize(item.user_name)}</Text>}
                                    description={item.user_email}
                                />
                                <Tag
                                    color="blue"
                                    style={{ fontWeight: 'bold', fontSize: '13px', letterSpacing: '.5px' }}
                                >
                                    {capitalize(item.role)}
                                </Tag>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </Modal>
    );
};
