import React, { useState } from 'react';
import { ReportFilesContext } from './report-files.context';
import { ReportFileDto } from 'services/access-reports/access-reports.service';
import { PaginationRequest } from 'global/interfaces';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPIAccessReports } from 'services/access-reports/api-access-reports.service';
import { useAPISseReportFiles } from 'services/sse/report-files/api-sse-report-files.service';
import dayjs from 'dayjs';

type Props = {
    children?: React.ReactNode;
};

const ReportFilesProvider: React.FC<Props> = (props) => {
    const [reportFiles, setReportFiles] = useState<ReportFileDto[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [totalReportFiles, setTotalReportFiles] = useState<number>(0);
    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
    const [eventSource, setEventSource] = useState<EventSource | undefined>(undefined);

    const [isCreateReportFileModalVisible, setIsCreateReportFileModalVisible] = useState<boolean>(false);

    const messenger = useMessenger();
    const accessReportsService = useAPIAccessReports();
    const reportsFilesSseService = useAPISseReportFiles();

    const getReportFiles = (pagination?: PaginationRequest) => {
        if (pagination) {
            pagination.page != currentPage && setCurrentPage(pagination.page);
            pagination.pageSize != pageSize && setPageSize(pagination.pageSize);
        } else {
            pagination = { page: currentPage, pageSize };
        }

        const timestamp = dayjs().utc().unix();
        accessReportsService
            .listReportFiles(pagination)
            .then((data) => {
                setReportFiles(data.report_files);
                setTotalReportFiles(data.total);
                let subscribe = false;
                data.report_files.map((r) => {
                    if (r.status_id <= 2) {
                        subscribe = true;
                    }
                });
                subscribe
                    ? setEventSource(reportsFilesSseService.subscribeReporFilesEvent(timestamp))
                    : setEventSource(undefined);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'access-reports.report-files.list-error' });
            })
            .finally(() => {
                setIsLoadingTable(false);
            });
    };

    return (
        <ReportFilesContext.Provider
            value={{
                reportFiles,
                currentPage,
                pageSize,
                totalReportFiles,
                isLoadingTable,
                setReportFiles,
                setCurrentPage,
                setPageSize,
                setTotalReportFiles,
                setIsLoadingTable,
                getReportFiles,
                eventSource,
                setEventSource,
                isCreateReportFileModalVisible,
                setIsCreateReportFileModalVisible,
            }}
        >
            {props.children}
        </ReportFilesContext.Provider>
    );
};

export default ReportFilesProvider;
