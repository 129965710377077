import { useEffect, useState } from 'react';
import { OperationImportModalController } from 'fragments/operations/fragments/operation-import-modal/interfaces';
import { useOperationContext } from 'fragments/operations/context/operations.context';
import { CreateOperationInput, OperationType } from 'services/operations/operations.service';
import { OperationActionType } from 'services/operation-actions/operation-actions.service';
import { useForm } from 'antd/lib/form/Form';

export const useOperationImportModalController = (): /* <--Dependency Injections  like services hooks */
OperationImportModalController => {
    /* State */
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [file, setFile] = useState<File | undefined>();
    const {
        isNewOperationModalVisible,
        createOperationName,
        operationActionType,
        operationType,
        isLoadingCheck,
        checkError,
        setIsNewOperationModalVisible,
        setCreateOperationName,
        setOperationType,
        setCheckError,
        checkOperation,
        setOperationActionType,
    } = useOperationContext();
    const [array, setArray] = useState<any[]>([]);
    const fileReader = new FileReader();
    const [form] = useForm();

    /* Listeners */
    useEffect(() => {
        disableSubmit();
    }, [array, createOperationName, checkError]);

    /* View Events */

    const csvFileToArray = (string: string) => {
        const csvHeader = string.slice(0, string.indexOf('\n')).split(';');
        const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

        const array = csvRows.map((i) => {
            const values = i.split(';');
            const obj = csvHeader.reduce((object: any, header: string, index: number) => {
                object[header.trim().slice(1, -1)] = values[index]?.trim().slice(1, -1);
                return object;
            }, {});
            return obj;
        });
        setArray(array);
    };

    const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoadingUpload(true);
        if (e.target.files?.length && e.target.files[0]) {
            setFile(e.target.files[0]);
            fileReader.onload = function (event) {
                const csvOutput = event.target!.result;
                //TODO: cuando se definan nuevas operaciones hay que crear un switch y castear
                //  el array obtenido del csv a una interface definida segun el actionType y operationType
                csvFileToArray(csvOutput as string);
            };

            fileReader.readAsText(e.target.files[0]);
            setIsLoadingUpload(false);
            checkError === 'csv' && setCheckError('');
        }
    };

    const onCancelButtonPressed = () => {
        setIsNewOperationModalVisible(false);
        setCurrentStep(0);
        setOperationType(OperationType.DEFAULT);
        setOperationActionType(OperationActionType.DEFAULT);
        setCreateOperationName('');
        form.resetFields();
        setFile(undefined);
        setArray([]);
        setCheckError('');
    };

    const onSelectOption = (info: any, type: OperationType) => {
        setOperationType(type);
        setOperationActionType(Number(info.key));
        setCurrentStep(1);
    };

    const onBackButtonPressed = () => {
        setCurrentStep(0);
        setOperationType(OperationType.DEFAULT);
        setOperationActionType(OperationActionType.DEFAULT);
        setCreateOperationName('');
        form.resetFields();
        setFile(undefined);
        setArray([]);
        setCheckError('');
    };

    const onChangeOperationName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCreateOperationName(e.target.value);
        checkError === 'name' && setCheckError('');
    };

    const onCloseTag = () => {
        setFile(undefined);
        setArray([]);
    };

    const onSubmitButtonPressed = () => {
        const input: CreateOperationInput = {
            name: createOperationName,
            operation_action_type: operationActionType,
            operation_type: operationType,
            actions: array,
        };
        checkOperation(input);
    };
    /* Private Methods */

    // Return state and events
    const disableSubmit = () => {
        if (array.length == 0 || createOperationName.length < 4 || createOperationName.length > 100 || !!checkError) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    };

    return {
        currentStep,
        checkError,
        isLoadingCheck,
        isNewOperationModalVisible,
        onCancelButtonPressed,
        onSelectOption,
        onBackButtonPressed,
        onSubmitButtonPressed,
        submitDisabled,
        onUploadFile,
        array,
        file,
        form,
        createOperationName,
        isLoadingUpload,
        onChangeOperationName,
        onCloseTag,
    };
};
