import React from 'react';
import 'fragments/access-reports-dashboard/fragments/filter-modal-access-reports/filter-modal-access-reports.scss';
import { FilterModalAccessReportsFragmentProps } from 'fragments/access-reports-dashboard/fragments/filter-modal-access-reports/interfaces';
import { useFilterModalAccessReportsController } from 'fragments/access-reports-dashboard/fragments/filter-modal-access-reports/filter-modal-access-reports.controller';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Drawer, Select, Tag, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ITag } from 'global/interfaces';
import dayjs from 'dayjs';
import { CustomField } from 'services/custom-fields/custom-fields.service';
const { Option } = Select;
const { Text, Title } = Typography;
const CheckboxGroup = Checkbox.Group;

export const FilterModalAccessReportsFragment: React.FC<FilterModalAccessReportsFragmentProps> = (props) => {
    const { useController = useFilterModalAccessReportsController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));

    const forMap = (tag: ITag) => {
        const tagElem = (
            <Tag
                closable={controller.isTableLoading ? false : tag.closable}
                onClose={(e) => {
                    e.preventDefault();
                    controller.onCloseTag(tag);
                }}
                color={tag.color}
                style={{ fontWeight: 'bold' }}
            >
                <Text strong>{tag.name}: </Text> {tag.value}
            </Tag>
        );
        return (
            <span key={tag.id} style={{ display: 'inline-block', marginBottom: '2px' }}>
                {tagElem}
            </span>
        );
    };
    const tagChild = controller.tags.map(forMap);
    const mapFields = (fields: CustomField[]) => {
        const fieldInputs = fields.map((f) => {
            switch (f.type_id) {
                case 1:
                case 3:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            initialValue={controller.selectedCustomFields.find((field) => field.id == f.id)?.value}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                allowClear
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                tokenSeparators={[',']}
                                placeholder={translate({ key: 'form.placeholder.rfid-card.custom-field-tags' })}
                                dropdownStyle={{ display: 'none' }}
                            />
                        </Form.Item>
                    );
                case 2:
                    return (
                        <Col key={f.id} xs={24} id={`filter-site-cards-date-picker-${f.name}`}>
                            <Form.Item
                                label={
                                    <Text type="secondary" strong>
                                        {f.name}
                                    </Text>
                                }
                                name={f.name}
                                initialValue={controller.selectedCustomFields.find((field) => field.id == f.id)?.value}
                            >
                                <DatePicker.RangePicker
                                    style={{ width: '100%' }}
                                    getPopupContainer={() =>
                                        document.getElementById(
                                            `filter-site-cards-date-picker-${f.name}`,
                                        ) as HTMLElement
                                    }
                                    format="DD-MM-YYYY"
                                    onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                    value={controller.selectedCustomFields.find((field) => field.id == f.id)?.value}
                                />
                            </Form.Item>
                        </Col>
                    );

                case 4:
                    return (
                        <Form.Item
                            key={f.id}
                            label={
                                <Text type="secondary" strong>
                                    {f.name}
                                </Text>
                            }
                            name={f.name}
                            initialValue={controller.selectedCustomFields.find((field) => field.id == f.id)?.value}
                        >
                            <Select
                                showSearch
                                placeholder={f.name}
                                allowClear
                                mode="multiple"
                                onChange={(e) => controller.onChangeField(f.id, f.type_id, e)}
                                style={{ width: '100%' }}
                                disabled={controller.isLoading}
                                filterOption={(inputValue, option) => {
                                    const opt = f.values?.find((item: any) => item.value == option?.value);
                                    return !!opt?.value.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                            >
                                {f.values?.map((v) => (
                                    <Option key={v.id} value={v.value}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {v.value}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    );
                default:
                    return <></>;
            }
        });
        return fieldInputs;
    };
    // Render
    return (
        <>
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#f2f2f2',
                    padding: '10px',
                }}
            >
                <Text strong>{translate({ key: 'general.filters' })}:</Text>

                <div className="filter-tags">{tagChild}</div>
            </div>
            <Drawer
                forceRender
                open={controller.isFilterModalVisible}
                destroyOnClose={true}
                title={
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'access-reports.filter-modal' })}
                    </Title>
                }
                className={'filter-modal-access-reports'}
                closable={false}
                width={650}
                placement="right"
                footer={
                    <div style={{ width: '100%', display: 'flex' }}>
                        {[
                            <Button
                                form="filter-access-reports-form"
                                key="reset"
                                className={'button primary'}
                                style={{ marginRight: 'auto' }}
                                loading={controller.isLoading}
                                onClick={controller.onResetButtonPressed}
                            >
                                {translate({ key: 'general.reset' })}
                            </Button>,
                            <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                                {translate({ key: 'general.cancel' })}
                            </Button>,
                            <Button
                                form="filrter-access-reports-form"
                                key="submit"
                                type="primary"
                                className={'button primary'}
                                loading={controller.isLoading}
                                htmlType="submit"
                            >
                                {translate({ key: 'general.search' })}
                            </Button>,
                        ]}
                    </div>
                }
            >
                <Form
                    id="filrter-access-reports-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    // layout="horizontal"
                    layout="vertical"
                    // size="middle"
                    size="small"
                    className={'form'}
                    form={controller.form}
                    onFinish={controller.onSubmitButtonPressed}
                >
                    <Col xs={24} id="create-key-date-picker">
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.duration' })}
                                </Text>
                            }
                            name="date_picker"
                            initialValue={
                                controller.dateRange.from && controller.dateRange.from
                                    ? [
                                          dayjs(controller.dateRange.from, 'YYYY/MM/DD'),
                                          dayjs(controller.dateRange.to, 'YYYY/MM/DD'),
                                      ]
                                    : undefined
                            }
                        >
                            <DatePicker.RangePicker
                                style={{ width: '100%' }}
                                onChange={controller.onPickedDate}
                                getPopupContainer={() =>
                                    document.getElementById('create-key-date-picker') as HTMLElement
                                }
                                format="DD-MM-YYYY"
                                disabledDate={controller.disabledDate}
                            />
                        </Form.Item>
                    </Col>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.lock' })}
                            </Text>
                        }
                        name="lock_ids"
                        // required
                        // rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                        initialValue={controller.selectedLockIds}
                    >
                        <Select
                            placeholder={translate({ key: 'form.placeholder.lock.name' })}
                            mode="multiple"
                            allowClear
                            loading={controller.isLoadingLocks}
                            onChange={controller.onLockSelect}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                const opt = controller.locks.find((item) => item.id == option?.value);
                                return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                            }}
                        >
                            {optionLocks}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {controller.toggleTable
                                    ? translate({ key: 'label.name-email-identifier' })
                                    : translate({ key: 'label.name-email' })}
                            </Text>
                        }
                        name="searchInput"
                        initialValue={controller.searchInput}
                    >
                        <Input placeholder={'example@accefy.io...'} onChange={controller.onSearchInputChange} />
                    </Form.Item>
                    {controller.toggleTable && (
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.access-method' })}
                                </Text>
                            }
                            name="accessMethodCheck"
                            required
                            rules={[
                                {
                                    required: !controller.tempAccessMethodIds.length ? true : false,
                                    message: translate({ key: 'form.select-an-option-error' }),
                                },
                            ]}
                        >
                            <>
                                <Checkbox
                                    indeterminate={controller.indeterminate}
                                    onChange={controller.onCheckAllAccessMethodOption}
                                    checked={controller.checkAllAccessMethod}
                                >
                                    Seleccionar todos
                                </Checkbox>
                                <Divider style={{ margin: '5px' }} />
                                <CheckboxGroup
                                    options={controller.accessMethodOptions}
                                    value={controller.tempAccessMethodIds}
                                    onChange={controller.onCheckAccessMethodOption}
                                />
                            </>
                        </Form.Item>
                    )}
                    {controller.toggleTable && (
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.state' })}
                                </Text>
                            }
                            name="reportState"
                            required
                            rules={[
                                {
                                    required: !controller.tempReportStateIds.length ? true : false,
                                    message: translate({ key: 'form.select-an-option-error' }),
                                },
                            ]}
                        >
                            <>
                                <CheckboxGroup
                                    options={controller.reportStateOptions}
                                    value={controller.tempReportStateIds}
                                    onChange={controller.onCheckReportStateOption}
                                />
                            </>
                        </Form.Item>
                    )}
                    {controller.toggleTable &&
                        !!controller.customFields.length &&
                        controller.tempAccessMethodIds.find((x) => x == 7) && <>{mapFields(controller.customFields)}</>}
                </Form>
            </Drawer>
        </>
    );
};
