import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const RemoteAccessSvg = () => (
    <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 768 768"
        stroke="currentColor"
        fill="currentColor"
    >
        <defs>
            <style>
                {
                    '.RemoteAccess-1{fill:none;stroke:#000;stroke-width:20.75px;}.RemoteAccess-1,.RemoteAccess-2{stroke-miterlimit:10;}.RemoteAccess-2{stroke:#fff;stroke-width:0.25px;}'
                }
            </style>
        </defs>
        <title>iconos_accefy_sga</title>
        <path
            className="RemoteAccess-1"
            d="M497.29,182.9v-48A72.91,72.91,0,0,0,424.89,62H223.79a72.89,72.89,0,0,0-73.14,72.65V633.11A72.65,72.65,0,0,0,223,706H424.89A72.66,72.66,0,0,0,498,633.86V585.1"
        />
        <path d="M669.43,307.86a10.68,10.68,0,0,0-.59-1.17,186.9,186.9,0,0,0-342.2,0,12.72,12.72,0,0,0-.58,1.17,186.21,186.21,0,0,0,0,148.53,12.72,12.72,0,0,0,.58,1.17,187.27,187.27,0,0,0,171.1,111.69h0a187.24,187.24,0,0,0,171.1-111.69,10.68,10.68,0,0,0,.59-1.17A186.21,186.21,0,0,0,669.43,307.86Zm-30.09-7.59H592.63a197.59,197.59,0,0,0-32.37-69.41A164.61,164.61,0,0,1,639.34,300.27ZM435.26,230.86a197.61,197.61,0,0,0-32.38,69.41H356.17A164.59,164.59,0,0,1,435.26,230.86ZM345,323.66h52.63a326.19,326.19,0,0,0-4.79,46.77H334.62A161.86,161.86,0,0,1,345,323.66Zm-10.4,70.17h58.23a327.61,327.61,0,0,0,4.78,46.77H345A161.86,161.86,0,0,1,334.6,393.83ZM356.17,464h46.71a197.65,197.65,0,0,0,32.38,69.4A164.62,164.62,0,0,1,356.17,464Zm153.27,79.9V464a81.39,81.39,0,0,1-23.39.07V543.9C461.31,536.5,439.8,506,427.33,464h57.8a82.74,82.74,0,0,1-46.31-23.4H421.58a297.29,297.29,0,0,1-5.13-41,83.08,83.08,0,0,1-1.08-29.31c.29-2.06.67-4.09,1.11-6.09a297.54,297.54,0,0,1,5.11-40.57h17a82.71,82.71,0,0,1,44.89-23.4H427.34c12.46-42,34-72.45,58.72-79.91v79.5a82.65,82.65,0,0,1,11.42-.79,84.43,84.43,0,0,1,12,.86V220.35c24.75,7.46,46.25,37.94,58.72,79.91H511.59a82.8,82.8,0,0,1,44.83,23.4h17.5a297.47,297.47,0,0,1,5.27,44.13,82.47,82.47,0,0,1,0,28.15,298.07,298.07,0,0,1-5.29,44.66H556.14A82.77,82.77,0,0,1,509.83,464h58.34C555.69,506,534.19,536.5,509.44,543.9Zm50.82-10.5h0A197.63,197.63,0,0,0,592.63,464h46.71A164.64,164.64,0,0,1,560.26,533.4ZM660.85,393.83a162.16,162.16,0,0,1-10.36,46.77H597.86a326.19,326.19,0,0,0,4.79-46.77Zm0-23.4H602.68a327.61,327.61,0,0,0-4.78-46.77h52.62a161.84,161.84,0,0,1,10.37,46.77Z" />
        <path
            className="RemoteAccess-2"
            d="M534.3,360.08a35.71,35.71,0,0,0-71.41,0,34.76,34.76,0,0,0,23.81,33.09l-11.43,43.56H520.5l-10-43.56A35.7,35.7,0,0,0,534.3,360.08Z"
        />
    </svg>
);
const RemoteAccessIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={RemoteAccessSvg} {...props} />;
export default RemoteAccessIcon;
