import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CheckboxOption, ITag } from 'global/interfaces';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CustomFieldTag, FilterCustomField, SiteCardsFilterModalController } from './interfaces';
import { GetRfidCardsInterface, useSiteCardsContext } from 'fragments/site-cards-dashboard/context/site-cards.context';
import dayjs, { Dayjs } from 'dayjs';
import { CustomField } from 'services/custom-fields/custom-fields.service';
import { useAPICustomFields } from 'services/custom-fields/api-custom-fields.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useSiteCardsFilterModalController = (): /* <--Dependency Injections  like services hooks */
SiteCardsFilterModalController => {
    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;
    const { translate } = useTranslator();
    const messenger = useMessenger();
    const customFieldsService = useAPICustomFields();

    const validateCardNumberRegExp = /^\d+$/;

    /* State */
    const [isLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [rfidCardStatusOptions, setRfidCardStatusOptions] = useState<CheckboxOption[]>([]);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(true);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);

    //________________TAGS________________
    const [tempLockIds, setTempLockIds] = useState<string[]>([]);
    const [tempLabel, setTempLabel] = useState<string[]>([]);
    const [tempCardNumber, setTempCardNumber] = useState<string[]>([]);
    const [tempAuthorizer, setTempAuthorizer] = useState<string[]>([]);
    const [tempDateRange, setTempDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [tempStatusIds, setTempStatusIds] = useState<number[]>([]);
    const [tempSelectedCustomFields, setTempSelectedCustomFields] = useState<FilterCustomField[]>([]);
    const {
        isFilterModalVisible,
        selectedLockIds,
        dateRange,
        label,
        authorizer,
        pageSize,
        selectedRfidCardStatusOptions,
        cardNumber,
        setCardNumber,
        setSelectedRfidCardStatusOptions,
        setAuthorizer,
        setLabel,
        setIsFilterModalVisible,
        setDateRange,
        setSelectedLockIds,
        setCurrentPage,
        setPageSize,
        setSortedInfo,
        setOrder,
        setOrderBy,
        locks,
        isLoadingLocks,
        getLocks,
        getRfidCards,
        tags,
        setTags,
        rfidCards,
        isLoadingTable,
        selectedCustomFields,
        setSelectedCustomFields,
    } = useSiteCardsContext();

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            getLocks();
            getCustomFields();
            setDefaultRfidCardStatusOptions();
        }
    }, [selectedSite]);

    useEffect(() => {
        setSubmitDisabled(disableSubmitButton());
    }, [tempLockIds, tempLabel, tempCardNumber, tempAuthorizer, tempDateRange, tempStatusIds]);

    //________________TAGS________________
    useEffect(() => {
        mapTags({
            devices: selectedLockIds,
            since: dateRange.from,
            until: dateRange.to,
            card: cardNumber,
            label,
            authorizer,
            status: selectedRfidCardStatusOptions,
            customFields: selectedCustomFields,
        });
        setTempLockIds(selectedLockIds);
        setTempLabel(label);
        setTempCardNumber(cardNumber);
        setTempAuthorizer(authorizer);
        setTempDateRange(dateRange);
        setTempStatusIds(selectedRfidCardStatusOptions);
        setTempSelectedCustomFields(selectedCustomFields);
    }, [
        selectedLockIds,
        dateRange,
        label,
        cardNumber,
        authorizer,
        selectedRfidCardStatusOptions,
        locks,
        selectedCustomFields,
    ]);

    useEffect(() => {
        form.resetFields();
    }, [rfidCards]);

    /* View Events */
    // ________________Button Events___________________

    const onCancelButtonPressed = () => {
        setTempLockIds(selectedLockIds);
        setTempLabel(label);
        setTempCardNumber(cardNumber);
        setTempAuthorizer(authorizer);
        setTempDateRange(dateRange);
        setTempStatusIds(selectedRfidCardStatusOptions);
        setIsFilterModalVisible(false);
        customFields.map((f) => {
            form.setFieldsValue({
                [f.name]: selectedCustomFields.find((field) => field.id == f.id)?.value,
            });
        });
        setIndeterminate(
            !!selectedRfidCardStatusOptions.length &&
                selectedRfidCardStatusOptions.length < defaultRfidCardStatusOptions.length,
        );
        form.resetFields();
    };

    const onResetButtonPressed = () => {
        resetStates();
        setDefaultRfidCardStatusOptions();
        setIsFilterModalVisible(false);
        getRfidCards({
            pagination: { page: 0, pageSize },
            orderTable: { order: 'ascend', orderBy: 'label' },
        });
        form.resetFields();
    };

    const disableSubmitButton = (): boolean => {
        if (tempLockIds.length || tempStatusIds.length) return false;
        if (tempDateRange.from || tempDateRange.to) return false;
        if (tempLabel.length || tempAuthorizer.length) return false;
        if (tempCardNumber.length) return false;
        return true;
    };

    const onSubmitButtonPressed = () => {
        setSelectedLockIds(tempLockIds);
        setCardNumber(tempCardNumber);
        setLabel(tempLabel);
        setAuthorizer(tempAuthorizer);
        setDateRange(tempDateRange);
        setSelectedRfidCardStatusOptions(tempStatusIds);
        setSelectedCustomFields(tempSelectedCustomFields);
        getRfidCards({
            pagination: { page: 0, pageSize },
            search: {
                devices: tempLockIds,
                since: tempDateRange.from,
                until: tempDateRange.to,
                label: tempLabel,
                card: tempCardNumber,
                authorizer: tempAuthorizer,
                status: tempStatusIds,
                customFields: tempSelectedCustomFields,
            },
        });
        setIsFilterModalVisible(false);
    };
    // ________________Input Events___________________

    const onLockSelect = (value: string[]) => {
        setTempLockIds(value);
    };

    const onCardNumberChange = (value: string[]) => {
        setTempCardNumber(value);
    };

    const onLabelInputChange = (value: string[]) => {
        setTempLabel(value);
    };

    const onAuthorizerInputChange = (value: string[]) => {
        setTempAuthorizer(value);
    };

    const onPickedDate = (date: [Dayjs | null, Dayjs | null]) => {
        if (date[0] && date[1]) {
            setTempDateRange({ from: date[0].format('YYYY-MM-DD'), to: date[1].format('YYYY-MM-DD') });
        }
    };

    const onCheckRfidCardStatusOptions = (list: CheckboxValueType[]) => {
        setTempStatusIds(list as number[]);
        setIndeterminate(!!list.length && list.length < rfidCardStatusOptions.length);
        setCheckAll(list.length === rfidCardStatusOptions.length);
    };

    const onCheckAllRfidCardStatusOptions = (e: CheckboxChangeEvent) => {
        setTempStatusIds(e.target.checked ? rfidCardStatusOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChangeField = (id: string, type: number, value: any) => {
        const field = tempSelectedCustomFields.find((f) => f.id == id);
        if (field) {
            setTempSelectedCustomFields(
                tempSelectedCustomFields.map((f) => {
                    if (f.id == id) {
                        return { ...f, value: value };
                    }
                    return f;
                }),
            );
        } else {
            setTempSelectedCustomFields([
                ...tempSelectedCustomFields,
                { id, type_id: type, name: customFields.find((f) => f.id == id)?.name || '', value },
            ]);
        }
    };

    /* Private Methods */
    // ________________TAGS___________________
    const onCloseTag = (tag: ITag) => {
        switch (tag.name) {
            case translate({ key: 'label.lock' }): {
                const newSelectedLockIds = selectedLockIds.filter((lockId) => lockId !== tag.id);
                setSelectedLockIds(newSelectedLockIds);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: newSelectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            case translate({ key: 'label.card' }): {
                const newCardNumber = cardNumber.filter((searchTerm) => searchTerm !== tag.id);
                setCardNumber(newCardNumber);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: newCardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            case translate({ key: 'label.label' }): {
                const newLabel = label.filter((searchTerm) => searchTerm !== tag.id);
                setLabel(newLabel);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: newLabel,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            case translate({ key: 'label.responsible' }): {
                const newAuthorizer = authorizer.filter((searchTerm) => searchTerm !== tag.id);
                setAuthorizer(newAuthorizer);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: label,
                        authorizer: newAuthorizer,
                        status: selectedRfidCardStatusOptions,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            case translate({ key: 'label.duration' }): {
                setDateRange({ from: '', to: '' });
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        since: '',
                        until: '',
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        devices: selectedLockIds,
                        status: selectedRfidCardStatusOptions,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }

            case translate({ key: 'label.state' }): {
                const newSelectedRfidCardStatus = selectedRfidCardStatusOptions.filter((id) => id !== tag.id);
                setSelectedRfidCardStatusOptions(newSelectedRfidCardStatus);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: newSelectedRfidCardStatus,
                        customFields: selectedCustomFields,
                    },
                });
                break;
            }
            default: {
                const newSelectedRfidCardCustomFields = selectedCustomFields.map((field) => {
                    if (field.type_id == 2 && field.id == tag.id) {
                        return { ...field, value: [] };
                    } else if (field.type_id == 3 && typeof tag.id == 'string' && tag.id.includes(field.id)) {
                        const newValues = field.value.filter((value: any) => `${field.id}_${value.value}` != tag.id);
                        return { ...field, value: newValues };
                    } else {
                        const newValues = field.value.filter((valueId: any) => `${field.id}_${valueId}` != tag.id);
                        return { ...field, value: newValues };
                    }
                });
                setSelectedCustomFields(newSelectedRfidCardCustomFields);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                        customFields: newSelectedRfidCardCustomFields,
                    },
                });
                break;
            }
        }
    };

    const mapTags = (search: GetRfidCardsInterface) => {
        const {
            devices: selectedLockIds,
            since,
            until,
            card,
            label,
            authorizer,
            status,
            customFields: fields,
        } = search;
        const tempLockTags: ITag[] = [];
        const tempLabelTags: ITag[] = [];
        const tempCardTags: ITag[] = [];
        const tempAuthorizerTags: ITag[] = [];
        const tempDateTags: ITag[] = [];
        const tempSelectedRfidCardStatus: ITag[] = [];
        const tempSelectedRfidCardCustomFields: ITag[] = [];

        fields.map((field) => {
            if (field.type_id == 2) {
                !!field.value.length &&
                    tempSelectedRfidCardCustomFields.push({
                        id: field.id,
                        name: field.name,
                        value: `Desde: ${dayjs(field.value[0]).format('DD/MM/YYYY')} Hasta: ${dayjs(
                            field.value[1],
                        ).format('DD/MM/YYYY')}`,
                        color: 'magenta',
                        closable: true,
                    });
            } else if (field.type_id == 3) {
                !!field.value.length &&
                    field.value.map((value: any) => {
                        tempSelectedRfidCardCustomFields.push({
                            id: `${field.id}_${value.value}`,
                            name: field.name,
                            value: value.value,
                            color: 'magenta',
                            closable: true,
                        });
                    });
            } else if (field.type_id == 4) {
                !!field.value.length &&
                    field.value.map((valueId: any) => {
                        tempSelectedRfidCardCustomFields.push({
                            id: `${field.id}_${valueId}`,
                            name: field.name,
                            value:
                                customFields.find((x) => x.id == field.id)?.values?.find((v) => v.id == valueId)
                                    ?.value || '',
                            color: 'magenta',
                            closable: true,
                        });
                    });
            } else {
                !!field.value.length &&
                    field.value.map((f: any) => {
                        tempSelectedRfidCardCustomFields.push({
                            id: `${field.id}_${f}`,
                            name: field.name,
                            value: f,
                            color: 'magenta',
                            closable: true,
                        });
                    });
            }
        });

        selectedLockIds.map((id) => {
            const selectedLock = locks.find((lock) => lock.id === id);
            tempLockTags.push({
                id,
                name: translate({ key: 'label.lock' }),
                value: `${selectedLock?.name}`,
                color: 'geekblue',
                closable: true,
            });
        });

        label.map((searchTerm) => {
            tempLabelTags.push({
                id: searchTerm,
                name: translate({ key: 'label.label' }),
                value: searchTerm,
                color: 'purple',
                closable: true,
            });
        });

        card.map((searchTerm) => {
            tempCardTags.push({
                id: searchTerm,
                name: translate({ key: 'label.card' }),
                value: searchTerm.toString(),
                color: 'cyan',
                closable: true,
            });
        });

        authorizer.map((searchTerm) => {
            tempAuthorizerTags.push({
                id: searchTerm,
                name: translate({ key: 'label.responsible' }),
                value: searchTerm,
                color: 'purple',
                closable: true,
            });
        });

        if (since !== '' || until !== '') {
            tempDateTags.push({
                id: since + until,
                name: translate({ key: 'label.duration' }),
                value: `Desde: ${since} Hasta: ${until}`,
                color: 'cyan',
                closable: true,
            });
        }
        if (status.length !== rfidCardStatusOptions.length) {
            status.map((value) => {
                const selectedRfidCardStatus = rfidCardStatusOptions.find((option) => option.value === value);
                tempSelectedRfidCardStatus.push({
                    id: value,
                    name: translate({ key: 'label.state' }),
                    value: `${selectedRfidCardStatus?.label}`,
                    color: 'green',
                    closable: true,
                });
            });
            if (tempSelectedRfidCardStatus.length === 1) {
                tempSelectedRfidCardStatus[0].closable = false;
            }
        }

        setTags([
            ...tempLockTags,
            ...tempLabelTags,
            ...tempCardTags,
            ...tempAuthorizerTags,
            ...tempDateTags,
            ...tempSelectedRfidCardStatus,
            ...tempSelectedRfidCardCustomFields,
        ]);
    };

    // ________________Form___________________
    const defaultRfidCardStatusOptions: CheckboxOption[] = [
        { value: 1, label: translate({ key: 'rfid-card.label.valid' }) },
        { value: 2, label: translate({ key: 'rfid-card.label.deleted' }) },
        { value: 3, label: translate({ key: 'rfid-card.label.deleting' }) },
        { value: 4, label: translate({ key: 'rfid-card.label.delete-failed' }) },
    ];
    const setDefaultRfidCardStatusOptions = (): void => {
        setRfidCardStatusOptions(defaultRfidCardStatusOptions);
        setTempStatusIds([1, 4]);
        setSelectedRfidCardStatusOptions([1, 4]);
        setCheckAll(false);
    };

    const resetStates = () => {
        setAuthorizer([]);
        setLabel([]);
        setCardNumber([]);
        setSelectedLockIds([]);
        setDateRange({ from: '', to: '' });
        setCurrentPage(0);
        setPageSize(20);
        setSortedInfo({
            order: 'ascend',
            columnKey: 'label',
        });
        setOrder('ascend');
        setOrderBy('label');
        setDefaultRfidCardStatusOptions();
        setSelectedCustomFields([]);
    };

    const getCustomFields = () => {
        setIsLoadingCustomFields(true);
        customFieldsService
            .listCustomFields()
            .then((response: CustomField[]) => {
                setCustomFields(response);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: translate({ key: 'custom-fields.error-list' }) });
            })
            .finally(() => {
                setIsLoadingCustomFields(false);
            });
    };

    async function autocompleteTags(input: string, fieldId: string): Promise<CustomFieldTag[]> {
        return customFieldsService
            .autocompleteTags(input, fieldId)
            .then((res) => res.tags.map((t) => ({ label: t, value: t })));
    }

    // Return state and events
    return {
        tags,
        form,
        isLoading,
        locks,
        isLoadingLocks,
        isFilterModalVisible,
        selectedLockIds,
        label,
        cardNumber,
        tempCardNumber,
        submitDisabled,
        authorizer,
        rfidCardStatusOptions,
        tempStatusIds,
        isLoadingTable,
        dateRange,
        validateCardNumberRegExp,
        onLabelInputChange,
        onCancelButtonPressed,
        onSubmitButtonPressed,
        onResetButtonPressed,
        onCardNumberChange,
        onAuthorizerInputChange,
        onLockSelect,
        onPickedDate,
        onCloseTag,
        checkAll,
        indeterminate,
        onCheckRfidCardStatusOptions,
        onCheckAllRfidCardStatusOptions,
        customFields,
        onChangeField,
        selectedCustomFields,
        autocompleteTags,
    };
};
