import { useEffect, useState } from 'react';
import { SitePolicyActionListController } from 'fragments/site-policies/fragments/site-policy-action-list/interfaces';
import { NewSitePolicyActionDto } from 'services/site-policies/site-policies.service';
import { useAPISitePolicies } from 'services/site-policies/api-policies.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useSitePolicyActionListController = (
    policyId: string,
): /* <--Dependency Injections  like services hooks */
SitePolicyActionListController => {
    const sitePoliciesService = useAPISitePolicies();
    const messenger = useMessenger();
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [actions, setActions] = useState<NewSitePolicyActionDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);
    useEffect(() => {
        getActions(policyId);
    }, []);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onButtonPressed = () => {
        // Example event
    };

    /* Private Methods */
    //Ex. const increaseCount = () => {}

    const getActions = (id: string) => {
        setIsLoading(true);
        sitePoliciesService
            .getSitePolicyActionById(id)
            .then((data) => {
                setActions(data);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'site-policies.list-actions-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Return state and events
    return { actions, isLoading, onButtonPressed };
};
