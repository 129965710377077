import {
    CreateLockInput,
    LockDto,
    Locks,
    PaginatedLocksResponse,
    SearchLocksInterface,
} from 'services/locks/locks.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';
import { OrderTable, PaginationRequest } from 'global/interfaces';

export const useAPILocks = (): Locks => {
    const [session] = useLocalSession();
    const listLocks = async (
        pagination?: PaginationRequest,
        order?: OrderTable,
        search?: SearchLocksInterface,
    ): Promise<PaginatedLocksResponse> => {
        pagination = pagination || { page: 0, pageSize: 10 };
        order = order || { order: undefined, orderBy: undefined };
        search = search || { searchInput: '', lockType: [], lockStatus: [] };
        const lockStatus = JSON.stringify(search.lockStatus);
        const lockType = JSON.stringify(search.lockType);
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/locks?page=${pagination.page}&pageSize=${pagination.pageSize}&order=${order.order}&orderBy=${order.orderBy}&lockType=${lockType}&lockStatus=${lockStatus}&input=${search.searchInput}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as PaginatedLocksResponse;
        } catch (error) {
            throw error;
        }
    };

    const listDevicesRfidCardsListFilter = async (): Promise<LockDto[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/devices/filter/rfid-cards`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as LockDto[];
        } catch (error) {
            throw error;
        }
    };

    const listDevicesRfidCardDevicesListFilter = async (): Promise<LockDto[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/devices/filter/rfid-card-devices`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as LockDto[];
        } catch (error) {
            throw error;
        }
    };

    const listLocksInKeyCreation = async (): Promise<PaginatedLocksResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL + `/user/sites/${session.selectedSite?.id}/locks/minimal`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            const filteredLocks = response.data.locks.filter(
                (lock: LockDto) => lock.lock_status_id === 0 || lock.lock_status_id === 1,
            );
            return { locks: filteredLocks, total: filteredLocks.length } as PaginatedLocksResponse;
            // return response.data as PaginatedLocksResponse;
        } catch (error) {
            throw error;
        }
    };

    const listLocksInCardCreation = async (
        pagination?: PaginationRequest,
        order?: OrderTable,
        search?: SearchLocksInterface,
    ): Promise<PaginatedLocksResponse> => {
        pagination = pagination || { page: 0, pageSize: 10 };
        order = order || { order: undefined, orderBy: undefined };
        search = search || { searchInput: '', lockType: [], lockStatus: [] };
        const lockStatus = JSON.stringify(search.lockStatus);
        const lockType = JSON.stringify(search.lockType);
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/locksbyadmin?page=${pagination.page}&pageSize=${pagination.pageSize}&order=${order.order}&orderBy=${order.orderBy}&lockType=${lockType}&lockStatus=${lockStatus}&input=${search.searchInput}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as PaginatedLocksResponse;
        } catch (error) {
            throw error;
        }
    };

    const createLock = async (input: CreateLockInput): Promise<LockDto> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/user/locks`, input, {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as LockDto;
        } catch (err) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    const resetLock = async (zoneId: string): Promise<string> => {
        try {
            const response = await axios.put(
                process.env.REACT_APP_API_BASE_URL + `/user/locks/${zoneId}/reset`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as string;
        } catch (err) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    const checkConnection = async (zoneId: string): Promise<{ code: number; msg: string }> => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/user/locks/${zoneId}/check`, {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as { code: number; msg: string };
        } catch (err) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    return {
        listLocks,
        createLock,
        listDevicesRfidCardsListFilter,
        listDevicesRfidCardDevicesListFilter,
        listLocksInKeyCreation,
        listLocksInCardCreation,
        resetLock,
        checkConnection,
    };
};
