import { LoginController } from 'auth/fragments/login/interfaces';
import { LoginInput, SendResetPasswordMailInput } from 'auth/services/auth/auth.service';
import { useState } from 'react';
import { Form } from 'antd';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useLocation, useNavigate } from 'react-router-dom';
// import { LocationDescriptor } from 'history';
import { useAPIAuthService } from 'auth/services/auth/api-auth.service';

export const useLoginController = (authService = useAPIAuthService(), messenger = useMessenger()): LoginController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [isRecoverPassLoading, setIsRecoverPassLoading] = useState(false);
    const [isRecoverPassVisible, setIsRecoverPassVisible] = useState(false);
    const [password, setPassword] = useState<string>('');

    /* Hooks */
    const [recoverPassForm] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const { from } = (location.state as { from: any }) || { from: { pathname: '/' } };

    /* View Events */
    const onLoginSubmit = (formInputs: unknown) => {
        setIsLoading(true);
        const input = formInputs as LoginInput;
        input.grant_type = 'password';
        //Auth route component will handle re-render
        input.password = password;
        input.device_id = 'aaa123';
        input.username.toLowerCase();
        authService
            .login(input)
            .then(() => {
                navigate(from, { replace: true });
            })
            .catch((errorCode: string) => {
                if (errorCode == 'invalid_grant%!(EXTRA string=Invalid credentials)') {
                    messenger.showErrorMessage({ key: 'auth.login-error-invalid-credentials' });
                } else {
                    messenger.showErrorMessage({ key: 'auth.login-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onRecoverPassSubmit = (formInputs: unknown) => {
        setIsRecoverPassLoading(true);
        const input = formInputs as SendResetPasswordMailInput;
        authService
            .sendResetPasswordMail(input)
            .then(() => {
                recoverPassForm.resetFields();
                messenger.showSuccessMessage({ key: 'auth.forgot-pass-success' });
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'auth.forgot-pass-error' });
            })
            .finally(() => {
                recoverPassForm.resetFields();
                setIsRecoverPassVisible(false);
                setIsRecoverPassLoading(false);
            });
    };

    const onCancelRecoverPass = () => {
        recoverPassForm.resetFields();
        setIsRecoverPassVisible(false);
    };

    const onForgotPassPressed = () => {
        setIsRecoverPassVisible(true);
    };

    const onPasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    /* Private Methods */

    // Return state and events
    return {
        recoverPassForm,
        isLoading,
        password,
        isRecoverPassLoading,
        isRecoverPassVisible,
        onLoginSubmit,
        onRecoverPassSubmit,
        onCancelRecoverPass,
        onForgotPassPressed,
        onPasswordInputChange,
    };
};
