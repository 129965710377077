import {
    Operations,
    OperationListInput,
    PaginatedOperationsResponse,
    OperationCommand,
    CreateOperationInput,
    CreateOperationResponse,
    PreviewActionItem,
} from 'services/operations/operations.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useAPIOperations = (): Operations => {
    const [session] = useLocalSession();

    const createOperation = async (input: CreateOperationInput): Promise<CreateOperationResponse> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_RFID_BASE_URL + `/user/sites/${session.selectedSite?.id}/operations`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const operationsList = async ({
        pagination,
        orderTable,
    }: OperationListInput): Promise<PaginatedOperationsResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/operations?page=${pagination.page}&pageSize=${
                        pagination.pageSize
                    }&order=${orderTable.order || ''}&orderBy=${orderTable.orderBy || ''}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const operationsCommand = async (operationId: string, command: OperationCommand): Promise<any> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/operations/${operationId}/${command}`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const checkOperation = async (input: CreateOperationInput): Promise<PreviewActionItem[]> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_RFID_BASE_URL + `/user/sites/${session.selectedSite?.id}/operations/check`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        checkOperation,
        createOperation,
        operationsList,
        operationsCommand,
    };
};
