import { SorterResult } from 'antd/lib/table/interface';
import { ITag, OrderTable, PaginationRequest, SorterInfo } from 'global/interfaces';
import React, { useContext } from 'react';
import { LockDto } from 'services/locks/locks.service';
import {
    NewSitePolicyDto,
    SearchSitePoliciesInterface,
    SitePolicyActionType,
} from 'services/site-policies/site-policies.service';

export interface GetSitePoliciesInterface {
    pagination?: PaginationRequest;
    orderTable?: OrderTable;
    search?: SearchSitePoliciesInterface;
}

export interface SitePolicyContextInterface {
    sitePolicies: NewSitePolicyDto[];
    totalSitePolicies: number;
    currentPage: number;
    pageSize: number;
    searchInput: string;
    isCreateSitePolicyModalVisible: boolean;
    isListLoading: boolean;
    sitePolicyActionTypes: SitePolicyActionType[];
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    sortedInfo: SorterResult<SorterInfo>;
    tags: ITag[];
    selectedStatusIds: number[];
    selectedLockIds: string[];
    isTableLoading: boolean;
    isFilterModalVisible: boolean;
    isControlModalVisible: boolean;
    isEditModalVisible: boolean;
    isViewRedeemsModalVisible: boolean;
    locks: LockDto[];
    isLoadingLocks: boolean;
    operatorSwitch: boolean;
    expandedRowKeys: string[];
    expandAll: boolean;
    selectedPolicyIdForViewRedeemers: string;

    setSelectedPolicyIdForViewRedeemers: React.Dispatch<React.SetStateAction<string>>;
    setExpandedRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
    setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
    setOperatorSwitch: React.Dispatch<React.SetStateAction<boolean>>;
    setLocks: React.Dispatch<React.SetStateAction<LockDto[]>>;
    setSelectedStatusIds: React.Dispatch<React.SetStateAction<number[]>>;
    setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSortedInfo: React.Dispatch<React.SetStateAction<SorterResult<SorterInfo>>>;
    setSelectedLockIds: React.Dispatch<React.SetStateAction<string[]>>;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsControlModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsViewRedeemsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoadingLocks: React.Dispatch<React.SetStateAction<boolean>>;
    setIsListLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setSitePolicies: React.Dispatch<React.SetStateAction<NewSitePolicyDto[]>>;
    setTotalSitePolicies: React.Dispatch<React.SetStateAction<number>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
    setIsCreateSitePolicyModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setSitePolicyActionTypes: React.Dispatch<React.SetStateAction<SitePolicyActionType[]>>;

    //________________Functions___________
    getSitePolicies: (payload: GetSitePoliciesInterface) => void;
    getLocks: () => void;
}

export const SitePolicyContext = React.createContext<SitePolicyContextInterface>({
    sitePolicies: [],
    isCreateSitePolicyModalVisible: false,
    searchInput: '',
    currentPage: 0,
    pageSize: 20,
    isListLoading: false,
    sitePolicyActionTypes: [],
    totalSitePolicies: 0,
    isTableLoading: false,
    selectedLockIds: [],
    selectedStatusIds: [],
    tags: [],
    locks: [],
    sortedInfo: { order: undefined, columnKey: undefined },
    orderBy: undefined,
    order: undefined,
    isFilterModalVisible: false,
    isControlModalVisible: false,
    isEditModalVisible: false,
    isViewRedeemsModalVisible: false,
    isLoadingLocks: false,
    operatorSwitch: false,
    expandedRowKeys: [],
    expandAll: false,
    selectedPolicyIdForViewRedeemers: '',

    setSelectedPolicyIdForViewRedeemers: () => {
        /* Nothing to do here*/
    },
    setExpandedRowKeys: () => {
        /* Nothing to do here*/
    },
    setExpandAll: () => {
        /* Nothing to do here*/
    },
    setOperatorSwitch: () => {
        /* Nothing to do here*/
    },
    setIsLoadingLocks: () => {
        /* Nothing to do here*/
    },
    setLocks: () => {
        /* Nothing to do here*/
    },
    setIsFilterModalVisible: () => {
        /* Nothing to do here*/
    },
    setIsViewRedeemsModalVisible: () => {
        /* Nothing to do here*/
    },
    setIsTableLoading: () => {
        /* Nothing to do here*/
    },
    setSelectedLockIds: () => {
        /* Nothing to do here*/
    },
    setSelectedStatusIds: () => {
        /* Nothing to do here*/
    },
    setTags: () => {
        /* Nothing to do here*/
    },
    setOrder: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setSortedInfo: () => {
        /* Nothing to do here*/
    },

    setTotalSitePolicies: () => {
        /* Nothing to do here*/
    },
    // setIsCreateKeyModalVisible: () => {
    //     /* Nothing to do here*/
    // },
    setIsControlModalVisible: () => {
        /* Nothing to do here*/
    },
    setIsEditModalVisible: () => {
        /* Nothing to do here*/
    },
    setIsListLoading: () => {
        /* Nothing to do here*/
    },
    setSitePolicies: () => {
        /* Nothing to do here*/
    },
    setSearchInput: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setIsCreateSitePolicyModalVisible: () => {
        /* Nothing to do here*/
    },
    setSitePolicyActionTypes: () => {
        /* Nothing to do here*/
    },
    //__________Functions__________
    getSitePolicies: () => {
        /* Nothing to do here*/
    },
    getLocks: () => {
        /* Nothing to do here*/
    },
});

export const useSitePoliciesContext = (): SitePolicyContextInterface => {
    const store = useContext(SitePolicyContext);
    return store;
};
