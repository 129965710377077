import {
    OperationActions,
    PaginatedOperationActionsResponse,
    OperationActionsListInput,
    OperationActionCommand,
} from 'services/operation-actions/operation-actions.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useAPIOperationActions = (): OperationActions => {
    const [session] = useLocalSession();
    const operationActionsList = async ({
        operationId,
    }: OperationActionsListInput): Promise<PaginatedOperationActionsResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/operations/${operationId}/actions`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const operationActionsCommand = async (
        operationId: string,
        actionId: string,
        command: OperationActionCommand,
    ): Promise<any> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/operations/${operationId}/actions/${actionId}/${command}`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        operationActionsList,
        operationActionsCommand,
    };
};
