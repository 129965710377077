import { useEffect, useState } from 'react';
import { CustomFieldsDashboardController } from 'fragments/custom-fields-dashboard/interfaces';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CustomField, SortCustomFieldsRequest } from 'services/custom-fields/custom-fields.service';
import { useCustomFieldsContext } from './context/custom-fields.context';
import { useAPICustomFields } from 'services/custom-fields/api-custom-fields.service';
import { MainError } from 'services/dtos/errors..dto';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import dayjs from 'dayjs';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPIMain } from 'services/main/api-main.service';
import { ROLES } from 'services/main/main.service';
import { useNavigate } from 'react-router-dom';

export const useCustomFieldsDashboardController = (): /* <--Dependency Injections  like services hooks */
CustomFieldsDashboardController => {
    const customFieldsService = useAPICustomFields();
    const mainService = useAPIMain();
    const { translate } = useTranslator();
    const messenger = useMessenger();
    const [session, , , ,] = useLocalSession();
    const { selectedSite } = session;
    const navigate = useNavigate();

    /* State */
    const [dataSource, setDataSource] = useState<CustomField[]>([]);
    const [deletingFieldId, setDeletingFieldId] = useState<string>('');
    const [isDeletingField, setIsDeletingField] = useState<boolean>(false);

    const {
        customFieldsList,
        isLoadingList,
        getCustomFieldsList,
        setIsAddFieldModalVisible,
        getCustomField,
        setIsEditFieldModalVisible,
    } = useCustomFieldsContext();

    useEffect(() => {
        setDataSource(customFieldsList);
    }, [customFieldsList]);

    useEffect(() => {
        if (session.token && selectedSite) {
            mainService.checkRole().then((res) => {
                if (res.role == ROLES.ROLE_SITE_ADMIN || res.role == ROLES.ROLE_SUPERADMIN) {
                    getCustomFieldsList();
                } else {
                    navigate('/rfidcards');
                }
            });
        }
    }, [selectedSite]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onAddButtonPressed = () => {
        setIsAddFieldModalVisible(true);
    };

    const onDeleteButtonPressed = (id: string) => {
        setDeletingFieldId(id);
        setIsDeletingField(true);
        customFieldsService
            .deleteCustomField(id)
            .then(() => {
                getCustomFieldsList();
            })
            .catch((err: MainError) => {
                if (err.code == 409 && err.msg == 'deleted') {
                    messenger.showErrorMessage({
                        key: translate({ key: 'custom-fields.error-delete-deleted' }),
                    });
                    getCustomFieldsList();
                } else if (err.code == 409 && err.msg == 'role') {
                    messenger.showErrorMessage({ key: translate({ key: 'rfid-card.role-error' }) });
                    navigate('/rfidcards');
                } else {
                    messenger.showErrorMessage({ key: translate({ key: 'custom-fields.error-delete"' }) });
                }
            })
            .finally(() => {
                setDeletingFieldId('');
                setIsDeletingField(false);
            });
    };

    const onEditButtonPressed = (id: string) => {
        setIsEditFieldModalVisible(true);
        getCustomField(id);
    };

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex((i) => i.id === active.id);
                const overIndex = previous.findIndex((i) => i.id === over?.id);
                const newOrder = arrayMove(previous, activeIndex, overIndex);
                const input: SortCustomFieldsRequest = {
                    prev_order: previous.map((x) => x.id),
                    new_order: newOrder.map((x) => x.id),
                };
                sortRows(input);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    };

    /* Private Methods */
    const sortRows = (input: SortCustomFieldsRequest) => {
        customFieldsService
            .sortCustomFields(input)
            .then(() => {
                console.log('');
            })
            .catch((err: MainError) => {
                if (err.code == 409) {
                    switch (err.msg) {
                        case 'locked':
                            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.locked-error' }) });
                            break;
                        case 'role':
                            messenger.showErrorMessage({ key: translate({ key: 'rfid-card.role-error' }) });
                            navigate('/rfidcards');
                            break;
                        case 'order_changed':
                            messenger.showErrorMessage({
                                key: translate({ key: 'custom-fields.error-drag-sort-order-changed' }),
                            });
                            break;
                        default:
                            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.error-drag-sort' }) });
                            break;
                    }
                } else {
                    messenger.showErrorMessage({ key: translate({ key: 'custom-fields.error-drag-sort' }) });
                }
                setDataSource(customFieldsList);
                getCustomFieldsList();
            });
    };

    const mapDtoToTableRow = (dto: CustomField): CustomField => {
        const viewModelObject = {
            ...dto,
            created_at: `${dayjs(dto.created_at).format('DD/MM/YYYY HH:mm')}  ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
            updated_at: `${dayjs(dto.updated_at).format('DD/MM/YYYY HH:mm')}  ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
        };
        return viewModelObject;
    };

    // Return state and events
    return {
        itemsViewModelTable: dataSource.map(mapDtoToTableRow),
        onDragEnd,
        isLoadingList,
        deletingFieldId,
        isDeletingField,
        onAddButtonPressed,
        onDeleteButtonPressed,
        onEditButtonPressed,
    };
};
