import React from 'react';
import 'fragments/main/main.scss';
import { MainFragmentProps } from 'fragments/main/interfaces';
import { Layout } from 'antd';
import { AppBarFragment } from 'fragments/app-bar/app-bar.fragment';
import { SidePanelFragment } from 'navigators/side-panel/side-panel.fragment';
import { AppRoutes } from 'fragments/main/routes/app-routes';
import MainProvider from 'fragments/context/main/main-provider.component';
import { SitesModalFragment } from 'fragments/sites-modal/sites-modal.fragment';
import SitesProvider from 'fragments/sites-modal/context/sites-provider.component';
import { useLocalSession } from 'auth/helpers/session.hooks';
const { Content, Footer } = Layout;

export const MainFragment: React.FC<MainFragmentProps> = (props) => {
    const [session] = useLocalSession();

    // Render
    return (
        <div className={'main'}>
            <MainProvider>
                <div>
                    <SitesProvider>
                        <Layout style={{ minHeight: '100vh' }}>
                            <AppBarFragment />
                            <Layout>
                                <SidePanelFragment />
                                <Layout>
                                    <Content className={'content-root'}>
                                        <AppRoutes />
                                        {session.user_profile?.role === 'superadmin' && <SitesModalFragment />}
                                    </Content>
                                    <Footer className={'footer-root'}>
                                        <a href="https://accefy.io" className={'footer-link'}>
                                            accefy.io
                                        </a>
                                        ©
                                    </Footer>
                                </Layout>
                            </Layout>
                        </Layout>
                    </SitesProvider>
                </div>
            </MainProvider>
        </div>
    );
};
