import React from 'react';
import 'fragments/passcodes/passcodes.scss';
import { PasscodesFragmentProps, PasscodeTableRow } from 'fragments/passcodes/interfaces';
import { usePasscodesController } from 'fragments/passcodes/passcodes.controller';
import { Button, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { FilterPasscodesModalFragment } from './fragments/filter-passcodes-modal/filter-passcodes-modal.fragment';
import { CreatePasscodeModalFragment } from 'fragments/common/create-passcode-modal/create-passcode-modal.fragment';
const { Text } = Typography;

export const PasscodesFragment: React.FC<PasscodesFragmentProps> = (props) => {
    const { useController = usePasscodesController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    // Render
    return (
        <div className={'passcodes'}>
            <Space style={{ marginBottom: '10px', justifyContent: 'flex-end' }}>
                <Button className={'button primary'} type="primary" onClick={controller.onOpenFilterModal}>
                    {translate({ key: 'general.filters' })}
                </Button>
                <Tooltip placement="top" title={controller.locks.length < 1 ? 'No hay cerraduras compatibles' : ''}>
                    <Button
                        className={controller.locks.length < 1 ? '' : 'button primary'}
                        type="primary"
                        onClick={controller.onOpenCreateModal}
                        disabled={controller.locks.length < 1}
                    >
                        {translate({ key: 'passcodes.generate' })}
                    </Button>
                </Tooltip>
            </Space>
            <Table
                columns={[
                    {
                        key: 'label',
                        title: translate({ key: 'label.label' }),
                        dataIndex: 'label',
                        sorter: !!controller.totalPasscodes,
                    },
                    {
                        key: 'code',
                        title: translate({ key: 'label.code' }),
                        dataIndex: 'code',
                        render: (code) => (
                            <Text copyable style={{ marginBottom: 0 }}>
                                {code}
                            </Text>
                        ),
                    },
                    {
                        key: 'type',
                        title: translate({ key: 'label.type' }),
                        dataIndex: 'type',
                        render: (type) => (
                            <Tag
                                color="default"
                                style={{
                                    fontWeight: 'bold',
                                    marginBottom: '4px',
                                }}
                            >
                                {type}
                            </Tag>
                        ),
                    },
                    {
                        key: 'lockName',
                        title: translate({ key: 'label.lock' }),
                        dataIndex: 'lock_name',
                        sorter: !!controller.totalPasscodes,
                    },
                    {
                        key: 'created_by_email',
                        title: translate({ key: 'label.authorizer' }),
                        dataIndex: 'created_by_email',
                    },
                    {
                        key: 'validity',
                        title: translate({ key: 'label.duration' }),
                        dataIndex: 'validity',
                        render: (validity) => (
                            <Tooltip title={validity.tooltip}>
                                <div style={{ width: 'fit-content' }}>
                                    {validity.from == validity.to ? (
                                        <Tag
                                            color="default"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '4px',
                                            }}
                                        >
                                            {`${validity.from} De ${validity.fromHour} a ${validity.toHour} hs (${validity.timeZone})`}
                                        </Tag>
                                    ) : (
                                        <>
                                            <Tag
                                                color="default"
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '4px',
                                                }}
                                            >
                                                {`Del: ${validity.from} ${validity.fromHour} hs (${validity.timeZone})`}
                                            </Tag>
                                            <Tag color="default" style={{ fontWeight: 'bold' }}>
                                                {`Al: ${validity.to} ${validity.toHour} hs (${validity.timeZone})`}
                                            </Tag>
                                        </>
                                    )}
                                </div>
                            </Tooltip>
                        ),
                    },
                    {
                        key: 'state',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'state',
                        sorter: !!controller.totalPasscodes,
                        render: (state) =>
                            state === 0 ? (
                                <Tag color="error" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                    {translate({ key: 'general.invalid' })}
                                </Tag>
                            ) : state === 1 ? (
                                <Tag color="success" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                    {translate({ key: 'general.valid' })}
                                </Tag>
                            ) : (
                                state === 2 && (
                                    <Tag color="default" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                        {translate({ key: 'general.inactive' })}
                                    </Tag>
                                )
                            ),
                    },
                ]}
                dataSource={controller.itemsViewModelTable}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalPasscodes,
                    pageSizeOptions: ['20', '30', '40'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isTableLoading}
                rowKey={(itemsViewModelTable: PasscodeTableRow) => itemsViewModelTable.id}
            />
            <CreatePasscodeModalFragment
                onClose={controller.onCloseCreateModal}
                onFinish={controller.onFinishCreatePasscode}
                isModalVisible={controller.isVisibleCreatePasscodeModal}
            />
            <FilterPasscodesModalFragment />
        </div>
    );
};
