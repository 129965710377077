import React, { useContext } from 'react';

export interface MainContextInterface {
    isAdminSitesModalVisible: boolean;

    setIsAdminSitesModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainContext = React.createContext<MainContextInterface>({
    isAdminSitesModalVisible: false,

    setIsAdminSitesModalVisible: () => {
        /* Nothing to do here*/
    },
});

export const useMainContext = (): MainContextInterface => {
    const store = useContext(MainContext);
    return store;
};
