import React from 'react';
import 'fragments/download-reports/download-reports.scss';
import { DownloadReportsFragmentProps } from 'fragments/download-reports/interfaces';
import { useDownloadReportsController } from 'fragments/download-reports/download-reports.controller';

import { ReportFilesListFragment } from './fragments/report-files-list/report-files-list.fragment';
import { DownloadReportsOldViewFragment } from './fragments/download-reports-old-view/download-reports-old-view.fragment';

export const DownloadReportsFragment: React.FC<DownloadReportsFragmentProps> = (props) => {
    const { useController = useDownloadReportsController } = props;
    const controller = useController();

    // Render
    return controller.toggleDownloadView ? <ReportFilesListFragment /> : <DownloadReportsOldViewFragment />;
};
