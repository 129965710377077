import React from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { Navigate, Routes } from 'react-router-dom';
import { LoginFragment } from 'auth/fragments/login/login.fragment';
import { ResetPasswordFragment } from 'auth/fragments/reset-password/reset-password.fragment';
import FlexLoader from 'components/flex-loader/flex-loader.component';

export type RoutePrivateProps = RouteProps;

const AuthRoute: React.FC<RoutePrivateProps> = (props) => {
    const [session, , , isLoading] = useLocalSession();
    const location = useLocation();
    return (
        <div>
            {isLoading ? (
                <div style={{ display: 'flex', height: '100vh' }}>
                    <FlexLoader />
                </div>
            ) : (
                <Routes>
                    {!session.token && <Route path={'/login'} element={<LoginFragment />} />}
                    <Route path={'/reset-password/:token'} element={<ResetPasswordFragment />} />
                    <Route
                        path="*"
                        element={
                            !isLoading && session.token ? (
                                props.children
                            ) : (
                                <Navigate to={'/login'} state={{ from: location }} />
                            )
                        }
                    />
                </Routes>
            )}
        </div>
    );
};

export default AuthRoute;
