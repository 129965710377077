import React, { useState } from 'react';
import { MassiveRfidCardsFormContext } from './massive-rfid-cards-form.context';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { CreateRfidCardInput, CreateRfidCardResponse, SiteRfidCardDto } from 'services/rfidcards/rfidcards.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPISee } from 'services/sse/api-sse.service';
import { CreationCardRow } from '../fragments/massive-load-modal/interfaces';

type Props = {
    children?: React.ReactNode;
};

const MassiveRfidCardsFormProvider: React.FC<Props> = (props) => {
    const { translate } = useTranslator();
    const messenger = useMessenger();

    const [isCreationFormLoading, setisCreationFormLoading] = useState<boolean>(false);
    const [isRetryCreationLoading, setIsRetryCreationLoading] = useState<boolean>(false);
    const [isMassiveLoadModalVisible, setIsMassiveLoadModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [isUserReassignmentModalVisible, setIsUserReassignmentModalVisible] = useState<boolean>(false);
    const [selectedCard, setSelectedCard] = useState<SiteRfidCardDto>();
    const [creationEvent, setCreationEvent] = useState<EventSource>();
    const [eventId, setEventId] = useState<string | undefined>();
    const [rows, setRows] = useState<CreationCardRow[]>([]);
    const [retryInputValues, setRetryInputValues] = useState<{ nbf_date: string; exp_date: string; type_id: number }>();

    // Functions
    const rfidCardsService = useAPIRfidcard();
    const sseService = useAPISee();

    /*public methods*/
    const sendAndSubscribe = (createInput: CreateRfidCardInput) => {
        const { nbf_date, exp_date, type_id } = createInput;
        const input = !eventId ? createInput : { ...createInput, prev_event_id: eventId };
        setRetryInputValues({ nbf_date, exp_date, type_id });
        rfidCardsService
            .createRfidCard(input)
            .then((response: CreateRfidCardResponse) => {
                const responseToRows: CreationCardRow[] = response.sub_event_stream.map((r) => {
                    return {
                        ...r,
                        status: 1,
                        message: '',
                    };
                });
                setRows(responseToRows);
                setIsMassiveLoadModalVisible(true);
                setEventId(response.event_id);
                const eventSource = sseService.subscribeEvent(response.event_id);
                setCreationEvent(eventSource);
            })
            .catch((err) => {
                messenger.showErrorMessage({ key: translate({ key: 'rfid-card.error-cannot-create' }) });
                resetCreationResultStates();
            })
            .finally(() => {
                setisCreationFormLoading(false);
                setIsRetryCreationLoading(false);
            });
    };

    const resetCreationResultStates = () => {
        setEventId(undefined);
        setRetryInputValues(undefined);
        setIsMassiveLoadModalVisible(false);
        setRows([]);
    };

    return (
        <MassiveRfidCardsFormContext.Provider
            value={{
                rows,
                setRows,
                retryInputValues,
                isMassiveLoadModalVisible,
                isEditModalVisible,
                setIsEditModalVisible,
                isUserReassignmentModalVisible,
                setIsUserReassignmentModalVisible,
                selectedCard,
                setSelectedCard,
                setIsMassiveLoadModalVisible,
                sendAndSubscribe,
                resetCreationResultStates,
                creationEvent,
                setCreationEvent,
                isCreationFormLoading,
                setisCreationFormLoading,
                isRetryCreationLoading,
                setIsRetryCreationLoading,
            }}
        >
            {props.children}
        </MassiveRfidCardsFormContext.Provider>
    );
};

export default MassiveRfidCardsFormProvider;
