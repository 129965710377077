import React from 'react';
import 'fragments/download-reports/fragments/report-files-list/report-files-list.scss';
import {
    ReportFileTableRow,
    ReportFilesListFragmentProps,
} from 'fragments/download-reports/fragments/report-files-list/interfaces';
import { useReportFilesListController } from 'fragments/download-reports/fragments/report-files-list/report-files-list.controller';
import { Button, Row, Space, Table, Tag } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    DownloadOutlined,
    PlusOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ExpandableRowReportFileDetailFragment } from '../expandable-row-report-file-detail/expandable-row-report-file-detail.fragment';
import { CreateReportFileModalFragment } from '../create-report-file-modal/create-report-file-modal.fragment';

export const ReportFilesListFragment: React.FC<ReportFilesListFragmentProps> = (props) => {
    const { useController = useReportFilesListController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const getStatusIcon = (status: number) => {
        switch (status) {
            case 1:
                return <ClockCircleOutlined />;
            case 2:
                return <SyncOutlined spin />;
            case 3:
                return <CheckCircleOutlined />;
            case 4:
                return <CloseCircleOutlined />;
            case 5:
                return <ClockCircleOutlined />;

            default:
                return <></>;
        }
    };

    // Render
    return (
        <div className={'report-files-list'}>
            {controller.isSuperAdmin && (
                <div style={{ backgroundColor: '#fff', marginBottom: '1.5rem', padding: '1rem', width: '100%' }}>
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: '#f2f2f2',
                            padding: '10px',
                        }}
                    >
                        <Text strong>Notas: </Text>

                        <Text>Bienvenidos a Accefy!</Text>
                    </div>
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '20px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <CreateReportFileModalFragment />
                </div>
                <Space>
                    <Button
                        className={'button'}
                        type="default"
                        style={{ justifySelf: 'flex-end', padding: '3px' }}
                        onClick={controller.onOpenCreateReportFileModal}
                        icon={<PlusOutlined />}
                    />
                </Space>
            </div>

            <Table
                columns={[
                    {
                        key: 'createdAt',
                        title: translate({ key: 'label.created-at' }),
                        dataIndex: 'createdAt',
                    },
                    {
                        key: 'expirationDate',
                        title: translate({ key: 'access-reports.report-files.expire-date-header' }),
                        dataIndex: 'expirationDate',
                    },
                    {
                        key: 'createdByName',
                        title: translate({ key: 'label.created-by' }),
                        dataIndex: 'createdByName',
                        render: (createdByName) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {createdByName}
                            </div>
                        ),
                    },
                    {
                        key: 'dateRange',
                        title: translate({ key: 'label.date-range' }),
                        dataIndex: 'dateRange',
                        render: (dateRange) => (
                            <span>
                                {dateRange.from == dateRange.to ? (
                                    <Tag
                                        color="default"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '4px',
                                        }}
                                    >
                                        {`${dateRange.from}`}
                                    </Tag>
                                ) : (
                                    <>
                                        <Tag
                                            color="default"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '4px',
                                            }}
                                        >
                                            {`Del: ${dateRange.from}`}
                                        </Tag>
                                        <Tag color="default" style={{ fontWeight: 'bold' }}>
                                            {`Al:  ${dateRange.to}`}
                                        </Tag>
                                    </>
                                )}
                            </span>
                        ),
                    },
                    {
                        key: 'state',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'state',
                        render: (state) => (
                            <Tag color={state.color} icon={getStatusIcon(state.id)} style={{ fontWeight: 'bold' }}>
                                {state.label}
                            </Tag>
                        ),
                    },
                    {
                        key: 'action',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id, state }) => (
                            <div style={{ display: 'flex', gap: '.5rem' }}>
                                {state.id == 3 && (
                                    <Button
                                        disabled={controller.isLoadingDownload}
                                        loading={controller.isLoadingDownload}
                                        icon={<DownloadOutlined />}
                                        onClick={() => controller.onDownloadButtonPressed(id)}
                                    />
                                )}
                            </div>
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModel}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalReportFiles,
                    pageSizeOptions: ['20', '30', '40'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isLoadingTable}
                rowKey={(itemsViewModel: ReportFileTableRow) => itemsViewModel.id}
                expandable={{
                    expandedRowRender: (reportFile) => (
                        <ExpandableRowReportFileDetailFragment reportFileId={reportFile.id} />
                    ),
                }}
            />
        </div>
    );
};
