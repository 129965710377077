import React from 'react';
import { Session } from 'auth/helpers/session';

export interface SessionContextInterface {
    isLoading: boolean;
    session: Session;
    setSession: (session: Session) => void;
}

export const SessionContext = React.createContext<SessionContextInterface>({
    isLoading: true,
    session: {},
    setSession: () => {
        /* Nothing to do here*/
    },
});
