import React from 'react';
import 'navigators/side-panel/side-panel.scss';
import { Button, Col, Divider, Layout, Select, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useSidePanelController } from 'navigators/side-panel/side-panel.controller';
import { SidePanelFragmentProps } from 'navigators/side-panel/interfaces';
import { NavigationMenu } from 'navigators/navigation/navigation';
import { SettingFilled } from '@ant-design/icons';
import { useSitesContext } from 'fragments/sites-modal/context/sites.context';

const { Text } = Typography;
const { Sider } = Layout;
const { Option } = Select;

export const SidePanelFragment: React.FC<SidePanelFragmentProps> = (props) => {
    const { useController = useSidePanelController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { sites } = useSitesContext();
    const [session] = useLocalSession();

    const optionSites = sites.map((site) => (
        <Option key={site.id} value={site.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {site.name}
            </div>
        </Option>
    ));

    // Render
    return (
        <div className={'side-panel'}>
            <Sider collapsible collapsed={controller.isCollapsed} onCollapse={controller.onCollapseButtonPressed}>
                <Divider plain>
                    <Text strong> {translate({ key: 'nav.site-select' })}</Text>
                </Divider>
                <Col xs={24} id="select-site-side-bar" style={{ margin: '1rem' }}>
                    <Select
                        showSearch
                        filterOption={(inputValue, option) => {
                            const opt = sites.find((item) => item.id == option?.value);
                            return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                        style={{ width: '100%' }}
                        getPopupContainer={() => document.getElementById(`select-site-side-bar`) as HTMLElement}
                        placeholder={translate({ key: 'form.placeholder-sites' })}
                        value={controller.selectedSite.id}
                        onChange={controller.onSiteSelect}
                        dropdownRender={(menu) => (
                            <div>
                                {menu}
                                {session.user_profile?.role === 'superadmin' && (
                                    <div>
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 0 }}>
                                            <Button
                                                className={'admin-sites-btn'}
                                                type="text"
                                                onClick={controller.onAdminSitesButtonPressed}
                                            >
                                                <SettingFilled /> {translate({ key: 'special.admin-sites' })}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    >
                        {optionSites}
                    </Select>
                </Col>
                <NavigationMenu translate={translate} controller={controller} />
            </Sider>
        </div>
    );
};
