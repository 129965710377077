import React from 'react';
import 'fragments/massive-rfid-cards-form/fragments/massive-load-modal/massive-load-modal.scss';
import { MassiveLoadModalFragmentProps } from 'fragments/massive-rfid-cards-form/fragments/massive-load-modal/interfaces';
import { useMassiveLoadModalController } from 'fragments/massive-rfid-cards-form/fragments/massive-load-modal/massive-load-modal.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    LoadingOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
    CheckCircleOutlined,
    QuestionCircleOutlined,
    InfoCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, Table, Tooltip, Typography } from 'antd';
const { Title, Text } = Typography;
const { info } = Modal;

export const MassiveLoadModalFragment: React.FC<MassiveLoadModalFragmentProps> = (props) => {
    const { useController = useMassiveLoadModalController, resetForm } = props;
    const controller = useController(resetForm);
    const { translate } = useTranslator();

    const showRetryInfoModal = () => {
        info({
            title: translate({ key: 'general.try-again' }),
            content: translate({ key: 'rfid-card.try-again-description' }),
        });
    };
    const showErrorInfoModal = (err: string) => {
        info({
            title: 'Error',
            content: err,
        });
    };

    // Render
    return (
        <Modal
            open={controller.isMassiveLoadModalVisible}
            destroyOnClose={true}
            width={800}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'rfid-card.result-modal-title' })}
                </Title>
            }
            className={'massive-cards-load-modal'}
            closable={false}
            centered
            footer={
                <footer className={'massive-cards-load-modal-footer'}>
                    <Button
                        key="close"
                        type={'default'}
                        disabled={controller.isLoading}
                        onClick={controller.onCloseModalPressed}
                    >
                        {translate({ key: 'general.close' })}
                    </Button>
                    <div className={'massive-cards-load-modal-footer-retry'}>
                        {controller.statusCount.warning > 0 && !controller.isLoading ? (
                            <Space>
                                <Button
                                    type={'primary'}
                                    disabled={controller.isLoading}
                                    onClick={controller.onTryAgainPressed}
                                    loading={controller.isRetryCreationLoading}
                                >
                                    {`${translate({ key: 'general.try-again' })} (${controller.retryRelations.length})`}
                                </Button>
                                <Button
                                    type={'link'}
                                    icon={<InfoCircleOutlined />}
                                    style={{ fontSize: '1rem' }}
                                    onClick={showRetryInfoModal}
                                ></Button>
                            </Space>
                        ) : null}
                    </div>
                </footer>
            }
        >
            <div>
                <Row justify="center">
                    {controller.statusCount.unknown > 0 && (
                        <Col
                            span={controller.statusCount.unknown > 0 ? 6 : 8}
                            className={'massive-cards-load-modal-scrollable-result-count'}
                        >
                            <Text strong type="secondary">
                                {controller.statusCount.unknown}/{controller.rows.length}
                            </Text>
                            <Text type="secondary">{translate({ key: 'general.unknown' })}s</Text>
                        </Col>
                    )}
                    <Col
                        span={controller.statusCount.unknown > 0 ? 6 : 8}
                        className={'massive-cards-load-modal-scrollable-result-count'}
                    >
                        <Text strong type="success">
                            {controller.statusCount.success}/{controller.rows.length}
                        </Text>
                        <Text type="success">{translate({ key: 'rfid-card.results.success' })}</Text>
                    </Col>
                    <Col
                        span={controller.statusCount.unknown > 0 ? 6 : 8}
                        className={'massive-cards-load-modal-scrollable-result-count'}
                    >
                        <Text strong type="warning">
                            {controller.statusCount.warning}/{controller.rows.length}
                        </Text>
                        <Text type="warning">{translate({ key: 'rfid-card.results.warning' })}</Text>
                    </Col>
                    <Col
                        span={controller.statusCount.unknown > 0 ? 6 : 8}
                        className={'massive-cards-load-modal-scrollable-result-count'}
                        color="red"
                    >
                        <Text strong type="danger">
                            {controller.statusCount.failed}/{controller.rows.length}
                        </Text>
                        <Text type="danger">{translate({ key: 'rfid-card.results.error' })}</Text>
                    </Col>
                </Row>
            </div>
            <div className={'massive-cards-load-modal-scrollable'}>
                <Table
                    loading={controller.isRetryCreationLoading}
                    columns={[
                        {
                            key: 'label',
                            title: translate({ key: 'label.label' }),
                            dataIndex: 'label',
                        },
                        {
                            key: 'card_number',
                            title: translate({ key: 'label.card-number' }),
                            dataIndex: 'card_number',
                        },
                        {
                            key: 'device_name',
                            title: translate({ key: 'label.lock' }),
                            dataIndex: 'device_name',
                        },
                        {
                            key: 'status',
                            title: translate({ key: 'label.state' }),
                            dataIndex: 'status',
                            render: (status, { message }) => {
                                switch (status) {
                                    case 0:
                                        return (
                                            <Tooltip title={translate({ key: 'rfid-card.tooltip.unknown' })}>
                                                <ClockCircleOutlined style={{ color: '#262626' }} />
                                            </Tooltip>
                                        );
                                    case 1:
                                        return (
                                            <Tooltip title={translate({ key: 'rfid-card.tooltip.loading' })}>
                                                <LoadingOutlined style={{ color: 'blue' }} />
                                            </Tooltip>
                                        );
                                    case 2:
                                        return (
                                            <Tooltip title={translate({ key: 'rfid-card.tooltip.success' })}>
                                                <CheckCircleOutlined style={{ color: 'green' }} />
                                            </Tooltip>
                                        );
                                    case 3:
                                        return (
                                            <>
                                                <Tooltip title={translate({ key: 'rfid-card.tooltip.error' })}>
                                                    <CloseCircleOutlined style={{ color: 'red' }} />
                                                </Tooltip>
                                                <Button
                                                    type={'link'}
                                                    icon={<InfoCircleOutlined />}
                                                    style={{ fontSize: '.75rem' }}
                                                    onClick={() => {
                                                        showErrorInfoModal(message || 'unknown');
                                                    }}
                                                ></Button>
                                            </>
                                        );
                                    case 4:
                                        return (
                                            <>
                                                <Tooltip title={translate({ key: 'rfid-card.tooltip.error' })}>
                                                    <ExclamationCircleOutlined style={{ color: '#FAAD14' }} />
                                                </Tooltip>
                                                <Button
                                                    type={'link'}
                                                    icon={<InfoCircleOutlined />}
                                                    style={{ fontSize: '.75rem' }}
                                                    onClick={() => {
                                                        showErrorInfoModal(message || 'unknown');
                                                    }}
                                                ></Button>
                                            </>
                                        );
                                    default:
                                        return (
                                            <Tooltip title={translate({ key: 'rfid-card.tooltip.stand-by' })}>
                                                <QuestionCircleOutlined style={{ color: '#666' }} />
                                            </Tooltip>
                                        );
                                }
                            },
                        },
                    ]}
                    pagination={false}
                    dataSource={controller.rows}
                />
            </div>
        </Modal>
    );
};
