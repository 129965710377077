import {
    GetAccessRfidCardsInterface,
    SiteCardsContext,
} from 'fragments/site-cards-dashboard/context/site-cards.context';
// import { CreateKeySummaryDto } from '../fragments/create-key-summary/interfaces';
import React, { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { ITag, SorterInfo } from 'global/interfaces';
import { useAPILocks } from 'services/locks/api-locks.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { LockDto } from 'services/locks/locks.service';
import {
    FilterRfidCardsCustomFieldInterface,
    SearchRfidCardsInterface,
    SiteRfidCardDetailResponse,
    SiteRfidCardDto,
} from 'services/rfidcards/rfidcards.service';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { DeletionCardRow } from '../fragments/delete-site-card-progress-modal/interfaces';
import { useAPISee } from 'services/sse/api-sse.service';
import { MainError } from 'services/dtos/errors..dto';
import { FilterCustomField } from '../fragments/site-cards-filter-modal/interfaces';
import dayjs from 'dayjs';

type Props = {
    children?: React.ReactNode;
};

const SiteCardsProvider: React.FC<Props> = (props) => {
    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
    const [editSiteCardDetail, setEditSiteCardDetail] = useState<SiteRfidCardDetailResponse | undefined>(undefined);
    const [isUserReassignmentModalVisible, setIsUserReassignmentModalVisible] = useState<boolean>(false);
    // const [creationSummary, setCreationSummary] = useState<CreateKeySummaryDto[]>([]);
    const [rfidCards, setRfidCards] = useState<SiteRfidCardDto[]>([]);
    const [selectedCard, setSelectedCard] = useState<SiteRfidCardDto>();
    const [label, setLabel] = useState<string[]>([]);
    const [cardNumber, setCardNumber] = useState<string[]>([]);
    const [authorizer, setAuthorizer] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [totalRfidCards, setTotalRfidCards] = useState<number>();
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>('ascend');
    const [orderBy, setOrderBy] = useState<string | undefined>('label');
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [selectedLockIds, setSelectedLockIds] = useState<string[]>([]);
    const [selectedRfidCardStatusOptions, setSelectedRfidCardStatusOptions] = useState<number[]>([1, 4]);
    const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
        from: '',
        to: '',
    });
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: 'ascend',
        columnKey: 'label',
    });

    const [locks, setLocks] = useState<LockDto[]>([]);
    const [isLoadingLocks, setIsLoadingLocks] = useState(false);
    const [tags, setTags] = useState<ITag[]>([]);

    const [isVisibleDeleteCardProgressModal, setIsVisibleDeleteCardProgressModal] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [deleteCard, setDeleteCard] = useState<{ id: string; cardNumber: string } | undefined>();
    const [deletionEventSource, setDeletionEventSource] = useState<EventSource | undefined>();
    const [deletionModalRows, setDeletionModalRows] = useState<DeletionCardRow[]>([]);
    const [selectedCustomFields, setSelectedCustomFields] = useState<FilterCustomField[]>([]);

    // Functions
    const rfidCardService = useAPIRfidcard();
    const messenger = useMessenger();
    const sseService = useAPISee();
    const locksService = useAPILocks();
    const [, , , ,] = useLocalSession();

    const parseCustomFieldsToRequest = (fields: FilterCustomField[]): FilterRfidCardsCustomFieldInterface[] => {
        return fields
            .map((f) => {
                return {
                    custom_field_id: f.id,
                    type_id: f.type_id,
                    values:
                        f.type_id == 2
                            ? [
                                  `${dayjs(f.value[0]).format('YYYY-MM-DD')}T00:00:00Z`,
                                  `${dayjs(f.value[1]).format('YYYY-MM-DD')}T23:59:59Z`,
                              ]
                            : f.type_id == 3
                            ? f.value.map((v: any) => v.value || v)
                            : f.value,
                };
            })
            .filter((f) => !!f.values.length);
    };

    const getRfidCards = (payload: GetAccessRfidCardsInterface) => {
        setIsLoadingTable(true);

        let { pagination, orderTable } = payload;
        if (pagination) {
            pagination.page != currentPage && setCurrentPage(pagination.page);
            pagination.pageSize != pageSize && setPageSize(pagination.pageSize);
        } else {
            pagination = { page: currentPage, pageSize };
        }
        if (orderTable) {
            orderTable.order !== order && setOrder(orderTable.order);
            orderTable.orderBy !== orderBy && setOrderBy(orderTable.orderBy);
            if (!orderTable.order && !orderTable.orderBy) {
                setSortedInfo({
                    order: undefined,
                    columnKey: undefined,
                });
            }
        } else {
            orderTable = { order, orderBy };
        }
        const searchInput: SearchRfidCardsInterface = payload.search
            ? { ...payload.search, custom_fields: parseCustomFieldsToRequest(payload.search.customFields) }
            : {
                  since: dateRange.from || '',
                  until: dateRange.to || '',
                  card: cardNumber,
                  label: label,
                  devices: selectedLockIds,
                  authorizer: authorizer,
                  status: selectedRfidCardStatusOptions,
                  custom_fields: parseCustomFieldsToRequest(selectedCustomFields),
              };

        rfidCardService
            .listSiteRfidCards({ pagination, orderTable, search: searchInput })
            .then((data) => {
                setRfidCards(data.rfid_cards);
                setTotalRfidCards(data.total);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'cards.list-error' });
            })
            .finally(() => {
                setIsLoadingTable(false);
            });
    };

    const getLocks = () => {
        setIsLoadingLocks(true);
        locksService
            .listDevicesRfidCardsListFilter()
            .then((data) => {
                setLocks(data);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingLocks(false);
            });
    };

    const getSiteCardDetail = (id: string) => {
        setIsLoadingDetail(true);
        rfidCardService
            .getSiteRfidCardDetail(id)
            .then((data) => {
                setEditSiteCardDetail(data);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'rfid-card.get-detail-error' });
            })
            .finally(() => {
                setIsLoadingDetail(false);
            });
    };

    const deleteSiteCard = (id: string, cardNumber: string) => {
        setIsLoadingDelete(true);
        setDeleteCard({ id, cardNumber });
        rfidCardService
            .deleteSiteRfidCard(id)
            .then((data) => {
                setIsVisibleDeleteCardProgressModal(true);
                setDeletionModalRows(data.sub_event_streams);
                setDeletionEventSource(sseService.subscribeEvent(data.event_id));
            })
            .catch((err: MainError) => {
                if (err.code == 409) {
                    switch (err.msg) {
                        case 'role':
                            messenger.showErrorMessage({ key: 'rfid-card.role-error' });
                            break;
                        case 'locked':
                            messenger.showErrorMessage({ key: 'rfid-card.locked-error' });
                            break;
                        default:
                            messenger.showErrorMessage({ key: 'rfid-card.delete-error' });
                    }
                } else {
                    messenger.showErrorMessage({ key: 'rfid-card.delete-error' });
                }
                setDeleteCard(undefined);
                setDeletionEventSource(undefined);
                setIsVisibleDeleteCardProgressModal(false);
                getRfidCards({});
            })
            .finally(() => {
                setIsLoadingDelete(false);
            });
    };

    return (
        <SiteCardsContext.Provider
            value={{
                isLoadingDelete,
                setIsLoadingDelete,
                editSiteCardDetail,
                setEditSiteCardDetail,
                isVisibleDeleteCardProgressModal,
                setIsVisibleDeleteCardProgressModal,
                deletionModalRows,
                setDeletionModalRows,
                deletionEventSource,
                setDeletionEventSource,
                deleteCard,
                setDeleteCard,
                sortedInfo,
                setSortedInfo,
                selectedRfidCardStatusOptions,
                setSelectedRfidCardStatusOptions,
                authorizer,
                setAuthorizer,
                selectedLockIds,
                setSelectedLockIds,
                dateRange,
                setDateRange,
                isFilterModalVisible,
                setIsFilterModalVisible,
                isEditModalVisible,
                setIsEditModalVisible,
                isLoadingDetail,
                setIsLoadingDetail,
                isUserReassignmentModalVisible,
                setIsUserReassignmentModalVisible,
                rfidCards,
                setRfidCards,
                totalRfidCards,
                setTotalRfidCards,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize,
                label,
                setLabel,
                cardNumber,
                setCardNumber,
                isLoadingTable,
                setIsLoadingTable,
                order,
                setOrder,
                orderBy,
                setOrderBy,
                locks,
                isLoadingLocks,
                selectedCard,
                setSelectedCard,
                selectedCustomFields,
                setSelectedCustomFields,
                //Functions
                deleteSiteCard,
                getRfidCards,
                getLocks,
                tags,
                setTags,
                getSiteCardDetail,
            }}
        >
            {props.children}
        </SiteCardsContext.Provider>
    );
};

export default SiteCardsProvider;
