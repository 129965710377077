import { useEffect, useState } from 'react';
import {
    DownloadReportsOldViewController,
    OptionMonth,
} from 'fragments/download-reports/fragments/download-reports-old-view/interfaces';
import { useForm } from 'antd/lib/form/Form';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPIAccessReports } from 'services/access-reports/api-access-reports.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { DownloadAccessReportDto, UserListDto } from 'services/access-reports/access-reports.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import dayjs from 'dayjs';
import { RadioChangeEvent } from 'antd';

export const useDownloadReportsOldViewController = (
    accessReportService = useAPIAccessReports(),
    messenger = useMessenger(),
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
DownloadReportsOldViewController => {
    /* State */
    const [users, setUsers] = useState<UserListDto[]>([]);
    const [optionMonth, setOptionMonth] = useState<OptionMonth[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<OptionMonth>({ label: '', since: '', until: '' });
    const [selectedUser, setSelectedUser] = useState<string>('');
    const [downloadFilename, setDownloadFilename] = useState<string>('');
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
    const [downloadData, setDownloadData] = useState<DownloadAccessReportDto[]>([]);
    const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const monthOfYear: { key: number; label: string }[] = [
        { key: 0, label: translate({ key: 'general.january' }) },
        { key: 1, label: translate({ key: 'general.february' }) },
        { key: 2, label: translate({ key: 'general.march' }) },
        { key: 3, label: translate({ key: 'general.april' }) },
        { key: 4, label: translate({ key: 'general.may' }) },
        { key: 5, label: translate({ key: 'general.june' }) },
        { key: 6, label: translate({ key: 'general.july' }) },
        { key: 7, label: translate({ key: 'general.august' }) },
        { key: 8, label: translate({ key: 'general.september' }) },
        { key: 9, label: translate({ key: 'general.october' }) },
        { key: 10, label: translate({ key: 'general.november' }) },
        { key: 11, label: translate({ key: 'general.december' }) },
        { key: -1, label: translate({ key: 'general.december' }) },
        { key: -2, label: translate({ key: 'general.november' }) },
        { key: -3, label: translate({ key: 'general.october' }) },
        { key: -4, label: translate({ key: 'general.september' }) },
    ];

    const downloadDataHeaders = [
        { label: translate({ key: 'label.user' }), key: 'user_full_name' },
        { label: translate({ key: 'label.email' }), key: 'user_email' },
        { label: translate({ key: 'label.lock' }), key: 'lock' },
        { label: translate({ key: 'label.date' }), key: 'datetime' },
    ];

    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            getUsers();
        }
        getMonthOptions();
        setShowDownloadButton(false);
        form.resetFields();
    }, [selectedSite]);

    /* View Events */
    const onSubmitButtonPressed = () => {
        setDownloadFilename(`${dayjs().format('YYYYMMDDHHmmss')}_reportes_accefy.csv`);
        downloadAccessReports(selectedUser, selectedMonth.since, selectedMonth.until);
    };
    const onMonthSelect = ({ target: { value } }: RadioChangeEvent) => {
        setSelectedMonth(optionMonth[value]);
        setShowDownloadButton(false);
    };
    const onUserSelect = (value: string) => {
        setShowDownloadButton(false);
        setSelectedUser(value);
    };

    /* Private Methods */

    const getMonthOptions = () => {
        const currentMonth = dayjs().month();
        const options: OptionMonth[] = [];
        for (let i = 0; i < 3; i++) {
            options.unshift({
                label: monthOfYear.find((month) => month.key === currentMonth - i)?.label,
                since: dayjs()
                    .month(currentMonth - i)
                    .startOf('M')
                    .format('YYYY-MM-DD'),
                until: dayjs()
                    .month(currentMonth - i)
                    .endOf('M')
                    .format('YYYY-MM-DD'),
            });
        }
        setOptionMonth(options);
    };
    const getUsers = () => {
        setIsLoading(true);
        accessReportService
            .listUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.get-users-error' });
                console.log('get-users-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const downloadAccessReports = (userId: string, since: string, until: string) => {
        setIsLoadingDownload(true);
        accessReportService
            .downloadAccessReports(userId, since, until)
            .then((data) => {
                data = data.map((report) => {
                    return {
                        ...report,
                        datetime: `${report.datetime.substring(0, 10)} ${report.datetime.substring(11, 16)} (${
                            selectedSite?.time_zone
                        })`,
                    };
                });
                setDownloadData(data);
                setShowDownloadButton(true);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.generate-report-error' });
                console.log('generate-report-error', error);
            })
            .finally(() => {
                setIsLoadingDownload(false);
            });
    };

    // Return state and events
    return {
        form,
        users,
        isLoading,
        optionMonth,
        selectedUser,
        monthOfYear,
        downloadData,
        isLoadingDownload,
        downloadDataHeaders,
        downloadFilename,
        showDownloadButton,
        onSubmitButtonPressed,
        onMonthSelect,
        onUserSelect,
    };
};
