import { GetAccessKeysInterface, KeyContext } from 'fragments/keys-dashboard/context/keys.context';
// import { CreateKeySummaryDto } from '../fragments/create-key-summary/interfaces';
import React, { useEffect, useState } from 'react';
import { KeyDto } from 'services/keys/keys.service';
import { SorterResult } from 'antd/lib/table/interface';
import { ITag, SorterInfo } from 'global/interfaces';
import { useAPIKeys } from 'services/keys/api-keys.service';
import { useAPILocks } from 'services/locks/api-locks.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { LockDto } from 'services/locks/locks.service';
import { useSearchParams } from 'react-router-dom';

type Props = {
    children?: React.ReactNode;
};

const KeysProvider: React.FC<Props> = (props) => {
    const [searchParams] = useSearchParams();
    const l = searchParams.get('l') || '';

    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    // const [creationSummary, setCreationSummary] = useState<CreateKeySummaryDto[]>([]);
    const [keys, setKeys] = useState<KeyDto[]>([]);
    const [assignedTo, setAssignedTo] = useState<string[]>([]);
    const [authorizer, setAuthorizer] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalKeys, setTotalKeys] = useState<number>();
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>(undefined);
    const [orderBy, setOrderBy] = useState<string | undefined>(undefined);
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [selectedLockIds, setSelectedLockIds] = useState<string[]>(l.length ? [l] : []);
    const [selectedKeyStatusOptions, setSelectedKeyStatusOptions] = useState<number[]>([]);
    const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
        from: '',
        to: '',
    });
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });

    const [locks, setLocks] = useState<LockDto[]>([]);
    const [isLoadingLocks, setIsLoadingLocks] = useState(false);
    const [tags, setTags] = useState<ITag[]>([]);

    // Functions
    const keyService = useAPIKeys();
    const messenger = useMessenger();
    const locksService = useAPILocks();

    useEffect(() => {
        if (l.length) setSelectedLockIds([l]);
    }, []);

    const getKeys = (payload: GetAccessKeysInterface) => {
        let { pagination, orderTable, search } = payload;
        if (pagination) {
            pagination.page != currentPage && setCurrentPage(pagination.page);
            pagination.pageSize != pageSize && setPageSize(pagination.pageSize);
        } else {
            pagination = { page: currentPage, pageSize };
        }
        if (orderTable) {
            orderTable.order !== order && setOrder(orderTable.order);
            orderTable.orderBy !== orderBy && setOrderBy(orderTable.orderBy);
            if (!orderTable.order && !orderTable.orderBy) {
                setSortedInfo({
                    order: undefined,
                    columnKey: undefined,
                });
            }
        } else {
            orderTable = { order, orderBy };
        }

        if (!search) {
            search = {
                locks: [],
                since: '',
                until: '',
                assignedTo: [],
                authorizer: [],
                status: [],
            };
        }
        setIsLoadingTable(true);
        keyService
            .listKeys(pagination, orderTable, search)
            .then((data) => {
                setKeys(data.keys);
                setTotalKeys(data.total);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'key.list-error' });
            })
            .finally(() => {
                setIsLoadingTable(false);
            });
    };

    const getLocks = () => {
        setIsLoadingLocks(true);
        locksService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingLocks(false);
            });
    };

    return (
        <KeyContext.Provider
            value={{
                sortedInfo,
                setSortedInfo,
                selectedKeyStatusOptions,
                setSelectedKeyStatusOptions,
                authorizer,
                setAuthorizer,
                selectedLockIds,
                setSelectedLockIds,
                dateRange,
                setDateRange,
                isFilterModalVisible,
                setIsFilterModalVisible,
                // creationSummary,
                // setCreationSummary,
                keys,
                setKeys,
                totalKeys,
                setTotalKeys,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize,
                assignedTo,
                setAssignedTo,
                isLoadingTable,
                setIsLoadingTable,
                order,
                setOrder,
                orderBy,
                setOrderBy,
                locks,
                isLoadingLocks,
                //Functions
                getKeys,
                getLocks,
                tags,
                setTags,
            }}
        >
            {props.children}
        </KeyContext.Provider>
    );
};

export default KeysProvider;
