export interface SseEvent<T> {
    sub_event_stream_id: string;
    EventID: string;
    event_type: string;
    extended_info: T;
}

export enum EventTypes {
    //Creation
    EVENT_CARDS_REMOTE_CREATION_STARTED = 'cards.remote_creation_started',
    EVENT_CARDS_CARD_LOCK_CREATION_SUCCEEDED = 'cards.card_lock_creation_succeeded',
    EVENT_CARDS_CARD_LOCK_CREATION_FAILED = 'cards.card_lock_creation_failed',
    EVENT_TTLOCKAPI_CARD_CREATION_FAILED = 'ttlock_api.card_creation_failed',
    EVENT_CARDS_REMOTE_CREATION_ENDED = 'cards.remote_creation_ended',
    EVENT_CARD_CREATION_OVERWRITTEN = 'cards.card_creation_overwritten',
    EVENT_CARDS_REMOTE_CREATION_RESOURCE_LOCKED = 'cards.remote_creation_resource_locked',
    EVENT_HANDLER_CREATION_ERROR = 'event_handler.creation_error',

    //Deletion
    EVENT_CARDS_REMOTE_DELETION_STARTED = 'cards.remote_deletion_started',
    EVENT_CARDS_CARD_LOCK_DELETION_SUCCEEDED = 'cards.card_lock_deletion_succeeded',
    EVENT_CARDS_CARD_LOCK_DELETION_FAILED = 'cards.card_lock_deletion_failed',
    EVENT_TTLOCKAPI_CARD_DELETION_FAILED = 'ttlock_api.card_deletion_failed',
    EVENT_CARDS_REMOTE_DELETION_ENDED = 'cards.remote_deletion_ended',
    EVENT_HANDLER_DELETION_ERROR = 'event_handler.deletion_error',

    EVENT_CARDS_CARD_LOCK_DELETION_STARTED = 'cards.card_lock_deletion_started',
    EVENT_CARDS_CARD_LOCK_DELETION_MISSING_ID = 'cards.card_lock_deletion_missing_id',

    EVENT_CARDS_REMOTE_SITE_CARD_DELETION_ENDED = 'cards.remote_site_card_deletion_ended',
    EVENT_CARDS_REMOTE_SITE_CARD_DELETION_SUCCEEDED = 'cards.remote_site_card_deletion_succeeded',
    EVENT_CARDS_REMOTE_SITE_CARD_DELETION_FAILED = 'cards.remote_site_card_deletion_failed',

    //Operations
    EVENT_OPERATION_CARD_FAILED = 'operation.cards.failed',
    EVENT_OPERATION_CARD_STOP_FAILED = 'operation.cards.stop_failed',
    EVENT_OPERATION_CARD_STOP_SUCCEEDED = 'operation.cards.stop_succeeded',
    EVENT_OPERATION_CARD_START_FAILED = 'operation.cards.start_failed',
    EVENT_OPERATION_CARD_RETRY_FAILED = 'operation.cards.retry_failed',
    EVENT_OPERATION_UNHANDLED = 'operation.cards.unhandled',

    EVENT_OPERATION_CARD_CREATION_STOP_STARTED = 'operation.cards.creation_stop_started',
    EVENT_OPERATION_CARD_CREATION_STARTED = 'operation.cards.creation_started',
    EVENT_OPERATION_CARD_CREATION_RESUMED = 'operation.cards.creation_resumed',
    EVENT_OPERATION_CARD_CREATION_RETRIED = 'operation.cards.creation_retried',
    EVENT_OPERATION_CARD_CREATION_SUCCEEDED = 'operation.cards.creation_succeeded',
    EVENT_OPERATION_CARD_CREATION_FAILED = 'operation.cards.creation_failed',

    //Actions
    EVENT_OPERATION_ACTION_CARD_STATUS_CHANGED = 'action.cards.status_changed',

    //others
    EVENT_HEARTBEAT = 'heartbeat',
    EVENT_TIMEOUT = 'timeout',
    EVENT_STREAM_ERROR = 'stream_error',
}

export interface SeeEventService {
    subscribeEvent(eventId: string): EventSource;
    subscribeStream(eventStreamId: string): EventSource;
}
