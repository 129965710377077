import React from 'react';
import 'fragments/site-policies/site-policies.scss';
import { CreateSitePolicyFragmentProps } from 'fragments/site-policies/fragments/create-site-policy/interfaces';
import { useCreateSitePolicyController } from 'fragments/site-policies/fragments/create-site-policy/create-site-policy.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useSitePoliciesContext } from 'fragments/site-policies/context/site-policies.context';
import {
    Form,
    Input,
    Button,
    Tooltip,
    Modal,
    Typography,
    Cascader,
    DatePicker,
    TimePicker,
    Col,
    InputNumber,
    Switch,
} from 'antd';
const { Title, Text } = Typography;

import {
    MinusCircleOutlined,
    PlusOutlined,
    UpCircleOutlined,
    DownCircleOutlined,
    InfoCircleOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';

export const CreateSitePolicyFragment: React.FC<CreateSitePolicyFragmentProps> = (props) => {
    const { useController = useCreateSitePolicyController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { isCreateSitePolicyModalVisible } = useSitePoliciesContext();

    const formItemLayout = {
        labelCol: {
            xs: { span: 18 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 18 },
            sm: { span: 20 },
        },
    };

    return (
        <Modal
            open={isCreateSitePolicyModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'site-policies.create-title' })}
                </Title>
            }
            className={'create-key'}
            closable={false}
            centered
            footer={[
                <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form="create-site-policy-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isFormLoading}
                    htmlType="submit"
                >
                    {translate({ key: 'general.add' })}
                </Button>,
            ]}
        >
            <Form
                name="create-site-policy-form"
                onFinish={controller.onFinish}
                className={'form'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                // form={controller.form}
            >
                {/* <Descriptions title={'Identificador'} style={{ width: '50%' }} />
                <Tooltip title="Identificador único que lo diferenciará en el listado">
                    <InfoCircleOutlined />
                </Tooltip> */}
                <Form.Item
                    label={
                        <>
                            <Text type="secondary" strong style={{ paddingRight: '8px' }}>
                                {translate({ key: 'label.identifier' })}
                            </Text>
                            <Tooltip title="Identificador único que lo diferenciará en el listado">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </>
                    }
                    rules={[{ required: true, message: translate({ key: 'site-policies.id-empty' }) }]}
                    name={'id'}
                >
                    <Input />
                </Form.Item>
                {/* <div style={{ display: 'flex' }}>
               
                    
                </div> */}
                <Col xs={24} id="create-site-policy-picker">
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.expires-at' })}
                            </Text>
                        }
                        required
                    >
                        <div style={{ display: 'flex' }}>
                            {!controller.withoutCaducity && (
                                <>
                                    <Form.Item
                                        required
                                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                        name="date_picker"
                                        style={{ width: '40%', marginBottom: '10px' }}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            onChange={controller.onPickedDate}
                                            getPopupContainer={() =>
                                                document.getElementById('create-site-policy-picker') as HTMLElement
                                            }
                                            disabledDate={controller.disabledDate}
                                            format="DD-MM-YYYY"
                                            disabled={controller.isLoading}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: '20%', marginBottom: '10px', marginRight: '1rem' }}
                                        required
                                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                        name="time_picker"
                                    >
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            onChange={controller.onPickedHour}
                                            getPopupContainer={() =>
                                                document.getElementById('create-site-policy-picker') as HTMLElement
                                            }
                                            format="HH"
                                            disabledHours={controller.disabledHour}
                                            disabled={controller.isLoading || !controller.selectedDate}
                                        />
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item name="without-caducity" style={{ width: '40%', marginBottom: '10px' }}>
                                <Switch
                                    onChange={controller.onChangeSwitchWithoutCaducity}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                    checked={controller.withoutCaducity}
                                />
                                <Text type="secondary" strong style={{ paddingLeft: '8px' }}>
                                    {translate({ key: 'label.without-caducity' })}
                                </Text>
                            </Form.Item>
                        </div>
                    </Form.Item>
                </Col>
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.limit-of-uses' })}
                        </Text>
                    }
                    required
                >
                    <div style={{ display: 'flex' }}>
                        {!controller.withoutLimit && (
                            <Form.Item
                                // label={
                                //     <Text type="secondary" strong>
                                //         {translate({ key: 'label.limit-of-uses' })}
                                //     </Text>
                                // }
                                required
                                rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                name="limit-of-uses"
                                style={{ marginBottom: '10px', marginRight: '1rem' }}
                                initialValue={controller.limit}
                            >
                                <InputNumber min={1} value={controller.limit} onChange={controller.onChangeLimit} />
                            </Form.Item>
                        )}
                        <Form.Item style={{ width: '50%', marginBottom: '10px' }} name="without-limit-of-uses">
                            <Switch
                                onChange={controller.onChangeSwitchWithoutLimit}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked
                                checked={controller.withoutLimit}
                            />
                            <Text type="secondary" strong style={{ paddingLeft: '8px' }}>
                                {translate({ key: 'label.without-limit-of-uses' })}
                            </Text>
                        </Form.Item>
                    </div>
                </Form.Item>
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.actions' })}
                        </Text>
                    }
                >
                    {/* <Descriptions title={'Acciones'}></Descriptions> */}
                    <Form.List
                        name="actions"
                        rules={[
                            {
                                validator: async (_, actions) => {
                                    if (!actions || actions.length < 1) {
                                        return Promise.reject(new Error('Al menos una acción'));
                                    }
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove, move }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        {...(index === 0 ? formItemLayout : formItemLayout)}
                                        label={index === 0 ? '' : ''}
                                        required={false}
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            noStyle
                                            required
                                            rules={[
                                                {
                                                    validator: async (_, action) => {
                                                        if (!action) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    translate({ key: 'site-policies.action-count' }),
                                                                ),
                                                            );
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <Cascader
                                                options={controller.cascadeValues}
                                                loadData={controller.onLoadSitePolicyActionTypeParam}
                                                placeholder="Selecciona una acción"
                                                className={'cascader'}
                                                onChange={controller.onSitePolicyActionTypeParamSelected}
                                                changeOnSelect
                                            />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <span>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        remove(field.name);
                                                        controller.onRemoveAction(index);
                                                    }}
                                                />
                                                <UpCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        move(index, index - 1);
                                                    }}
                                                />
                                                <DownCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        move(index, index + 1);
                                                    }}
                                                />
                                            </span>
                                        ) : null}
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Tooltip
                                        title={fields.length >= 50 ? 'Max. 50 Items' : ''}
                                        style={{ width: 'fitContent' }}
                                    >
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add();
                                                controller.onAddAction();
                                            }}
                                            style={{ width: fields.length >= 50 ? '100%' : '60%' }}
                                            icon={<PlusOutlined />}
                                            disabled={fields.length >= 50}
                                        >
                                            Agregar nueva acción
                                        </Button>
                                    </Tooltip>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
            </Form>
        </Modal>
    );
};
