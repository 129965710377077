import React from 'react';
import 'fragments/sites-modal/sites-list/sites-list.scss';
import { Button, List, Divider } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import SimpleItemList from 'components/simple-item-list/simple-item-list.component';
import { SitesListFragmentProps } from 'fragments/sites-modal/sites-list/interfaces';
import { useSitesListController } from 'fragments/sites-modal/sites-list/sites-list.controller';

export const SitesListFragment: React.FC<SitesListFragmentProps> = (props) => {
    const { useController = useSitesListController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div style={{ display: 'grid' }}>
            <List
                header={
                    <div className={'list-header'}>
                        <h3 className={'list-title'}>{translate({ key: 'special.admin-sites' })}</h3>
                        <Button className={'button primary'} type="primary" onClick={controller.onNewSiteButtonPressed}>
                            {translate({ key: 'general.add' })}
                        </Button>
                    </div>
                }
                itemLayout="horizontal"
                className={'sites-dashboard'}
                size="large"
                pagination={{
                    simple: true,
                    onChange: controller.onPageChange,
                    pageSize: controller.pageSize,
                    total: controller.totalSites,
                }}
                dataSource={controller.itemsViewModel}
                renderItem={(viewModel) => (
                    <SimpleItemList
                        {...viewModel}
                        // TODO: adaptar en un futuro

                        // actions={{
                        //     onOptionClick: controller.onOptionsClick(viewModel.keyValue),
                        //     delete: {
                        //         icon: viewModel.disabled ? <CheckOutlined /> : <StopOutlined />,
                        //         label: viewModel.disabled
                        //             ? translate({ key: 'general.enable' })
                        //             : translate({ key: 'general.disable' }),
                        //         onClick: () =>
                        //             viewModel.disabled
                        //                 ? controller.onConfirmDisableOrEnable(viewModel.keyValue)
                        //                 : showConfirmModal(viewModel.keyValue),
                        //     },
                        //     edit: {
                        //         icon: <EditOutlined />,
                        //         label: translate({ key: 'general.edit' }),
                        //         onClick: () => history.push(`site/${viewModel.keyValue}/update`),
                        //     },
                        // }}
                    />
                )}
            />
            <Divider />
            <Button
                key="back"
                className={'btn-cancel'}
                onClick={controller.onCloseModal}
                style={{ justifySelf: 'flex-end', marginBottom: '2%' }}
            >
                {translate({ key: 'general.close' })}
            </Button>
        </div>
    );
};
