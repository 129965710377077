import { MetricsService, CommonMetricResponse } from 'services/metrics-service/metrics-service.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useAPIMetricsService = (): MetricsService => {
    const [session] = useLocalSession();
    const getUsersByState = async (force: boolean): Promise<CommonMetricResponse> => {
        const response = await axios.get(
            process.env.REACT_APP_API_METRICS_BASE_URL + '/user/sites/${session.selectedSite?.id}/users/status' + force
                ? '/force'
                : '',
            {
                headers: { Authorization: 'Bearer ' + session.token },
            },
        );
        return response.data as CommonMetricResponse;
    };

    return {
        getUsersByState,
    };
};
