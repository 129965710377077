import React from 'react';
import 'fragments/metrics-dashboard/metrics-dashboard.scss';
import { MetricsDashboardFragmentProps } from 'fragments/metrics-dashboard/interfaces';
import { useMetricsDashboardController } from 'fragments/metrics-dashboard/metrics-dashboard.controller';
import { Col, Row, Typography } from 'antd';
import { UserByStateFragment } from './fragments/user-by-state/user-by-state.fragment';
import { useTranslator } from 'tools/view-hooks/translator-hook';

const { Title } = Typography;

export const MetricsDashboardFragment: React.FC<MetricsDashboardFragmentProps> = (props) => {
    const { useController = useMetricsDashboardController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'metrics-dashboard'}>
            <Title
                style={{
                    padding: '10px',
                    textAlign: 'center',
                    color: 'white',
                    width: '100%',
                    backgroundColor: '#7c5fbc',
                }}
                level={3}
            >
                {translate({ key: 'metrics.title-metrics-by-site' })}
            </Title>
            {/* INICIO FILAS */}
            <Row style={{ width: '100%', minHeight: '100px', gap: '1rem', marginBottom: '10px' }}>
                <Col style={{ backgroundColor: 'white', width: 'calc(50% - .5rem)', height: '100%' }}>
                    <UserByStateFragment />
                </Col>
                <Col style={{ backgroundColor: 'white', width: 'calc(50% - .5rem)', height: '100%' }}>
                    {/* Inserte aqui una metrica */}
                </Col>
            </Row>
            {/* FIN FILAS */}
            <Title
                style={{
                    // paddingTop: '1rem',
                    // paddingBottom: '.5rem',
                    padding: '10px',
                    textAlign: 'center',
                    color: 'white',
                    width: '100%',
                    backgroundColor: '#7c5fbc',
                    // borderBottom: '5px solid #7c5fbc',
                }}
                level={3}
            >
                {translate({ key: 'metrics.title-general-metrics' })}
            </Title>
            {/* INICIO FILAS */}
            <Row style={{ width: '100%', minHeight: '100px', gap: '1rem', marginBottom: '10px' }}>
                <Col style={{ backgroundColor: 'white', width: 'calc(50% - .5rem)', height: '100%' }}>
                    {/* Inserte aqui una metrica */}
                </Col>
                <Col style={{ backgroundColor: 'white', width: 'calc(50% - .5rem)', height: '100%' }}>
                    {/* Inserte aqui una metrica */}
                </Col>
            </Row>
            {/* FIN FILAS */}
        </div>
    );
};
