import React from 'react';
import 'fragments/locks-dashboard/fragments/create-lock/create-lock.scss';
import { CreateLockFragmentProps } from 'fragments/locks-dashboard/fragments/create-lock/interfaces';
import { useCreateLockController } from 'fragments/locks-dashboard/fragments/create-lock/create-lock.controller';
import { Modal, Form, Button, Input, Typography, Radio } from 'antd';
import { useLocksContext } from 'fragments/locks-dashboard/context/locks.context';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Title, Text } = Typography;

export const CreateLockFragment: React.FC<CreateLockFragmentProps> = (props) => {
    const { useController = useCreateLockController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { isCreateLockModalVisible } = useLocksContext();

    // Render
    return (
        <Modal
            open={isCreateLockModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'lock.create-title' })}
                </Title>
            }
            className={'create-lock'}
            closable={false}
            centered
            footer={[
                <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form="create-lock-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isLoading}
                    htmlType="submit"
                >
                    {translate({ key: 'general.add' })}
                </Button>,
            ]}
        >
            <Form
                id="create-lock-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                onFinish={controller.onFormSubmit}
            >
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.name' })}
                        </Text>
                    }
                    name="lock_name"
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                >
                    <Input placeholder={translate({ key: 'form.placeholder.lock.name' })} />
                </Form.Item>
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.type' })}
                        </Text>
                    }
                    name="lock_type_id"
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                >
                    <Radio.Group>
                        <Radio.Button value={1}>{translate({ key: 'lock.type-ga' })}</Radio.Button>
                        <Radio.Button value={5}>{translate({ key: 'lock.type-gt' })}</Radio.Button>
                    </Radio.Group>
                </Form.Item>{' '}
                <Text italic>{translate({ key: 'lock.lock-type-hint' })}</Text>
            </Form>
        </Modal>
    );
};
