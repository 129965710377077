export enum ROLES {
    ROLE_SUPERADMIN = 'superadmin',
    ROLE_SITE_ADMIN = 'site_admin',
    ROLE_FULL_ADMIN = 'full_admin',
    ROLE_BASIC_ADMIN = 'basic_admin',
    OTHER = 'other',
}

export interface CheckRoleResponse {
    role: ROLES;
}

export interface Main {
    checkRole(): Promise<CheckRoleResponse>;
}
