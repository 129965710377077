import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPIAuthService } from 'auth/services/auth/api-auth.service';
import { useMainContext } from 'fragments/context/main/main.context';
import { useSitesContext } from 'fragments/sites-modal/context/sites.context';
import { SidePanelController } from 'navigators/side-panel/interfaces';
import { useState, useEffect } from 'react';
import { useAPISites } from 'services/sites/api-sites.service';
import { SiteDtoSimple } from 'services/sites/sites.service';

export const useSidePanelController = (
    /* <--Dependency Injections  like services hooks */
    authService = useAPIAuthService(),
    siteService = useAPISites(),
    [session, saveSession] = useLocalSession(),
): SidePanelController => {
    /* State */
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [selectedSite, setSelectedSiteId] = useState<string>('');
    const [selectedSite, setSelectedSite] = useState<SiteDtoSimple>({ id: '', name: '', time_zone: '' });
    const { setIsAdminSitesModalVisible } = useMainContext();
    const { sites, setSites } = useSitesContext();

    /* Listeners */
    useEffect(() => {
        getSites();
    }, []);

    /* View Events */
    const handleLogOut = () => {
        authService.logout();
    };

    const onCollapseButtonPressed = () => {
        setIsCollapsed(!isCollapsed);
    };

    const onSiteSelect = (value: string) => {
        const site: SiteDtoSimple = getLoadedSite(value);
        setSelectedSite(site);
        saveSession({ token: session.token, user_profile: session.user_profile, selectedSite: site });
    };

    const onAdminSitesButtonPressed = () => {
        setIsAdminSitesModalVisible(true);
    };

    /* Private Methods */
    const getLoadedSite = (id: string): SiteDtoSimple => {
        return sites.filter((site) => {
            return site.id == id;
        })[0];
    };

    const getSites = () => {
        setIsLoading(true);
        siteService
            .userSites()
            .then((data) => {
                setSites(data.sites);
                if (data.sites.length > 0) {
                    if (!session.selectedSite) {
                        setSelectedSite(data.sites[0]);
                    } else {
                        setSelectedSite(session.selectedSite);
                    }

                    saveSession({
                        token: session.token,
                        user_profile: session.user_profile,
                        ...(!session.selectedSite
                            ? { selectedSite: data.sites[0] }
                            : { selectedSite: session.selectedSite }),
                    });
                }
            })
            .catch((error) => {
                console.log('error-sites', error);
            })
            .finally(() => setIsLoading(false));
    };

    // Return state and events
    return {
        handleLogOut,
        onCollapseButtonPressed,
        isLoading,
        isCollapsed,
        selectedSite,
        onSiteSelect,
        onAdminSitesButtonPressed,
    };
};
