import { useEffect, useState } from 'react';
import { UserByStateController } from 'fragments/metrics-dashboard/fragments/user-by-state/interfaces';
import { useAPIMetricsService } from 'services/metrics-service/api-metrics-service.service';
import dayjs from 'dayjs';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useUserByStateController = (): /* <--Dependency Injections  like services hooks */
UserByStateController => {
    /* State */
    const metricService = useAPIMetricsService();
    const messenger = useMessenger();
    const [data, setData] = useState<{ type: string; value: number }[]>([]);
    const [updatedAt, setUpdatedAt] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* Listeners */
    useEffect(() => {
        getData(false);
    }, []);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onRefreshButtonPressed = () => {
        getData(true);
    };

    /* Private Methods */
    const getData = (force: boolean) => {
        setIsLoading(true);
        !isLoading &&
            metricService
                .getUsersByState(force)
                .then((res) => {
                    setData(res.data.map((item) => ({ type: item.label, value: item.count })));
                    // setUpdatedAt(dayjs.utc(res.updated_at).format('DD/MM/YYYY HH:mm') + '(UTC)');
                })
                .catch(() => {
                    messenger.showErrorMessage({ key: 'metrics.load-error' });
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    // Return state and events
    return { isLoading, updatedAt, data, onRefreshButtonPressed };
};
