import React from 'react';
import 'fragments/site-policies/fragments/view-redeemers-modal/view-redeemers-modal.scss';
import {
    RedeemTableRow,
    ViewRedeemersModalFragmentProps,
} from 'fragments/site-policies/fragments/view-redeemers-modal/interfaces';
import { useViewRedeemersModalController } from 'fragments/site-policies/fragments/view-redeemers-modal/view-redeemers-modal.controller';
import { Button, Input, Modal, Tag, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { SearchOutlined } from '@ant-design/icons';
import Table from 'antd/es/table';

const { Text, Title } = Typography;

export const ViewRedeemersModalFragment: React.FC<ViewRedeemersModalFragmentProps> = (props) => {
    const { useController = useViewRedeemersModalController, policyId } = props;
    const controller = useController(policyId);
    const { translate } = useTranslator();
    // Render
    return (
        <Modal
            open={controller.isViewRedeemsModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0, marginBottom: 0 }}>
                    {translate({ key: 'Listado de canjes' })}
                </Title>
            }
            className={'keys-filter-modal'}
            closable={false}
            centered
            width={'700px'}
            footer={[
                <Button
                    form="view-redeemers-modal"
                    key="reset"
                    className={'button primary'}
                    style={{ marginLeft: 'auto' }}
                    onClick={controller.onCloseButtonPressed}
                >
                    {translate({ key: 'general.close' })}
                </Button>,
            ]}
        >
            <div style={{ display: 'flex', gap: '10px', paddingBottom: '1rem' }}>
                <Input
                    style={{ borderRadius: '5px' }}
                    placeholder={translate({ key: 'general.search' })}
                    onChange={controller.onSearchInputChange}
                />
                <Button
                    key="search"
                    className={'button primary'}
                    style={{ marginRight: 'auto' }}
                    icon={<SearchOutlined />}
                    loading={controller.isLoading}
                    onClick={controller.onSearchButtonPressed}
                />
            </div>
            <Table
                scroll={{ y: '50vh' }}
                columns={[
                    {
                        key: 'name',
                        title: translate({ key: 'label.name' }),
                        dataIndex: 'name',
                        sorter: !!controller.totalRedeems,
                        sortOrder: controller.sortedInfo.columnKey === 'name' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'email',
                        title: translate({ key: 'label.email' }),
                        dataIndex: 'email',
                        sorter: !!controller.totalRedeems,
                        sortOrder: controller.sortedInfo.columnKey === 'email' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'date',
                        title: translate({ key: 'label.date' }),
                        dataIndex: 'date',
                        sortOrder: controller.sortedInfo.columnKey === 'date' ? controller.sortedInfo.order : null,
                        sorter: !!controller.totalRedeems,
                        render: (date) => (
                            <Tag color="default" style={{ fontWeight: 'bold' }}>
                                {date}
                            </Tag>
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModel}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalRedeems,
                    pageSizeOptions: ['10', '20', '30', '40'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isLoading}
                rowKey={(itemsViewModel: RedeemTableRow) => itemsViewModel.id}
            />
        </Modal>
    );
};
