import React from 'react';
import 'fragments/custom-fields-dashboard/fragments/edit-custom-field-modal/edit-custom-field-modal.scss';
import { EditCustomFieldModalFragmentProps } from 'fragments/custom-fields-dashboard/fragments/edit-custom-field-modal/interfaces';
import { useEditCustomFieldModalController } from 'fragments/custom-fields-dashboard/fragments/edit-custom-field-modal/edit-custom-field-modal.controller';
import { Button, Col, Form, Input, Modal, Select, Switch, Tag, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { PlusOutlined, LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { CustomFieldValue } from 'services/custom-fields/custom-fields.service';

const { Option } = Select;
const { Text, Title, Paragraph } = Typography;
const { confirm } = Modal;

export const EditCustomFieldModalFragment: React.FC<EditCustomFieldModalFragmentProps> = (props) => {
    const { useController = useEditCustomFieldModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const showConfirmChangeTypeModal = (value: number) => {
        confirm({
            title: translate({ key: 'general.sure-to-edit' }),
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action-custom-field-type' }),
            okText: translate({ key: 'general.confirm' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onSelectFieldTypeOption(value);
            },
            onCancel() {
                controller.form.setFieldValue('field_type', 4);
            },
        });
    };
    const showConfirmSaveChanges = () => {
        confirm({
            title: translate({ key: 'general.sure-to-save' }),
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'custom-fields.edit-save-changes-message' }),
            okText: translate({ key: 'general.confirm' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onSaveChangesButtonPressed();
            },
            onCancel() {
                console.log();
            },
        });
    };

    const fieldTypeOptions = controller.fieldTypeOptions.map((opt) => (
        <Option key={opt.id} value={opt.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {opt.value}
            </div>
        </Option>
    ));

    const formatDefaultValues = (values: CustomFieldValue[]) => {
        const columnLenght = Math.ceil(values.length / 3);
        const array1 = values.slice(0, columnLenght);
        const array2 = values.slice(columnLenght, columnLenght * 2);
        const array3 = values.slice(columnLenght * 2);
        return (
            <>
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '30%',
                    }}
                >
                    {array1.map((x) => (
                        <Paragraph
                            style={{ marginBottom: '4px' }}
                            key={x.id}
                            editable={{
                                tooltip: translate({ key: 'custom-fields.edit-default-value-tooltip' }),
                                onChange: (v) => controller.editDefaultValue(x.id, v),
                                maxLength: 200,
                            }}
                            ellipsis
                        >
                            {x.value}
                        </Paragraph>
                    ))}
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '30%',
                    }}
                >
                    {array2.map((x) => (
                        <Paragraph
                            style={{ marginBottom: '4px' }}
                            key={x.id}
                            ellipsis
                            editable={{
                                tooltip: translate({ key: 'custom-fields.edit-default-value-tooltip' }),
                                onChange: (v) => controller.editDefaultValue(x.id, v),
                                maxLength: 200,
                            }}
                        >
                            {x.value}
                        </Paragraph>
                    ))}
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '30%',
                    }}
                >
                    {array3.map((x) => (
                        <Paragraph
                            style={{ marginBottom: '4px' }}
                            key={x.id}
                            ellipsis
                            editable={{
                                tooltip: translate({ key: 'custom-fields.edit-default-value-tooltip' }),
                                onChange: (v) => controller.editDefaultValue(x.id, v),
                                maxLength: 200,
                            }}
                        >
                            {x.value}
                        </Paragraph>
                    ))}
                </Col>
            </>
        );
    };

    // Render
    return (
        <Modal
            open={controller.isEditFieldModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'custom-fields.edit-custom-field' })}
                </Title>
            }
            className={'add-custom-field-modal'}
            closable={false}
            centered
            footer={[
                !controller.isLoadingDetail && (
                    <Button
                        key="back"
                        className={'btn-cancel'}
                        onClick={controller.onCancelButtonPressed}
                        disabled={controller.isLoadingEdit}
                    >
                        {translate({ key: 'general.cancel' })}
                    </Button>
                ),
                !controller.isLoadingDetail && (
                    <Button
                        form="create-edit-custom-field-form"
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        className={'button primary'}
                        loading={controller.isLoadingEdit}
                        disabled={controller.isLoadingEdit || controller.fieldNameError}
                    >
                        {translate({ key: 'general.save' })}
                    </Button>
                ),
            ]}
        >
            {controller.isLoadingDetail ? (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        minHeight: '350px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <LoadingOutlined style={{ fontSize: '3rem', color: '#8224e2' }} />
                </div>
            ) : (
                <Form
                    id="create-edit-custom-field-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    size="middle"
                    className={'form'}
                    form={controller.form}
                    onFinish={
                        controller.defaultValuesModified
                            ? showConfirmSaveChanges
                            : controller.onSaveChangesButtonPressed
                    }
                >
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.name' })}
                            </Text>
                        }
                        required
                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                        name="name"
                        validateStatus={controller.fieldNameError ? 'error' : undefined}
                        help={controller.fieldNameError ? translate({ key: 'custom-fields.name-error' }) : undefined}
                    >
                        <Input
                            placeholder={translate({ key: 'form.placeholder.custom-fields.name' })}
                            maxLength={100}
                            minLength={3}
                            value={controller.fieldName}
                            onChange={controller.onNameInputChange}
                            disabled={controller.isLoadingEdit}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.optional-field' })}
                            </Text>
                        }
                        name="optional"
                    >
                        <Switch
                            checkedChildren="Opcional"
                            unCheckedChildren="Requerido"
                            checked={controller.optionalChecked}
                            onChange={controller.onChangeOptionalSwitch}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ marginTop: '1.5rem' }}
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.field-type' })}
                            </Text>
                        }
                        name="field_type"
                        required
                        rules={[
                            {
                                required: !controller.selectedFieldType,
                                message: translate({ key: 'form.rules-required' }),
                            },
                        ]}
                        initialValue={controller.selectedFieldType}
                    >
                        <Select
                            placeholder={translate({ key: 'form.placeholder.custom-fields.type' })}
                            value={controller.selectedFieldType}
                            onChange={
                                controller.selectedFieldType == 4
                                    ? showConfirmChangeTypeModal
                                    : controller.onSelectFieldTypeOption
                            }
                            style={{ width: '100%' }}
                            loading={controller.isLoadingEdit}
                            disabled={controller.isLoadingEdit}
                        >
                            {fieldTypeOptions}
                        </Select>
                    </Form.Item>
                    {controller.selectedFieldType == 4 && (
                        <>
                            <Form.Item
                                label={
                                    <Text type="secondary" strong>
                                        {translate({ key: 'label.new-default-value' })}
                                    </Text>
                                }
                                name="new-default-values"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        gap: '10px',
                                    }}
                                >
                                    <Input
                                        placeholder={translate({ key: 'form.placeholder.custom-fields.default-value' })}
                                        maxLength={200}
                                        minLength={1}
                                        onChange={controller.onChangeDefaultValue}
                                        disabled={controller.isLoadingEdit}
                                        onSubmit={() => {
                                            console.log('submit');
                                        }}
                                    />

                                    <Button
                                        className={'button'}
                                        type="default"
                                        style={{ justifySelf: 'flex-end', padding: '3px' }}
                                        onClick={controller.onAddDefaultValue}
                                        icon={<PlusOutlined />}
                                        disabled={controller.isLoadingEdit}
                                    />
                                </div>
                                {controller.newDefaultValues.length > 0 && (
                                    <div
                                        style={{
                                            border: '1px solid #ddd',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        {controller.newDefaultValues.map((v) => (
                                            <Tag
                                                style={{ marginBottom: '1px' }}
                                                key={v}
                                                closable
                                                onClose={(e) => {
                                                    e.preventDefault();
                                                    controller.onRemoveDefaultValue(v);
                                                }}
                                            >
                                                {v}
                                            </Tag>
                                        ))}
                                    </div>
                                )}
                            </Form.Item>
                            <Form.Item
                                label={
                                    <Text type="secondary" strong>
                                        {translate({ key: 'label.select-values' })}
                                    </Text>
                                }
                                name="new-default-values"
                            >
                                {controller.defaultValues.length > 0 && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            border: '1px solid #ddd',
                                            borderRadius: '5px',
                                            padding: '10px',
                                        }}
                                    >
                                        {formatDefaultValues(controller.defaultValues)}
                                    </div>
                                )}
                            </Form.Item>
                        </>
                    )}
                </Form>
            )}
        </Modal>
    );
};
