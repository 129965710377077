import {
    AuthService,
    LoginInput,
    LoginOutput,
    // ResetPasswordInput,
    SendResetPasswordMailInput,
    UserProfileDto,
} from 'auth/services/auth/auth.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';

export const useAPIAuthService = (): AuthService => {
    const [session, saveSession, removeSession] = useLocalSession();
    const sessionToken = session.token;

    const login = async (input: LoginInput): Promise<LoginOutput> => {
        try {
            input.grant_type = input.grant_type ?? 'refresh_token';
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/token', input);
            const output = response.data as LoginOutput;
            // No funciona el endpoint en el be
            const user = await me(output.access_token);

            saveSession({ token: output.access_token, user_profile: user });
            return output;
        } catch (e: unknown) {
            const error = e as ErrorDto;
            throw error.response.data.msg;
        }
    };

    const me = async (customToken?: string): Promise<UserProfileDto> => {
        const token = customToken === undefined ? sessionToken : customToken;
        const response = await axios.post(
            process.env.REACT_APP_API_BASE_URL + '/user/profiles',
            {},
            {
                headers: { Authorization: 'Bearer ' + token },
            },
        );

        return response.data as UserProfileDto;
    };

    const logout = async (): Promise<void> => {
        await removeSession();
    };

    const sendResetPasswordMail = async (input: SendResetPasswordMailInput): Promise<void> => {
        await axios.post(process.env.REACT_APP_API_BASE_URL + '/recover', input);
        return;
    };

    // const resetPassword = async (token: string, input: ResetPasswordInput): Promise<void> => {
    //     await axios.post(process.env.REACT_APP_API_BASE_URL + '/auth/resetPassword', input, {
    //         headers: { Authorization: 'Bearer ' + token },
    //     });
    //     return;
    // };

    return {
        login,
        me,
        logout,
        sendResetPasswordMail,
        // resetPassword,
    };
};
