import { useLocalSession } from 'auth/helpers/session.hooks';
import { SseEventServiceReportFiles } from './sse-report-files.service';
import { EventSourcePolyfill } from 'event-source-polyfill';

export const useAPISseReportFiles = (): SseEventServiceReportFiles => {
    const [session] = useLocalSession();

    const subscribeReporFilesEvent = (timestamp: number) => {
        const eventSource = new EventSourcePolyfill(
            `${process.env.REACT_APP_API_REPORTS_BASE_URL}/sga/reports/v1/sse/events/${session.selectedSite?.id}/subscribe?until=${timestamp}`,
            {
                headers: {
                    Authorization: 'Bearer ' + session.token,
                },
                heartbeatTimeout: 90000,
            },
        );
        return eventSource;
    };

    return {
        subscribeReporFilesEvent,
    };
};
