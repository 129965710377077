import { SorterResult } from 'antd/lib/table/interface';
import { SorterInfo } from 'global/interfaces';
import React, { useContext } from 'react';
import { LockDto } from 'services/locks/locks.service';

export interface LockContextInterface {
    isCreateLockModalVisible: boolean;
    isLoadingTable: boolean;
    locks: LockDto[];
    totalLocks?: number;
    currentPage: number;
    pageSize: number;
    searchInput: string;
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    sortedInfo: SorterResult<SorterInfo>;

    isFilterModalVisible: boolean;
    lockType: number[];
    lockStatus: number[];

    setIsCreateLockModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoadingTable: React.Dispatch<React.SetStateAction<boolean>>;
    setLocks: React.Dispatch<React.SetStateAction<LockDto[]>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setTotalLocks: React.Dispatch<React.SetStateAction<number | undefined>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
    setSortedInfo: React.Dispatch<React.SetStateAction<SorterResult<SorterInfo>>>;
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;

    setLockStatus: React.Dispatch<React.SetStateAction<number[]>>;
    setLockType: React.Dispatch<React.SetStateAction<number[]>>;

    setIsFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LockContext = React.createContext<LockContextInterface>({
    isCreateLockModalVisible: false,
    locks: [],
    currentPage: 0,
    pageSize: 10,
    searchInput: '',
    isLoadingTable: false,
    sortedInfo: { order: undefined, columnKey: undefined },
    orderBy: undefined,
    order: undefined,
    isFilterModalVisible: false,
    lockType: [],
    lockStatus: [],
    setLockType: () => {
        /* Nothing to do here*/
    },
    setLockStatus: () => {
        /* Nothing to do here*/
    },
    setIsFilterModalVisible: () => {
        /* Nothing to do here*/
    },
    setSortedInfo: () => {
        /* Nothing to do here*/
    },
    setOrder: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setIsLoadingTable: () => {
        /* Nothing to do here*/
    },
    setIsCreateLockModalVisible: () => {
        /* Nothing to do here*/
    },
    setLocks: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setTotalLocks: () => {
        /* Nothing to do here*/
    },
    setSearchInput: () => {
        /* Nothing to do here*/
    },
});

export const useLocksContext = (): LockContextInterface => {
    const store = useContext(LockContext);
    return store;
};
