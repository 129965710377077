import React from 'react';
import 'fragments/locks-dashboard/fragments/filter-locks-modal/filter-locks-modal.scss';
import { FilterLocksModalFragmentProps } from 'fragments/locks-dashboard/fragments/filter-locks-modal/interfaces';
import { useFilterLocksModalController } from 'fragments/locks-dashboard/fragments/filter-locks-modal/filter-locks-modal.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Button, Col, Divider, Form, Input, Modal, Row, Typography } from 'antd';
const { Text, Title } = Typography;

export const FilterLocksModalFragment: React.FC<FilterLocksModalFragmentProps> = (props) => {
    const { useController = useFilterLocksModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <Modal
            open={controller.isFilterModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'Busqueda y filtrado de cerraduras' })}
                </Title>
            }
            className={'filter-locks-modal'}
            closable={false}
            centered
            footer={[
                <Button
                    form="filrter-locks-form"
                    key="reset"
                    className={'button primary'}
                    style={{ marginRight: 'auto' }}
                    loading={controller.isLoading}
                    onClick={controller.onResetButtonPressed}
                >
                    {translate({ key: 'general.reset' })}
                </Button>,
                <Button
                    form="filrter-locks-form"
                    key="cancel"
                    className={'btn-cancel'}
                    onClick={controller.onCancelButtonPressed}
                    disabled={controller.isLoading}
                >
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form="filrter-locks-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isLoading}
                    htmlType="submit"
                    disabled={controller.isLoading}
                >
                    {translate({ key: 'general.search' })}
                </Button>,
            ]}
        >
            <Form
                id="filrter-locks-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                form={controller.form}
                onFinish={controller.onSubmitButtonPressed}
            >
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.lock' })}
                        </Text>
                    }
                    name="lockName"
                >
                    <Input
                        placeholder={translate({ key: `form.placeholder.lock.name` })}
                        onChange={controller.onSearchInputChange}
                        value={controller.searchInput}
                    />
                </Form.Item>
                <Divider style={{ margin: '8px 0' }} />
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.state' })}
                        </Text>
                    }
                    name="lockStatus"
                    required
                    rules={[
                        {
                            required: !controller.lockStatus.length ? true : false,
                            message: translate({ key: 'Debe seleccionar al menos una opcion' }),
                        },
                    ]}
                >
                    <Row style={{ gap: '10px' }}>
                        {controller.lockStatusOptions.map((option) => {
                            return (
                                <Col
                                    key={option.key}
                                    style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
                                >
                                    <Button
                                        type={option.selected ? 'primary' : 'default'}
                                        key={option.key}
                                        onClick={() => controller.onLockStatusOptionPressed(option.key)}
                                    >
                                        {option.label}
                                    </Button>
                                </Col>
                            );
                        })}
                    </Row>
                </Form.Item>
                <Divider style={{ margin: '8px 0' }} />
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.lock-type' })}
                        </Text>
                    }
                    name="lockType"
                    required
                    rules={[
                        {
                            required: !controller.lockType.length ? true : false,
                            message: translate({ key: 'Debe seleccionar al menos una opcion' }),
                        },
                    ]}
                >
                    <Row style={{ gap: '10px' }}>
                        {controller.lockTypeOptions.map((option) => {
                            return (
                                <Col
                                    key={option.key}
                                    style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
                                >
                                    <Button
                                        type={option.selected ? 'primary' : 'default'}
                                        key={option.key}
                                        onClick={() => controller.onLockTypeOptionPressed(option.key)}
                                    >
                                        {option.label}
                                    </Button>
                                </Col>
                            );
                        })}
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};
