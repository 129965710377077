import { SseEvent } from 'services/sse/sse.service';

export interface DeleteSiteCardProgressModalController {
    /* State */
    rows: DeletionCardRow[];
    statusCount: StatusCount;
    eventError: boolean;
    isLoadingDelete: boolean;
    enableRetry: boolean;
    isDeleting: boolean;
    success?: boolean;
    isVisibleDeleteCardProgressModal: boolean;
    deleteCard?: { id: string; cardNumber: string };
    /* Events */
    onCloseModalPressed: () => void;
    onTryAgainPressed: () => void;
}

export interface DeleteSiteCardProgressModalFragmentProps {
    useController?: () => DeleteSiteCardProgressModalController;
}

export interface DeletionCardRow {
    sub_event_stream_id: string;
    device_name: string;
    status_id: number;
    message?: string;
}
export interface StatusCount {
    success: number;
    failed: number;
    warning: number;
    unknown: number;
}

export enum DeleteSiteCardProgressModalActionTypes {
    HANDLE_EVENT = 'HANDLE_EVENT',
    SET_INITIAL_STATE = 'SET_INITIAL_STATE',
    UPDATE_STATUS_COUNT = 'UPDATE_STATUS_COUNT',
}
export interface DeleteSiteCardProgressModalAction {
    type: DeleteSiteCardProgressModalActionTypes;
    payload: SseEvent<{ code?: number; message?: string }> | DeletionCardRow[];
}

export type HandleEventAction = {
    type: DeleteSiteCardProgressModalActionTypes.HANDLE_EVENT;
    payload: SseEvent<{ code?: number; message?: string }>;
};

export type SetInitialStateAction = {
    type: DeleteSiteCardProgressModalActionTypes.SET_INITIAL_STATE;
    payload: DeletionCardRow[];
};
export type UpdateStatusCountAction = {
    type: DeleteSiteCardProgressModalActionTypes.UPDATE_STATUS_COUNT;
    payload: null;
};

export type ReducerAction = SetInitialStateAction | HandleEventAction | UpdateStatusCountAction;

export interface DeleteSiteCardProgressModalState {
    rows: DeletionCardRow[];
    events: SseEvent<{ code?: number; message?: string }>[];
    statusCount: StatusCount;
}
