import {
    SitePolicies,
    SitePolicyActionType,
    SitePolicyActionTypeParamsDto,
    CreateSitePolicy,
    SitePolicyPaginatedItemListDto,
    SearchSitePoliciesInterface,
    NewSitePolicyListDto,
    NewSitePolicyActionDto,
    SitePolicyRedeemListDto,
    SitePolicyActionEditFormDto,
    SitePolicyHistoryDto,
} from 'services/site-policies/site-policies.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';
import { OrderTable, PaginationRequest } from 'global/interfaces';

export const useAPISitePolicies = (): SitePolicies => {
    const [session] = useLocalSession();

    const listSitePolicies = async (
        pagination?: PaginationRequest,
        order?: OrderTable,
        search?: SearchSitePoliciesInterface,
    ): Promise<NewSitePolicyListDto> => {
        pagination = pagination || { page: 0, pageSize: 20 };
        order = order || { order: undefined, orderBy: undefined };
        search = search || {
            locks: [],
            searchInput: '',
            status: [],
            operator: true,
        };

        const operator = search.operator ? 'AND' : 'OR';
        const locks = JSON.stringify(search.locks);
        const status = JSON.stringify(search.status);
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies?page=${pagination.page}&pageSize=${pagination.pageSize}&order=${order.order}&orderBy=${order.orderBy}&searchTerm=${search.searchInput}&status=${status}&locks=${locks}&operator=${operator}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );

            return response.data as NewSitePolicyListDto;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const getSitePolicyActionById = async (id: string): Promise<NewSitePolicyActionDto[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies/${id}/actions`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            const data = response.data.map((item: NewSitePolicyActionDto) => ({
                ...item,
                values: JSON.parse(JSON.stringify(item.values)).join().split(','),
            }));
            return data;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };
    const getSitePolicyActionEditFormById = async (id: string): Promise<SitePolicyActionEditFormDto[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies/${id}/actions-edit-form`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            // const data = response.data.map((item: NewSitePolicyActionDto) => ({
            //     ...item,
            //     values: JSON.parse(JSON.stringify(item.values)).join().split(','),
            // }));
            // return data;
            return response.data as SitePolicyActionEditFormDto[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const getRedeemsByPolicyId = async (request: {
        search: { id: string; searchInput: string };
        pagination?: PaginationRequest;
        order?: OrderTable;
    }): Promise<SitePolicyRedeemListDto> => {
        let { pagination, order } = request;
        const { search } = request;
        pagination = pagination || { page: 0, pageSize: 30 };
        order = order || { order: undefined, orderBy: undefined };
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies/${search.id}/redeems?page=${pagination.page}&pageSize=${pagination.pageSize}&order=${order.order}&orderBy=${order.orderBy}&searchTerm=${search.searchInput}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as SitePolicyRedeemListDto;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const downloadOnePolicyById = async (id: string): Promise<any> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies/${id}/download`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                    responseType: 'arraybuffer',
                },
            );
            return response.data as any;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const downloadPolicies = async (ids?: string[]): Promise<any> => {
        const selectedIds = ids ? JSON.stringify(ids) : '';
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies/download?ids=${selectedIds}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                    responseType: 'arraybuffer',
                },
            );
            return response.data;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const deletePolicy = async (id: string): Promise<any> => {
        try {
            const response = await axios.delete(
                process.env.REACT_APP_API_BASE_URL + `/user/sites/${session.selectedSite?.id}/site-policies/${id}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const approveSitePolicy = async (id: string): Promise<any> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies/${id}/approve`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const sendSitePolicyToReview = async (id: string, observation: string): Promise<any> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies/${id}/reject`,
                {
                    observation,
                },
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const editSitePolicy = async (id: string, input: CreateSitePolicy): Promise<any> => {
        try {
            const response = await axios.put(
                process.env.REACT_APP_API_BASE_URL + `/user/sites/${session.selectedSite?.id}/site-policies/${id}`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const getSitePolicyHistory = async (id: string): Promise<SitePolicyHistoryDto[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/site-policies/${id}/history`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as unknown as SitePolicyHistoryDto[];
        } catch (error) {
            throw error;
        }
    };

    //_____________________________________________________________________________
    //______________________________________Viejo__________________________________
    const getSitePolicies = async (
        siteId: string,
        page: number | '' = '',
        pageSize: number | '' = '',
    ): Promise<SitePolicyPaginatedItemListDto> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${siteId}/site-policies?page=${page}&pageSize=${pageSize}`,
                // `/user/sites/${siteId}/site-policies`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as SitePolicyPaginatedItemListDto;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const getSitePolicyActionTypes = async (): Promise<SitePolicyActionType[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    // `/user/site/${siteId}/site-policies?page=${page}&pageSize=${pageSize}&inputEmail=${searchInput}&inputLockName=${searchInput}&inputCreator=${searchInput}&operator=OR`,
                    `/user/site-policy-action-types`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as SitePolicyActionType[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const getSitePolicyActionTypeParameters = async (
        siteId: string,
        id: string,
    ): Promise<SitePolicyActionTypeParamsDto[]> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    // `/user/site/${siteId}/site-policies?page=${page}&pageSize=${pageSize}&inputEmail=${searchInput}&inputLockName=${searchInput}&inputCreator=${searchInput}&operator=OR`,
                    `/user/sites/${siteId}/site-policy-action-types/${id}/parameters`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as SitePolicyActionTypeParamsDto[];
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    const createSitePolicy = async (input: CreateSitePolicy, siteId: string) => {
        try {
            console.log(input);
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + `/user/sites/${siteId}/site-policies`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as Record<string, never>;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    // const downloadPolicies = async (siteId: string): Promise<PolicyDownloadItemListDto[]> => {
    //     try {
    //         const response = await axios.get(
    //             process.env.REACT_APP_API_BASE_URL +
    //                 `/user/site/${siteId}/site-policies/download`,
    //             {
    //                 headers: { Authorization: 'Bearer ' + session.token },
    //             },
    //         );
    //         return response.data as PolicyDownloadItemListDto[];
    //     } catch (err: unknown) {
    //         const error = err as ErrorDto;
    //         throw error;
    //     }
    // };

    // const getPolicy = async (siteId: string, id: string): Promise<PolicyItemListDto> => {
    //     try {
    //         const response = await axios.get(
    //             process.env.REACT_APP_API_BASE_URL +
    //                 `/user/site/${siteId}/site-policies/${id}`,
    //             {
    //                 headers: { Authorization: 'Bearer ' + session.token },
    //             },
    //         );
    //         return response.data as PolicyItemListDto;
    //     } catch (err: unknown) {
    //         const error = err as ErrorDto;
    //         throw error;
    //     }
    // };

    // const updatePolicy = async (input: CreatePolicy, siteId: string) => {
    //     try {
    //         const response = await axios.put(process.env.REACT_APP_API_BASE_URL + `/user/site/${siteId}/site-policies`, input, {
    //             headers: { Authorization: 'Bearer ' + session.token },
    //         });
    //         return response.data as PolicyItemListDto;
    //     } catch (err: unknown) {
    //         const error = err as ErrorDto;
    //         throw error.response.data.msg;
    //     }
    // };

    // const deletePolicy = async (siteId: string, id: string) => {
    //     try {
    //         const response = await axios.delete(
    //             process.env.REACT_APP_API_BASE_URL + `/user/site/${siteId}/site-policies/${id}`,
    //             {
    //                 headers: { Authorization: 'Bearer ' + session.token },
    //             },
    //         );
    //         return response.data as PolicyItemListDto;
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    return {
        approveSitePolicy,
        editSitePolicy,
        sendSitePolicyToReview,
        getSitePolicies,
        getSitePolicyActionTypes,
        getSitePolicyActionTypeParameters,
        createSitePolicy,
        listSitePolicies,
        getSitePolicyActionById,
        getSitePolicyActionEditFormById,
        getRedeemsByPolicyId,
        downloadOnePolicyById,
        downloadPolicies,
        deletePolicy,
        getSitePolicyHistory,
    };
};
