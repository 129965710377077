import { useState } from 'react';
import { FilterPasscodesModalController } from 'fragments/passcodes/fragments/filter-passcodes-modal/interfaces';
import { usePasscodesContext } from 'fragments/passcodes/context/passcodes.context';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPIPasscodes } from 'services/passcodes/api-passcodes.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { formatDatetime } from 'tools/date-handling';
import { SearchPasscode } from 'services/passcodes/passcodes.service';
import { Dayjs } from 'dayjs';

export const useFilterPasscodesModalController = (
    messenger = useMessenger(),
    passcodesService = useAPIPasscodes(),
): /* <--Dependency Injections  like services hooks */
FilterPasscodesModalController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [isLoading] = useState<boolean>(false);
    const {
        setPasscodes,
        currentPage,
        pageSize,
        setTotalPasscodes,
        setIsTableLoading,
        order,
        orderBy,
        selectedLockIdsFilterModal,
        setSelectedLockIdsFilterModal,
        dateRange,
        setDateRange,
        searchCodeInput,
        setSearchCodeInput,
        searchLabelInput,
        setSearchLabelInput,
        isVisibleFilterPasscodeModal,
        setIsVisibleFilterPasscodeModal,
        locks,
    } = usePasscodesContext();
    const [form] = useForm();

    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onSearchButtonPressed = () => {
        setIsTableLoading(true);
        const search: SearchPasscode = {
            since: dateRange.from,
            until: dateRange.to,
            label: searchLabelInput,
            code: searchCodeInput,
            locks: selectedLockIdsFilterModal,
        };
        getPasscodeListFromFilterModal(search);
        setIsVisibleFilterPasscodeModal(false);
    };

    const onResetFiltersButtonPressed = () => {
        setDateRange({ from: '', to: '' });
        setSelectedLockIdsFilterModal([]);
        setSearchCodeInput('');
        setSearchLabelInput('');
        form.resetFields();
        const search: SearchPasscode = { since: '', until: '', label: '', code: '', locks: [] };
        getPasscodeListFromFilterModal(search);
        setIsVisibleFilterPasscodeModal(false);
    };

    const onCancelButtonPressed = () => {
        setIsVisibleFilterPasscodeModal(false);
    };

    const onLabelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchLabelInput(e.target.value);
    };

    const onCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchCodeInput(e.target.value);
    };

    const onLockSelect = (value: string[]) => {
        setSelectedLockIdsFilterModal(value);
    };

    const onPickedDate = (date: [Dayjs | null, Dayjs | null]) => {
        if (date[0] && date[1]) {
            setDateRange({ from: date[0].format('YYYY-MM-DD'), to: date[1].format('YYYY-MM-DD') });
        } else {
            setDateRange({ from: '', to: '' });
        }
    };

    /* Private Methods */
    //Ex. const increaseCount = () => {}
    const getPasscodeListFromFilterModal = (search: SearchPasscode) => {
        setIsTableLoading(true);
        passcodesService
            .listPasscodes(currentPage, pageSize, { order, orderBy }, search)
            .then((data) => {
                const passcodes = data.passcodes.map((code) => {
                    return {
                        ...code,
                        since: formatDatetime(code.since, selectedSite?.time_zone),
                        until: formatDatetime(code.until, selectedSite?.time_zone),
                    };
                });
                setPasscodes(passcodes);
                setTotalPasscodes(data.total);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'passcodes.list-error' });
                console.log('passcodes-list-error', error);
            })
            .finally(() => {
                setIsTableLoading(false);
            });
    };

    // Return state and events
    return {
        isVisibleFilterPasscodeModal,
        locks,
        dateRange,
        form,
        isLoading,
        selectedLockIdsFilterModal,
        searchCodeInput,
        searchLabelInput,
        onCodeInputChange,
        onLabelInputChange,
        onSearchButtonPressed,
        onCancelButtonPressed,
        onResetFiltersButtonPressed,
        onLockSelect,
        onPickedDate,
    };
};
