import {
    Admin,
    AdminDto,
    CreateAdminInput,
    PaginatedAdminsResponse,
    SearchAdminsInterface,
    UserZoneTypes,
} from 'services/admin/admin.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';
import { OrderTable, PaginationRequest } from 'global/interfaces';

export const useAPIAdmin = (): Admin => {
    const [session] = useLocalSession();

    const getUserZoneTypes = async (): Promise<UserZoneTypes[]> => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/user/roles`, {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as UserZoneTypes[];
        } catch (error) {
            throw error;
        }
    };

    const listAdmins = async (
        pagination?: PaginationRequest,
        order?: OrderTable,
        search?: SearchAdminsInterface,
    ): Promise<PaginatedAdminsResponse> => {
        pagination = pagination || { page: 0, pageSize: 15 };
        order = order || { order: undefined, orderBy: undefined };
        search = search || { searchInput: [], selectedLockIds: [], selectedRoles: [] };
        const searchInput = JSON.stringify(search.searchInput);
        const selectedLockIds = JSON.stringify(search.selectedLockIds);
        const selectedRoles = JSON.stringify(search.selectedRoles);
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/admins?search=${searchInput}&roles=${selectedRoles}&locks=${selectedLockIds}&page=${pagination.page}&pageSize=${pagination.pageSize}&order=${order.order}&orderBy=${order.orderBy}&operator=OR`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as PaginatedAdminsResponse;
        } catch (error) {
            throw error;
        }
    };

    const createAdmin = async (input: CreateAdminInput): Promise<AdminDto> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/user/admins`, input, {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as AdminDto;
        } catch (err) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    const deleteUserZone = async (siteId: string, id: string) => {
        try {
            const response = await axios.delete(
                process.env.REACT_APP_API_BASE_URL + `/user/sites/` + siteId + `/admins/` + id,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as Record<string, never>;
        } catch (error) {
            throw error;
        }
    };

    return {
        getUserZoneTypes,
        listAdmins,
        createAdmin,
        deleteUserZone,
    };
};
