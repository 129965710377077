import { useEffect, useState } from 'react';
import { ExpandableRowReportFileDetailController } from 'fragments/download-reports/fragments/expandable-row-report-file-detail/interfaces';
import { ReportFileDetailResponse } from 'services/access-reports/access-reports.service';
import { useAPIAccessReports } from 'services/access-reports/api-access-reports.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';

export const useExpandableRowReportFileDetailController = (
    reportFileId: string,
): /* <--Dependency Injections  like services hooks */
ExpandableRowReportFileDetailController => {
    /* State */
    const [reportFileDetail, setReportFileDetail] = useState<ReportFileDetailResponse | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const accessReportService = useAPIAccessReports();
    const messenger = useMessenger();

    /* Listeners */
    useEffect(() => {
        reportFileId && getReportFileDetail(reportFileId);
    }, [reportFileId]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const getReportFileDetail = (id: string) => {
        setIsLoading(true);
        accessReportService
            .getReportFileDetail(id)
            .then((data) => {
                setReportFileDetail(data);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.report-files.report-file-detail-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    /* Private Methods */
    //Ex. const increaseCount = () => {}

    // Return state and events
    return { reportFileDetail, isLoading };
};
