import React, { useEffect, useState } from 'react';
import { OperationContext, OperationListPayload } from './operations.context';
import {
    CreateOperationInput,
    OperationDto,
    OperationType,
    PreviewActionItem,
} from 'services/operations/operations.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPIOperations } from 'services/operations/api-operations.service';
import { SorterResult } from 'antd/lib/table/interface';
import { SorterInfo } from 'global/interfaces';
import { ErrorDto } from 'services/dtos/errors..dto';
import { OperationActionDto, OperationActionType } from 'services/operation-actions/operation-actions.service';
import { useNavigate } from 'react-router-dom';
import { useAPIOperationActions } from 'services/operation-actions/api-operation-actions.service';
import { OperationInfo } from '../fragments/operation-action-list/interfaces';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPISee } from 'services/sse/api-sse.service';

type Props = {
    children?: React.ReactNode;
};

const OperationProvider: React.FC<Props> = (props) => {
    //modal
    const [isNewOperationModalVisible, setIsNewOperationModalVisible] = useState<boolean>(false);
    const [operationType, setOperationType] = useState<OperationType>(OperationType.DEFAULT);
    const [createOperationName, setCreateOperationName] = useState<string>('');
    const [operationActionType, setOperationActionType] = useState<OperationActionType>(OperationActionType.DEFAULT);
    const [checkError, setCheckError] = useState<string>('');
    const [isLoadingCheck, setIsLoadingCheck] = useState<boolean>(false);

    //operationList
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [operations, setOperations] = useState<OperationDto[]>([]);
    const [activeOperation, setActiveOperation] = useState<OperationDto | undefined>();
    const [totalOperations, setTotalOperations] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>(undefined);
    const [orderBy, setOrderBy] = useState<string | undefined>(undefined);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });
    const [isLoadingOperationCommand, setIsLoadingOperationCommand] = useState<boolean>(false);
    const [isLoadingActionCommand, setIsLoadingActionCommand] = useState<boolean>(false);

    //preview
    const [previewActionList, setPreviewActionList] = useState<PreviewActionItem[]>([]);

    //actions
    const [operationInfo, setOperationInfo] = useState<OperationInfo | undefined>();
    const [actions, setActions] = useState<OperationActionDto[]>([]);
    const [totalOperationActions, setTotalOperationActions] = useState<number>(0);
    const [isLoadingActions, setIsLoadingActions] = useState<boolean>(false);

    // Events
    const [operationEventSource, setOperationEventSource] = useState<EventSource>();

    // services
    const messenger = useMessenger();
    const navigate = useNavigate();
    const { translate } = useTranslator();
    const [session] = useLocalSession();
    const sseService = useAPISee();
    const operationsService = useAPIOperations();
    const operationActionsService = useAPIOperationActions();

    /* Listeners */
    useEffect(() => {
        session.user_profile?.role !== 'superadmin' && navigate('/locks');
    }, [session.user_profile]);

    // Functions
    const getOperationDetail = (operationId: string) => {
        setIsLoadingActions(true);
        operationActionsService
            .operationActionsList({ operationId })
            .then((data) => {
                setActions(data.operation_actions);
                let finishedCount = 0;
                data.operation_actions.map((a) => {
                    if (a.status_id == 7 || a.status_id == 8 || a.status_id == 9) {
                        finishedCount += 1;
                    }
                });
                setOperationInfo({
                    count: {
                        finished: finishedCount,
                        total: data.operation_actions.length,
                    },
                    id: data.operation_id,
                    name: data.operation_name,
                    statusId: data.operation_status_id,
                    type: translate({ key: `operations.type.${data.operation_type_id}` }),
                    typeId: data.operation_type_id,
                });
                setTotalOperationActions(data.operation_actions.length);
                operationEventSource && operationEventSource.close();
                if (data.operation_status_id == 2 || data.operation_status_id == 3) {
                    const eventSource = sseService.subscribeStream(data.operation_id);
                    setOperationEventSource(eventSource);
                } else {
                    setOperationEventSource(undefined);
                }
            })
            .catch((error: ErrorDto) => {
                const err = error.response.data;
                if (err.code == 409) {
                    console.log(error);
                }
                messenger.showErrorMessage({ key: 'operations.actions.list-error' });
            })
            .finally(() => {
                setIsLoadingActions(false);
                setIsLoadingOperationCommand(false);
            });
    };

    const getOperations = (payload: OperationListPayload) => {
        let { pagination, orderTable } = payload;
        if (pagination) {
            pagination.page != currentPage && setCurrentPage(pagination.page);
            pagination.pageSize != pageSize && setPageSize(pagination.pageSize);
        } else {
            pagination = { page: currentPage, pageSize };
        }
        if (orderTable) {
            orderTable.order !== order && setOrder(orderTable.order);
            orderTable.orderBy !== orderBy && setOrderBy(orderTable.orderBy);
            if (!orderTable.order && !orderTable.orderBy) {
                setSortedInfo({
                    order: undefined,
                    columnKey: undefined,
                });
            }
        } else {
            orderTable = { order, orderBy };
        }
        setIsTableLoading(true);
        operationsService
            .operationsList({ pagination, orderTable })
            .then((data) => {
                setOperations(data.operations);
                const operation = data.operations.find((op) => op.status_id == 2 || op.status_id == 3);
                operationEventSource && operationEventSource.close();
                if (operation) {
                    const eventSource = sseService.subscribeStream(operation.id);
                    setActiveOperation(operation);
                    setOperationEventSource(eventSource);
                } else {
                    setActiveOperation(undefined);
                    setOperationEventSource(undefined);
                }

                setTotalOperations(data.total);
            })
            .catch((error: ErrorDto) => {
                const err = error.response.data;
                if (err.code == 409) {
                    console.log(error);
                }
                messenger.showErrorMessage({ key: 'operations.list-error' });
            })
            .finally(() => {
                setIsTableLoading(false);
                setIsLoadingOperationCommand(false);
            });
    };

    const checkOperation = (input: CreateOperationInput) => {
        setIsLoadingCheck(true);
        setCheckError('');
        operationsService
            .checkOperation(input)
            .then((data) => {
                setPreviewActionList(data);
                navigate('/operations/actions/preview', { replace: true });
                setIsNewOperationModalVisible(false);
            })
            .catch((error: ErrorDto) => {
                const err = error.response.data;
                if (err?.code == 400) {
                    setCheckError('csv');
                } else if (err?.code == 409) {
                    if (err.msg == 'name') {
                        setCheckError('name');
                    }
                } else {
                    messenger.showErrorMessage({ key: 'operations.check-error' });
                }
            })
            .finally(() => {
                setIsLoadingCheck(false);
            });
    };

    return (
        <OperationContext.Provider
            value={{
                operationInfo,
                actions,
                totalOperationActions,
                isLoadingActions,
                setIsLoadingActions,
                setOperationInfo,
                setActions,
                setTotalOperationActions,
                isTableLoading,
                setIsTableLoading,
                operations,
                setOperations,
                totalOperations,
                setTotalOperations,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize,
                order,
                setOrder,
                orderBy,
                setOrderBy,
                sortedInfo,
                setSortedInfo,
                isLoadingActionCommand,
                setIsLoadingActionCommand,
                isLoadingOperationCommand,
                setIsLoadingOperationCommand,
                isNewOperationModalVisible,
                operationType,
                operationActionType,
                previewActionList,
                setIsNewOperationModalVisible,
                setOperationType,
                setOperationActionType,
                setPreviewActionList,
                createOperationName,
                setCreateOperationName,
                isLoadingCheck,
                setIsLoadingCheck,
                checkError,
                setCheckError,
                operationEventSource,
                setOperationEventSource,
                activeOperation,
                setActiveOperation,
                //Functions
                getOperations,
                checkOperation,
                getOperationDetail,
            }}
        >
            {props.children}
        </OperationContext.Provider>
    );
};

export default OperationProvider;
