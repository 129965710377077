import {
    Sites,
    CreateSiteInput,
    SiteDto,
    PaginatedSitesWithDtoSimple,
    PaginatedTimeZones,
} from 'services/sites/sites.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors..dto';

export const useAPISites = (): Sites => {
    const [session] = useLocalSession();
    const userSites = async (
        currentPage: number | '' = '',
        pageSize: number | '' = '',
    ): Promise<PaginatedSitesWithDtoSimple> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL + `/user/sites?page=${currentPage}&pageSize=${pageSize}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            response.data.sites = (response.data as PaginatedSitesWithDtoSimple).sites.filter((site) => site != null);
            return response.data as PaginatedSitesWithDtoSimple;
        } catch (error) {
            throw error;
        }
    };

    const createSite = async (input: CreateSiteInput): Promise<SiteDto> => {
        try {
            const timeZoneString = input.time_zone.toString();
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + '/user/sites',
                { ...input, time_zone: timeZoneString },
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as SiteDto;
        } catch (err) {
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    const timeZones = async (): Promise<PaginatedTimeZones> => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/user/timezones', {
                headers: { Authorization: 'Bearer ' + session.token },
            });
            return response.data as PaginatedTimeZones;
        } catch (error) {
            throw error;
        }
    };

    return {
        userSites,
        createSite,
        timeZones,
    };
};
