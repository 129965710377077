import React from 'react';
import 'auth/fragments/reset-password/reset-password.scss';
import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import { ResetPasswordFragmentProps } from 'auth/fragments/reset-password/interfaces';
import { Link } from 'react-router-dom';
import { useResetPasswordController } from 'auth/fragments/reset-password/reset-password.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text } = Typography;

export const ResetPasswordFragment: React.FC<ResetPasswordFragmentProps> = (props) => {
    const { useController = useResetPasswordController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <Row className={'reset-password'} justify={'center'}>
            <Col flex={'400px'}>
                <Form
                    name="basic"
                    layout={'vertical'}
                    className={'reset-form'}
                    initialValues={{ remember: true }}
                    // onFinish={controller.onResetPasswordSubmit}
                >
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'auth.password-input-label' })}
                            </Text>
                        }
                        name="password"
                        rules={[{ required: true }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'auth.repeat-password-input-label' })}
                            </Text>
                        }
                        name="repeat-password"
                        rules={[{ required: true }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" block={true} htmlType="submit" loading={controller.isLoading}>
                            {translate({ key: 'auth.reset-password-button-babel' })}
                        </Button>
                    </Form.Item>
                    <Divider>
                        <Typography.Text type={'secondary'} style={{ fontSize: 12 }}>
                            {translate({ key: 'auth.already-has-account' })}
                        </Typography.Text>
                    </Divider>
                    <Link to={'/login'}>
                        <Button block>{translate({ key: 'auth.go-to-login-button-label' })}</Button>
                    </Link>
                </Form>
            </Col>
        </Row>
    );
};
