import { SimpleItemListProps } from 'components/simple-item-list/simple-item-list.component';
import { useMainContext } from 'fragments/context/main/main.context';
import { useSitesContext } from 'fragments/sites-modal/context/sites.context';
import { SitesListController } from 'fragments/sites-modal/sites-list/interfaces';
import { useState, useEffect } from 'react';
import { useAPISites } from 'services/sites/api-sites.service';
import { SiteDtoSimple } from 'services/sites/sites.service';

export const useSitesListController = (siteService = useAPISites()): SitesListController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [sites, setSites] = useState<SiteDtoSimple[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize] = useState<number>(5);
    const [totalSites, setTotalSites] = useState<number>();
    const { setIsCreateSiteFragmentVisible } = useSitesContext();
    const { setIsAdminSitesModalVisible } = useMainContext();

    /* Listeners */
    useEffect(() => {
        getSites(currentPage);
    }, [currentPage]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onNewSiteButtonPressed = () => {
        setIsCreateSiteFragmentVisible(true);
    };

    const onCloseModal = () => {
        setIsAdminSitesModalVisible(false);
    };

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    /* Private Methods */
    const mapDtoToViewModel = (dto: SiteDtoSimple): SimpleItemListProps => {
        return {
            keyValue: dto.id,
            title: dto.name,
        };
    };

    const getSites = (page: number = currentPage) => {
        setIsLoading(true);
        siteService
            .userSites(page - 1, pageSize)
            .then((data) => {
                setSites(data.sites);
                setTotalSites(data.total);
            })
            .catch((error) => {
                console.log('error-sites', error);
            })
            .finally(() => setIsLoading(false));
    };

    // Return state and events
    return {
        isLoading,
        sites,
        currentPage,
        pageSize,
        totalSites,
        onNewSiteButtonPressed,
        onCloseModal,
        onPageChange,
        itemsViewModel: sites.map(mapDtoToViewModel),
    };
};
