import React, { Key } from 'react';
import 'fragments/site-cards-dashboard/site-cards-dashboard.scss';
import { Button, Col, Row, Table, Typography, Tooltip, Space, Modal } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    CloseCircleOutlined,
    CheckCircleOutlined,
    UnorderedListOutlined,
    RollbackOutlined,
    DeleteFilled,
    ExclamationCircleOutlined,
    LoadingOutlined,
    EditOutlined,
} from '@ant-design/icons';

import { SiteCardsFilterModalFragment } from './fragments/site-cards-filter-modal/site-cards-filter-modal.fragment';
import FilterOutlinedIcon from 'assets/icons/filter-outlined-icon';
import { SiteCardsDashboardFragmentProps, SiteCardsTableRow } from './interfaces';
import { useSiteCardsDashboardController } from './site-cards-dashboard.controller';
import { useNavigate } from 'react-router-dom';
import { DeleteSiteCardProgressModalFragment } from './fragments/delete-site-card-progress-modal/delete-site-card-progress-modal.fragment';
import { SiteCardCustomField } from 'services/rfidcards/rfidcards.service';
import { EditSiteCardModalFragment } from './fragments/edit-site-card-modal/edit-site-card-modal.fragment';
const { Text } = Typography;
const { confirm, error } = Modal;

export const SiteCardsDashboardFragment: React.FC<SiteCardsDashboardFragmentProps> = (props) => {
    const { useController = useSiteCardsDashboardController } = props;
    const { translate } = useTranslator();
    const navigate = useNavigate();

    const showRoleErrorModal = () => {
        error({
            title: <Text strong>{translate({ key: 'general.permission-denied' })}</Text>,
            // icon: <CloseCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            content: translate({ key: 'rfid-card.role-error' }),
            okText: translate({ key: 'general.close' }),
            onOk() {
                console.log();
            },
        });
    };
    const controller = useController(showRoleErrorModal);

    const showConfirmDeleteModal = (id: string, cardNumber: string) => {
        confirm({
            title: <Text strong>{translate({ key: 'general.sure-to-delete' })}</Text>,
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'rfid-card.delete-site-card-modal-warning' }),
            okText: translate({ key: 'general.remove' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onOnDeleteSiteCardButtonPressed(id, cardNumber);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };

    const getStatusIcon = (status: string) => {
        switch (status) {
            case translate({ key: 'rfid-card.label.valid' }):
                return <CheckCircleOutlined style={{ fontSize: '1.5rem', color: '#52C41A' }} />;
            case translate({ key: 'rfid-card.label.deleted' }):
                return <CloseCircleOutlined style={{ fontSize: '1.5rem', color: '#CF1322' }} />;
            case translate({ key: 'rfid-card.label.deleting' }):
                return <LoadingOutlined style={{ fontSize: '1.5rem', color: '#1890FF' }} />;
            case translate({ key: 'rfid-card.label.delete-failed' }):
                return <ExclamationCircleOutlined style={{ color: '#faad14', fontSize: '1.5rem' }} />;
            default:
                return <></>;
        }
    };
    const formatCustomFields = (fields: SiteCardCustomField[]) => {
        return fields.map((field) => (
            <Col key={field.name}>
                <Text strong>{field.name}:</Text>
                <Text> {field.value}</Text>
            </Col>
        ));
    };

    // Render
    return (
        <div className={'keys-dashboard'}>
            <Space style={{ marginBottom: '10px', justifyContent: 'flex-start' }}>
                <Button
                    className={'button primary'}
                    type="primary"
                    style={{ justifySelf: 'flex-end', marginBottom: '10px' }}
                    onClick={() => navigate('/massive-rfid-card-form')}
                >
                    {translate({ key: 'general.back' })}
                </Button>
            </Space>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '10px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <SiteCardsFilterModalFragment />
                    <EditSiteCardModalFragment />
                </div>
                <Space>
                    <Tooltip title={translate({ key: 'general.filters' })}>
                        <Button
                            className={'button'}
                            type="default"
                            style={{ justifySelf: 'flex-end', padding: '3px' }}
                            onClick={controller.onFilterButtonPressed}
                            icon={<FilterOutlinedIcon style={{ width: '22px', height: '22px' }} />}
                        />
                    </Tooltip>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        key: 'label',
                        title: translate({ key: 'label.label' }),
                        dataIndex: 'label',
                        sorter: !!controller.totalRfidCards,
                        sortOrder: controller.sortedInfo.columnKey === 'label' ? controller.sortedInfo.order : null,
                        width: '500px',
                        render: (label) => (
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '500px',
                                }}
                            >
                                <Tooltip placement="topLeft" title={label}>
                                    {label}
                                </Tooltip>
                            </div>
                        ),
                    },
                    {
                        key: 'cardNumber',
                        title: translate({ key: 'label.card-number' }),
                        dataIndex: 'card_number',
                        sorter: !!controller.totalRfidCards,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'cardNumber' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'device_count',
                        title: translate({ key: 'label.locks' }),
                        dataIndex: 'device_count',
                    },
                    {
                        key: 'state',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'state',
                        render: (state) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip placement="topLeft" title={state.label}>
                                    {getStatusIcon(state.label)}
                                </Tooltip>
                            </div>
                        ),
                        sorter: !!controller.totalRfidCards,
                        sortOrder: controller.sortedInfo.columnKey === 'state' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'action',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id, state, card_number }) => (
                            <div style={{ display: 'flex', gap: '.5rem' }}>
                                <Button
                                    disabled={state.id != 1 || controller.isLoadingDelete}
                                    loading={id == controller.deleteCard?.id && controller.isLoadingDelete}
                                    danger
                                    icon={<DeleteFilled />}
                                    onClick={() => showConfirmDeleteModal(id, card_number)}
                                />
                                {state.id == 1 && (
                                    <Button
                                        disabled={state.id != 1}
                                        icon={<EditOutlined />}
                                        onClick={() => controller.onEditButtonPressed(id)}
                                    />
                                )}
                                {state.id == 4 && (
                                    <Button
                                        disabled={controller.isLoadingRestore}
                                        loading={id == controller.cardRestoreId && controller.isLoadingRestore}
                                        icon={<RollbackOutlined />}
                                        onClick={() => controller.onRestoreStatusButtonPressed(id)}
                                    />
                                )}
                                {state.id == 3 && (
                                    <Button
                                        disabled={state.id != 3 || controller.isLoadingOpenModal}
                                        loading={id == controller.deleteCard?.id && controller.isLoadingOpenModal}
                                        icon={<UnorderedListOutlined />}
                                        onClick={() => controller.onReopenModalButtonPressed(id, card_number)}
                                    />
                                )}
                            </div>
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModelTable}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalRfidCards,
                    pageSizeOptions: ['20', '30', '50'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isLoadingTable}
                rowKey={(itemsViewModelTable: SiteCardsTableRow) => itemsViewModelTable.id}
                expandable={{
                    onExpand: (expanded, card) => {
                        controller.onExpandChange(card.id, expanded);
                    },
                    expandedRowKeys: controller.expandedRows.map((e) =>
                        e.expanded ? (e.rowId as unknown as Key) : '',
                    ),
                    expandedRowRender: (card) => {
                        return (
                            <div>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.locks' })}:</Text>
                                        <Text> {card.devices.text}</Text>
                                    </Col>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.last-update' })}:</Text>
                                        <Text> {card.updatedAt}</Text>
                                    </Col>
                                </Row>
                                {card.customFields && (
                                    <Row style={{ gap: '0 2rem' }}>{formatCustomFields(card.customFields)}</Row>
                                )}
                            </div>
                        );
                    },
                }}
            />
            <DeleteSiteCardProgressModalFragment />
        </div>
    );
};
