import { useEffect } from 'react';
import { AdminsDashboardController, AdminsTableRow } from 'fragments/admins-dashboard/interfaces';
import { AdminDto, SearchAdminsInterface } from 'services/admin/admin.service';
import { useAPIAdmin } from 'services/admin/api-admin.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useAdminContext } from './context/admins.context';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { TablePaginationConfig } from 'antd/es/table';
import { capitalize } from 'tools/string-handling';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

export const useAdminsDashboardController = (
    adminService = useAPIAdmin(),
    messenger = useMessenger(),
    { translate } = useTranslator(),
): AdminsDashboardController => {
    /* State */
    const {
        setIsCreateAdminModalVisible,
        setSelectedAdminId,
        setSearchInput,
        setSelectedLockIds,
        setSelectedRoles,
        searchInput,
        selectedLockIds,
        selectedRoles,
        currentPage,
        pageSize,
        totalAdmins,
        admins,
        isTableLoading,
        sortedInfo,
        setIsEditAdminModalVisible,
        setIsFilterModalVisible,
        setRoles,
        setIsTableLoading,
        setSortedInfo,
        getAdmins,
    } = useAdminContext();
    const [session] = useLocalSession();
    const { selectedSite } = session;
    const { state } = useLocation();
    /* Listeners */
    useEffect(() => {
        if (session.token && session.selectedSite) {
            setSearchInput([]);
            setSelectedLockIds([]);
            setSelectedLockIds(state ? [state as string] : []);
            setSelectedRoles([]);
            getAdmins({
                pagination: { page: 0, pageSize: 15 },
                orderTable: { order: undefined, orderBy: undefined },
                search: {
                    searchInput: [],
                    selectedLockIds: state ? [state as string] : [],
                    selectedRoles: [],
                },
            });
        }
    }, [selectedSite]);
    /* View Events */
    //________________button events________________

    const onFilterButtonPressed = () => {
        adminService
            .getUserZoneTypes()
            .then((data) => {
                setRoles(data);
                setIsFilterModalVisible(true);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'roles.list-error' });
                console.log('roles-list-error', error);
            });
    };

    const onAddAdminButtonPressed = () => {
        setIsCreateAdminModalVisible(true);
        // adminService
        //     .getUserZoneTypes()
        //     .then((data) => {
        //         setRoles(data);
        //     })
        //     .catch((error) => {
        //         messenger.showErrorMessage({ key: 'roles.list-error' });
        //         console.log('roles-list-error', error);
        //     });
    };

    const onEditAdminButtonPressed = (adminId: string) => {
        setIsEditAdminModalVisible(true);
        setSelectedAdminId(adminId);
    };

    //________________table events________________
    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.column?.key,
        });
        getAdmins({
            pagination: { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            orderTable: {
                order: sorter.order,
                orderBy: sorter.column?.key,
            },
            search: {
                searchInput,
                selectedLockIds,
                selectedRoles,
            },
        });
    };

    const onDeleteRole = (id: string) => {
        setIsTableLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const siteId = selectedSite !== null ? selectedSite!.id : '';
        adminService
            .deleteUserZone(siteId, id)
            .then(() => {
                if (admins.length < 2 && currentPage > 0) {
                    // setCurrentPage(currentPage - 1);
                    getAdmins({ pagination: { page: currentPage - 1, pageSize } });
                } else {
                    getAdmins({});
                }
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'roles.list-error' });
                console.log('roles-list-error', error);
            });
    };

    /* Private Methods */

    const mapDtoToTableRow = (dto: AdminDto): AdminsTableRow => {
        const viewModelObject = {
            id: dto.id,
            role: dto.role,
            userEmail: dto.user_email,
            userName: capitalize(dto.user_name),
            lockName: capitalize(dto.lock),
            authorizer: capitalize(dto.authorizer_name),
            authorizerEmail: dto.authorizer_email,
            createdAt: `${dayjs(dto.created_at).format('DD/MM/YYYY')}  ${dayjs(dto.created_at).format('HH:MM')}  ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
        };
        return viewModelObject;
    };

    // Return state and events
    return {
        currentPage,
        pageSize,
        totalAdmins,
        isTableLoading,
        sortedInfo,
        onAddAdminButtonPressed,
        onEditAdminButtonPressed,
        onFilterButtonPressed,
        itemsViewModelTable: admins.map(mapDtoToTableRow),
        handleTableChange,
        onDeleteRole,
    };
};
