import { useContext } from 'react';
import { SessionContext } from 'auth/helpers/session.context';
import { isTokenExpired, Session } from 'auth/helpers/session';

export const SESSION_STORAGE_SESSION_KEY = 'SESSION_STORAGE_SESSION_KEY';

export type SessionHook = () => [Session, (session: Session) => void, () => void, boolean];

export const useLocalSession: SessionHook = () => {
    const { isLoading, session, setSession } = useContext(SessionContext);

    const setSessionOnLocalStorage = (session: Session) => {
        window.sessionStorage.setItem(SESSION_STORAGE_SESSION_KEY, JSON.stringify(session));
        setSession(session);
    };

    const killSession = () => {
        window.sessionStorage.removeItem(SESSION_STORAGE_SESSION_KEY);
        setSession({});
    };

    const getSession = (): Session => {
        if (session.token && isTokenExpired(session.token)) {
            setSessionOnLocalStorage({});
            return {};
        }
        return session;
    };

    return [getSession(), setSessionOnLocalStorage, killSession, isLoading];
};
