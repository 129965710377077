import { AccessReportsDashboardFragment } from 'fragments/access-reports-dashboard/access-reports-dashboard.fragment';
import AccessReportsProvider from 'fragments/access-reports-dashboard/context/access-reports-provider.component';
import { AdminsDashboardFragment } from 'fragments/admins-dashboard/admins-dashboard.fragment';
import AdminsProvider from 'fragments/admins-dashboard/context/admins-provider.component';
import CustomFieldsProvider from 'fragments/custom-fields-dashboard/context/custom-fields-provider.component';
import { CustomFieldsDashboardFragment } from 'fragments/custom-fields-dashboard/custom-fields-dashboard.fragment';
import ReportFilesProvider from 'fragments/download-reports/context/report-files-provider.component';
import { DownloadReportsFragment } from 'fragments/download-reports/download-reports.fragment';
import KeysProvider from 'fragments/keys-dashboard/context/keys-provider.component';
import { KeysDashboardFragment } from 'fragments/keys-dashboard/keys-dashboard.fragment';
import LocksProvider from 'fragments/locks-dashboard/context/locks-provider.component';
import { LocksDashboardFragment } from 'fragments/locks-dashboard/locks-dashboard.fragment';
import MassiveRfidCardsFormProvider from 'fragments/massive-rfid-cards-form/context/massive-rfid-cards-form-provider.component';
import { MassiveRfidCardsFormFragment } from 'fragments/massive-rfid-cards-form/massive-rfid-cards-form.fragment';
import { MetricsDashboardFragment } from 'fragments/metrics-dashboard/metrics-dashboard.fragment';
import OperationProvider from 'fragments/operations/context/operations-provider.component';
import { OperationActionListFragment } from 'fragments/operations/fragments/operation-action-list/operation-action-list.fragment';
import { OperationActionPreviewListFragment } from 'fragments/operations/fragments/operation-action-preview-list/operation-action-preview-list.fragment';
import { OperationListFragment } from 'fragments/operations/fragments/operation-list/operation-list.fragment';
import PasscodesProvider from 'fragments/passcodes/context/passcodes-provider.component';
import { PasscodesFragment } from 'fragments/passcodes/passcodes.fragment';
import RfidCardsProvider from 'fragments/rfid-cards-dashboard/context/rfid-cards-provider.component';
import { RfidCardsDashboardFragment } from 'fragments/rfid-cards-dashboard/rfid-cards-dashboard.fragment';
import SiteCardsProvider from 'fragments/site-cards-dashboard/context/site-cards-provider.component';
import { SiteCardsDashboardFragment } from 'fragments/site-cards-dashboard/site-cards-dashboard.fragment';
import SitePolicyProvider from 'fragments/site-policies/context/site-policies-provider.component';
import { SitePoliciesFragment } from 'fragments/site-policies/site-policies.fragment';
import React from 'react';
import { Outlet, Route, Routes, Navigate } from 'react-router';

export const AppRoutes: React.FC = () => {
    return (
        <div>
            <OperationProvider>
                <Routes>
                    <Route path={'/operations'} element={<OperationListFragment />} />
                    <Route path={'/operations/actions'} element={<OperationActionListFragment />} />
                    <Route path={'/operations/actions/preview'} element={<OperationActionPreviewListFragment />} />

                    <Route path="/operations/*" element={<Navigate to="/locks" replace />} />
                </Routes>
            </OperationProvider>

            <Routes>
                <Route path={'/operations/*'} element={<Outlet />} />
                <Route
                    path={'/locks'}
                    element={
                        <LocksProvider>
                            <LocksDashboardFragment />
                        </LocksProvider>
                    }
                />
                <Route path={'/reports'}>
                    <Route
                        index
                        element={
                            <AccessReportsProvider>
                                <AccessReportsDashboardFragment />
                            </AccessReportsProvider>
                        }
                    />
                    <Route
                        path={'download'}
                        element={
                            <ReportFilesProvider>
                                <DownloadReportsFragment />
                            </ReportFilesProvider>
                        }
                    />
                </Route>
                <Route
                    path={'/keys'}
                    element={
                        <KeysProvider>
                            <KeysDashboardFragment />
                        </KeysProvider>
                    }
                />
                <Route
                    path={'/passcodes'}
                    element={
                        <PasscodesProvider>
                            <PasscodesFragment />
                        </PasscodesProvider>
                    }
                />
                <Route
                    path={'/rfidcards'}
                    element={
                        <RfidCardsProvider>
                            <RfidCardsDashboardFragment />
                        </RfidCardsProvider>
                    }
                />
                <Route
                    path={'/custom-fields'}
                    element={
                        <CustomFieldsProvider>
                            <CustomFieldsDashboardFragment />
                        </CustomFieldsProvider>
                    }
                />
                <Route
                    path={'/site-cards'}
                    element={
                        <SiteCardsProvider>
                            <SiteCardsDashboardFragment />
                        </SiteCardsProvider>
                    }
                />
                <Route
                    path={'/massive-rfid-card-form'}
                    element={
                        <MassiveRfidCardsFormProvider>
                            <MassiveRfidCardsFormFragment />
                        </MassiveRfidCardsFormProvider>
                    }
                />
                <Route
                    path={'/admins'}
                    element={
                        <AdminsProvider>
                            <AdminsDashboardFragment />
                        </AdminsProvider>
                    }
                />
                <Route
                    path={'/policies'}
                    element={
                        <SitePolicyProvider>
                            <SitePoliciesFragment />
                        </SitePolicyProvider>
                    }
                />
                <Route
                    path={'/metrics'}
                    element={
                        // <MetricsProvider>
                        <MetricsDashboardFragment />
                        // </MetricsProvider>
                    }
                />
                <Route path="*" element={<Navigate to="/locks" replace />} />
            </Routes>
        </div>
    );
};
