import { SorterResult } from 'antd/lib/table/interface';
import { LockContext } from 'fragments/locks-dashboard/context/locks.context';
import { SorterInfo } from 'global/interfaces';
import React, { useState } from 'react';
import { LockDto } from 'services/locks/locks.service';

type Props = {
    children?: React.ReactNode;
};

const LocksProvider: React.FC<Props> = (props) => {
    const [isCreateLockModalVisible, setIsCreateLockModalVisible] = useState<boolean>(false);
    const [locks, setLocks] = useState<LockDto[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalLocks, setTotalLocks] = useState<number>();
    const [searchInput, setSearchInput] = useState<string>('');
    const [lockStatus, setLockStatus] = useState<number[]>([]);
    const [lockType, setLockType] = useState<number[]>([]);

    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>(undefined);
    const [orderBy, setOrderBy] = useState<string | undefined>(undefined);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });
    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);

    return (
        <LockContext.Provider
            value={{
                order,
                setOrder,
                orderBy,
                setOrderBy,
                sortedInfo,
                setSortedInfo,
                isLoadingTable,
                setIsLoadingTable,
                isCreateLockModalVisible,
                setIsCreateLockModalVisible,
                locks,
                setLocks,
                pageSize,
                setPageSize,
                currentPage,
                setCurrentPage,
                totalLocks,
                setTotalLocks,
                searchInput,
                setSearchInput,

                isFilterModalVisible,
                setIsFilterModalVisible,
                lockType,
                setLockType,
                lockStatus,
                setLockStatus,
            }}
        >
            {props.children}
        </LockContext.Provider>
    );
};

export default LocksProvider;
