import React, { useContext } from 'react';
import { LockDto } from 'services/locks/locks.service';
import { PasscodesDto } from 'services/passcodes/passcodes.service';

export interface PasscodesContextInterface {
    isVisibleFilterPasscodeModal: boolean;
    selectedLockIdsFilterModal: string[];
    dateRange: { from: string; to: string };
    searchCodeInput: string;
    searchLabelInput: string;

    locks: LockDto[];

    currentPage: number;
    totalPasscodes: number;
    pageSize: number;
    isTableLoading: boolean;
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    passcodes: PasscodesDto[];
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    setPasscodes: React.Dispatch<React.SetStateAction<PasscodesDto[]>>;
    setTotalPasscodes: React.Dispatch<React.SetStateAction<number>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;

    setLocks: React.Dispatch<React.SetStateAction<LockDto[]>>;

    setSelectedLockIdsFilterModal: React.Dispatch<React.SetStateAction<string[]>>;
    setIsVisibleFilterPasscodeModal: React.Dispatch<React.SetStateAction<boolean>>;
    setDateRange: React.Dispatch<React.SetStateAction<{ from: string; to: string }>>;
    setSearchCodeInput: React.Dispatch<React.SetStateAction<string>>;
    setSearchLabelInput: React.Dispatch<React.SetStateAction<string>>;
}

export const PasscodesContext = React.createContext<PasscodesContextInterface>({
    isVisibleFilterPasscodeModal: false,
    selectedLockIdsFilterModal: [],
    dateRange: { from: '', to: '' },
    searchCodeInput: '',
    searchLabelInput: '',

    locks: [],
    passcodes: [],
    currentPage: 0,
    pageSize: 20,
    isTableLoading: false,
    orderBy: undefined,
    order: undefined,
    totalPasscodes: 0,
    setOrder: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setTotalPasscodes: () => {
        /* Nothing to do here*/
    },
    setPasscodes: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setIsTableLoading: () => {
        /* Nothing to do here*/
    },

    setLocks: () => {
        /* Nothing to do here*/
    },

    setIsVisibleFilterPasscodeModal: () => {
        /* Nothing to do here*/
    },
    setSelectedLockIdsFilterModal: () => {
        /* Nothing to do here*/
    },
    setDateRange: () => {
        /* Nothing to do here*/
    },
    setSearchLabelInput: () => {
        /* Nothing to do here*/
    },
    setSearchCodeInput: () => {
        /* Nothing to do here*/
    },
});

export const usePasscodesContext = (): PasscodesContextInterface => {
    const store = useContext(PasscodesContext);
    return store;
};
