import { useState } from 'react';
import { CreateLockController } from 'fragments/locks-dashboard/fragments/create-lock/interfaces';
import { useLocksContext } from 'fragments/locks-dashboard/context/locks.context';
import { useAPILocks } from 'services/locks/api-locks.service';
import { CreateLockInput } from 'services/locks/locks.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';

export const useCreateLockController = (
    lockService = useAPILocks(),
    messenger = useMessenger(),
): CreateLockController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { setIsCreateLockModalVisible, setLocks, setTotalLocks, searchInput } = useLocksContext();
    const [session] = useLocalSession();

    /* Listeners */

    /* View Events */
    const onCancelButtonPressed = () => {
        setIsCreateLockModalVisible(false);
    };

    const onFormSubmit = (formInputs: unknown) => {
        if (!session.selectedSite) return;
        setIsLoading(true);
        const input = formInputs as CreateLockInput;
        input.site_id = session.selectedSite.id;
        input.lock_name = input.lock_name.trim();

        lockService
            .createLock(input)
            .then(() => {
                messenger.showSuccessMessage({ key: 'lock.create-success' });
                getLocks();
                setIsCreateLockModalVisible(false);
            })
            .catch((errorMsg) => {
                if (errorMsg == 'lock') {
                    messenger.showErrorMessage({ key: 'lock.create-error-existing-lock' });
                } else {
                    messenger.showErrorMessage({ key: 'lock.create-error' });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    /* Private Methods */

    const getLocks = () => {
        setIsLoading(true);
        lockService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks);
                setTotalLocks(data.total);
            })
            .catch((error) => {
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Return state and events
    return { isLoading, onFormSubmit, onCancelButtonPressed };
};
