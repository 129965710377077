import React from 'react';
import 'fragments/site-policies/fragments/edit-site-policy/edit-site-policy.scss';
import { EditSitePolicyFragmentProps } from 'fragments/site-policies/fragments/edit-site-policy/interfaces';
import { useEditSitePolicyController } from 'fragments/site-policies/fragments/edit-site-policy/edit-site-policy.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    Form,
    Input,
    Button,
    Tooltip,
    Modal,
    Typography,
    Cascader,
    DatePicker,
    TimePicker,
    Col,
    InputNumber,
    Switch,
    Timeline,
    Skeleton,
} from 'antd';
const { Title, Text } = Typography;

import {
    MinusCircleOutlined,
    PlusOutlined,
    UpCircleOutlined,
    DownCircleOutlined,
    InfoCircleOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { UserAddOutlined, SyncOutlined } from '@ant-design/icons';
import KeyBtIcon from 'assets/icons/key-bt-icon';
import dayjs from 'dayjs';

export const EditSitePolicyFragment: React.FC<EditSitePolicyFragmentProps> = (props) => {
    const { useController = useEditSitePolicyController, policyId } = props;
    const controller = useController(policyId);

    const { translate } = useTranslator();

    const getIconFromActionName = (name: string, extra?: string) => {
        switch (name) {
            case 'generar llave':
                return <KeyBtIcon style={{ width: '15px', height: '15px', color: '#108ee9' }} />;
            case 'asignar rol':
                if (extra === 'full') {
                    return <UserAddOutlined style={{ color: '#D48806' }} />;
                }
                return <UserAddOutlined style={{ color: '#777' }} />;
            case 'restablecer cerradura':
                return <SyncOutlined style={{ color: '#108ee9' }} />;
            default:
                return <></>;
        }
    };

    // Render
    return (
        <div className={'edit-site-policy'}>
            <Modal
                forceRender
                open={controller.isEditModalVisible}
                destroyOnClose={true}
                title={
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'site-policies.edit-title' })}
                    </Title>
                }
                className={'create-key'}
                closable={false}
                centered
                footer={[
                    <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                        {translate({ key: 'general.cancel' })}
                    </Button>,
                    <Button
                        form="edit-site-policy-form"
                        key="submit"
                        type="primary"
                        className={'button primary'}
                        loading={controller.isFormLoading}
                        htmlType="submit"
                        disabled={controller.actions.length == 0}
                    >
                        {translate({ key: 'general.add' })}
                    </Button>,
                ]}
            >
                {controller.policy && (
                    <Form
                        name="edit-site-policy-form"
                        onFinish={controller.onFinish}
                        className={'form'}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        layout="horizontal"
                        size="middle"
                        form={controller.form}
                    >
                        <Form.Item
                            label={
                                <>
                                    <Text type="secondary" strong style={{ paddingRight: '8px' }}>
                                        {translate({ key: 'label.identifier' })}
                                    </Text>
                                    <Tooltip title="Identificador único que lo diferenciará en el listado">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </>
                            }
                            rules={[{ required: true, message: translate({ key: 'site-policies.id-empty' }) }]}
                            name={'id'}
                            initialValue={controller.policy.name}
                        >
                            <Input />
                        </Form.Item>

                        <Col xs={24} id="create-site-policy-picker">
                            <Form.Item
                                label={
                                    <Text type="secondary" strong>
                                        {translate({ key: 'label.expires-at' })}
                                    </Text>
                                }
                                required
                            >
                                <div style={{ display: 'flex' }}>
                                    {!controller.withoutCaducity && (
                                        <>
                                            <Form.Item
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: translate({ key: 'form.rules-required' }),
                                                    },
                                                ]}
                                                name="date_picker"
                                                style={{ width: '40%', marginBottom: '10px' }}
                                                initialValue={
                                                    controller.policy &&
                                                    dayjs(controller.policy.expires_at, 'YYYY/MM/DD')
                                                }
                                            >
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    onChange={controller.onPickedDate}
                                                    getPopupContainer={() =>
                                                        document.getElementById(
                                                            'create-site-policy-picker',
                                                        ) as HTMLElement
                                                    }
                                                    disabledDate={controller.disabledDate}
                                                    format="DD-MM-YYYY"
                                                    disabled={controller.isFormLoading}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{ width: '20%', marginBottom: '10px', marginRight: '1rem' }}
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: translate({ key: 'form.rules-required' }),
                                                    },
                                                ]}
                                                name="time_picker"
                                                initialValue={
                                                    controller.policy && dayjs.utc(controller.policy.expires_at)
                                                }
                                            >
                                                <TimePicker
                                                    style={{ width: '100%' }}
                                                    onChange={controller.onPickedHour}
                                                    getPopupContainer={() =>
                                                        document.getElementById(
                                                            'create-site-policy-picker',
                                                        ) as HTMLElement
                                                    }
                                                    format="HH"
                                                    disabledHours={controller.disabledHour}
                                                    disabled={controller.isFormLoading || !controller.selectedDate}
                                                />
                                            </Form.Item>
                                        </>
                                    )}

                                    <Form.Item
                                        name="without-caducity"
                                        style={{ width: '40%', marginBottom: '10px' }}
                                        initialValue={controller.withoutCaducity}
                                    >
                                        <div>
                                            <Switch
                                                onChange={controller.onChangeSwitchWithoutCaducity}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                defaultChecked={controller.withoutCaducity}
                                                checked={controller.withoutCaducity}
                                            />
                                            <Text type="secondary" strong style={{ paddingLeft: '8px' }}>
                                                {translate({ key: 'label.without-caducity' })}
                                            </Text>
                                        </div>
                                    </Form.Item>
                                </div>
                            </Form.Item>
                        </Col>

                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.limit-of-uses' })}
                                </Text>
                            }
                            required
                        >
                            <div style={{ display: 'flex' }}>
                                {!controller.withoutLimit && (
                                    <Form.Item
                                        // label={
                                        //     <Text type="secondary" strong>
                                        //         {translate({ key: 'label.limit-of-uses' })}
                                        //     </Text>
                                        // }
                                        required
                                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                        name="limit-of-uses"
                                        style={{ marginBottom: '10px', marginRight: '1rem' }}
                                        initialValue={controller.limit}
                                    >
                                        <InputNumber
                                            min={1}
                                            value={controller.limit}
                                            onChange={controller.onChangeLimit}
                                        />
                                    </Form.Item>
                                )}
                                <Form.Item
                                    style={{ width: '50%', marginBottom: '10px' }}
                                    name="without-limit-of-uses"
                                    initialValue={controller.policy.redeem_total == 0}
                                >
                                    <div>
                                        <Switch
                                            onChange={controller.onChangeSwitchWithoutLimit}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            defaultChecked={controller.policy.redeem_total == 0}
                                            checked={controller.withoutLimit}
                                        />
                                        <Text type="secondary" strong style={{ paddingLeft: '8px' }}>
                                            {translate({ key: 'label.without-limit-of-uses' })}
                                        </Text>
                                    </div>
                                </Form.Item>
                            </div>
                        </Form.Item>
                        <div>
                            <div className={'site-policy-action-list'} style={{ width: '100%', paddingTop: '10px' }}>
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.actions' })}
                                </Text>
                                {/* <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '.5rem',
                                        marginTop: '10px',
                                    }}
                                >
                                    <div style={{ display: 'flex', gap: '1rem' }}>
                                        <div>
                                            <KeyBtIcon style={{ width: '15px', height: '15px', color: '#108ee9' }} />
                                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                                {translate({ key: 'site-policies.actions.generate-key' })}
                                            </Text>
                                        </div>
                                        <div>
                                            <SyncOutlined style={{ color: '#108ee9' }} />
                                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                                {translate({ key: 'site-policies.actions.reset-lock' })}
                                            </Text>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', gap: '1rem' }}>
                                        <div>
                                            <UserAddOutlined style={{ color: '#777' }} />
                                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                                {translate({ key: 'site-policies.actions.assign-role-basic' })}
                                            </Text>
                                        </div>
                                        <div>
                                            <UserAddOutlined style={{ color: '#D48806' }} />
                                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                                {translate({ key: 'site-policies.actions.assign-role-full' })}
                                            </Text>
                                        </div>
                                    </div>
                                </div> */}
                                {controller.isLoadingActions ? (
                                    <>
                                        <Skeleton.Input active size="small" block />
                                        <Skeleton.Input active size="small" block />
                                    </>
                                ) : (
                                    <div
                                        style={{
                                            marginTop: '1rem',
                                            width: '100%',
                                            display: 'flex',
                                        }}
                                    >
                                        <Timeline
                                            style={{ marginTop: '.25rem', width: '100%' }}
                                            items={controller.actions.map((action) => {
                                                const values = action.values;
                                                return {
                                                    key: action.position,
                                                    style: { paddingBottom: '10px', gap: '1rem' },
                                                    children: (
                                                        <>
                                                            <Text strong style={{ paddingRight: '6px' }}>
                                                                {`${action.position + 1})`}
                                                            </Text>
                                                            {getIconFromActionName(action.name, values[0])}
                                                            <Text strong style={{ paddingLeft: '6px' }}>
                                                                {action.name === 'asignar rol'
                                                                    ? ` - ${values[1]}`
                                                                    : ` - ${values[0]}`}
                                                            </Text>
                                                            <span>
                                                                <MinusCircleOutlined
                                                                    style={{ marginLeft: '12px' }}
                                                                    onClick={() => {
                                                                        controller.onRemoveAction(action.position);
                                                                    }}
                                                                />
                                                                {action.position + 1 != 1 && (
                                                                    <UpCircleOutlined
                                                                        style={{ marginLeft: '2px' }}
                                                                        onClick={() => {
                                                                            controller.onMoveUpAction(action.position);
                                                                        }}
                                                                    />
                                                                )}
                                                                {action.position + 1 < controller.actions.length && (
                                                                    <DownCircleOutlined
                                                                        style={{ marginLeft: '2px' }}
                                                                        onClick={() => {
                                                                            controller.onMoveDownAction(
                                                                                action.position,
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            </span>
                                                        </>
                                                    ),
                                                };
                                            })}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'site-policies.new-action' })}
                                </Text>
                            }
                        >
                            <Form.Item validateTrigger={['onChange', 'onBlur']} required name={'action'}>
                                <Cascader
                                    options={controller.cascadeValues}
                                    loadData={controller.onLoadSitePolicyActionTypeParam}
                                    placeholder="Selecciona una acción"
                                    className={'cascader'}
                                    onChange={controller.onSitePolicyActionTypeParamSelected}
                                    placement={'bottomLeft'}
                                    changeOnSelect
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        controller.onAddAction();
                                    }}
                                    style={{ width: controller.actions.length >= 20 ? '100%' : '60%' }}
                                    icon={<PlusOutlined />}
                                    disabled={controller.actions.length >= 20}
                                >
                                    Agregar acción
                                </Button>
                            </Form.Item>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '.5rem',
                                    marginTop: '10px',
                                }}
                            >
                                <Text strong style={{ paddingLeft: '0.4rem' }}>
                                    {translate({ key: 'site-policies.actions.glosary' })}
                                </Text>
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    <div>
                                        <KeyBtIcon style={{ width: '15px', height: '15px', color: '#108ee9' }} />
                                        <Text strong style={{ paddingLeft: '0.4rem' }}>
                                            {translate({ key: 'site-policies.actions.generate-key' })}
                                        </Text>
                                    </div>
                                    <div>
                                        <SyncOutlined style={{ color: '#108ee9' }} />
                                        <Text strong style={{ paddingLeft: '0.4rem' }}>
                                            {translate({ key: 'site-policies.actions.reset-lock' })}
                                        </Text>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    <div>
                                        <UserAddOutlined style={{ color: '#777' }} />
                                        <Text strong style={{ paddingLeft: '0.4rem' }}>
                                            {translate({ key: 'site-policies.actions.assign-role-basic' })}
                                        </Text>
                                    </div>
                                    <div>
                                        <UserAddOutlined style={{ color: '#D48806' }} />
                                        <Text strong style={{ paddingLeft: '0.4rem' }}>
                                            {translate({ key: 'site-policies.actions.assign-role-full' })}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </div>
    );
};
