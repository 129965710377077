import { useEffect, useReducer, useState } from 'react';
import {
    ReportFileTableRow,
    ReportFilesListController,
} from 'fragments/download-reports/fragments/report-files-list/interfaces';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useReportFilesContext } from 'fragments/download-reports/context/report-files.context';
import { ReportFileDto } from 'services/access-reports/access-reports.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { hardcodeSiteId, useAPIAccessReports } from 'services/access-reports/api-access-reports.service';
import dayjs from 'dayjs';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { ReportFileListActionTypes, reportFilesListReducer } from './reducer';
import { ReportFilesEventTypes } from 'services/sse/report-files/sse-report-files.service';
import { SseEvent } from 'services/sse/sse.service';
import download from 'tools/download';

export const useReportFilesListController = (
    accessReportService = useAPIAccessReports(),
    messenger = useMessenger(),
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
ReportFilesListController => {
    /* State */

    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
    const [eventError, setEventError] = useState<boolean>(false);
    const {
        reportFiles,
        currentPage,
        pageSize,
        totalReportFiles,
        isLoadingTable,
        getReportFiles,
        eventSource,
        setIsCreateReportFileModalVisible,
    } = useReportFilesContext();
    const [session] = useLocalSession();
    const { selectedSite } = session;
    const [state, dispatch] = useReducer(reportFilesListReducer, {
        reportFiles: [],
        events: [],
    });
    /* Listeners */
    useEffect(() => {
        dispatch({ type: ReportFileListActionTypes.SET_INITIAL_STATE, payload: [] });
        if (session.token && selectedSite) {
            setIsSuperAdmin(session.user_profile?.role === 'superadmin');
            if (!!hardcodeSiteId.find((id) => id !== session.selectedSite?.id)) {
                // redirect ?
            }
            getReportFiles();
        }
    }, [selectedSite]);

    useEffect(() => {
        dispatch({ type: ReportFileListActionTypes.SET_INITIAL_STATE, payload: reportFiles });

        if (eventSource) {
            eventSource.onerror = (e: any) => {
                setEventError(true);
                eventSource?.close();
            };
            eventSource.onmessage = (event) => {
                const eventData: SseEvent<{ status_id?: number; report_file_id: string }> = JSON.parse(event.data);
                if (eventData.event_type !== ReportFilesEventTypes.EVENT_HEARTBEAT) {
                    dispatch({
                        type: ReportFileListActionTypes.HANDLE_EVENT,
                        payload: { event: eventData, report_file_id: eventData.sub_event_stream_id },
                    });
                }
            };
        }
    }, [reportFiles]);

    useEffect(() => {
        const inProgress = state.reportFiles.find((x) => x.status_id === 1);

        if (!inProgress) {
            eventSource?.close();
        }
    }, [state.reportFiles]);

    /* View Events */
    const onDownloadButtonPressed = (id: string) => {
        setIsLoadingDownload(true);
        accessReportService
            .downloadReportFile(id)
            .then((data) => {
                download(data, `${dayjs().format('YYYYMMDDHHmmss')}_reportes_accefy.csv`);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'access-reports.report-files.download-report-file-error' });
            })
            .finally(() => {
                setIsLoadingDownload(false);
            });
    };
    const handleTableChange = (
        pagination: TablePaginationConfig,
        filter: Record<string, FilterValue | null>,
        sorter: SorterResult<ReportFileTableRow> | SorterResult<ReportFileTableRow>[],
    ) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        getReportFiles({ page: currentPage === page ? 0 : page, pageSize: currentPageSize });
    };

    const onOpenCreateReportFileModal = () => {
        setIsCreateReportFileModalVisible(true);
    };
    /* Private Methods */
    const getLabelAndColorByStatusId = (status: number): { label: string; color: string } => {
        switch (status) {
            case 1:
                return {
                    label: translate({ key: 'access-reports.report-files.status.pending' }),
                    color: 'default',
                };
            case 2:
                return {
                    label: translate({ key: 'access-reports.report-files.status.in-progress' }),
                    color: 'processing',
                };
            case 3:
                return { label: translate({ key: 'access-reports.report-files.status.created' }), color: 'success' };
            case 4:
                return { label: translate({ key: 'access-reports.report-files.status.error' }), color: 'error' };
            case 5:
                return { label: translate({ key: 'access-reports.report-files.status.expired' }), color: 'default' };
            default:
                return { label: '', color: 'default' };
        }
    };

    const mapDtoToTableRow = (dto: ReportFileDto): ReportFileTableRow => {
        const viewModelObject: ReportFileTableRow = {
            id: dto.id,
            createdAt: `${dayjs(dto.created_at).format('DD/MM/YYYY')}`,
            expirationDate: `${dayjs(dto.expiration_date).format('DD/MM/YYYY')}`,
            createdByName: dto.created_by_name,
            dateRange: {
                from: dayjs(dto.since).format('DD/MM/YYYY'),
                to: dayjs(dto.until).format('DD/MM/YYYY'),
            },
            state: {
                id: dto.status_id,
                label: getLabelAndColorByStatusId(dto.status_id).label,
                color: getLabelAndColorByStatusId(dto.status_id).color,
            },
        };
        return viewModelObject;
    };

    // Return state and events
    return {
        itemsViewModel: state.reportFiles.map(mapDtoToTableRow),
        isLoadingTable,
        pageSize,
        currentPage,
        totalReportFiles,
        isLoadingDownload,
        isSuperAdmin,
        onDownloadButtonPressed,
        handleTableChange,
        onOpenCreateReportFileModal,
    };
};
