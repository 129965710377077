import React, { useState } from 'react';
import { MainContext } from 'fragments/context/main/main.context';

type Props = {
    children?: React.ReactNode;
};

const MainProvider: React.FC<Props> = (props) => {
    const [isAdminSitesModalVisible, setIsAdminSitesModalVisible] = useState(false);

    return (
        <MainContext.Provider
            value={{
                isAdminSitesModalVisible,
                setIsAdminSitesModalVisible,
            }}
        >
            {props.children}
        </MainContext.Provider>
    );
};

export default MainProvider;
