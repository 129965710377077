import React from 'react';
import 'fragments/rfid-cards-dashboard/fragments/delete-rfid-card-modal/delete-rfid-card-modal.scss';
import { Button, Modal, Space, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { DeleteRfidCardModalFragmentProps } from './interfaces';
import { useDeleteRfidCardModalController } from './delete-rfid-card-modal.controller';
import {
    LoadingOutlined,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';
const { Title } = Typography;

export const DeleteRfidCardModalFragment: React.FC<DeleteRfidCardModalFragmentProps> = (props) => {
    const { useController = useDeleteRfidCardModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <Modal
            open={controller.isDeleteModalVisible}
            destroyOnClose={true}
            className={'delete-rfid-card-modal'}
            closable={false}
            centered
            footer={
                controller.step == 1 || controller.step == 2
                    ? null
                    : [
                          controller.step == 0 && (
                              <Button
                                  key="back"
                                  className={'btn-cancel'}
                                  onClick={controller.onCancelButtonPressed}
                                  style={{ marginLeft: 'auto' }}
                              >
                                  {translate({ key: 'general.cancel' })}
                              </Button>
                          ),
                          controller.step == 0 && (
                              <Button
                                  key="delete"
                                  className={'button primary'}
                                  onClick={controller.onDeleteButtonPressed}
                              >
                                  {translate({ key: 'general.delete' })}
                              </Button>
                          ),
                          controller.step == -1 && (
                              <Button
                                  key="error"
                                  className={'button primary'}
                                  style={{ marginLeft: 'auto' }}
                                  onClick={controller.onCloseButtonPressed}
                              >
                                  {translate({ key: 'general.close' })}
                              </Button>
                          ),
                          controller.step == 3 && (
                              <Button
                                  key="finish"
                                  className={'button primary'}
                                  style={{ marginLeft: 'auto' }}
                                  onClick={controller.onFinishButtonPressed}
                              >
                                  {translate({ key: 'general.finish' })}
                              </Button>
                          ),
                      ]
            }
        >
            <Space style={{ minHeight: '100px', padding: '0 1rem' }}>
                {controller.step == 0 && (
                    <Space>
                        <ExclamationCircleOutlined
                            style={{ fontSize: '2.5rem', marginRight: '.8rem', color: '#faad14' }}
                        />
                        <Title level={4} style={{ padding: 0 }}>
                            {translate({ key: 'rfid-card.sure-to-delete' })}
                        </Title>
                    </Space>
                )}
                {controller.step == 1 && (
                    <Space>
                        <LoadingOutlined style={{ fontSize: '2.5rem', marginRight: '.8rem', color: '#5c349a' }} />
                        <Title level={4} style={{ padding: 0 }}>
                            {translate({ key: 'rfid-card.deleting' })}
                        </Title>
                    </Space>
                )}
                {controller.step == 2 && (
                    <Space>
                        <LoadingOutlined style={{ fontSize: '2.5rem', marginRight: '.8rem', color: '#5c349a' }} />
                        <Title level={4} style={{ padding: 0 }}>
                            {translate({ key: 'rfid-card.deleting-step-2' })}
                        </Title>
                    </Space>
                )}
                {controller.step == 3 && (
                    <Space>
                        <CheckCircleOutlined style={{ fontSize: '2.5rem', marginRight: '.8rem', color: '#52C41A' }} />
                        <Title level={4} style={{ padding: 0 }}>
                            {translate({ key: 'rfid-card.delete-success' })}
                        </Title>
                    </Space>
                )}
                {controller.step == -1 && (
                    <Space>
                        <CloseCircleOutlined style={{ fontSize: '2.5rem', marginRight: '.8rem', color: '#CF1322' }} />
                        <Title level={4} style={{ padding: 0, margin: 0 }}>
                            {translate({ key: 'rfid-card.delete-error' })}
                        </Title>
                    </Space>
                )}
            </Space>
        </Modal>
    );
};
