import React from 'react';
import 'fragments/passcodes/fragments/filter-passcodes-modal/filter-passcodes-modal.scss';
import { FilterPasscodesModalFragmentProps } from 'fragments/passcodes/fragments/filter-passcodes-modal/interfaces';
import { useFilterPasscodesModalController } from 'fragments/passcodes/fragments/filter-passcodes-modal/filter-passcodes-modal.controller';
import { Button, Col, DatePicker, Form, Input, Modal, Select, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Option } = Select;
const { Text, Title } = Typography;

export const FilterPasscodesModalFragment: React.FC<FilterPasscodesModalFragmentProps> = (props) => {
    const { useController = useFilterPasscodesModalController } = props;
    const controller = useController();

    const { translate } = useTranslator();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));
    // Render
    return (
        <Modal
            open={controller.isVisibleFilterPasscodeModal}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'passcodes.filter-modal-title' })}
                </Title>
            }
            className={'filter-passcodes-modal'}
            closable={false}
            centered
            footer={[
                <Button
                    key="reset"
                    className={'button primary'}
                    style={{ marginRight: 'auto' }}
                    onClick={controller.onResetFiltersButtonPressed}
                    disabled={controller.isLoading}
                >
                    {translate({ key: 'form.reset-filters' })}
                </Button>,
                <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form="filrter-access-reports-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isLoading}
                    htmlType="submit"
                >
                    {translate({ key: 'general.search' })}
                </Button>,
            ]}
        >
            <Form
                id="filrter-access-reports-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                form={controller.form}
                onFinish={controller.onSearchButtonPressed}
            >
                <Col xs={24} id="create-key-date-picker">
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.duration' })}
                            </Text>
                        }
                        required
                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                        name="date_picker"
                    >
                        <DatePicker.RangePicker
                            style={{ width: '100%' }}
                            onChange={(e: any) => {
                                e && controller.onPickedDate(e);
                            }}
                            getPopupContainer={() => document.getElementById('create-key-date-picker') as HTMLElement}
                            format="DD-MM-YYYY"
                        />
                    </Form.Item>
                </Col>
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.locks' })}
                        </Text>
                    }
                    name="lock_ids"
                >
                    <Select
                        placeholder={translate({ key: 'form.placeholder.lock.name' })}
                        mode="multiple"
                        allowClear
                        value={controller.selectedLockIdsFilterModal}
                        onChange={controller.onLockSelect}
                        style={{ width: '100%' }}
                        filterOption={(inputValue, option) => {
                            const opt = controller.locks.find((item) => item.id == option?.value);
                            return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    >
                        {optionLocks}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.label' })}
                        </Text>
                    }
                    name="label"
                >
                    <Input
                        placeholder={`Busqueda por etiqueta`}
                        onChange={controller.onLabelInputChange}
                        value={controller.searchLabelInput}
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.code' })}
                        </Text>
                    }
                    name="code"
                >
                    <Input
                        placeholder={'Busqueda por codigo'}
                        maxLength={20}
                        onChange={controller.onCodeInputChange}
                        value={controller.searchCodeInput}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
