import { useState, useEffect } from 'react';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { KeyScheduleOption } from 'services/keys/keys.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
import { TablePaginationConfig } from 'antd';
import { RfidCardDto, SiteCardCustomField } from 'services/rfidcards/rfidcards.service';
import { RfidCardTableRow, RfidCardsDashboardController } from './interfaces';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { OrderTable } from 'global/interfaces';
import { useRfidCardsContext } from './context/rfid-cards.context';
dayjs.extend(utc);
// dayjs.extend(timezone);

export const useRfidCardsDashboardController = ({ translate } = useTranslator()): RfidCardsDashboardController => {
    /* State */
    const [isLoading] = useState<boolean>(false);
    const [isCreateRfidCardModalVisible, setIsCreateRfidCardModalVisible] = useState<boolean>(false);
    const [session] = useLocalSession();
    const {
        sortedInfo,
        setSortedInfo,
        setIsFilterModalVisible,
        setIsDeleteModalVisible,
        rfidCards,
        totalRfidCards,
        setCurrentPage,
        setPageSize,
        pageSize,
        currentPage,
        setOrder,
        setOrderBy,
        isLoadingTable,
        authorizer,
        setAuthorizer,
        label,
        setLabel,
        cardNumber,
        setCardNumber,
        selectedDevicesIds,
        setSelectedDevicesIds,
        selectedRfidCardStatusOptions,
        setSelectedRfidCardStatusOptions,
        dateRange,
        setDateRange,
        getRfidCards,
        setDeleteCardId,
        setDeleteCardDeviceId,
        selectedCustomFields,
    } = useRfidCardsContext();
    const { selectedSite } = session;

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            resetStates();
            getRfidCards({
                pagination: { page: 0, pageSize },
                orderTable: { order: undefined, orderBy: undefined },
                search: {
                    since: '',
                    until: '',
                    card: [],
                    devices: [],
                    label: [],
                    authorizer: [],
                    status: [],
                    customFields: [],
                },
            });
        }
    }, [selectedSite]);

    /* View Events */
    const handleTableChange = (
        pagination: TablePaginationConfig,
        filter: Record<string, FilterValue | null>,
        sorter: SorterResult<RfidCardTableRow> | SorterResult<RfidCardTableRow>[],
    ) => {
        const sort = Array.isArray(sorter) ? sorter[0] : sorter;
        const sortAsOrder: OrderTable = {
            order: sort.order as 'ascend' | 'descend' | undefined,
            orderBy: sort.column?.key as string,
        };
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setSortedInfo({
            order: sort.order,
            columnKey: sort.column?.key,
        });
        getRfidCards({
            pagination: { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            orderTable: sortAsOrder,
            search: {
                since: dateRange[0]?.format('YYYY-MM-DD') || '',
                until: dateRange[1]?.format('YYYY-MM-DD') || '',
                card: cardNumber,
                devices: selectedDevicesIds,
                label,
                authorizer,
                status: selectedRfidCardStatusOptions,
                customFields: selectedCustomFields,
            },
        });
    };

    const onOnDeleteRfidCardButtonPressed = (id: string, deviceId: string) => {
        setDeleteCardId(id);
        setDeleteCardDeviceId(deviceId);
        setIsDeleteModalVisible(true);
    };
    const onCloseCreateRfidCard = () => {
        setIsCreateRfidCardModalVisible(false);
    };
    const onFilterButtonPressed = () => {
        setIsFilterModalVisible(true);
    };

    /* Private Methods */
    const resetStates = () => {
        setAuthorizer([]);
        setLabel([]);
        setCardNumber([]);
        setSelectedDevicesIds([]);
        setSelectedRfidCardStatusOptions([]);
        setDateRange([null, null]);
        setCurrentPage(0);
        setPageSize(20);
        setSortedInfo({
            order: undefined,
            columnKey: undefined,
        });
        setOrder(undefined);
        setOrderBy(undefined);
    };

    const mapDtoToTableRow = (dto: RfidCardDto): RfidCardTableRow => {
        const viewModelObject: RfidCardTableRow = {
            id: dto.id,
            deviceId: dto.device_id,
            label: dto.label,
            cardNumber: dto.card_number,
            cardTypeId: dto.card_type_id,
            deviceName: dto.device_name,
            authorizerName: dto.created_by_name,
            authorizerEmail: dto.created_by_email,
            updatedByName: dto.updated_by_name,
            updatedByEmail: dto.updated_by_email,
            deleteDisabled: dto.state >= 3,
            time: formatTime(dayjs.utc(dto.nbf_time).format('HH:mm'), dayjs.utc(dto.expires_at_time).format('HH:mm')),
            dow: getKeySchedules(dto.dow),
            validity: {
                from: dayjs(dto.nbf_date).format('DD/MM/YYYY'),
                to: dayjs(dto.expires_at_date).format('DD/MM/YYYY'),
                color: getLabelAndColorByState(dto.state).color,
                tooltip: getLabelAndColorByState(dto.state).label,
            },
            state: {
                label: getLabelAndColorByState(dto.state).label,
                id: dto.state,
            },
            createdAt: `${dayjs(dto.created_at).format('DD/MM/YYYY')}  ${dayjs(dto.created_at).format('HH:mm')}  ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
            updatedAt: `${dayjs(dto.updated_at).format('DD/MM/YYYY')}  ${dayjs(dto.updated_at).format('HH:mm')}  ${
                selectedSite ? '(' + selectedSite.time_zone + ')' : ''
            }`,
        };
        if (dto.custom_fields) {
            viewModelObject.customFields = parseCustomFields(dto.custom_fields);
        }
        return viewModelObject;
    };

    const parseCustomFields = (fields: SiteCardCustomField[]): SiteCardCustomField[] => {
        return fields.map((field) => {
            switch (field.type_id) {
                case 2:
                    return {
                        ...field,
                        value: `${dayjs(field.value).format('DD/MM/YYYY')}  ${dayjs(field.value).format('HH:mm')}`,
                    };
                case 3:
                    return {
                        ...field,
                        value: JSON.parse(field.value).join(', '),
                    };
                default:
                    return field;
            }
        });
    };

    const formatTime = (from: string, to: string) => {
        return `De ${from} a ${to} hs. ${selectedSite ? '(' + selectedSite.time_zone + ')' : ''}`;
    };

    const getLabelAndColorByState = (state: number): { label: string; color: string } => {
        switch (state) {
            case 1:
                return { label: translate({ key: 'rfid-card.label.pending' }), color: 'rgba(147, 94, 224, 0.6)' };
            case 2:
                return { label: translate({ key: 'rfid-card.label.valid' }), color: 'rgba(109, 206, 201, 0.8)' };
            case 3:
                return { label: translate({ key: 'rfid-card.label.deleted' }), color: 'rgba(242, 61, 79, 0.6)' };
            case 4:
                return { label: translate({ key: 'rfid-card.label.overwritten' }), color: 'rgba(242, 61, 79, 0.6)' };
            case 5:
                return { label: translate({ key: 'rfid-card.label.expired' }), color: 'rgba(242, 61, 79, 0.6)' };
            case 6:
                return { label: translate({ key: 'rfid-card.label.deleting' }), color: 'rgba(242, 61, 79, 0.6)' };

            default:
                return { label: 'invalid', color: 'rgba(242, 61, 79, 0.6)' };
        }
    };

    const getKeySchedules = (dow: number): KeyScheduleOption[] => {
        const defaults = { selected: false, disabled: false };
        const defaultKeySchedules = [
            { key: 1, label: translate({ key: 'general.monday' }), ...defaults },
            { key: 2, label: translate({ key: 'general.tuesday' }), ...defaults },
            { key: 4, label: translate({ key: 'general.wednesday' }), ...defaults },
            { key: 8, label: translate({ key: 'general.thursday' }), ...defaults },
            { key: 16, label: translate({ key: 'general.friday' }), ...defaults },
            { key: 32, label: translate({ key: 'general.saturday' }), ...defaults },
            { key: 64, label: translate({ key: 'general.sunday' }), ...defaults },
        ];
        for (let i = dow, j = 0; i >= 1; j++) {
            if (i % 2 === 1) {
                defaultKeySchedules[j].selected = true;
            }
            i = Math.floor(i / 2);
        }
        const temp = defaultKeySchedules.pop();
        temp && defaultKeySchedules.unshift(temp);
        return defaultKeySchedules;
    };

    // Return state and events
    return {
        isLoading,
        isLoadingTable,
        rfidCards,
        totalRfidCards,
        pageSize,
        currentPage,
        sortedInfo,
        isCreateRfidCardModalVisible,
        onOnDeleteRfidCardButtonPressed,
        onCloseCreateRfidCard,
        onFilterButtonPressed,
        handleTableChange,
        itemsViewModelTable: rfidCards.map(mapDtoToTableRow),
    };
};
