import React from 'react';
import 'fragments/common/create-passcode-modal/create-passcode-modal.scss';
import { CreatePasscodeModalFragmentProps } from 'fragments/common/create-passcode-modal/interfaces';
import { useCreatePasscodeModalController } from 'fragments/common/create-passcode-modal/create-passcode-modal.controller';
import { Button, Col, DatePicker, Form, Input, Modal, Select, Space, Tabs, TimePicker, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

export const CreatePasscodeModalFragment: React.FC<CreatePasscodeModalFragmentProps> = (props) => {
    const { useController = useCreatePasscodeModalController, isModalVisible, onClose, lockId, onFinish } = props;
    const controller = useController(onClose, lockId, onFinish);

    const { translate } = useTranslator();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));

    // Render
    return (
        <Modal
            open={isModalVisible}
            destroyOnClose={true}
            title={
                controller.currentStep == 0 && (
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'passcodes.create-modal' })}
                    </Title>
                )
            }
            className={'create-passcode-modal'}
            closable={false}
            centered
            footer={[
                controller.currentStep == 0 && (
                    <Button
                        key="back"
                        className={'btn-cancel'}
                        onClick={controller.onCancelButtonPressed}
                        disabled={controller.isLoading}
                    >
                        {translate({ key: 'general.cancel' })}
                    </Button>
                ),
                controller.currentStep == 0 && (
                    <Button
                        form={'create-passcode-form'}
                        key="submit"
                        type="primary"
                        className={'button primary'}
                        loading={controller.isLoading || controller.isCheckDateLoading}
                        htmlType="submit"
                        disabled={controller.isInvalidDateRange}
                    >
                        {translate({ key: 'passcodes.generate' })}
                    </Button>
                ),
                controller.currentStep == 1 && (
                    <Button key="back" className={'btn-cancel'} onClick={controller.onCloseButtonPressed}>
                        {translate({ key: 'general.close' })}
                    </Button>
                ),
                controller.currentStep == 1 && (
                    <Button
                        key="create_other"
                        type="primary"
                        className={'button primary'}
                        onClick={controller.onCreateOtherPasscodeButtonPressed}
                    >
                        {translate({ key: 'passcodes.generate-other' })}
                    </Button>
                ),
            ]}
        >
            {controller.currentStep == 0 ? (
                <Form
                    id="create-passcode-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    size="middle"
                    className={'form'}
                    form={controller.form}
                    onFinish={controller.onCreateButtonPressed}
                >
                    <Tabs
                        defaultActiveKey={controller.selectedType.toString()}
                        onChange={controller.onTypeChange}
                        centered
                    >
                        <TabPane
                            style={{ width: '100%' }}
                            tab={<strong>{translate({ key: 'passcodes.single-use' })}</strong>}
                            key="1"
                        >
                            {controller.selectedType === 1 && (
                                <>
                                    <Text type="secondary" strong style={{ marginBottom: '1rem' }}>
                                        {translate({ key: 'passcodes.passcode-single-use-description-1' })}{' '}
                                        <span style={{ color: '#262626d9' }}>
                                            {translate({ key: 'passcodes.passcode-single-use-description-2' })}
                                        </span>{' '}
                                        {translate({ key: 'passcodes.passcode-single-use-description-3' })}
                                    </Text>
                                    <Form.Item
                                        style={{ marginTop: '1.5rem' }}
                                        label={
                                            <Text type="secondary" strong>
                                                {translate({ key: 'label.lock' })}
                                            </Text>
                                        }
                                        name="lock_id"
                                        required
                                        rules={[
                                            {
                                                required: !controller.selectedLockId,
                                                message: translate({ key: 'form.rules-required' }),
                                            },
                                        ]}
                                        initialValue={controller.selectedLockId || undefined}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={translate({ key: 'form.placeholder.lock.name' })}
                                            allowClear
                                            value={controller.selectedLockId}
                                            onChange={controller.onLockSelect}
                                            style={{ width: '100%' }}
                                            filterOption={(inputValue, option) => {
                                                const opt = controller.locks.find((item) => item.id == option?.value);
                                                return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                                            }}
                                            loading={controller.isLoadingLocks}
                                            disabled={controller.isLoading}
                                        >
                                            {optionLocks}
                                        </Select>
                                    </Form.Item>

                                    <Col xs={24} id="create-passcode-picker">
                                        <Form.Item
                                            label={
                                                <Text type="secondary" strong>
                                                    {translate({ key: 'label.duration' })}
                                                </Text>
                                            }
                                            required
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <Form.Item
                                                    required
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate({ key: 'form.rules-required' }),
                                                        },
                                                    ]}
                                                    name="date_picker"
                                                    style={{ width: '50%', marginBottom: '10px' }}
                                                >
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        onChange={controller.onPickedDate}
                                                        getPopupContainer={() =>
                                                            document.getElementById(
                                                                'create-passcode-picker',
                                                            ) as HTMLElement
                                                        }
                                                        disabledDate={controller.disabledDate}
                                                        format="DD/MM/YYYY"
                                                        disabled={controller.isLoading}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ width: '50%', marginBottom: '10px' }}
                                                    required
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate({ key: 'form.rules-required' }),
                                                        },
                                                    ]}
                                                    name="time_picker"
                                                >
                                                    <TimePicker
                                                        style={{ width: '100%' }}
                                                        onChange={controller.onPickedHour}
                                                        getPopupContainer={() =>
                                                            document.getElementById(
                                                                'create-passcode-picker',
                                                            ) as HTMLElement
                                                        }
                                                        format="HH:00"
                                                        disabledHours={controller.disabledHour}
                                                        disabled={
                                                            controller.isLoading ||
                                                            !controller.selectedDateRange.sinceDate
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                            <Text type="secondary" strong>
                                                {controller.formatedDates.length == 2 ? (
                                                    <>
                                                        {translate({ key: 'passcodes.the-code-expire' })}
                                                        <span style={{ color: '#262626d9' }}>
                                                            {' '}
                                                            {controller.formatedDates[1]}
                                                        </span>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </Text>
                                        </Form.Item>
                                    </Col>

                                    <Form.Item
                                        label={
                                            <Text type="secondary" strong>
                                                {translate({ key: 'label.label' })}
                                            </Text>
                                        }
                                        required
                                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                        name="code_label"
                                    >
                                        <Input
                                            placeholder={translate({ key: 'form.placeholder.passcodes.label' })}
                                            maxLength={20}
                                            minLength={3}
                                            onChange={controller.onLabelInputChange}
                                            disabled={controller.isLoading}
                                        />
                                    </Form.Item>
                                </>
                            )}
                        </TabPane>
                        <TabPane
                            style={{ width: '100%' }}
                            tab={<strong>{translate({ key: 'passcodes.temporary' })}</strong>}
                            key="2"
                        >
                            {controller.selectedType === 2 && (
                                <>
                                    <Text type="secondary" strong style={{ marginBottom: '1rem' }}>
                                        {translate({ key: 'passcodes.passcode-temporary-description-1' })}{' '}
                                        <span style={{ color: '#262626d9' }}>
                                            {translate({ key: 'passcodes.passcode-temporary-description-2' })}
                                        </span>{' '}
                                        {translate({ key: 'passcodes.passcode-temporary-description-3' })}
                                    </Text>

                                    <Form.Item
                                        style={{ marginTop: '1.5rem' }}
                                        label={
                                            <Text type="secondary" strong>
                                                {translate({ key: 'label.lock' })}
                                            </Text>
                                        }
                                        name="lock_id_temp"
                                        required
                                        rules={[
                                            {
                                                required: !controller.selectedLockId,
                                                message: translate({ key: 'form.rules-required' }),
                                            },
                                        ]}
                                        initialValue={controller.selectedLockId || undefined}
                                        validateStatus={controller.isInvalidDateRange ? 'error' : undefined}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={translate({ key: 'form.placeholder.lock.name' })}
                                            allowClear
                                            value={controller.selectedLockId}
                                            onChange={controller.onLockSelect}
                                            style={{ width: '100%' }}
                                            filterOption={(inputValue, option) => {
                                                const opt = controller.locks.find((item) => item.id == option?.value);
                                                return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                                            }}
                                            loading={controller.isLoadingLocks}
                                            disabled={controller.isLoading || controller.isCheckDateLoading}
                                        >
                                            {optionLocks}
                                        </Select>
                                    </Form.Item>
                                    <Col xs={24} id="create-passcode-range-picker">
                                        <Form.Item
                                            label={
                                                <Text type="secondary" strong>
                                                    {translate({ key: 'label.duration' })}
                                                </Text>
                                            }
                                            required
                                            name="date_range"
                                            rules={[
                                                { required: false, message: translate({ key: 'form.rules-required' }) },
                                                () => ({
                                                    validator(val, value) {
                                                        const arr = Object.values(controller.selectedDateRange).filter(
                                                            (e) => e === '',
                                                        );
                                                        if (!arr.length) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error(`Este campo es obligatorio`));
                                                    },
                                                }),
                                            ]}
                                            validateStatus={controller.isInvalidDateRange ? 'error' : undefined}
                                            help={
                                                controller.isInvalidDateRange
                                                    ? 'Ya existe un codigo para este rango de fechas en esta cerradura'
                                                    : undefined
                                            }
                                        >
                                            <RangePicker
                                                onCalendarChange={(e) => {
                                                    e && controller.onPickedDateRange(e);
                                                }}
                                                style={{ width: '100%', marginBottom: '10px' }}
                                                getPopupContainer={() =>
                                                    document.getElementById(
                                                        'create-passcode-range-picker',
                                                    ) as HTMLElement
                                                }
                                                format={'DD/MM/YYYY - HH:00'}
                                                disabledDate={controller.disabledDate}
                                                disabledTime={(e) => (e ? controller.disabledTime(e) : {})}
                                                showTime={{
                                                    showHour: true,
                                                    showMinute: false,
                                                    showSecond: false,
                                                    format: 'HH:00',
                                                }}
                                                disabled={controller.isLoading || controller.isCheckDateLoading}
                                            />
                                            <Text type="secondary" strong>
                                                {controller.formatedDates.length === 2 ? (
                                                    <>
                                                        {translate({ key: 'passcodes.valid-since' })}
                                                        <span style={{ color: '#262626d9' }}>
                                                            {' ' + controller.formatedDates[0] + ' '}
                                                        </span>
                                                        {translate({ key: 'passcodes.until' })}
                                                        <span style={{ color: '#262626d9' }}>
                                                            {' ' + controller.formatedDates[1]}
                                                        </span>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </Text>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item
                                        label={
                                            <Text type="secondary" strong>
                                                {translate({ key: 'label.label' })}
                                            </Text>
                                        }
                                        required
                                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                        name="code_label_temp"
                                    >
                                        <Input
                                            placeholder={translate({ key: 'form.placeholder.passcodes.label' })}
                                            maxLength={20}
                                            minLength={3}
                                            onChange={controller.onLabelInputChange}
                                            disabled={controller.isLoading || controller.isCheckDateLoading}
                                        />
                                    </Form.Item>
                                </>
                            )}
                        </TabPane>
                    </Tabs>
                </Form>
            ) : (
                <Space direction="vertical" align="center" style={{ width: '100%', margin: '1rem 0' }}>
                    <Title type="secondary" level={2} style={{ padding: 0 }}>
                        {translate({ key: 'passcodes.your-code-is' })}
                    </Title>
                    <Title level={1} copyable>
                        {controller.createdPasscode.code}
                    </Title>

                    <Text type="secondary" strong>
                        {translate({ key: 'label.type' })}:
                        <span style={{ color: '#262626d9' }}>
                            {' '}
                            {controller.createdPasscode.type === 1 && translate({ key: 'passcodes.single-use' })}
                            {controller.createdPasscode.type === 2 && translate({ key: 'passcodes.temporary' })}
                        </span>
                    </Text>
                    <Text type="secondary" strong>
                        {translate({ key: 'passcodes.valid-since' })}:
                        <span style={{ color: '#262626d9' }}> {controller.createdPasscode.since}</span>
                    </Text>
                    <Text type="secondary" strong>
                        {translate({ key: 'passcodes.valid-until' })}:
                        <span style={{ color: '#262626d9' }}> {controller.createdPasscode.until}</span>
                    </Text>

                    <Text type="secondary" strong>
                        {translate({ key: 'label.lock' })}:
                        <span style={{ color: '#262626d9', marginRight: '2rem' }}>
                            {' '}
                            {controller.createdPasscode.lock_name}
                        </span>
                    </Text>
                    <Text type="secondary" strong>
                        {translate({ key: 'label.label' })}:
                        <span style={{ color: '#262626d9' }}> {controller.createdPasscode.label}</span>
                    </Text>

                    <Text type="secondary" strong>
                        {/* {translate({ key: 'label.label' })}: */}
                        {/* <span style={{ color: '#262626d9' }}> {controller.createdPasscode.label}</span> */}
                        Para abrir presione:
                        <span style={{ color: '#262626d9' }}> {controller.createdPasscode.code}#</span>
                    </Text>
                    <Text type="secondary" strong>
                        <ExclamationCircleOutlined style={{ color: 'orange' }} />{' '}
                        {controller.createdPasscode.type === 1 && (
                            <>
                                {translate({ key: 'passcodes.passcode-single-use-description-1' })}{' '}
                                <span style={{ color: '#262626d9' }}>
                                    {translate({ key: 'passcodes.passcode-single-use-description-2' })}
                                </span>{' '}
                                {translate({ key: 'passcodes.passcode-single-use-description-3' })}
                            </>
                        )}
                        {controller.createdPasscode.type === 2 && (
                            <>
                                {translate({ key: 'passcodes.passcode-temporary-description-1' })}{' '}
                                <span style={{ color: '#262626d9' }}>
                                    {translate({ key: 'passcodes.passcode-temporary-description-2' })}
                                </span>{' '}
                                {translate({ key: 'passcodes.passcode-temporary-description-3' })}
                            </>
                        )}
                    </Text>
                </Space>
            )}
        </Modal>
    );
};
