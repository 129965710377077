import { SorterResult } from 'antd/lib/table/interface';
import { ITag, OrderTable, PaginationRequest, SorterInfo } from 'global/interfaces';
import React, { useContext } from 'react';
import { LockDto } from 'services/locks/locks.service';
import {
    SiteRfidCardDto,
    SiteRfidCardDetailResponse,
    FilterRfidCardsCustomFieldInterface,
} from 'services/rfidcards/rfidcards.service';
import { DeletionCardRow } from '../fragments/delete-site-card-progress-modal/interfaces';
import { FilterCustomField } from '../fragments/site-cards-filter-modal/interfaces';
// import { CreateKeySummaryDto } from '../fragments/create-key-summary/interfaces';
export interface GetAccessRfidCardsInterface {
    pagination?: PaginationRequest;
    orderTable?: OrderTable;
    search?: GetRfidCardsInterface;
}
export interface GetRfidCardsInterface {
    search?: string;
    since: string;
    until: string;
    card: string[];
    label: string[];
    devices: string[];
    authorizer: string[];
    status: number[];
    customFields: FilterCustomField[];
}

export interface SiteCardsContextInterface {
    isLoadingTable: boolean;
    rfidCards: SiteRfidCardDto[];
    totalRfidCards?: number;
    selectedCard?: SiteRfidCardDto;
    currentPage: number;
    pageSize: number;
    label: string[];
    cardNumber: string[];
    authorizer: string[];
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    selectedLockIds: string[];
    dateRange: { from: string; to: string };
    selectedRfidCardStatusOptions: number[];
    sortedInfo: SorterResult<SorterInfo>;
    isLoadingLocks: boolean;
    locks: LockDto[];
    tags: ITag[];
    // MODAL VISIBLE
    isFilterModalVisible: boolean;
    isEditModalVisible: boolean;
    isUserReassignmentModalVisible: boolean;

    deleteCard?: { id: string; cardNumber: string };
    isVisibleDeleteCardProgressModal: boolean;
    deletionModalRows: DeletionCardRow[];
    deletionEventSource?: EventSource;
    isLoadingDelete: boolean;

    isLoadingDetail: boolean;
    editSiteCardDetail?: SiteRfidCardDetailResponse;
    selectedCustomFields: FilterCustomField[];
    setSelectedCustomFields: React.Dispatch<React.SetStateAction<FilterCustomField[]>>;
    setEditSiteCardDetail: React.Dispatch<React.SetStateAction<SiteRfidCardDetailResponse | undefined>>;
    setIsLoadingDetail: React.Dispatch<React.SetStateAction<boolean>>;

    setIsLoadingDelete: React.Dispatch<React.SetStateAction<boolean>>;
    setDeleteCard: React.Dispatch<React.SetStateAction<{ id: string; cardNumber: string } | undefined>>;
    setIsVisibleDeleteCardProgressModal: React.Dispatch<React.SetStateAction<boolean>>;
    setDeletionModalRows: React.Dispatch<React.SetStateAction<DeletionCardRow[]>>;
    setDeletionEventSource: React.Dispatch<React.SetStateAction<EventSource | undefined>>;

    setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
    setSelectedRfidCardStatusOptions: React.Dispatch<React.SetStateAction<number[]>>;
    setDateRange: React.Dispatch<React.SetStateAction<{ from: string; to: string }>>;
    setSelectedLockIds: React.Dispatch<React.SetStateAction<string[]>>;
    setIsFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUserReassignmentModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    // setCreationSummary: React.Dispatch<React.SetStateAction<CreateRfidCardSummaryDto[]>>;
    setRfidCards: React.Dispatch<React.SetStateAction<SiteRfidCardDto[]>>;
    setTotalRfidCards: React.Dispatch<React.SetStateAction<number | undefined>>;
    setSelectedCard: React.Dispatch<React.SetStateAction<SiteRfidCardDto | undefined>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setLabel: React.Dispatch<React.SetStateAction<string[]>>;
    setCardNumber: React.Dispatch<React.SetStateAction<string[]>>;
    setAuthorizer: React.Dispatch<React.SetStateAction<string[]>>;

    setIsLoadingTable: React.Dispatch<React.SetStateAction<boolean>>;
    setSortedInfo: React.Dispatch<React.SetStateAction<SorterResult<SorterInfo>>>;
    //________________Functions___________
    getRfidCards: (payload: GetAccessRfidCardsInterface) => void;
    getLocks: () => void;
    getSiteCardDetail: (id: string) => void;
    deleteSiteCard: (id: string, cardNumber: string) => void;
}

export const SiteCardsContext = React.createContext<SiteCardsContextInterface>({
    isLoadingTable: false,
    isLoadingLocks: false,
    isFilterModalVisible: false,
    isEditModalVisible: false,
    isUserReassignmentModalVisible: false,
    locks: [],
    rfidCards: [],
    label: [],
    cardNumber: [],
    authorizer: [],
    selectedRfidCardStatusOptions: [1, 4],
    tags: [],
    currentPage: 0,
    pageSize: 20,
    orderBy: 'label',
    order: 'ascend',
    selectedCard: undefined,
    selectedLockIds: [],
    dateRange: { from: '', to: '' },
    sortedInfo: { order: 'ascend', columnKey: 'label' },
    deleteCard: undefined,
    isVisibleDeleteCardProgressModal: false,
    deletionModalRows: [],
    deletionEventSource: undefined,
    isLoadingDelete: false,
    isLoadingDetail: false,
    editSiteCardDetail: undefined,
    selectedCustomFields: [],
    setSelectedCustomFields: () => {
        /* Nothing to do*/
    },
    setEditSiteCardDetail: () => {
        /* Nothing to do*/
    },
    setIsLoadingDetail: () => {
        /* Nothing to do here*/
    },
    setIsLoadingDelete: () => {
        /* Nothing to do here*/
    },
    setDeleteCard: () => {
        /* Nothing to do here*/
    },
    setIsVisibleDeleteCardProgressModal: () => {
        /* Nothing to do here*/
    },
    setDeletionModalRows: () => {
        /* Nothing to do here*/
    },
    setDeletionEventSource: () => {
        /* Nothing to do here*/
    },
    setTags: () => {
        /* Nothing to do here*/
    },
    setSelectedRfidCardStatusOptions: () => {
        /* Nothing to do here*/
    },
    setDateRange: () => {
        /* Nothing to do here*/
    },
    setAuthorizer: () => {
        /* Nothing to do here*/
    },
    setSelectedLockIds: () => {
        /* Nothing to do here*/
    },
    setOrder: () => {
        /* Nothing to do here*/
    },
    setSelectedCard: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setTotalRfidCards: () => {
        /* Nothing to do here*/
    },
    setIsFilterModalVisible: () => {
        /* Nothing to do here*/
    },
    setIsEditModalVisible: () => {
        /* Nothing to do here*/
    },
    setIsUserReassignmentModalVisible: () => {
        /* Nothing to do here*/
    },
    setRfidCards: () => {
        /* Nothing to do here*/
    },
    setLabel: () => {
        /* Nothing to do here*/
    },
    setCardNumber: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setIsLoadingTable: () => {
        /* Nothing to do here*/
    },
    setSortedInfo: () => {
        /* Nothing to do here*/
    },
    //__________Functions__________
    getRfidCards: () => {
        /* Nothing to do here*/
    },
    getLocks: () => {
        /* Nothing to do here*/
    },
    deleteSiteCard: () => {
        /* Nothing to do here*/
    },
    getSiteCardDetail: () => {
        /* Nothing to do here*/
    },
});

export const useSiteCardsContext = (): SiteCardsContextInterface => {
    const store = useContext(SiteCardsContext);
    return store;
};
