import { useEffect, useState } from 'react';
import { FilterSitePoliciesModalController } from 'fragments/site-policies/fragments/filter-site-policies-modal/interfaces';
import { useSitePoliciesContext } from 'fragments/site-policies/context/site-policies.context';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { CheckboxOption, ITag } from 'global/interfaces';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { SearchSitePoliciesInterface } from 'services/site-policies/site-policies.service';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export const useFilterSitePoliciesModalController = (): /* <--Dependency Injections  like services hooks */
FilterSitePoliciesModalController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);

    const { translate } = useTranslator();
    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    const [statusOptions, setStatusOptions] = useState<CheckboxOption[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(true);
    //________________TAGS________________
    const [tempLockIds, setTempLockIds] = useState<string[]>([]);
    const [tempSearchInput, setTempSearchInput] = useState<string>('');
    const [tempStatusIds, setTempStatusIds] = useState<number[]>([]);

    const {
        setIsFilterModalVisible,
        isTableLoading,
        isLoadingLocks,
        pageSize,
        tags,
        setTags,
        locks,
        sitePolicies,
        searchInput,
        setSearchInput,
        selectedLockIds,
        setSelectedLockIds,
        selectedStatusIds,
        setSelectedStatusIds,
        isFilterModalVisible,
        getLocks,
        getSitePolicies,
        operatorSwitch,
        setOperatorSwitch,
    } = useSitePoliciesContext();

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            setSelectedLockIds([]);
            setSearchInput('');
            setTempSearchInput('');
            setTempLockIds([]);
            getLocks();
            setDefaultStatusOptions();
        }
    }, [selectedSite]);

    useEffect(() => {
        mapTags({
            locks: selectedLockIds,
            searchInput,
            operator: operatorSwitch,
            status: selectedStatusIds,
        });
        setTempLockIds(selectedLockIds);
        setTempSearchInput(searchInput);
        setTempStatusIds(selectedStatusIds);
    }, [selectedLockIds, searchInput, selectedStatusIds, locks]);

    useEffect(() => {
        form.resetFields();
    }, [sitePolicies]);

    /* View Events */
    // ________________Button Events___________________
    const onCancelButtonPressed = () => {
        setTempLockIds(selectedLockIds);
        setTempSearchInput(searchInput);
        setTempStatusIds(selectedStatusIds);
        setIsFilterModalVisible(false);
        setIndeterminate(!!selectedStatusIds.length && selectedStatusIds.length < defaultStatusOptions.length);
        form.resetFields();
    };

    const onResetButtonPressed = () => {
        setSelectedLockIds([]);
        setSearchInput('');
        setTempSearchInput('');
        setTempLockIds([]);
        getSitePolicies({ pagination: { page: 0, pageSize }, orderTable: { order: undefined, orderBy: undefined } });
        setIsFilterModalVisible(false);
        setDefaultStatusOptions();
        form.resetFields();
    };
    const onSubmitButtonPressed = () => {
        setSelectedLockIds(tempLockIds);
        setSearchInput(tempSearchInput);
        setSelectedStatusIds(tempStatusIds);
        getSitePolicies({
            pagination: { page: 0, pageSize },
            search: {
                locks: tempLockIds,
                searchInput: tempSearchInput,
                status: tempStatusIds,
                operator: operatorSwitch,
            },
        });
        setIsFilterModalVisible(false);
    };
    // ________________Input Events___________________
    const onLockSelect = (value: string[]) => {
        setTempLockIds(value);
    };

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTempSearchInput(e.target.value);
    };

    const onOperatorSwitchChange = (checked: boolean) => {
        console.log(`switch to ${checked}`);
        setOperatorSwitch(checked);
    };

    const onCheckStatusOptions = (list: CheckboxValueType[]) => {
        setTempStatusIds(list as number[]);
        setIndeterminate(!!list.length && list.length < statusOptions.length);
        setCheckAll(list.length === statusOptions.length);
    };

    const onCheckAllStatusOptions = (e: CheckboxChangeEvent) => {
        setTempStatusIds(e.target.checked ? statusOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    /* Private Methods */

    // ________________Form___________________
    const defaultStatusOptions: CheckboxOption[] = [
        { value: 0, label: translate({ key: 'site-policies.status-options.stand-by' }) },
        { value: 1, label: translate({ key: 'site-policies.status-options.enabled' }) },
        { value: 2, label: translate({ key: 'site-policies.status-options.in-review' }) },
        { value: 3, label: translate({ key: 'site-policies.status-options.finished' }) },
        { value: 4, label: translate({ key: 'site-policies.status-options.deleted' }) },
    ];

    const setDefaultStatusOptions = (): void => {
        const keyStatusIds: number[] = defaultStatusOptions.map((item) => item.value);
        setStatusOptions(defaultStatusOptions);
        setTempStatusIds(keyStatusIds);
        setSelectedStatusIds(keyStatusIds);
        setCheckAll(true);
    };

    // ________________TAGS___________________

    const onCloseTag = (tag: ITag) => {
        switch (tag.name) {
            case translate({ key: 'label.lock' }): {
                const newSelectedLockIds = selectedLockIds.filter((lockId) => lockId !== tag.id);
                setSelectedLockIds(newSelectedLockIds);
                getSitePolicies({
                    pagination: { page: 0, pageSize },
                    search: {
                        locks: newSelectedLockIds,
                        searchInput,
                        status: selectedStatusIds,
                        operator: operatorSwitch,
                    },
                });
                break;
            }
            case translate({ key: 'label.name' }): {
                setSearchInput('');
                getSitePolicies({
                    pagination: { page: 0, pageSize },
                    search: {
                        locks: selectedLockIds,
                        searchInput: '',
                        status: selectedStatusIds,
                        operator: operatorSwitch,
                    },
                });
                break;
            }

            case translate({ key: 'label.state' }): {
                const newSelectedStatus = selectedStatusIds.filter((id) => id !== tag.id);
                setSelectedStatusIds(newSelectedStatus);
                getSitePolicies({
                    pagination: { page: 0, pageSize },
                    search: {
                        locks: selectedLockIds,
                        searchInput,
                        status: newSelectedStatus,
                        operator: operatorSwitch,
                    },
                });

                break;
            }
            default:
                break;
        }
    };

    const mapTags = (search: SearchSitePoliciesInterface) => {
        const { locks: selectedLockIds, searchInput, status } = search;
        const tempLockTags: ITag[] = [];
        const tempSearchTags: ITag[] = [];
        const tempSelectedStatus: ITag[] = [];

        selectedLockIds.map((id) => {
            const selectedLock = locks.find((lock) => lock.id === id);
            tempLockTags.push({
                id,
                name: translate({ key: 'label.lock' }),
                value: `${selectedLock?.name}`,
                color: 'geekblue',
                closable: true,
            });
        });

        if (searchInput !== '') {
            tempSearchTags.push({
                id: searchInput,
                name: translate({ key: 'label.name' }),
                value: searchInput,
                color: 'purple',
                closable: true,
            });
        }

        if (status.length !== statusOptions.length) {
            status.map((value) => {
                const selectedStatus = statusOptions.find((option) => option.value === value);
                tempSelectedStatus.push({
                    id: value,
                    name: translate({ key: 'label.state' }),
                    value: `${selectedStatus?.label}`,
                    color: 'green',
                    closable: true,
                });
            });
            if (tempSelectedStatus.length === 1) {
                tempSelectedStatus[0].closable = false;
            }
        }

        setTags([...tempLockTags, ...tempSearchTags, ...tempSelectedStatus]);
    };
    // Return state and events
    return {
        form,
        isFilterModalVisible,
        isLoadingLocks,
        selectedLockIds,
        locks,
        searchInput,
        tags,
        isTableLoading,
        operatorSwitch,
        checkAll,
        indeterminate,
        tempStatusIds,
        tempLockIds,
        statusOptions,
        onOperatorSwitchChange,
        onSearchInputChange,
        onCancelButtonPressed,
        onResetButtonPressed,
        onLockSelect,
        onSubmitButtonPressed,
        onCloseTag,
        onCheckStatusOptions,
        onCheckAllStatusOptions,
    };
};
