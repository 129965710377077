import React from 'react';
import 'fragments/download-reports/fragments/expandable-row-report-file-detail/expandable-row-report-file-detail.scss';
import { ExpandableRowReportFileDetailFragmentProps } from 'fragments/download-reports/fragments/expandable-row-report-file-detail/interfaces';
import { useExpandableRowReportFileDetailController } from 'fragments/download-reports/fragments/expandable-row-report-file-detail/expandable-row-report-file-detail.controller';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, List, Row, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import Title from 'antd/es/typography/Title';
const { Text } = Typography;
export const ExpandableRowReportFileDetailFragment: React.FC<ExpandableRowReportFileDetailFragmentProps> = (props) => {
    const { useController = useExpandableRowReportFileDetailController, reportFileId } = props;
    const controller = useController(reportFileId);
    const { translate } = useTranslator();

    const getAccessMethodById = (id: number): string => {
        switch (id) {
            case 1:
                return translate({ key: 'access-reports.access-method-option.app' });
            case 4:
                return translate({ key: 'access-reports.access-method-option.passcode' });
            case 7:
                return translate({ key: 'access-reports.access-method-option.rfid' });
            case 8:
                return translate({ key: 'access-reports.access-method-option.fingerprint' });
            case 12:
                return translate({ key: 'access-reports.access-method-option.remote-access' });
            case 46:
                return translate({ key: 'access-reports.access-method-option.button' });
            default:
                return translate({ key: 'general.unknown' });
        }
    };
    // Render
    return (
        <div className={'expandable-row-report-file-detail'}>
            {!controller.isLoading && controller.reportFileDetail ? (
                <Row gutter={8} style={{ width: '100%', justifyContent: 'space-between' }}>
                    {controller.reportFileDetail.access_methods.length && (
                        <Col style={{ padding: '0 1rem' }}>
                            <Title level={5} style={{ padding: 0, marginTop: '.5rem', fontSize: '14px' }}>
                                {translate({ key: 'label.access-method' })}
                            </Title>
                            <List
                                className="expandable-row-report-file-detail-col"
                                dataSource={controller.reportFileDetail.access_methods}
                                renderItem={(item) => (
                                    <List.Item key={item}>
                                        <List.Item.Meta description={<Text>{getAccessMethodById(item)}</Text>} />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    )}
                    {controller.reportFileDetail.users.length && (
                        <Col span={8} style={{ padding: '0 1rem' }}>
                            <Title style={{ padding: 0, marginTop: '.5rem', fontSize: '14px' }}>
                                {translate({ key: 'label.user' })}
                            </Title>
                            <List
                                className="expandable-row-report-file-detail-col"
                                dataSource={controller.reportFileDetail.users}
                                renderItem={(item) => (
                                    <List.Item key={item.name}>
                                        <List.Item.Meta description={<Text>{`${item.name} - ${item.email}`}</Text>} />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    )}
                    {controller.reportFileDetail.site_cards.length && (
                        <Col span={8} style={{ padding: '0 1rem' }}>
                            <Title style={{ padding: 0, marginTop: '.5rem', fontSize: '14px' }}>
                                {translate({ key: 'label.card' })}
                            </Title>
                            <List
                                className="expandable-row-report-file-detail-col"
                                dataSource={controller.reportFileDetail.site_cards}
                                renderItem={(item) => (
                                    <List.Item key={item}>
                                        <List.Item.Meta description={<Text>{item}</Text>} />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    )}
                </Row>
            ) : (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        minHeight: '350px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {controller.isLoading && <LoadingOutlined style={{ fontSize: '3rem', color: '#8224e2' }} />}
                </div>
            )}
        </div>
    );
};
