import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { SorterInfo } from 'global/interfaces';
import { OperationDto } from 'services/operations/operations.service';
import { SseEvent } from 'services/sse/sse.service';

export interface OperationListController {
    /* State */
    isTableLoading: boolean;
    isLoadingOperationCommand: boolean;
    itemsViewModelTable: OperationTableRow[];
    totalOperations: number;
    currentPage: number;
    pageSize: number;
    sortedInfo: SorterResult<SorterInfo>;
    eventError: boolean;
    /* Events */
    onReloadButtonPressed: () => void;
    onAddNewOperationButtonPressed: () => void;
    onViewOperationActions: (id: string) => void;
    onCommandButtonPressed: (id: string, status: number) => void;
    handleTableChange: (pagination: TablePaginationConfig, filters: any, sorter: any) => void;
}

export interface OperationListFragmentProps {
    useController?: () => OperationListController;
}

export interface OperationTableRow {
    id: string;
    name: string;
    createdByEmail: string;
    type: string;
    statusId: number;
    createdAt: string;
    count: {
        finished: number;
        total: number;
    };
    updatedByEmail: string;
    updatedAt: string;
}

export enum OperationListActionTypes {
    HANDLE_EVENT = 'HANDLE_EVENT',
    SET_INITIAL_STATE = 'SET_INITIAL_STATE',
}
export interface OperationListStateAction {
    type: OperationListActionTypes;
    payload: SseEvent<{ status_id?: number }> | OperationDto[];
}

export type HandleEventAction = {
    type: OperationListActionTypes.HANDLE_EVENT;
    payload: SseEvent<{ status_id?: number }>;
};

export type SetInitialStateAction = {
    type: OperationListActionTypes.SET_INITIAL_STATE;
    payload: OperationDto[];
};

export type ReducerAction = SetInitialStateAction | HandleEventAction;
export interface OperationListState {
    operations: OperationDto[];
    events: SseEvent<{ status_id?: number }>[];
}
