import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const RfidSvg = () => (
    <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 768 768"
        stroke="currentColor"
        fill="currentColor"
    >
        <defs>
            <style>
                {
                    '.RfidSvgcls-1{font-size:197.33px;font-family:Calibri-Bold, Calibri;font-weight:700;}.RfidSvgcls-2{fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:18px;}'
                }
            </style>
        </defs>
        <title>iconos_accefy_sga</title>
        <text className="RfidSvgcls-1" transform="translate(91.03 524.26)">
            RFID
        </text>
        <path
            className="RfidSvgcls-2"
            d="M533.51,443.68v136A70.58,70.58,0,0,1,463.14,650H112.83a70.57,70.57,0,0,1-70.37-70.37V354.85a70.57,70.57,0,0,1,70.37-70.37H373.27"
        />
        <path d="M532,297.28a101.7,101.7,0,0,0-33.61-23.65c-18.45-8-39.47-10.4-60.74-7a21.56,21.56,0,0,0-11.51,5.87,22.36,22.36,0,0,0-6.77,14.66,16.88,16.88,0,0,0,5.42,13.59,17.41,17.41,0,0,0,14.22,4c18.88-3,37.26.94,51.76,11s24.13,25.51,27.1,43.58a81,81,0,0,1-.08,24.62,17.5,17.5,0,0,0,3.69,14,16.72,16.72,0,0,0,13,5.8,21.8,21.8,0,0,0,14.31-5.68,22.48,22.48,0,0,0,7.43-13.69c5.43-33-3.43-64.75-24.32-87.21Zm100,20.44c-9.13-43.11-33.64-79.59-69.26-103.1S482.64,182,437,189a23.29,23.29,0,0,0-17,12c-3.49,6.5-3.48,13.9,0,19.43s10,8.35,17,7.39a160.43,160.43,0,0,1,41.3-.91c32.77,3.61,62,18,83.41,41s33.71,53.39,35.17,86.61a183.52,183.52,0,0,1-1.78,31.91,17.5,17.5,0,0,0,2.14,10.94,15.71,15.71,0,0,0,8.28,6.84,19.46,19.46,0,0,0,11.74.26A23.68,23.68,0,0,0,628,397.92a21.43,21.43,0,0,0,5.58-11.23,188.48,188.48,0,0,0-1.55-69Zm76.81,76.81a39.13,39.13,0,0,0,3.2-13.8c7.24-55.92-2.66-110.39-28.37-156.11a235.27,235.27,0,0,0-37.46-49.41,236.66,236.66,0,0,0-31.76-26.07,230.8,230.8,0,0,0-47.34-24.59c-40.53-15.42-85.69-19.85-131.37-12.88a22.43,22.43,0,0,0-15.32,9,21.09,21.09,0,0,0-4.57,10.39,16.91,16.91,0,0,0,1.81,10.37,15.23,15.23,0,0,0,7.17,7.43,17.47,17.47,0,0,0,10.72,1.42c2.95-.35,5.78-.7,8.85-1.17h0c35.13-4.21,69.69-.57,101.15,10.66,51.09,18.31,91.14,55.71,112.63,105.2,12.14,27.6,18,58.25,17.2,90-.26,10.86-1.63,21.83-2.77,32.78a17.77,17.77,0,0,0,2.91,12.13,16.35,16.35,0,0,0,10.12,6.66,20.4,20.4,0,0,0,13.19-2.09,23.62,23.62,0,0,0,10-9.91Z" />
    </svg>

    // <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     className="h-6 w-6"
    //     fill="none"
    //     viewBox="0 0 24 24"
    //     stroke="currentColor"
    //     strokeWidth="2"
    // >
);
const RfidIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={RfidSvg} {...props} />;
export default RfidIcon;
