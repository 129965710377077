import { useEffect, useReducer, useState } from 'react';
import {
    ReducerAction,
    MassiveLoadModalActionTypes,
    MassiveLoadModalController,
    MassiveLoadModalState,
} from './interfaces';
import { useMassiveRfidCardsFormContext } from 'fragments/massive-rfid-cards-form/context/massive-rfid-cards-form.context';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { EventTypes, SseEvent } from 'services/sse/sse.service';
import { CreateRfidCardRelation } from 'services/rfidcards/rfidcards.service';

export const useMassiveLoadModalController = (
    resetForm: () => void,
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
MassiveLoadModalController => {
    /* State */
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [retryRelations, setRetryRelations] = useState<CreateRfidCardRelation[]>([]);
    const {
        isMassiveLoadModalVisible,
        isRetryCreationLoading,
        setIsRetryCreationLoading,
        rows,
        creationEvent,
        sendAndSubscribe,
        retryInputValues,
        resetCreationResultStates,
    } = useMassiveRfidCardsFormContext();

    useEffect(() => {
        if (!creationEvent) return;
        dispatch({ type: MassiveLoadModalActionTypes.SET_INITIAL_STATE, payload: rows });
        creationEvent.onerror = (e) => {
            dispatch({ type: MassiveLoadModalActionTypes.UPDATE_STATUS_COUNT, payload: null });
            setIsLoading(false);
            creationEvent.close();
        };
        creationEvent.onmessage = (event) => {
            const eventData: SseEvent<{ code?: number; message?: string }> = JSON.parse(event.data);
            dispatch({ type: MassiveLoadModalActionTypes.HANDLE_EVENT, payload: eventData });
        };

        return () => {
            creationEvent.close();
        };
    }, [creationEvent]);

    const getErrorMessage = (code?: number, msg?: string): string => {
        if (!code) return '';
        if (code != 400 && code != 409 && code != 500) {
            return translate({ key: `rfid-card.errors.code.${code}` });
        }
        return msg || '';
    };

    const reducer: React.Reducer<MassiveLoadModalState, ReducerAction> = (state, { type, payload }) => {
        switch (type) {
            case MassiveLoadModalActionTypes.SET_INITIAL_STATE:
                return {
                    rows: payload,
                    events: [],
                    statusCount: {
                        success: 0,
                        failed: 0,
                        warning: 0,
                        unknown: 0,
                    },
                };
            case MassiveLoadModalActionTypes.HANDLE_EVENT: {
                let rows = state.rows;
                const statusCount = state.statusCount;
                switch (payload.event_type) {
                    case EventTypes.EVENT_CARDS_CARD_LOCK_CREATION_SUCCEEDED: {
                        rows = rows.map((r) =>
                            r.sub_event_stream_id == payload.sub_event_stream_id ? { ...r, status: 2 } : r,
                        );
                        statusCount.success = statusCount.success + 1;
                        break;
                    }
                    case EventTypes.EVENT_CARDS_CARD_LOCK_CREATION_FAILED: {
                        const { code, message } = payload.extended_info;
                        rows = rows.map((r) =>
                            r.sub_event_stream_id == payload.sub_event_stream_id
                                ? { ...r, status: 3, message: getErrorMessage(code, message) }
                                : r,
                        );
                        statusCount.failed = statusCount.failed + 1;
                        break;
                    }
                    case EventTypes.EVENT_TTLOCKAPI_CARD_CREATION_FAILED: {
                        const { code, message } = payload.extended_info;
                        const canRetry =
                            code == 1 || code == 41 || code == 42 || code == 43 || code == 47 || code == 48;
                        rows = rows.map((r) =>
                            r.sub_event_stream_id == payload.sub_event_stream_id
                                ? { ...r, status: canRetry ? 4 : 3, message: getErrorMessage(code, message) }
                                : r,
                        );
                        if (canRetry) {
                            statusCount.warning = statusCount.warning + 1;
                        } else {
                            statusCount.failed = statusCount.failed + 1;
                        }
                        break;
                    }
                    case EventTypes.EVENT_CARDS_REMOTE_CREATION_RESOURCE_LOCKED: {
                        rows = rows.map((r) =>
                            r.sub_event_stream_id == payload.sub_event_stream_id
                                ? { ...r, status: 4, message: translate({ key: `rfid-card.errors.resource-locked` }) }
                                : r,
                        );
                        statusCount.warning = statusCount.warning + 1;
                        break;
                    }
                    case EventTypes.EVENT_CARDS_REMOTE_CREATION_ENDED:
                        setIsLoading(false);
                        const relations: CreateRfidCardRelation[] = [];
                        rows.map((r) => {
                            r.status === 4 && relations.push({ card_id: r.card_id, device_id: r.device_id });
                        });
                        setRetryRelations(relations);
                        creationEvent?.close();
                        break;
                    default:
                        break;
                }

                return {
                    ...state,
                    rows,
                    statusCount,
                    events: [...state.events, payload],
                };
            }
            case MassiveLoadModalActionTypes.UPDATE_STATUS_COUNT: {
                const rows = state.rows;
                const statusCount = state.statusCount;
                const relations: CreateRfidCardRelation[] = [];
                rows.map((r) => {
                    r.status === 4 && relations.push({ card_id: r.card_id, device_id: r.device_id });
                });
                setRetryRelations(relations);
                let unknownStatus = 0;
                const mapRows = rows.map((r) => {
                    if (r.status === 1) {
                        unknownStatus++;
                        return { ...r, status: 0, message: translate({ key: `general.unknown` }) };
                    }
                    return r;
                });
                statusCount.unknown = unknownStatus;
                return {
                    ...state,
                    rows: mapRows,
                    statusCount,
                };
            }
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(reducer, {
        rows,
        events: [],
        statusCount: {
            success: 0,
            failed: 0,
            warning: 0,
            unknown: 0,
        },
    });

    /* View Events */
    const onCloseModalPressed = () => {
        creationEvent?.close();
        dispatch({ type: MassiveLoadModalActionTypes.SET_INITIAL_STATE, payload: [] });
        setRetryRelations([]);
        resetCreationResultStates();
        resetForm();
    };

    const onTryAgainPressed = () => {
        setIsRetryCreationLoading(true);
        dispatch({ type: MassiveLoadModalActionTypes.SET_INITIAL_STATE, payload: [] });
        const input = { relations: retryRelations, ...retryInputValues! };
        setIsLoading(true);
        sendAndSubscribe(input);
        setRetryRelations([]);
    };

    /*private methods*/

    // Return state and events
    return {
        rows: state.rows,
        statusCount: state.statusCount,
        isLoading,
        retryRelations,
        isMassiveLoadModalVisible,
        isRetryCreationLoading,
        onCloseModalPressed,
        onTryAgainPressed,
    };
};
