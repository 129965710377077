/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, Key } from 'react';
import {
    CreateKeyController,
    CreateKeySummaryDto,
    CreateKeySummaryTableRecord,
} from 'fragments/common/create-key/interfaces';
import { useAPIKeys } from 'services/keys/api-keys.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { CreateKeyInput, KeyScheduleOption, OverwriteSmartTokenRecordRequest } from 'services/keys/keys.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useAPILocks } from 'services/locks/api-locks.service';
import { LockDto } from 'services/locks/locks.service';
// import { ErrorDto } from 'services/dtos/errors..dto';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/lib/date-picker';

export const useCreateKeyController = (
    onClose: () => void,
    lockId?: string,
    onFinish?: () => void,
    keyService = useAPIKeys(),
    messenger = useMessenger(),
    lockService = useAPILocks(),
): CreateKeyController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disabledDowButtons, setDisabledDowButtons] = useState<boolean>(false);
    const [isDatePickerVisible, setIsDatePickerVisible] = useState<boolean>(false);
    const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [keySchedules, setKeySchedules] = useState<KeyScheduleOption[]>([]);
    const [dowBytewise, setDowBytewise] = useState<number>(0);
    const [selectedClientEmails, setSelectedClientEmails] = useState<string[]>([]);
    const [selectedLockIds, setSelectedLockIds] = useState<string[]>([]);
    const [locks, setLocks] = useState<LockDto[]>([]);
    const [dateRange, setDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [timeRange, setTimeRange] = useState<{ from: string; to: string }>({ from: '00:00', to: '23:59' });
    // const [creationSummary, setCreationSummary] = useState<CreateKeySummaryDto[]>([]);
    const [marks, setMarks] = useState<any>({ 0: '00:00hs', 1439: '23:59hs' });

    const [isContinueEnabled, setIsContinueEnabled] = useState(false);
    const [isDataSending, setIsDataSending] = useState(false);
    const [successCount, setSuccessCount] = useState(0);
    const [warningCount, setWarningCount] = useState(0);
    const [errorCount, setErrorCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState<CreateKeySummaryTableRecord[]>([]);
    const [rows, setRows] = useState<CreateKeySummaryDto[]>([]);
    const [isCancelLoading, setIsCancelLoading] = useState(true);
    const [cancelTimeout, setCancelTimeout] = useState(3);

    const [dowString, setDowString] = useState<string>('');
    const [session] = useLocalSession();
    const { translate } = useTranslator();
    const [form] = useForm();
    const [isLockListingLoading, setIsLockListingLoading] = useState(false);

    const durations: any[] = [
        { amount: 0, unit: 'day' },
        { amount: 1, unit: 'day' },
        { amount: 1, unit: 'month' },
        { amount: 3, unit: 'month' },
        { amount: 6, unit: 'month' },
        { amount: 1, unit: 'year' },
        { amount: 2, unit: 'year' },
    ];

    /* Listeners */
    useEffect(() => {
        //TODO clean it, can we have 2 useEfffects? now we have 3 =D
        if (keySchedules.length > 0) {
            setInitialDayOptions();
        } else {
            setKeySchedules(getDefaultKeySchedules());
        }
    }, []);

    useEffect(() => {
        if (session.selectedSite) {
            getLocks();
        }
        setCurrentStep(0);
        setDisabledDowButtons(false);
        setMarks({ 0: '00:00hs', 1439: '23:59hs' });
        setDateRange({ from: '', to: '' });
        setDowBytewise(0);
        setDowString('');
    }, [session.selectedSite]);

    useEffect(() => {
        lockId && setSelectedLockIds([lockId]);
    }, [lockId]);

    /* View Events */

    const onCancelButtonPressed = () => {
        setSelectedLockIds([]);
        setSelectedClientEmails([]);
        setDowBytewise(0);
        setDowString('');
        setKeySchedules(getDefaultKeySchedules());
        setCurrentStep(0);
        setDisabledDowButtons(false);
        setDateRange({ from: '', to: '' });
        setMarks({ 0: '00:00hs', 1439: '23:59hs' });
        form.resetFields();
        onClose();
    };

    const onCloseModal = () => {
        setCurrentStep(0);
        setMarks({ 0: '00:00hs', 1439: '23:59hs' });
        onFinish && onFinish();
        onClose();
    };

    const onNextStepButtonPressed = () => {
        setCurrentStep(currentStep + 1);
    };

    const onPrevStepButtonPressed = () => {
        setCurrentStep(currentStep - 1);
    };

    const onCreateKeyFormSubmit = (formInputs: unknown) => {
        setIsLoading(true);
        const input = formInputs as CreateKeyInput;
        input.since_date = dateRange.from;
        input.until_date = dateRange.to;
        input.since_time = timeRange.from;
        input.until_time = timeRange.to;
        input.dow = dowBytewise;
        input.guests = selectedClientEmails.map((email) => {
            return { value: email, type_id: 1 };
        });
        input.locks = selectedLockIds.map((lock) => {
            return { lock_id: lock };
        });

        keyService
            .createKey(input)
            .then((r: CreateKeySummaryDto[]) => {
                setSelectedLockIds([]);
                setSelectedClientEmails([]);
                setDowBytewise(0);
                setKeySchedules(getDefaultKeySchedules());
                setDateRange({ from: '', to: '' });
                setDowString('');
                form.resetFields();
                // setCreationSummary(r);
                setMarks({ 0: '00:00hs', 1439: '23:59hs' });
                setCurrentStep(currentStep + 1);
                setRows(sortData(r));
                const timeoutInterval = setInterval(() => {
                    setCancelTimeout((pc) => {
                        if (pc < 1) {
                            setIsCancelLoading(false);
                            clearInterval(timeoutInterval);
                            return 0;
                        }
                        return pc - 1;
                    });
                }, 1000);
                setSuccessCount(
                    r.filter((r) => {
                        return r.status == 0;
                    }).length,
                );
                setWarningCount(
                    r.filter((r) => {
                        return r.status == 2;
                    }).length,
                );
                setErrorCount(
                    r.filter((r) => {
                        return r.status == 1;
                    }).length,
                );
            })
            .catch((errorMsg) => {
                if (errorMsg == 'user') {
                    messenger.showErrorMessage({ key: 'key.create-error-user-not-exist' });
                } else if (errorMsg == 'Role') {
                    messenger.showErrorMessage({ key: 'key.create-error-forbidden' });
                } else {
                    messenger.showErrorMessage({ key: 'key.create-error' });
                    console.log(errorMsg);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onClientEmailAdd = (value: string[]) => {
        setSelectedClientEmails(value);
        setInvalidEmails(value.filter((email) => !validateEmail(email.toLowerCase())));
    };
    const onLockSelect = (value: string[]) => {
        setSelectedLockIds(value);
    };

    const onPickedDate = (date: any) => {
        setDisabledDowButtons(false);
        if (date) {
            if (dayjs(date[0]).format('YYYY-MM-DD') == dayjs(date[1]).format('YYYY-MM-DD')) {
                const dow: KeyScheduleOption = dayOfWeekNumberToKeySchedules(dayjs(date[0]).day());
                setDowBytewise(dow.key);
                setDowString(dow.label);
            } else if (dayjs(date[1]).subtract(7, 'day') < dayjs(date[0])) {
                let keySchedules: KeyScheduleOption[] = getDefaultKeySchedules();
                let dow = 0;
                for (let i = dayjs(date[0]); i <= dayjs(date[1]); i = i.add(1, 'day')) {
                    keySchedules = keySchedules.map((item) => {
                        if (item.key === dayOfWeekNumberToKeySchedules(i.day()).key) {
                            dow += item.key;
                            return { ...item, selected: true };
                        }
                        return item;
                    });
                }
                keySchedules = keySchedules.map((item) => {
                    if (!item.selected) {
                        return { ...item, disabled: true };
                    }
                    return item;
                });

                if (dow === 31) {
                    setDowString(translate({ key: 'general.weekdays' }));
                } else if (dow === 63) {
                    setDowString(translate({ key: 'general.monday-to-saturday' }));
                } else if (dow === 127) {
                    setDowString(translate({ key: 'key.all-days' }));
                } else {
                    let dayString = '';
                    keySchedules.map((day) => {
                        if (day.selected) {
                            if (!dayString.length) {
                                dayString = day.label;
                            } else {
                                dayString = `${dayString}, ${day.label}`;
                            }
                        }
                    });
                    setDowString(dayString);
                }
                setDowBytewise(dow);
                setKeySchedules(keySchedules);
                setDisabledDowButtons(true);
            } else {
                onWeekdaysButtonPressed();
            }
            setDateRange({ from: dayjs(date[0]).format('YYYY-MM-DD'), to: dayjs(date[1]).format('YYYY-MM-DD') });
        } else {
            setDateRange({ from: '', to: '' });
        }
    };
    const disabledDate: RangePickerProps['disabledDate'] = (current: Dayjs) => {
        return current && current < dayjs().startOf('day');
    };

    const onDayPressed = (key: number) => {
        let dow = dowBytewise;
        const updatedKeySchedules = keySchedules.map((keySchedule) => {
            if (keySchedule.key == key) {
                keySchedule.selected = !keySchedule.selected;
                if (keySchedule.selected) {
                    dow += key;
                    setDowBytewise(dowBytewise + key);
                } else {
                    dow -= key;
                    setDowBytewise(dowBytewise - key);
                }
            }
            return keySchedule;
        });

        if (dow === 31) {
            setDowString(translate({ key: 'general.weekdays' }));
        } else if (dow === 63) {
            setDowString(translate({ key: 'general.monday-to-saturday' }));
        } else if (dow === 127) {
            setDowString(translate({ key: 'key.all-days' }));
        } else {
            let dayString = '';
            updatedKeySchedules.map((day) => {
                if (day.selected) {
                    if (!dayString.length) {
                        dayString = day.label;
                    } else {
                        dayString = `${dayString}, ${day.label}`;
                    }
                }
            });
            setDowString(dayString);
        }
        setKeySchedules(updatedKeySchedules);
    };

    const onSelectDuration = (e: any) => {
        setIsDatePickerVisible(false);
        setDisabledDowButtons(false);
        const selectedDuration: any = durations[e.target.value as number];
        if ((e.target.value as number) == 7) {
            setDateRange({ from: '', to: '' });
            setIsDatePickerVisible(true);
            setKeySchedules(getDefaultKeySchedules());
            setDowBytewise(0);
            setDowString('');
        } else if ((e.target.value as number) < 2) {
            const keyScheduleOption: KeyScheduleOption = dayOfWeekNumberToKeySchedules(
                dayjs().add(selectedDuration.amount, selectedDuration.unit).day(),
            );
            const updatedKeySchedules = getDefaultKeySchedules().map((keySchedule) => {
                if (keySchedule.key == keyScheduleOption.key) {
                    keySchedule.selected = !keySchedule.selected;
                    setDowString(keySchedule.label);
                }
                return keySchedule;
            });
            setKeySchedules(updatedKeySchedules);
            setDowBytewise(keyScheduleOption.key);
            setDateRange({
                from: dayjs().add(selectedDuration.amount, selectedDuration.unit).format('YYYY-MM-DD'),
                to: dayjs().add(selectedDuration.amount, selectedDuration.unit).format('YYYY-MM-DD'),
            });
        } else {
            onWeekdaysButtonPressed();
            setDateRange({
                from: dayjs().format('YYYY-MM-DD'),
                to: dayjs().add(selectedDuration.amount, selectedDuration.unit).format('YYYY-MM-DD'),
            });
        }
    };

    const onWeekdaysButtonPressed = () => {
        getDefaultKeySchedules();
        const onlyWeekdays = getDefaultKeySchedules().map((option): KeyScheduleOption => {
            if (option.key < 32) {
                return { ...option, selected: true };
            }
            return option;
        });
        setKeySchedules(onlyWeekdays);
        setDowString(translate({ key: 'general.weekdays' }));
        setDowBytewise(31);
    };

    const onSetTimeSlider = (value: number[]) => {
        setTimeRange({ from: sliderFormatter(value[0]), to: sliderFormatter(value[1]) });
        setMarks({
            0: '00:00hs',
            [value[0]]: `${sliderFormatter(value[0])}hs`,
            [value[1]]: `${sliderFormatter(value[1])}hs`,
            1439: '23:59hs',
        });
    };

    //_________________ summary __________________
    const onSelectChange = (x: Key[], y: CreateKeySummaryTableRecord[]) => {
        setSelectedRows(y);
        setIsContinueEnabled(y.length > 0);
    };

    const onContinue = () => {
        setIsDataSending(true);
        const overwriteRequest: OverwriteSmartTokenRecordRequest[] = [];
        for (let i = 0; i < selectedRows.length; i++) {
            const sr = selectedRows[i];
            overwriteRequest.push({
                smart_token_record_id: rows[sr.key].smart_token_record_id!,
            });
        }
        // setCreationSummary([]);
        keyService
            .overwriteKeys(overwriteRequest)
            .then((r: CreateKeySummaryDto[]) => {
                setRows(sortData(r));
                setSuccessCount(
                    r.filter((r) => {
                        return r.status == 0;
                    }).length,
                );
                setWarningCount(
                    r.filter((r) => {
                        return r.status == 2;
                    }).length,
                );
                setErrorCount(
                    r.filter((r) => {
                        return r.status == 1;
                    }).length,
                );
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'key.create-error' });
            })
            .finally(() => {
                setIsDataSending(false);
                setSelectedRows([]);
            });
    };

    /* Private Methods */
    const sliderFormatter = (value: number): string => {
        const hours: number = Math.floor(value! / 60);
        const finalMinutes: number = Math.floor(value! % 60);
        const formatterTime: string = dayjs().set('hour', hours).set('minute', finalMinutes).format('HH:mm');
        return formatterTime;
    };

    const validateEmailRegExp =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    const validateEmail = (value: string): boolean => {
        return validateEmailRegExp.test(value);
    };
    const dayOfWeekNumberToKeySchedules = (value: number): KeyScheduleOption => {
        const defaults = { selected: false, disabled: false };
        switch (value) {
            case 1:
                return { key: 1, label: translate({ key: 'general.monday' }), ...defaults };
            case 2:
                return { key: 2, label: translate({ key: 'general.tuesday' }), ...defaults };
            case 3:
                return { key: 4, label: translate({ key: 'general.wednesday' }), ...defaults };
            case 4:
                return { key: 8, label: translate({ key: 'general.thursday' }), ...defaults };
            case 5:
                return { key: 16, label: translate({ key: 'general.friday' }), ...defaults };
            case 6:
                return { key: 32, label: translate({ key: 'general.saturday' }), ...defaults };
            case 0:
                return { key: 64, label: translate({ key: 'general.sunday' }), ...defaults };
            default:
                return { key: 0, label: '', ...defaults };
        }
    };

    const getDefaultKeySchedules = (): KeyScheduleOption[] => {
        const defaults = { selected: false, disabled: false };
        return [
            { key: 64, label: translate({ key: 'general.sunday' }), ...defaults },
            { key: 1, label: translate({ key: 'general.monday' }), ...defaults },
            { key: 2, label: translate({ key: 'general.tuesday' }), ...defaults },
            { key: 4, label: translate({ key: 'general.wednesday' }), ...defaults },
            { key: 8, label: translate({ key: 'general.thursday' }), ...defaults },
            { key: 16, label: translate({ key: 'general.friday' }), ...defaults },
            { key: 32, label: translate({ key: 'general.saturday' }), ...defaults },
        ];
    };

    const setInitialDayOptions = () => {
        const initialDayOptions = keySchedules;
        setKeySchedules(
            getDefaultKeySchedules().map((dayOption): KeyScheduleOption => {
                const initialValue = initialDayOptions.find((o) => o.key == dayOption.key);
                if (initialValue) {
                    return {
                        ...dayOption,
                        selected: true,
                    };
                } else {
                    return dayOption;
                }
            }),
        );
    };

    const getLocks = () => {
        setIsLockListingLoading(true);
        lockService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks);
            })
            .catch((error) => {
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLockListingLoading(false);
            });
    };

    const sortData = (unsortedData: CreateKeySummaryDto[]): CreateKeySummaryDto[] => {
        return unsortedData.sort(function (a, b) {
            if (a.status === b.status) {
                if (a.email == b.email) {
                    if (a.lock != null && b.lock != null) {
                        return a.lock.localeCompare(b.lock);
                    }
                }
                return a.email.localeCompare(b.email);
            }
            return a.status > b.status ? -1 : 1;
        });
    };

    // Return state and events
    return {
        isContinueEnabled,
        isDataSending,
        selectedRows,
        successCount,
        warningCount,
        errorCount,
        data: rows,
        isCancelLoading,
        cancelTimeout,
        onSelectChange,
        onContinue,
        onCloseModal,
        dowString,
        marks,
        isLockListingLoading,
        isLoading,
        keySchedules,
        dateRange,
        timeRange,
        locks,
        selectedClientEmails,
        dowBytewise,
        selectedLockIds,
        form,
        currentStep,
        invalidEmails,
        validateEmailRegExp,
        isDatePickerVisible,
        disabledDowButtons,
        onSelectDuration,
        disabledDate,
        onSetTimeSlider,
        onNextStepButtonPressed,
        onPrevStepButtonPressed,
        onCancelButtonPressed,
        onCreateKeyFormSubmit,
        onPickedDate,
        onWeekdaysButtonPressed,
        onDayPressed,
        onClientEmailAdd,
        onLockSelect,
    };
};
