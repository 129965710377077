import React from 'react';
import 'fragments/download-reports/fragments/create-report-file-modal/create-report-file-modal.scss';
import { CreateReportFileModalFragmentProps } from 'fragments/download-reports/fragments/create-report-file-modal/interfaces';
import { useCreateReportFileModalController } from 'fragments/download-reports/fragments/create-report-file-modal/create-report-file-modal.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Modal, Select, Typography } from 'antd';
import dayjs from 'dayjs';
const { Option } = Select;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

export const CreateReportFileModalFragment: React.FC<CreateReportFileModalFragmentProps> = (props) => {
    const { useController = useCreateReportFileModalController } = props;
    const controller = useController();

    const { translate } = useTranslator();

    const optionCards = controller.siteCards.map((card) => (
        <Option key={card.id} value={card.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {card.label}
            </div>
        </Option>
    ));
    const optionUsers = controller.users.map((user) => (
        <Option key={user.user_id} value={user.user_id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {`${user.name} - ${user.email}`}
            </div>
        </Option>
    ));

    // Render
    return (
        <Modal
            open={controller.isCreateReportFileModalVisible}
            className={'create-report-file-modal'}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'access-reports.report-files.modal-title' })}
                </Title>
            }
            closable={false}
            centered
            footer={[
                <Button
                    key="back"
                    className={'btn-cancel'}
                    onClick={controller.onCancelButtonPressed}
                    disabled={controller.isLoading}
                >
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form={'create-report-file-modal'}
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isLoading}
                    htmlType="submit"
                    disabled={controller.disableSubmit && controller.isLoading}
                >
                    {translate({ key: 'access-reports.report-files.generate' })}
                </Button>,
            ]}
        >
            <Form
                id="create-report-file-modal"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                form={controller.form}
                onFinish={controller.onCreateButtonPressed}
            >
                <Col xs={24} id="create-report-file-picker">
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.date-range' })}
                            </Text>
                        }
                        required
                        rules={[
                            {
                                required: true,
                                message: translate({ key: 'form.rules-required' }),
                            },
                        ]}
                        name="date_picker"
                        style={{ width: '100%' }}
                        initialValue={
                            controller.dateRange.from && controller.dateRange.from
                                ? [
                                      dayjs(controller.dateRange.from, 'DD-MM-YYYY'),
                                      dayjs(controller.dateRange.to, 'DD-MM-YYYY'),
                                  ]
                                : undefined
                        }
                    >
                        <RangePicker
                            onChange={controller.onPickedDateRange}
                            style={{ width: '100%' }}
                            getPopupContainer={() =>
                                document.getElementById('create-report-file-range-picker') as HTMLElement
                            }
                            format={'DD-MM-YYYY'}
                            disabledDate={controller.disabledDate}
                            disabled={controller.isLoading}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.access-method' })}
                            </Text>
                        }
                        name="accessMethodCheck"
                        required
                        rules={[
                            {
                                required: !controller.selectedAccessMethodIds.length ? true : false,
                                message: translate({ key: 'form.select-an-option-error' }),
                            },
                        ]}
                    >
                        <>
                            <Checkbox
                                indeterminate={controller.indeterminate}
                                onChange={controller.onCheckAllAccessMethodOption}
                                checked={controller.checkAllAccessMethod}
                            >
                                Seleccionar todos
                            </Checkbox>
                            <Divider style={{ margin: '10px' }} />
                            <CheckboxGroup
                                options={controller.accessMethodOptions}
                                value={controller.selectedAccessMethodIds}
                                onChange={controller.onCheckAccessMethodOption}
                            />
                        </>
                    </Form.Item>
                    {!!controller.selectedAccessMethodIds.find((x) => x == 7) && (
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.cards' })}
                                </Text>
                            }
                            name="cards"
                            required
                            rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                            initialValue={controller.selectedCards}
                        >
                            <Select
                                showSearch
                                placeholder={translate({ key: 'form.placeholder.access-report.cards' })}
                                allowClear
                                mode="multiple"
                                loading={controller.isLoading}
                                onChange={controller.onCardSelect}
                                style={{ width: '100%' }}
                                filterOption={(inputValue, option) => {
                                    const opt = controller.siteCards.find((item) => item.id == option?.value);
                                    return !!opt?.label.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                            >
                                <Option key={'default'} value={'all'}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {translate({ key: 'label.all-cards' })}
                                    </div>
                                </Option>
                                {optionCards}
                            </Select>
                        </Form.Item>
                    )}
                    {!!controller.selectedAccessMethodIds.find((x) => x == 1) && (
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.user' })}
                                </Text>
                            }
                            name="users"
                            required
                            rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                            initialValue={controller.selectedUsers}
                        >
                            <Select
                                showSearch
                                placeholder={translate({ key: 'form.placeholder.access-report.users' })}
                                allowClear
                                mode="multiple"
                                loading={controller.isLoading}
                                onChange={controller.onUserSelect}
                                style={{ width: '100%' }}
                                filterOption={(inputValue, option) => {
                                    const opt = controller.users.find((item) => item.user_id == option?.value);
                                    return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                            >
                                <Option key={'default'} value={'all'}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {translate({ key: 'label.all-users' })}
                                    </div>
                                </Option>
                                {optionUsers}
                            </Select>
                        </Form.Item>
                    )}
                </Col>
            </Form>
        </Modal>
    );
};
