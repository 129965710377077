import { useEffect, useState } from 'react';
import {
    OperationActionPreviewListController,
    PreviewActionField,
    PreviewActionItemTableRow,
} from 'fragments/operations/fragments/operation-action-preview-list/interfaces';
import { useAPIOperations } from 'services/operations/api-operations.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useOperationContext } from 'fragments/operations/context/operations.context';
import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { SorterInfo } from 'global/interfaces';
import { OperationType, PreviewActionItem, PreviewActionItemError } from 'services/operations/operations.service';
import { useNavigate } from 'react-router-dom';
import { OperationActionType } from 'services/operation-actions/operation-actions.service';
import { ErrorDto } from 'services/dtos/errors..dto';
import { CsvAddRfidCardActionItem } from '../operation-import-modal/interfaces';

export const useOperationActionPreviewListController = (): /* <--Dependency Injections  like services hooks */
OperationActionPreviewListController => {
    const [session] = useLocalSession();
    const operationsService = useAPIOperations();
    const { selectedSite } = session;
    const messenger = useMessenger();

    /* State */

    const {
        getOperations,
        pageSize: opPageSize,
        operationType,
        operationActionType,
        setOperationType,
        setOperationActionType,
        createOperationName,
        setCreateOperationName,
        previewActionList,
        setPreviewActionList,
        isLoadingCheck,
        checkOperation,
        checkError,
    } = useOperationContext();
    const [previewActionListPaginated, setPreviewActionListPaginated] = useState<PreviewActionItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [invalidRows, setInvalidRows] = useState<number>(0);
    const [order, setOrder] = useState<undefined | 'ascend' | 'descend'>(undefined);
    const [orderBy, setOrderBy] = useState<keyof PreviewActionItem | undefined>(undefined);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<SorterInfo>>({
        order: undefined,
        columnKey: undefined,
    });

    const navigate = useNavigate();

    /* Listeners */
    useEffect(() => {
        let invalidRowsCount = 0;
        previewActionList.map((r) => {
            if (r.status_id != 1) {
                invalidRowsCount += 1;
            }
        });
        setInvalidRows(invalidRowsCount);
        handleTableChange({ pageSize, current: currentPage }, {}, sortedInfo);
    }, [previewActionList]);

    /* View Events */
    const onGenerateButtonPressed = () => {
        setIsLoading(true);
        const actions: CsvAddRfidCardActionItem[] = previewActionList.map((action): CsvAddRfidCardActionItem => {
            return {
                card_number: action.card_number,
                device_id: action.device_id,
                label: action.label,
                site_card_id: action.site_card_id,
            };
        });
        operationsService
            .createOperation({
                name: createOperationName,
                operation_action_type: operationActionType,
                operation_type: operationType,
                actions,
            })
            .then(() => {
                messenger.showSuccessMessage({ key: 'operations.creation-successfully' });
                onGoBackButtonPressed();
            })
            .catch((error: ErrorDto) => {
                const err = error.response.data;
                if (err.code == 409) {
                    console.log(error);
                }
                messenger.showErrorMessage({ key: 'operations.creation-error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onChangeOperationName = (value: string) => {
        setCreateOperationName(value);
    };
    const onRevalidateButtonPressed = () => {
        const actions: CsvAddRfidCardActionItem[] = previewActionList.map((action): CsvAddRfidCardActionItem => {
            return {
                card_number: action.card_number,
                device_id: action.device_id,
                label: action.label,
                site_card_id: action.site_card_id,
            };
        });
        checkOperation({
            name: createOperationName,
            operation_action_type: operationActionType,
            operation_type: operationType,
            actions,
        });
    };

    const onGoBackButtonPressed = () => {
        setPreviewActionList([]);
        setCreateOperationName('');
        setOperationType(OperationType.DEFAULT);
        setOperationActionType(OperationActionType.DEFAULT);
        getOperations({
            pagination: {
                page: 0,
                pageSize: opPageSize,
            },
        });
        navigate('/operations', { replace: true });
    };

    const onFieldChange = (id: string, key: string, value: string) => {
        const action = previewActionList.find((a) => a.id == id);
        if (action) {
            const errors = action.errors.map((e) => (e.column == key ? { ...e, color: '#FAAD14' } : e));
            const actionList = previewActionList.map((a) => (a.id == id ? { ...a, errors: errors, [key]: value } : a));
            setPreviewActionList(actionList);
        }
    };

    const onDeleteRow = (id: string) => {
        const filteredActions = previewActionList.filter((action) => action.id != id);
        const orderedActionList = sortTable(filteredActions, order, orderBy);
        let page = currentPage;
        if (filteredActions.length / pageSize == currentPage && currentPage != 0) {
            page = currentPage - 1;
        }
        setPreviewActionListPaginated(orderedActionList.slice(pageSize * page, pageSize * page + pageSize));
        setPreviewActionList(filteredActions);
        setCurrentPage(page);
    };

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setCurrentPage(page);
        setPageSize(currentPageSize);
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.column?.key,
        });
        const orderedActionList = sortTable(previewActionList, sorter.order, sorter.column?.key);
        setPreviewActionListPaginated(
            orderedActionList.slice(currentPageSize * page, currentPageSize * page + currentPageSize),
        );
    };
    /* Private Methods */
    const sortTable = (
        actionList: PreviewActionItem[],
        order?: 'ascend' | 'descend',
        key?: keyof PreviewActionItem,
    ): PreviewActionItem[] => {
        setOrder(order);
        setOrderBy(key);
        if (!order || !key) return actionList;
        return actionList.slice().sort((a, b) => {
            const valueA = a[key];
            const valueB = b[key];

            if (order === 'ascend') {
                if (valueA < valueB) return -1;
                if (valueA > valueB) return 1;
            } else if (order === 'descend') {
                if (valueA > valueB) return -1;
                if (valueA < valueB) return 1;
            }

            return 0;
        });
    };

    const getField = (key: string, value: string, errors: PreviewActionItemError[]): PreviewActionField => {
        const field: PreviewActionField = {
            key,
            value,
            editable: false,
            color: '#262626D9',
            message: '',
        };
        if (!errors.length) return field;

        errors.map((e) => {
            if (e.column == key) {
                field.editable = true;
                if (field.color != '#FAAD14') {
                    field.color = e.color || 'red';
                }
                field.message = field.message
                    ? `${field.message}\n ${e.message} Filas: ${e.related_rows}`
                    : `${field.message}\n ${e.message} Filas: ${e.related_rows}`;
            }
        });
        return field;
    };

    const mapDtoToTableRow = (dto: PreviewActionItem): PreviewActionItemTableRow => {
        return {
            id: dto.id,
            index: dto.index,
            label: getField('label', dto.label, dto.errors),
            device_name: dto.device_name,
            device_id: getField('device_id', dto.device_id, dto.errors),
            card_number: getField('card_number', dto.card_number, dto.errors),
            status_id: dto.status_id,
            errors: dto.errors,
        };
    };

    // Return state and events
    return {
        previewActionListPaginated: previewActionListPaginated.map(mapDtoToTableRow),
        totalOperationActions: previewActionList.length,
        currentPage,
        pageSize,
        sortedInfo,
        isLoading,
        invalidRows,
        isLoadingCheck,
        createOperationName,
        onChangeOperationName,
        checkError,
        onDeleteRow,
        onGoBackButtonPressed,
        onRevalidateButtonPressed,
        onGenerateButtonPressed,
        handleTableChange,
        onFieldChange,
    };
};
