import { useEffect, useState } from 'react';
import { CreateReportFileModalController } from 'fragments/download-reports/fragments/create-report-file-modal/interfaces';
import { useReportFilesContext } from 'fragments/download-reports/context/report-files.context';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPIAccessReports } from 'services/access-reports/api-access-reports.service';
import { useForm } from 'antd/es/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import dayjs, { Dayjs } from 'dayjs';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
    SiteCardListCreateReportFileFormResponse,
    UserListCreateReportFileFormResponse,
} from 'services/access-reports/access-reports.service';
import { CheckboxOption } from 'global/interfaces';
import { RangePickerProps } from 'antd/es/date-picker';

export const useCreateReportFileModalController = (
    accessReportService = useAPIAccessReports(),
    messenger = useMessenger(),
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
CreateReportFileModalController => {
    /* State */
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedCards, setSelectedCards] = useState<string[]>([]);
    const [users, setUsers] = useState<UserListCreateReportFileFormResponse[]>([]);
    const [siteCards, setSiteCards] = useState<SiteCardListCreateReportFileFormResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });

    const [selectedAccessMethodIds, setSelectedAccessMethodIds] = useState<number[]>([]);
    const [accessMethodOptions, setAccessMethodOptions] = useState<CheckboxOption[]>([]);
    const [checkAllAccessMethod, setCheckAllAccessMethod] = useState(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(true);
    const [showSelectCards, setShowSelectCards] = useState<boolean>(true);
    const [showSelectUsers, setShowSelectUsers] = useState<boolean>(true);

    const { getReportFiles, isCreateReportFileModalVisible, setIsCreateReportFileModalVisible } =
        useReportFilesContext();

    const [form] = useForm();

    const [session] = useLocalSession();
    const { selectedSite } = session;
    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            resetStates();
            setDefaultAccessMethodOptions();
            getUsers();
            getSiteCards();
        }

        setDisableSubmit(true);
        form.resetFields();
    }, [selectedSite]);

    /* View Events */
    const resetStates = () => {
        form.resetFields();
        form.setFieldsValue({
            date_picker: undefined,
            accessMethodCheck: [],
            cards: [],
            users: [],
        });
        setDisableSubmit(true);
        setDefaultAccessMethodOptions();
        setShowSelectCards(true);
        setShowSelectUsers(true);
        setSelectedUsers([]);
        setSelectedCards([]);
        setDateRange({ from: '', to: '' });
    };
    const onCreateButtonPressed = () => {
        setIsLoading(true);

        accessReportService
            .createReportFile({
                access_methods: selectedAccessMethodIds,
                site_cards: selectedCards.filter((c) => c != 'all'),
                users: selectedUsers.filter((u) => u != 'all'),
                since: dayjs(dateRange.from).format('YYYY-MM-DD'),
                until: dayjs(dateRange.to).format('YYYY-MM-DD'),
            })
            .then(() => {
                setIsCreateReportFileModalVisible(false);
                getReportFiles({ page: 0, pageSize: 20 });
                resetStates();
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.report-files.create-report-file-error' });
                console.log('get-users-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const onCancelButtonPressed = () => {
        resetStates();
        setIsCreateReportFileModalVisible(false);
    };
    const disabledDate: RangePickerProps['disabledDate'] = (current: Dayjs) => {
        return current && current > dayjs().endOf('day');
    };

    const onPickedDateRange = (date: any) => {
        date && setDateRange({ from: dayjs(date[0]).format('YYYY-MM-DD'), to: dayjs(date[1]).format('YYYY-MM-DD') });
    };
    const onUserSelect = (value: string[]) => {
        if (value.find((u) => u == 'all')) {
            setSelectedUsers(['all']);
            form.setFieldValue('users', ['all']);
        } else {
            setSelectedUsers(value.filter((u) => u != 'all'));
            form.setFieldValue(
                'users',
                value.filter((u) => u != 'all'),
            );
        }
    };
    const onCardSelect = (value: string[]) => {
        if (value.find((c) => c == 'all')) {
            setSelectedCards(['all']);
            form.setFieldValue('cards', ['all']);
        } else {
            setSelectedCards(value.filter((c) => c != 'all'));
            form.setFieldValue(
                'cards',
                value.filter((c) => c != 'all'),
            );
        }
    };

    const onCheckAccessMethodOption = (list: CheckboxValueType[]) => {
        setDisableSubmit(false);
        setSelectedAccessMethodIds(list as number[]);
        setIndeterminate(!!list.length && list.length < accessMethodOptions.length);
        setCheckAllAccessMethod(list.length === accessMethodOptions.length);
        if (list.find((key) => key === 1)) {
            setShowSelectUsers(true);
        } else {
            setShowSelectUsers(false);
            setSelectedUsers([]);
            form.setFieldsValue({ users: undefined });
        }
        if (list.find((key) => key === 7)) {
            setShowSelectCards(true);
        } else {
            setShowSelectCards(false);
            setSelectedCards([]);
            form.setFieldsValue({ cards: undefined });
        }
    };
    const onCheckAllAccessMethodOption = (e: CheckboxChangeEvent) => {
        setSelectedAccessMethodIds(e.target.checked ? accessMethodOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAllAccessMethod(e.target.checked);
        setShowSelectCards(e.target.checked);
        setShowSelectUsers(e.target.checked);
    };
    /* Private Methods */

    const getUsers = () => {
        setIsLoading(true);
        accessReportService
            .getUsersCreateReportFileForm()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.report-files.get-users-error' });
                console.log('get-users-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getSiteCards = () => {
        setIsLoading(true);
        accessReportService
            .getSiteCardsCreateReportFileForm()
            .then((data) => {
                setSiteCards(data);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.report-files.get-site-cards-error' });
                console.log('get-site-cards-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const defaultAccessMethodOptions: CheckboxOption[] = [
        { value: 1, label: translate({ key: 'access-reports.access-method-option.app' }) },
        { value: 4, label: translate({ key: 'access-reports.access-method-option.passcode' }) },
        { value: 7, label: translate({ key: 'access-reports.access-method-option.rfid' }) },
        { value: 8, label: translate({ key: 'access-reports.access-method-option.fingerprint' }) },
        { value: 12, label: translate({ key: 'access-reports.access-method-option.remote-access' }) },
        { value: 46, label: translate({ key: 'access-reports.access-method-option.button' }) },
    ];

    const setDefaultAccessMethodOptions = (): void => {
        const accessMethodIds: number[] = defaultAccessMethodOptions.map((item) => item.value);
        setAccessMethodOptions(defaultAccessMethodOptions);
        setSelectedAccessMethodIds(accessMethodIds);
        setCheckAllAccessMethod(true);
    };
    // Return state and events
    return {
        isCreateReportFileModalVisible,
        selectedUsers,
        selectedCards,
        isLoading,
        form,
        disableSubmit,
        dateRange,
        users,
        siteCards,
        selectedAccessMethodIds,
        accessMethodOptions,
        checkAllAccessMethod,
        indeterminate,
        showSelectCards,
        showSelectUsers,
        onCreateButtonPressed,
        onCancelButtonPressed,
        disabledDate,
        onPickedDateRange,
        onUserSelect,
        onCardSelect,
        onCheckAllAccessMethodOption,
        onCheckAccessMethodOption,
    };
};
