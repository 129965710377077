import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const AddKeySvg = () => (
    <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 768 768"
        stroke="currentColor"
        fill="currentColor"
    >
        <title>iconos_accefy_sga_add-key</title>
        <path d="M357.61,132.72C313.35,177,298.82,239.12,312.24,296L89.71,518.51h0a15.14,15.14,0,0,0-4.52,9.89l-5.36,101.8A15.16,15.16,0,0,0,96.74,646.1L230.7,630h0A15.15,15.15,0,0,0,244.09,615V565.72h49.23a15.15,15.15,0,0,0,15.07-15.07V501.42h49.23a15.14,15.14,0,0,0,10.72-4.36L441,424.4c56.77,13.28,118.92-1,163.09-45.21,67.94-67.93,67.94-178.54,0-246.47s-178.54-67.94-246.48,0ZM379,154.15a144,144,0,1,1,60.62,239.79h0a15.12,15.12,0,0,0-14.9,3.85l-73.34,73.33H293.15a15.16,15.16,0,0,0-15.07,15.07v49.23H228.85a15.16,15.16,0,0,0-15.07,15.07v50.9L111.14,613.62l4-77.69L339,312.05A15.13,15.13,0,0,0,342.71,297a143.93,143.93,0,0,1,36.34-142.82Zm96.45,21.44a60.62,60.62,0,1,0,85.73,0,60.85,60.85,0,0,0-85.73,0ZM496.92,197a30.31,30.31,0,1,1,0,42.86A30.08,30.08,0,0,1,496.92,197Z" />
        <path d="M577,447a17.11,17.11,0,0,0-17.11,17.11v95.78H464.11a17.11,17.11,0,0,0,0,34.22h95.78v95.78a17.11,17.11,0,0,0,34.22,0V594.11h95.78a17.11,17.11,0,0,0,0-34.22H594.11V464.11A17.11,17.11,0,0,0,577,447Z" />
    </svg>
);
const AddKeyIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={AddKeySvg} {...props} />;
export default AddKeyIcon;
