import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const FingerprintSvg = () => (
    <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 768 768"
        stroke="currentColor"
        fill="currentColor"
    >
        <title>iconos_accefy_sga_fingerprint</title>
        <path d="M107,433.79h1.9a15.85,15.85,0,0,0,10.68-6,16,16,0,0,0,3.21-11.89,266.71,266.71,0,0,1,54.82-195.53A263.64,263.64,0,0,1,646.76,353.39a16,16,0,1,0,31.71-3.76A297.87,297.87,0,0,0,567.85,151.07,294.33,294.33,0,0,0,152.79,200.7a298.77,298.77,0,0,0-61.3,219.12,15.89,15.89,0,0,0,15.47,14Z" />
        <path d="M329.91,676.33a15.81,15.81,0,0,0,21.24-7.15,509.21,509.21,0,0,0,49.33-286.37,16,16,0,1,0-31.7,3.76A477.19,477.19,0,0,1,322.55,655a16,16,0,0,0,7.36,21.38Z" />
        <path d="M581.13,597.53a15.88,15.88,0,0,0,18.65-12.77,723.38,723.38,0,0,0,8.94-227.09A227.92,227.92,0,0,0,566.23,250,15.78,15.78,0,0,0,544,246.77a16,16,0,0,0-3.17,22.33A195.83,195.83,0,0,1,577.45,362,690.75,690.75,0,0,1,568.89,579a16,16,0,0,0,12.24,18.57Z" />
        <path d="M151.66,563.9a15.82,15.82,0,0,0,20.68-8.75A299.35,299.35,0,0,0,192.18,407.7a196.06,196.06,0,0,1,33.31-134.3,193.17,193.17,0,0,1,249.94-61.15,15.8,15.8,0,0,0,21.43-6.63,16,16,0,0,0-6.59-21.57,224.74,224.74,0,0,0-291,71,228.08,228.08,0,0,0-38.74,156.36A267.17,267.17,0,0,1,142.78,543.1a16.07,16.07,0,0,0,.14,12.26,15.88,15.88,0,0,0,8.74,8.54Z" />
        <path d="M406.57,679.39a15.81,15.81,0,0,0,20.79-8.29,581.92,581.92,0,0,0,42.55-296.52A86.45,86.45,0,0,0,438,316.36,85.44,85.44,0,0,0,317,330.83a86.74,86.74,0,0,0-17.44,64.11,411.83,411.83,0,0,1,2.6,61.32,15.86,15.86,0,1,0,31.71,1,446.46,446.46,0,0,0-2.79-66.11,54.65,54.65,0,0,1,10.86-40.63,54,54,0,0,1,96.55,27.87,549.81,549.81,0,0,1-40.2,279.92,16.12,16.12,0,0,0-.21,12.28,15.92,15.92,0,0,0,8.52,8.78Z" />
        <path d="M261.61,655.09A15.81,15.81,0,0,0,283,648.71a440.06,440.06,0,0,0,36.78-94.57,15.87,15.87,0,1,0-30.69-8.1A406.74,406.74,0,0,1,255,633.71a16,16,0,0,0,6.6,21.38Z" />
        <path d="M507.9,370.44A15.87,15.87,0,0,0,523.56,384h2.09a15.93,15.93,0,0,0,13.7-17.73,157,157,0,0,0-71.2-113.77A155,155,0,0,0,335.55,236a15.91,15.91,0,0,0-9.16,8,16.07,16.07,0,0,0-.82,12.21A15.86,15.86,0,0,0,345.7,266.3a123.45,123.45,0,0,1,105.7,13.16A125,125,0,0,1,508,370.25Z" />
        <path d="M490.78,658.34a15.84,15.84,0,0,0,19.78-10.21,648.5,648.5,0,0,0,32.72-185,15.86,15.86,0,1,0-31.71-.95,616.61,616.61,0,0,1-31.06,176.11,16,16,0,0,0,10.27,20.1Z" />
        <path d="M202.33,617.89A15.81,15.81,0,0,0,223.7,611,367.94,367.94,0,0,0,261.75,399.4a125.57,125.57,0,0,1,19-82.63,16,16,0,0,0-5-21.49,15.8,15.8,0,0,0-21.55,4.13A157.71,157.71,0,0,0,230.11,403.1a335.94,335.94,0,0,1-34.63,193.22,16,16,0,0,0,6.85,21.57Z" />
    </svg>
);
const FingerprintIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={FingerprintSvg} {...props} />;
export default FingerprintIcon;
