export const capitalize = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertToASCII = (string: string): string => {
    const combine = /[\u0300-\u036F]/g;
    return string.normalize('NFKD').replace(combine, '');
};

export const arrayToString = (arr: string[], and: string): string => {
    if (!arr.length) return '-';
    return arr
        .map((ap, i) => {
            if (i === arr.length - 1) return ap;
            if (i === arr.length - 2) return ap + ' ' + and + ' ';
            return ap + ', ';
        })
        .join('');
};
