import React, { useState } from 'react';
import { CustomFieldsContext } from './custom-fields.context';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPICustomFields } from 'services/custom-fields/api-custom-fields.service';
import { CustomField, GetCustomFieldsResponse } from 'services/custom-fields/custom-fields.service';

type Props = {
    children?: React.ReactNode;
};

const CustomFieldsProvider: React.FC<Props> = (props) => {
    const { translate } = useTranslator();
    const messenger = useMessenger();

    const [isLoadingList, setIsLoadingList] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
    const [isAddFieldModalVisible, setIsAddFieldModalVisible] = useState<boolean>(false);
    const [isEditFieldModalVisible, setIsEditFieldModalVisible] = useState<boolean>(false);
    const [editFieldData, setEditFieldData] = useState<GetCustomFieldsResponse | undefined>(undefined);
    const [customFieldsList, setCustomFieldsList] = useState<CustomField[]>([]);

    // Functions
    const customFieldsService = useAPICustomFields();

    /*public methods*/
    const getCustomFieldsList = () => {
        setIsLoadingList(true);
        customFieldsService
            .listCustomFields()
            .then((response: CustomField[]) => {
                setCustomFieldsList(response);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: translate({ key: 'custom-fields.error-list' }) });
            })
            .finally(() => {
                setIsLoadingList(false);
            });
    };

    const getCustomField = (id: string) => {
        setIsLoadingDetail(true);
        customFieldsService
            .getCustomField(id)
            .then((response: GetCustomFieldsResponse) => {
                setEditFieldData(response);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: translate({ key: 'custom-fields.error-detail' }) });
            })
            .finally(() => {
                setIsLoadingDetail(false);
            });
    };

    return (
        <CustomFieldsContext.Provider
            value={{
                isLoadingList,
                setIsLoadingList,
                isAddFieldModalVisible,
                setIsAddFieldModalVisible,
                customFieldsList,
                setCustomFieldsList,
                getCustomFieldsList,
                getCustomField,
                editFieldData,
                setEditFieldData,
                isLoadingDetail,
                setIsLoadingDetail,
                isEditFieldModalVisible,
                setIsEditFieldModalVisible,
            }}
        >
            {props.children}
        </CustomFieldsContext.Provider>
    );
};

export default CustomFieldsProvider;
