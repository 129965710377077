import { useEffect, useState } from 'react';
import { DownloadReportsController } from 'fragments/download-reports/interfaces';
import { hardcodeSiteId } from 'services/access-reports/api-access-reports.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useAPIMain } from 'services/main/api-main.service';
import { useNavigate } from 'react-router';
import { ROLES } from 'services/main/main.service';

export const useDownloadReportsController = (): /* <--Dependency Injections  like services hooks */
DownloadReportsController => {
    /* State */

    const [toggleDownloadView, setToggleDownloadView] = useState<boolean>(false);

    const [session] = useLocalSession();
    const { selectedSite } = session;

    const mainService = useAPIMain();
    const navigate = useNavigate();

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            mainService.checkRole().then((res) => {
                if (
                    res.role != ROLES.ROLE_SITE_ADMIN &&
                    res.role != ROLES.ROLE_SUPERADMIN &&
                    res.role != ROLES.ROLE_FULL_ADMIN
                ) {
                    navigate('/locks');
                }
            });
            if (!!hardcodeSiteId.find((id) => id === session.selectedSite?.id)) {
                setToggleDownloadView(true);
            } else {
                setToggleDownloadView(false);
            }
        }
    }, [selectedSite]);

    /* View Events */

    /* Private Methods */

    // Return state and events
    return {
        toggleDownloadView,
    };
};
