import React from 'react';
import 'fragments/operations/fragments/operation-list/operation-list.scss';
import {
    OperationListFragmentProps,
    OperationTableRow,
} from 'fragments/operations/fragments/operation-list/interfaces';
import { useOperationListController } from 'fragments/operations/fragments/operation-list/operation-list.controller';
import { Button, Col, Row, Table, Tag, Tooltip, Typography, Modal, Space } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined,
    SyncOutlined,
    LoadingOutlined,
    BorderOutlined,
    StopOutlined,
    PlayCircleOutlined,
    ReloadOutlined,
    UnorderedListOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { OperationImportModalFragment } from '../operation-import-modal/operation-import-modal.fragment';

const { Text } = Typography;
const { confirm } = Modal;

export const OperationListFragment: React.FC<OperationListFragmentProps> = (props) => {
    const { useController = useOperationListController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const showConfirmCommandModal = (id: string, statusId: number) => {
        confirm({
            title: <Text strong>{translate({ key: 'operations.command-modal-title' })}</Text>,
            icon: <InfoCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'button primary' },
            okText: translate({ key: 'general.execute' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onCommandButtonPressed(id, statusId);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };

    const getStatusTag = (status: number) => {
        switch (status) {
            case 1:
                return (
                    <Tag color="default" style={{ fontWeight: 'bold' }} icon={<ClockCircleOutlined />}>
                        {translate({ key: `operations.status.${status}` })}
                    </Tag>
                );
            case 2:
                return (
                    <Tag color="processing" style={{ fontWeight: 'bold' }} icon={<SyncOutlined spin />}>
                        {translate({ key: `operations.status.${status}` })}
                    </Tag>
                );
            case 3:
                return (
                    <Tag color="warning" style={{ fontWeight: 'bold' }} icon={<LoadingOutlined />}>
                        {translate({ key: `operations.status.${status}` })}
                    </Tag>
                );
            case 4:
                return (
                    <Tag color="orange" style={{ fontWeight: 'bold' }} icon={<BorderOutlined />}>
                        {translate({ key: `operations.status.${status}` })}
                    </Tag>
                );
            case 5:
                return (
                    <Tag color="success" style={{ fontWeight: 'bold' }} icon={<CheckCircleOutlined />}>
                        {translate({ key: `operations.status.${status}` })}
                    </Tag>
                );
            case 6:
                return (
                    <Tag color="error" style={{ fontWeight: 'bold' }} icon={<CloseCircleOutlined />}>
                        {translate({ key: `operations.status.${status}` })}
                    </Tag>
                );
            default:
                return <></>;
        }
    };
    // Render
    return (
        <div className={'operation-list'}>
            {controller.eventError && (
                <div
                    style={{
                        backgroundColor: '#fff',
                        marginBottom: '1.5rem',
                        padding: '1rem',
                        width: '100%',
                        gap: '10px',
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: '#ff000030',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <Text strong style={{ color: 'red' }}>
                                {translate({ key: 'general.error' })}:{' '}
                            </Text>
                            <Text strong style={{ color: 'red' }}>
                                {translate({ key: 'operations.reload-message' })}
                            </Text>
                        </div>
                        <Button
                            className=" primary"
                            type="primary"
                            style={{ padding: '3px', fontWeight: '600' }}
                            onClick={controller.onReloadButtonPressed}
                        >
                            {translate({ key: 'general.reload' })}
                        </Button>
                    </div>
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '10px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <OperationImportModalFragment />
                </div>
                <Space>
                    <Tooltip title={translate({ key: 'general.add' })}>
                        <Button
                            className="button"
                            type="default"
                            style={{ padding: '3px' }}
                            onClick={controller.onAddNewOperationButtonPressed}
                            icon={<PlusOutlined />}
                        />
                    </Tooltip>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        key: 'name',
                        title: translate({ key: 'label.name' }),
                        dataIndex: 'name',
                        sorter: !!controller.totalOperations,
                        sortOrder: controller.sortedInfo.columnKey === 'name' ? controller.sortedInfo.order : null,
                        render: (name) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {name}
                            </div>
                        ),
                    },
                    {
                        key: 'createdByEmail',
                        title: translate({ key: 'label.email' }),
                        dataIndex: 'createdByEmail',
                        sorter: !!controller.totalOperations,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'createdByEmail' ? controller.sortedInfo.order : null,
                        render: (createdByEmail) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {createdByEmail}
                            </div>
                        ),
                    },
                    {
                        key: 'createdAt',
                        title: translate({ key: 'label.created_at' }),
                        dataIndex: 'createdAt',
                        sorter: !!controller.totalOperations,
                        sortOrder: controller.sortedInfo.columnKey === 'createdAt' ? controller.sortedInfo.order : null,
                        render: (createdAt) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '150px' }}>
                                {createdAt}
                            </div>
                        ),
                    },
                    {
                        key: 'type',
                        title: translate({ key: 'label.type' }),
                        dataIndex: 'type',
                        // sorter: !!controller.totalOperations,
                        // sortOrder: controller.sortedInfo.columnKey === 'type' ? controller.sortedInfo.order : null,
                        render: (type) => <div>{type}</div>,
                    },
                    {
                        key: 'status',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'statusId',
                        sorter: !!controller.totalOperations,
                        sortOrder: controller.sortedInfo.columnKey === 'status' ? controller.sortedInfo.order : null,
                        render: (status) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{getStatusTag(status)}</div>
                        ),
                    },
                    {
                        key: 'count',
                        title: translate({ key: 'label.count' }),
                        dataIndex: 'count',
                        render: (count) => (
                            <div
                                style={{ display: 'flex', alignItems: 'center', fontWeight: '600', maxWidth: '100px' }}
                            >
                                {`${count.finished}/${count.total}`}
                            </div>
                        ),
                    },
                    {
                        key: 'actions',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id, statusId }) => (
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
                                <Tooltip
                                    placement="topLeft"
                                    title={translate({ key: 'operations.tooltips.view-actions' })}
                                >
                                    <Button
                                        disabled={controller.isLoadingOperationCommand}
                                        icon={<UnorderedListOutlined />}
                                        onClick={() => controller.onViewOperationActions(id)}
                                    />
                                </Tooltip>
                                {statusId == 2 && (
                                    <Tooltip placement="topLeft" title={translate({ key: 'operations.tooltips.stop' })}>
                                        <Button
                                            disabled={controller.isLoadingOperationCommand}
                                            danger
                                            icon={<StopOutlined />}
                                            onClick={() => showConfirmCommandModal(id, statusId)}
                                        />
                                    </Tooltip>
                                )}
                                {statusId == 4 && (
                                    <Tooltip
                                        placement="topLeft"
                                        title={translate({ key: 'operations.tooltips.resume' })}
                                    >
                                        <Button
                                            disabled={controller.isLoadingOperationCommand}
                                            icon={<PlayCircleOutlined />}
                                            onClick={() => showConfirmCommandModal(id, statusId)}
                                        />
                                    </Tooltip>
                                )}
                                {statusId == 1 && (
                                    <Tooltip
                                        placement="topLeft"
                                        title={translate({ key: 'operations.tooltips.start' })}
                                    >
                                        <Button
                                            disabled={controller.isLoadingOperationCommand}
                                            icon={<PlayCircleOutlined />}
                                            onClick={() => showConfirmCommandModal(id, statusId)}
                                        />
                                    </Tooltip>
                                )}
                                {statusId == 6 && (
                                    <Tooltip
                                        placement="topLeft"
                                        title={translate({ key: 'operations.tooltips.retry' })}
                                    >
                                        <Button
                                            disabled={controller.isLoadingOperationCommand}
                                            icon={<ReloadOutlined />}
                                            onClick={() => showConfirmCommandModal(id, statusId)}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModelTable}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalOperations,
                    pageSizeOptions: ['5', '10', '20'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isTableLoading}
                rowKey={(itemsViewModelTable: OperationTableRow) => itemsViewModelTable.id}
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <div>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.updated_by_email' })}: </Text>
                                        <Text>{record.updatedByEmail}</Text>
                                    </Col>

                                    <Col>
                                        <Text strong>{translate({ key: 'label.update-date' })}:</Text>
                                        <Text> {record.updatedAt}</Text>
                                    </Col>
                                </Row>
                            </div>
                        );
                    },
                }}
            />
        </div>
    );
};
