import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const KeyBtSvg = () => (
    <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 768 768"
        stroke="currentColor"
        fill="currentColor"
    >
        <defs>
            <style>{'.KeyBtSvg1{stroke-miterlimit:10;stroke-width:15px;}'}</style>
        </defs>
        <title>iconos_accefy_sga</title>
        <path
            className="KeyBtSvg1"
            d="M602.71,695.5a18.8,18.8,0,0,1-8.08-1.8c-4.62-2.18-12.45-8.21-12.45-24.38V586.55L513,649.94a9.63,9.63,0,0,1-13-14.24l82.13-75.33V554.6l-82.13-75.33a9.58,9.58,0,0,1-.64-13.61A9.7,9.7,0,0,1,513,465l69.17,63.4V445.65c0-16.17,7.83-22.33,12.45-24.39s14.24-4.23,26.69,6.16l49.4,41.2a23.38,23.38,0,0,1,.78,35.8l-57.75,53,57.75,53a24.33,24.33,0,0,1,8,18.09,23.72,23.72,0,0,1-8.73,17.71l-49.4,41.2c-7.32,6.28-13.73,8.08-18.61,8.08Zm-1.28-123.2v97c0,4.75,1,6.8,1.54,7.06a19.93,19.93,0,0,0,6-3.6l49.41-41.19a4.65,4.65,0,0,0,1.79-3.47,4.49,4.49,0,0,0-1.66-3.46Zm1.15-133.46c-.13.13-1.15,2.31-1.15,6.93v97l57.11-52.36A4.5,4.5,0,0,0,660.2,487a4.65,4.65,0,0,0-1.79-3.46l-49.54-41.2c-3.33-2.82-5.51-3.46-6.28-3.46Z"
        />
        <path d="M366.35,123.46C322.1,167.72,307.56,229.85,321,286.72L98.45,509.24h0a15.11,15.11,0,0,0-4.52,9.88L88.57,620.93a15.16,15.16,0,0,0,16.91,15.9l134-16.07h0a15.15,15.15,0,0,0,13.39-15.07V556.46h49.23a15.14,15.14,0,0,0,15.07-15.07V492.16h49.23a15.18,15.18,0,0,0,10.72-4.35l72.67-72.67c56.78,13.28,118.92-1,163.09-45.21,67.94-67.93,67.94-178.54,0-246.48s-178.54-67.94-246.47,0Zm21.43,21.43A144,144,0,1,1,448.4,384.67h0a15.15,15.15,0,0,0-14.9,3.84l-73.34,73.34H301.89a15.16,15.16,0,0,0-15.07,15.07v49.24H237.59a15.16,15.16,0,0,0-15.07,15.07v50.91L119.88,604.36l4-77.69L347.78,302.79a15.13,15.13,0,0,0,3.67-15.07,144,144,0,0,1,36.34-142.83Zm96.45,21.44a60.62,60.62,0,1,0,85.74,0,60.86,60.86,0,0,0-85.74,0Zm21.43,21.43a30.31,30.31,0,1,1,0,42.86A30.08,30.08,0,0,1,505.66,187.76Z" />
    </svg>
);
const KeyBtIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={KeyBtSvg} {...props} />;
export default KeyBtIcon;
