import React from 'react';
import 'fragments/site-cards-dashboard/fragments/delete-site-card-progress-modal/delete-site-card-progress-modal.scss';
import {
    DeleteSiteCardProgressModalFragmentProps,
    DeletionCardRow,
} from 'fragments/site-cards-dashboard/fragments/delete-site-card-progress-modal/interfaces';
import { useDeleteSiteCardProgressModalController } from 'fragments/site-cards-dashboard/fragments/delete-site-card-progress-modal/delete-site-card-progress-modal.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    LoadingOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
    CheckCircleOutlined,
    QuestionCircleOutlined,
    ExclamationCircleOutlined,
    WarningOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, Table, Tooltip, Typography, Spin, Divider, Tag } from 'antd';
const { Title, Text } = Typography;
const { info } = Modal;

export const DeleteSiteCardProgressModalFragment: React.FC<DeleteSiteCardProgressModalFragmentProps> = (props) => {
    const { useController = useDeleteSiteCardProgressModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <Modal
            open={controller.isVisibleDeleteCardProgressModal}
            destroyOnClose={true}
            width={800}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Title level={4} style={{ padding: 0 }}>
                        {`${translate({ key: 'rfid-card.label.deleting' })} ${translate({
                            key: 'label.card-number',
                        })}: ${controller.deleteCard?.cardNumber}`}
                    </Title>
                    {controller.isDeleting && <LoadingOutlined style={{ color: 'blue' }} />}
                    {controller.success != undefined && controller.rows.length > 0 && !controller.isDeleting && (
                        <div>
                            <Tag color={controller.success ? 'success' : 'error'} style={{ fontWeight: 'bold' }}>
                                {translate({ key: `general.${controller.success ? 'success' : 'failed'}` })}
                            </Tag>
                        </div>
                    )}
                </div>
            }
            className={'delete-site-card-progress-modal'}
            closable={false}
            centered
            footer={
                <footer className={'delete-site-card-progress-modal-footer'}>
                    <Button
                        key="close"
                        type={'default'}
                        disabled={controller.isLoadingDelete}
                        onClick={controller.onCloseModalPressed}
                    >
                        {translate({ key: 'general.close' })}
                    </Button>
                    <div className={'delete-site-card-progress-modal-footer-retry'}>
                        {controller.statusCount.failed + controller.statusCount.warning > 0 ? (
                            <Space>
                                <Button
                                    type={'primary'}
                                    disabled={!controller.enableRetry}
                                    onClick={controller.onTryAgainPressed}
                                    loading={controller.isLoadingDelete}
                                >
                                    {`${translate({ key: 'general.try-again' })} (${
                                        controller.statusCount.failed + controller.statusCount.warning
                                    })`}
                                </Button>
                            </Space>
                        ) : null}
                    </div>
                </footer>
            }
        >
            {controller.rows.length == 0 ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '12rem',
                    }}
                >
                    {controller.isDeleting ? (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: '4rem' }} spin />} />
                    ) : (
                        <>
                            {controller.success === false && (
                                <CloseCircleOutlined
                                    style={{ color: 'red', fontSize: '5rem', marginBottom: '1.5rem' }}
                                />
                            )}
                            {controller.success === false && (
                                <Title level={3} style={{ color: 'red' }}>
                                    {translate({ key: 'rfid-card.delete-failed' })}
                                </Title>
                            )}

                            {controller.success === true && (
                                <CheckCircleOutlined
                                    style={{ color: 'green', fontSize: '5rem', marginBottom: '1.5rem' }}
                                />
                            )}
                            {controller.success === true && (
                                <Title level={3} style={{ color: 'green' }}>
                                    {translate({ key: 'rfid-card.delete-success' })}
                                </Title>
                            )}

                            {controller.success !== undefined && controller.eventError && (
                                <WarningOutlined
                                    style={{ color: '#faad14', fontSize: '5rem', marginBottom: '1.5rem' }}
                                />
                            )}
                            {controller.success !== undefined && controller.eventError && (
                                <Title level={3} style={{ color: '#faad14' }}>
                                    {translate({ key: 'rfid-card.unhandled-error' })}
                                </Title>
                            )}
                        </>
                    )}
                </div>
            ) : controller.success !== undefined && !controller.isDeleting && controller.eventError ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '12rem',
                    }}
                >
                    <WarningOutlined style={{ color: '#faad14', fontSize: '5rem', marginBottom: '1.5rem' }} />

                    <Title level={3} style={{ color: '#faad14' }}>
                        {translate({ key: 'rfid-card.unhandled-error' })}
                    </Title>
                </div>
            ) : (
                <>
                    <div>
                        {controller.rows.filter((r: DeletionCardRow) => r.status_id == 5).length > 0 && (
                            <div
                                style={{
                                    marginBottom: '1.5rem',
                                    width: '100%',
                                    backgroundColor: '#faad1430',
                                    padding: '10px',
                                }}
                            >
                                <Text strong>{translate({ key: 'general.notes' })}: </Text>
                                <Text strong>
                                    {translate({ key: 'rfid-card.errors.delete-warning-1' })}
                                    <WarningOutlined
                                        style={{ color: '#faad14', padding: '0 .2rem', fontSize: '1rem' }}
                                    />
                                    {translate({ key: 'rfid-card.errors.delete-warning-2' })}
                                </Text>
                            </div>
                        )}

                        <Row justify="center">
                            {controller.statusCount.unknown > 0 && (
                                <Col
                                    span={controller.statusCount.unknown > 0 ? 6 : 8}
                                    className={'delete-site-card-progress-modal-scrollable-result-count'}
                                >
                                    <Text strong type="secondary">
                                        {controller.statusCount.unknown}/{controller.rows.length}
                                    </Text>
                                    <Text type="secondary">{translate({ key: 'general.unknown' })}s</Text>
                                </Col>
                            )}
                            <Col
                                span={controller.statusCount.unknown > 0 ? 6 : 8}
                                className={'delete-site-card-progress-modal-scrollable-result-count'}
                            >
                                <Text strong type="success">
                                    {controller.statusCount.success}/{controller.rows.length}
                                </Text>
                                <Text type="success">{translate({ key: 'rfid-card.results.success' })}</Text>
                            </Col>
                            <Col
                                span={controller.statusCount.unknown > 0 ? 6 : 8}
                                className={'delete-site-card-progress-modal-scrollable-result-count'}
                            >
                                <Text strong type="warning">
                                    {controller.statusCount.warning}/{controller.rows.length}
                                </Text>
                                <Text type="warning">{translate({ key: 'rfid-card.results.warning' })}</Text>
                            </Col>
                            <Col
                                span={controller.statusCount.unknown > 0 ? 6 : 8}
                                className={'delete-site-card-progress-modal-scrollable-result-count'}
                                color="red"
                            >
                                <Text strong type="danger">
                                    {controller.statusCount.failed}/{controller.rows.length}
                                </Text>
                                <Text type="danger">{translate({ key: 'rfid-card.results.error' })}</Text>
                            </Col>
                        </Row>
                    </div>
                    <div className={'delete-site-card-progress-modal-scrollable'}>
                        <Table
                            loading={controller.isLoadingDelete}
                            columns={[
                                {
                                    key: 'device_name',
                                    title: translate({ key: 'label.lock' }),
                                    dataIndex: 'device_name',
                                    render: (device_name, { status_id }) => {
                                        return (
                                            <div style={{}}>
                                                {status_id == 5 && <WarningOutlined style={{ color: '#faad14' }} />}
                                                {device_name}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    key: 'status_id',
                                    title: translate({ key: 'label.state' }),
                                    dataIndex: 'status_id',
                                    render: (status_id, { message }) => {
                                        switch (status_id) {
                                            case 0:
                                                return (
                                                    <Tooltip title={translate({ key: 'rfid-card.tooltip.stand-by' })}>
                                                        <ClockCircleOutlined style={{ color: '#262626' }} />
                                                    </Tooltip>
                                                );
                                            case 1:
                                                return (
                                                    <Tooltip title={translate({ key: 'rfid-card.tooltip.loading' })}>
                                                        <LoadingOutlined style={{ color: 'blue' }} />
                                                    </Tooltip>
                                                );
                                            case 2:
                                                return (
                                                    <Tooltip
                                                        title={translate({
                                                            key: 'rfid-card.tooltip.delete-success',
                                                        })}
                                                    >
                                                        <CheckCircleOutlined style={{ color: 'green' }} />
                                                    </Tooltip>
                                                );
                                            case 3:
                                                return (
                                                    <Tooltip
                                                        title={translate({ key: 'rfid-card.tooltip.delete-error' })}
                                                    >
                                                        <CloseCircleOutlined style={{ color: 'red' }} />
                                                    </Tooltip>
                                                );
                                            case 4:
                                                return (
                                                    <Tooltip
                                                        title={
                                                            message ||
                                                            translate({ key: 'rfid-card.tooltip.delete-error' })
                                                        }
                                                    >
                                                        <ExclamationCircleOutlined style={{ color: '#faad14' }} />
                                                    </Tooltip>
                                                );
                                            case 5:
                                                return (
                                                    <Tooltip
                                                        title={
                                                            message ||
                                                            translate({ key: 'rfid-card.tooltip.missing-id' })
                                                        }
                                                    >
                                                        <CloseCircleOutlined style={{ color: 'red' }} />
                                                    </Tooltip>
                                                );

                                            default:
                                                return (
                                                    <Tooltip title={translate({ key: 'rfid-card.tooltip.unknown' })}>
                                                        <QuestionCircleOutlined style={{ color: '#666' }} />
                                                    </Tooltip>
                                                );
                                        }
                                    },
                                },
                            ]}
                            pagination={false}
                            dataSource={controller.rows}
                            rowKey={(itemsViewModelTable: DeletionCardRow) => itemsViewModelTable.sub_event_stream_id}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
};
