import { useEffect, useState } from 'react';
import {
    EditCustomFieldModalController,
    FieldTypeOption,
} from 'fragments/custom-fields-dashboard/fragments/edit-custom-field-modal/interfaces';
import { useCustomFieldsContext } from 'fragments/custom-fields-dashboard/context/custom-fields.context';
import { useAPICustomFields } from 'services/custom-fields/api-custom-fields.service';
import { useForm } from 'antd/es/form/Form';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CustomFieldValue, EditCustomFieldsRequest } from 'services/custom-fields/custom-fields.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { MainError } from 'services/dtos/errors..dto';

export const useEditCustomFieldModalController = (): /* <--Dependency Injections  like services hooks */
EditCustomFieldModalController => {
    const customFieldsService = useAPICustomFields();
    const [form] = useForm();
    const { translate } = useTranslator();
    const messenger = useMessenger();

    const fieldTypeOptions: FieldTypeOption[] = [
        {
            id: 1,
            value: translate({ key: `custom-fields.type.1` }),
        },
        {
            id: 2,
            value: translate({ key: `custom-fields.type.2` }),
        },
        {
            id: 3,
            value: translate({ key: `custom-fields.type.3` }),
        },
        {
            id: 4,
            value: translate({ key: `custom-fields.type.4` }),
        },
    ];

    /* State */
    const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
    const [defaultValuesModified, setDefaultValuesModified] = useState<boolean>(false);
    const [fieldName, setFieldName] = useState<string>('');
    const [fieldNameError, setFieldNameError] = useState<boolean>(false);
    const [defaultValue, setDefaultValue] = useState<string>('');
    const [optionalChecked, setOptionalChecked] = useState<boolean>(false);
    const [selectedFieldType, setSelectedFieldType] = useState<number>(0);
    const [defaultValues, setDefaultValues] = useState<CustomFieldValue[]>([]);
    const [newDefaultValues, setNewDefaultValues] = useState<string[]>([]);

    const {
        isEditFieldModalVisible,
        setIsEditFieldModalVisible,
        editFieldData,
        setEditFieldData,
        isLoadingDetail,
        getCustomFieldsList,
        setIsLoadingDetail,
    } = useCustomFieldsContext();
    /* Listeners */

    useEffect(() => {
        if (editFieldData) {
            form.setFieldValue('name', editFieldData.name);
            form.setFieldValue('optional', editFieldData.optional);
            form.setFieldValue('field_type', editFieldData.type_id);
            setFieldName(editFieldData.name);
            setOptionalChecked(editFieldData.optional);
            setSelectedFieldType(editFieldData.type_id);
            setDefaultValues(editFieldData.values || []);
        }
    }, [editFieldData]);

    /* View Events */

    const resetFields = () => {
        setFieldName('');
        setOptionalChecked(false);
        setSelectedFieldType(0);
        setDefaultValues([]);
        setNewDefaultValues([]);
        setDefaultValue('');
        setEditFieldData(undefined);
        setFieldNameError(false);
        form.resetFields();
    };
    const onCancelButtonPressed = () => {
        resetFields();
        setIsEditFieldModalVisible(false);
    };
    const onSaveChangesButtonPressed = () => {
        const input: EditCustomFieldsRequest = {
            name: fieldName.trim(),
            optional: optionalChecked,
            type_id: selectedFieldType,
            new_default_values: newDefaultValues,
            default_values: defaultValues,
        };

        setIsLoadingEdit(true);
        customFieldsService
            .editCustomFields(editFieldData?.id || '', input)
            .then(() => {
                setIsEditFieldModalVisible(false);
                resetFields();
                form.resetFields();
                getCustomFieldsList();
            })
            .catch((err: MainError) => {
                if (err.code == 409) {
                    switch (err.msg) {
                        case 'locked':
                            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.locked-error' }) });
                            break;
                        case 'name':
                            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.name-error' }) });
                            setFieldNameError(true);
                            break;
                        default:
                            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.edit-error' }) });
                            break;
                    }
                } else {
                    messenger.showErrorMessage({ key: translate({ key: 'custom-fields.edit-error' }) });
                }
            })
            .finally(() => {
                setIsLoadingEdit(false);
            });
    };
    const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldName(e.target.value);
        setFieldNameError(false);
    };

    const onChangeOptionalSwitch = (value: boolean) => {
        setOptionalChecked(value);
    };

    const onSelectFieldTypeOption = (value: number) => {
        setDefaultValues([]);
        setNewDefaultValues([]);
        setSelectedFieldType(value);
    };

    const onAddDefaultValue = () => {
        const foundValue = newDefaultValues.find((v) => v == defaultValue);
        const foundOldValue = defaultValues.find((v) => v.value == defaultValue);
        if (!foundValue && !foundOldValue) {
            setNewDefaultValues([...newDefaultValues, defaultValue]);
        } else {
            messenger.showErrorMessage({ key: translate({ key: 'custom-fields.add-default-value-error' }) });
        }

        setDefaultValue('');
        form.resetFields(['new-default-values']);
    };

    const onChangeDefaultValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDefaultValue(e.target.value);
    };

    const onRemoveDefaultValue = (value: string) => {
        const filteredValues = newDefaultValues.filter((v) => v != value);
        setNewDefaultValues(filteredValues);
    };

    const editDefaultValue = (id: string, value: string) => {
        const foundValue = newDefaultValues.find((v) => v == value);
        const foundOldValue = defaultValues.find((v) => v.value == value);
        if (!value) {
            const filteredValues = defaultValues.filter((v) => v.id != id);
            setDefaultValues(filteredValues);
        } else {
            if (!foundValue && !foundOldValue) {
                const newValues = defaultValues.map((v) => (v.id == id ? { ...v, value } : v));
                setDefaultValues(newValues);
                setDefaultValuesModified(true);
            } else {
                messenger.showErrorMessage({ key: translate({ key: 'custom-fields.add-default-value-error' }) });
            }
        }
    };

    /*public methods*/

    // Return state and events
    return {
        isLoadingDetail,
        editFieldData,
        isEditFieldModalVisible,
        isLoadingEdit,
        form,
        fieldName,
        fieldNameError,
        optionalChecked,
        selectedFieldType,
        fieldTypeOptions,
        defaultValue,
        defaultValues,
        newDefaultValues,
        defaultValuesModified,
        onCancelButtonPressed,
        onSaveChangesButtonPressed,
        onNameInputChange,
        onChangeOptionalSwitch,
        onSelectFieldTypeOption,
        onAddDefaultValue,
        onChangeDefaultValue,
        onRemoveDefaultValue,
        editDefaultValue,
    };
};
